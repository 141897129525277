import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { compose, withProps, lifecycle } from 'recompose'
import reviewQueueActions from 'caseReport/stores/reviewQueue.actions'
import QueueMetricsContainer from './QueueMetricsContainer'

const enhance = compose(
  inject('reviewQueueStore'),
  observer,
  withProps(({ reviewQueueStore }) => {
    return {
      queueMetrics: toJS(reviewQueueStore.metrics),
      queueConfig: toJS(reviewQueueStore.config),
      actions: {
        reviewQueue: reviewQueueActions(reviewQueueStore),
      },
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.actions.reviewQueue.initQueueMetrics()
    },

    componentWillUnmount() {
      this.props.actions.reviewQueue.stopPolling()
    },
  })
)
export default enhance(QueueMetricsContainer)
