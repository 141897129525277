import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Icon } from 'antd'
import { MESSAGE_TYPES as MSG } from '../../Constants'
import './AppMessages.less'

@inject('appStore')
@observer
class AppMessages extends Component {
  hideMessage = () => {
    const { appStore } = this.props
    appStore.resetMessageState()
  }

  render() {
    const { appStore } = this.props
    const messageClass = appStore.messages.type === MSG.ERROR ? 'bad' : 'good'

    return (
      <div className={`app-flash-wrap app-flash-${messageClass} flash-visible`}>
        <div>
          {/* eslint-disable-next-line */}
          <a onClick={this.hideMessage}>
            <Icon type="close" />
          </a>
          <p
            style={{
              textAlign: 'left',
            }}
          >
            {appStore.messages.message}
          </p>
        </div>
      </div>
    )
  }
}

export default AppMessages
