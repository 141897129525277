import { FC } from 'react'
import { Menu } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import './Menu.less'
import { MenuProps } from 'antd/lib/menu'

const CasePageMenu: FC<MenuProps> = ({ className, children, ...props }) => (
  <Menu
    className={joinClassNames(['CasePageMenu', className])}
    mode="horizontal"
    theme="dark"
    {...props}
  >
    {children}
  </Menu>
)

export default CasePageMenu
