import React, { FC } from 'react'
import './CollapseButton.less'

type Props = {
  count: number
  collapsed: boolean
  onClick: () => void
}

const CollapseButton: FC<Props> = ({ count, collapsed, onClick }) => {
  if (count < 1) {
    return null
  }

  return (
    <div className="CollapseButton">
      {/* eslint-disable-next-line */}
      <span className="CollapseButton--btn" onClick={onClick}>
        {collapsed ? `${count} more...` : 'Show less'}
      </span>
    </div>
  )
}

export default CollapseButton
