export const LOOKBACK_LIMIT = {
  MAX_VALUE: 4,
  MAX_VALUE_UNIT: 'years',
  DEFAULT_VALUE: 1,
  DEFAULT_VALUE_UNIT: 'year',
}
export const SCORE_THRESHOLDS = {
  HIGH: 500,
  MEDIUM: 299,
}
export enum GUARANTEE_DISPOSITION_ACTIONS {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  ESCALATE = 'ESCALATE',
  REVIEW = 'REVIEW',
  CANCEL = 'CANCEL',
  RE_SUBMIT = 'RE_SUBMIT',
  THUMBS_DOWN = 'THUMBS_DOWN',
  THUMBS_UP = 'THUMBS_UP',
  UPDATE_ADDRESS = 'UPDATE_ADDRESS',
}
export const GUARANTEE_AUTO_D_REASON_TYPE = {
  MODEL_APPROVED: 'MODEL APPROVED',
  MODEL_DECLINED: 'MODEL DECLINED',
  MODEL_CANCELED: 'MODEL CANCELED',
  MANUAL_REVIEW: 'MANUAL REVIEW',
}
export const GUARANTEE_DISPOSITION_DISPLAY = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',
  IN_REVIEW: 'In Review',
  CANCELED: 'Canceled',
}
export const GUARANTEE_PLAN_TYPES = {
  DEFAULT: 'DEFAULT',
  LIVE: 'LIVE',
  TEST: 'TEST',
  TRIAL: 'TRIAL',
  UNRECOGNIZED: 'UNRECOGNIZED',
}
export const INVESTIGATION_REVIEW_DISPOSITION = {
  FRAUDULENT: 'FRAUDULENT',
  GOOD: 'GOOD',
  UNSET: 'UNSET',
}
export const ORDER_CHANNEL = {
  WEB: 'WEB',
  PHONE: 'PHONE',
  OFFLINE: 'OFFLINE',
}
export const CLAIM_DISPOSITION = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
}
export const CLAIM_STATUS = {
  PENDING: 'PENDING',
  PENDING_DOCS: 'PENDING_DOCS',
  IN_REVIEW: 'IN_REVIEW',
  NEED_MORE_INFO: 'NEED_MORE_INFO',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
  ON_HOLD: 'ON_HOLD',
} as const

export const CLAIM_DISPOSITION_REASON = {
  REVERSAL: 'REVERSAL',
  UNABLE_TO_VERIFY_PROOF_OF_SHIPMENT: 'UNABLE_TO_VERIFY_PROOF_OF_SHIPMENT',
  INELIGIBLE_CHARGEBACK: 'INELIGIBLE_CHARGEBACK',
  NO_FINANCIAL_LOSS: 'NO_FINANCIAL_LOSS',
  NOT_A_CHARGEBACK: 'NOT_A_CHARGEBACK',
  PRODUCT_REROUTED: 'PRODUCT_REROUTED',
  PRODUCT_RETURNED: 'PRODUCT_RETURNED',
  ORDER_NOT_GUARANTEED: 'ORDER_NOT_GUARANTEED',
  MISSING_INFORMATION: 'MISSING_INFORMATION',
  PAST_ELIGIBLE_SUBMISSION_DATE: 'PAST_ELIGIBLE_SUBMISSION_DATE',
  PENDING_REPRESENTMENT: 'PENDING_REPRESENTMENT',
  INVOICE_PAST_DUE: 'INVOICE_PAST_DUE',
  HISTORICAL_INGESTION: 'HISTORICAL_INGESTION',
  AUTO_DISPOSITION: 'AUTO_DISPOSITION',
  INELIGIBLE_PRE_AUTH_GATEWAY_STATUS_CODE:
    'INELIGIBLE_PRE_AUTH_GATEWAY_STATUS_CODE',
}

export const CLAIM_STATUS_TITLE = 'Chargeback Reimbursement Claim'
const NOTES_MEDIA_QUERIES = {
  MEDIUM: '(min-width: 1900px) and (max-width: 2349px)',
  MEDIUM_WITHOUT_CANNED_NOTES_OVERLAY: '(min-width: 2350px)',
  LARGE: '(min-width: 2900px)',
}
export const NOTES_BREAKPOINTS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  MEDIUM_WITHOUT_CANNED_NOTES_OVERLAY: 'MEDIUM_WITHOUT_CANNED_NOTES_OVERLAY',
  LARGE: 'LARGE',
}
// note: This list should match the list here: https://github.com/signifyd/platform/blob/master/guarantee/data/src/main/java/com/signifyd/guarantees/Guarantee.java#L47
// maybe todo to pull from backend in the future
const SUPPORT_USER_ID = 1
const NUKE_USER_ID = 25509
const TIME_OUT_USER_ID = 45254
const HARD_TIME_OUT_USER_ID = 45137
const MACHINE_GUN_USER_ID = 45253
export const AUTO_DECISION_REVIEWER_IDS = new Set([
  SUPPORT_USER_ID,
  28000,
  28001,
  28002,
  28003,
  28005,
  TIME_OUT_USER_ID,
  HARD_TIME_OUT_USER_ID,
  NUKE_USER_ID,
  MACHINE_GUN_USER_ID,
])
export const getNotesBreakpoint = () => {
  if (window.matchMedia(NOTES_MEDIA_QUERIES.LARGE).matches) {
    return NOTES_BREAKPOINTS.LARGE
  }
  if (
    window.matchMedia(NOTES_MEDIA_QUERIES.MEDIUM_WITHOUT_CANNED_NOTES_OVERLAY)
      .matches
  ) {
    return NOTES_BREAKPOINTS.MEDIUM_WITHOUT_CANNED_NOTES_OVERLAY
  }
  if (window.matchMedia(NOTES_MEDIA_QUERIES.MEDIUM).matches) {
    return NOTES_BREAKPOINTS.MEDIUM
  }

  return NOTES_BREAKPOINTS.SMALL
}
export const CANNED_NOTES = [
  'SIG:GTM:A',
  'SIG:GTM:D',
  'SIG:Escalation',
  'Prior internal order history is linked to suspicious or fraudulent activity.',
  'Email on this order looks suspicious. Cannot tie cardholder to email.',
  'Cannot tie cardholder to email, phone, billing or delivery address.',
  'Multiple unrelated names associated with cardholder email address. This is highly suspicious and a strong indicator of fraud.',
  'We strongly recommend you do not ship this product.',
  'There is too much risk involved to guarantee this order.',
  'SIG:HPV - High Purchase Velocity.',
  'IP billing mismatch',
  'SIG:RS - Freight forwarding/re-shipper service being used.',
  'SIG:Call - Placed a call. Waiting 24 hours for a callback.',
  'Approved based on prior order history, consistent purchase pattern and cardholder information.',
  'SIG:Power - Cardholder has consistent order history at the billing/delivery address under another email.',
  'SIG:Power - Multiple names are tied to billing/delivery address.',
  'Cannot tie email to the cardholder. Email is tied to the delivery recipient.',
  'This is a phone order. Typos are present.',
  'Mobile phone carrier tied to mobile IP carrier.',
  'SIG:ATO',
  'SIG:Risk',
  'SIG:AddData',
  'SIG:NCR',
  'SIG:Call',
  'SIG:CallFraud',
  'SIG:Seed',
  'SIG:DenyList',
  'SIG:Model',
  'SIG:NRS',
  'SIG:Power',
  'SIG:Cool',
  'SIG:Trend/Pattern',
  'CB:Reviewed CB:CR',
  'CB:Reviewed CB:Agent',
  'CB:Reviewed CB:Good',
  'CB:Reviewed CB:Merchant',
  'CB:Reviewed CB:Declined',
  'CB:Reviewed CB:AutoD',
]
export const MAX_NOTE_CHARACTERS = 1000
export const NOTES_DELETE_UNDO_DURATION = 5000
export const STATIC_NOTES = {
  CASE_RE_REVIEW: 'CASE_RE_REVIEW',
}
export const CASE_DECISION_CONFIRMATION_SECONDS = 60
export const TEST_CASE_TOOLTIP =
  'Test cases are not eligible for guarantee. You will not be billed for this order. Click to learn more.'
export const TEST_CASE_TEXT = 'TEST CASE'
export const TEST_CASE_LINK =
  'https://www.signifyd.com/resources/faq/working-with-teams/what-is-a-test-team/'
export const TRIAL_CASE_TOOLTIP =
  'Orders submitted during trial are not eligible for chargeback coverage, ' +
  'submission for re-review, or our full order review service.'
export const TRIAL_CASE_TEXT = 'TRIAL CASE'
export const CASE_LIST_EMPTY_CASE_MSG =
  'No cases found. Modify your filter selections or search terms.'
export const CASE_QUEUE_EMPTY_CASE_MSG = 'You have no cases in your queue.'
export const CASE_QUEUE_ERROR_TITLE = "Oops, there's something wrong."
export const CASE_QUEUE_ERROR_BODY =
  "No worries, we're checking it! Try refreshing."
export const CASE_QUEUE_NO_PENDING_TITLE = 'Great job, no pending cases!'
export const CASE_QUEUE_NO_PENDING_BODY =
  'Stretch yourself and try again later.'
export const CASE_LIST_LOADER_TEXT = 'Loading more cases...'
export const CASE_SEARCH_LIMIT = 50
export const ORGANIZATIONS_ENTRY_NO_DATA = 'No Organizations Found'
export const DEVICE_ENTRY_NO_DATA = 'No Device Info Found'
export const PHONE_ENTRY_NO_DATA = 'No Phones Found'
export const PHONE_ENTRY_NO_EKATA_DATA = 'No Ekata phone data found.'
export const ACCOUNT_ENTRY_NO_ARKOWL_DATA = 'No ArkOwl Data Found'
export const ACCOUNT_ENTRY_NO_EMAIL_HOST = 'No Email Host Found'
export const DEVICE_ENTRY_NO_INFO = 'No Device Info Found'
export const CASE_REPORT_MENU_TEXT = {
  RE_OPEN_TOOLTIP: 'Reopen Case for Guarantee',
  RE_OPEN_CONFIRM_TITLE: 'Reopen for Guarantee',
  RE_OPEN_CONFIRM_BODY:
    'Are you sure you want to reopen this case for guarantee?',
  CANCEL_TOOLTIP: 'Cancel Guarantee',
  CANCEL_CONFIRM_TITLE: 'Cancel Guarantee?',
  CANCEL_CONFIRM_CANCEL: 'No, don’t cancel',
  CANCEL_CONFIRM_OK: 'Cancel Guarantee',
  CANCEL_CONFIRM_BODY:
    'Are you sure you want to cancel the guarantee for this case?',
  APPROVE_TOOLTIP: 'Approve Guarantee',
  DECLINE_TOOLTIP: 'Decline Guarantee',
  ESCALATE_CASE_TOOLTIP: 'Escalate Case',
  CASE_ESCALATED_TOOLTIP:
    'Case is Escalated. Only two escalations are allowed for any case.',
  CASE_ESCALATED_SUCCESS_MSG: 'This case is now Escalated.',
  ESCALATE_CASE_MODAL_TITLE: 'Escalate Case',
  ESCALATE_CASE_MODAL_DESCRIPTION:
    'This action will advance the case to the next review level.',
  ESCALATE_CASE_MODAL_HINT: 'Choose your decision recommendation:',
  RE_SUBMIT_TOOLTIP: 'Resubmit for Guarantee',
  RE_SUBMIT_MODAL_TITLE: 'Why are you requesting that we re-review this case?',
  RE_SUBMIT_MODAL_OK: 'Request re-review',
  RE_SUBMIT_MODAL_CANCEL: 'Cancel',
  RE_SUBMIT_MODAL_INPUT_TITLE:
    'Please send as much detail as possible to help our analysts',
  RE_SUBMIT_MODAL_FILE_TITLE: 'Additional files',
  RE_SUBMIT_MODAL_ATTACH_FILES: 'Attach file(s)',
  IS_RE_SUBMIT_TOOLTIP: 'Case submitted for re-review',
  REVIEW_TOOLTIP: 'Start Review',
  THUMBS_UP_TOOLTIP: 'Flag Case as Good',
  THUMBS_DOWN_TOOLTIP: 'Flag Case as Bad',
  AGENT_FEEDBACK_TOOLTIP: 'Record Agent Feedback',
}
export const ALLOWED_UPLOAD_FILE_TYPES = 'image/*,.pdf,audio/*,video/*,.html'
export const CASE_PAGE_SIDER_TOOLTIPS = {
  RELOAD: 'Reload',
  NEXT_CASE: 'Next case',
  SWITCH_TO_CASE_LIST: 'Click to switch to case list',
  SWITCH_TO_CASE_QUEUE: 'Click to switch to case queue',
  FILTER: 'Filtering & Sorting',
}
export const CASE_NOTE_ATTACHMENT_DOWNLOAD_CUTOFF_DAYS = 14
export const JSONViewerAllowedRolesList = [
  'MANUAL_REVIEWER_ADMIN',
  'MANUAL_REVIEWER_LEAD',
  'DEVTOOLS_BETA',
  'ADMIN',
]
export const AUTH_FILTER_OPTIONS = [
  {
    label: 'Success',
    value: 'SUCCESS',
  },
  {
    label: 'Failure',
    value: 'FAILURE',
  },
  {
    label: 'Error',
    value: 'ERROR',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'None',
    value: 'NULL',
  },
]
export const CASE_FLAG_FILTER_OPTIONS = [
  {
    label: 'Good',
    value: 'GOOD',
  },
  {
    label: 'Bad',
    value: 'FRAUDULENT',
  },
  {
    label: 'None',
    value: 'UNSET',
  },
]
export const CASE_TYPE_OPTIONS = [
  {
    label: 'Trial',
    value: 'trial',
  },
  {
    label: 'Test',
    value: 'test',
  },
]

export const CASE_SEARCH_GENERAL_QUERY = 'general'
