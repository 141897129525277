import { Component } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './ExternalLink.less'

interface Props {
  url: string
  cssClass?: string
  saveVisited?: boolean
  title?: string
  target?: '_blank' | '_self'
}

interface State {
  visited?: boolean
}

class ExternalLink extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation()
    const { saveVisited = false } = this.props
    const { visited } = this.state

    if (saveVisited && !visited) {
      this.setState({
        visited: true,
      })
    }
  }

  render(): JSX.Element {
    const { url, cssClass, children, title, target = '_blank' } = this.props
    const { visited } = this.state

    return (
      <a
        className={joinClassNames([
          'ExternalLink',
          cssClass,
          visited ? ' visited' : '',
        ])}
        href={url}
        target={target}
        rel="noopener noreferrer"
        draggable="false"
        onClick={(e) => this.handleClick(e)}
        title={title}
        data-test-id="externalLink"
      >
        {children}
      </a>
    )
  }
}

export default ExternalLink
