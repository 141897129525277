import convertTimeUnit from 'core/utils/convertTimeUnits'

type TimerProps = {
  caseTimer: number
}

const CaseReviewTimer = (props: TimerProps) => {
  const { caseTimer } = props
  const convertedTime = convertTimeUnit(caseTimer)
  const { days, hours, minutes, seconds } = convertedTime
  const oneHour = 3600
  const oneDay = oneHour * 24

  const getDisplayTimes = () => {
    if (caseTimer >= oneDay) {
      return `${days}d ${hours}h`
    }
    if (caseTimer >= oneHour) {
      return `${hours}h ${minutes}m`
    }

    return `${minutes}m ${seconds}s`
  }

  return <span>{getDisplayTimes()}</span>
}

export default CaseReviewTimer
