import { Recipient, RecipientToUpdate } from '@signifyd/http'

export interface UpdateAddressFormValues {
  fullName: string | null
  city: string | null
  streetAddress: string | null
  provinceCode: string | null
  countryCode: string | null
  unit: string | null
  postalCode: string | null
}

export const convertRecipientToFormValues = (
  recipient: Recipient
): UpdateAddressFormValues => {
  const {
    fullName,
    address: {
      streetAddress,
      unit,
      postalCode,
      countryCode,
      provinceCode,
      city,
    },
  } = recipient

  return {
    fullName,
    city,
    postalCode,
    countryCode,
    provinceCode,
    streetAddress,
    unit,
  }
}

export const convertFormValuesToUpdatableRecipient = (
  originalRecipient: Recipient,
  formValues: UpdateAddressFormValues
): RecipientToUpdate => {
  const {
    confirmationEmail,
    confirmationPhone,
    shipmentId,
    organization,
    address: { latitude, longitude },
  } = originalRecipient

  const {
    fullName,
    city,
    postalCode,
    countryCode,
    provinceCode,
    streetAddress,
    unit,
  } = formValues

  return {
    fullName,
    confirmationPhone,
    confirmationEmail,
    organization,
    shipmentId,
    deliveryAddress: {
      streetAddress,
      unit,
      city,
      postalCode,
      countryCode,
      provinceCode,
      longitude,
      latitude,
    },
  }
}
