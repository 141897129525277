import { FC } from 'react'
import { Layout } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import CaseReportContainer from 'caseReport/containers/CaseReportContainer'
import {
  CasePageUIMenu,
  CaseReportMenu,
  CaseReportUIMenu,
} from 'caseReport/containers/CasePageMenu'
import CaseCustomerAndTeam from 'caseReport/components/CaseCustomerAndTeam'
import './_CaseDetails.less'
import { InvestigationInfo } from '@signifyd/http'
import { ErrorBoundary } from '@signifyd/legacy-components'

const { Header, Content } = Layout
type Props = {
  siderCollapsed: boolean
  notesCount: number
  caseLoaded: boolean
  case: InvestigationInfo
  notesModalVisible: boolean
  notesSiderVisible: boolean
  cannedNotesVisible: boolean
  onToggleNotesModal: (isVisible?: boolean) => void
  onToggleNotesSider: (isVisible?: boolean) => void
}

const CaseReportPageCaseDetails: FC<Props> = ({
  siderCollapsed,
  notesCount,
  caseLoaded,
  case: _case,
  notesModalVisible,
  notesSiderVisible,
  cannedNotesVisible,
  onToggleNotesModal,
  onToggleNotesSider,
}) => {
  const content = (
    <Content className="CaseReportPageCaseDetails--content">
      <CaseReportContainer />
    </Content>
  )
  const header = (
    <Header className="CaseReportPageCaseDetails--header">
      <CasePageUIMenu
        className="CaseReportPageCaseDetails--header-ui-menu"
        siderCollapsed={siderCollapsed}
      />
      <div className="CaseReportPageCaseDetails--header-inner">
        <div className="CaseReportPageCaseDetails--header-inner-left">
          {caseLoaded && _case && (
            <CaseCustomerAndTeam
              customerName={_case.customerName}
              teamName={_case.teamName}
              teamId={_case.teamId}
            />
          )}
        </div>
        <div className="CaseReportPageCaseDetails--header-inner-right">
          <CaseReportMenu />
          <CaseReportUIMenu
            caseLoaded={caseLoaded}
            notesCount={notesCount}
            notesModalVisible={notesModalVisible}
            notesSiderVisible={notesSiderVisible}
            onToggleNotesModal={onToggleNotesModal}
            onToggleNotesSider={onToggleNotesSider}
          />
        </div>
      </div>
    </Header>
  )

  return (
    <Layout
      className={joinClassNames([
        'CaseReportPageCaseDetails',
        siderCollapsed && 'CaseReportPageCaseDetails--sider-collapsed',
        cannedNotesVisible && 'CaseReportPageCaseDetails--canned-notes-visible',
        !notesSiderVisible && 'CaseReportPageCaseDetails--notes-collapsed',
      ])}
    >
      <ErrorBoundary message="Case Report Page failed to load.">
        {content}
        {header}
      </ErrorBoundary>
    </Layout>
  )
}

export default CaseReportPageCaseDetails
