import { FC } from 'react'
import { Input } from 'antd'
import { CASE_REPORT_MENU_TEXT as TEXT } from 'caseReport/Constants'
import ResubmitFileSelector from './ResubmitFileSelector'
import './ResubmitMenuItem.less'
import { UploadFile } from 'antd/lib/upload/interface'

const { TextArea } = Input
type Props = {
  onNoteChange: (note: string) => void
  onFileChange: (files: { fileList: Array<UploadFile> }) => void
}

const ResubmitMenuItem: FC<Props> = ({ onNoteChange, onFileChange }) => (
  <div>
    <p className="ResubmitMenuItem--title">
      {TEXT.RE_SUBMIT_MODAL_INPUT_TITLE}
    </p>
    <TextArea
      onChange={(e) => onNoteChange(e.target.value)}
      rows={6}
      className="ResubmitMenuItem--input"
    />
    <p className="ResubmitMenuItem--title">{TEXT.RE_SUBMIT_MODAL_FILE_TITLE}</p>
    <ResubmitFileSelector onFileChange={onFileChange} />
  </div>
)

export default ResubmitMenuItem
