import { FC } from 'react'
import { Modal, Button } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import SvgIcon from 'core/components/SvgIcon'
import { CASE_REPORT_MENU_TEXT as TEXT } from 'caseReport/Constants'
import './EscalateCaseModal.less'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'

type ProposedDisposition =
  | INV_GUARANTEE_DISPOSITION.APPROVED
  | INV_GUARANTEE_DISPOSITION.DECLINED
type Props = {
  visible: boolean
  proposedDisposition: ProposedDisposition | null
  loading: boolean | null | undefined
  onCancel: () => void
  onOk: () => void
  setProposedDisposition: (arg0: ProposedDisposition) => void
}

const EscalateCaseModal: FC<Props> = ({
  visible,
  proposedDisposition,
  loading,
  setProposedDisposition,
  onCancel,
  onOk,
  ...propsForTesting
}) => (
  <Modal
    wrapClassName="EscalateCaseModal"
    title={
      <div className="EscalateCaseModal--title">
        <h6>{TEXT.ESCALATE_CASE_MODAL_TITLE}</h6>
        <div>{TEXT.ESCALATE_CASE_MODAL_DESCRIPTION}</div>
      </div>
    }
    visible={visible}
    onCancel={() => !loading && onCancel()}
    footer={
      <div>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          data-test-id="escalateBtn"
          className="EscalateCaseModal--ok-btn"
          type="primary"
          loading={loading}
          disabled={proposedDisposition === null}
          onClick={onOk}
        >
          Escalate
        </Button>
      </div>
    }
    {...propsForTesting}
  >
    <div className="EscalateCaseModal--hint">
      {TEXT.ESCALATE_CASE_MODAL_HINT}
    </div>
    <div>
      <Button
        data-test-id="escalateApproveBtn"
        className={joinClassNames([
          'EscalateCaseModal--approve-btn',
          proposedDisposition === INV_GUARANTEE_DISPOSITION.APPROVED
            ? 'EscalateCaseModal--approve-btn-selected'
            : null,
        ])}
        onClick={() =>
          setProposedDisposition(INV_GUARANTEE_DISPOSITION.APPROVED)
        }
      >
        <SvgIcon
          className="EscalateCaseModal--icon"
          size={24}
          type={
            proposedDisposition === INV_GUARANTEE_DISPOSITION.APPROVED
              ? 'GUARANTEE_DISPOSITION_APPROVED'
              : 'GUARANTEE_DISPOSITION_APPROVE'
          }
        />{' '}
        Approve
      </Button>
      <Button
        data-test-id="escalateDeclinedBtn"
        className={joinClassNames([
          'EscalateCaseModal--decline-btn',
          proposedDisposition === INV_GUARANTEE_DISPOSITION.DECLINED
            ? 'EscalateCaseModal--decline-btn-selected'
            : null,
        ])}
        onClick={() =>
          setProposedDisposition(INV_GUARANTEE_DISPOSITION.DECLINED)
        }
      >
        <SvgIcon
          className="EscalateCaseModal--icon"
          size={24}
          type={
            proposedDisposition === INV_GUARANTEE_DISPOSITION.DECLINED
              ? 'GUARANTEE_DISPOSITION_DECLINED'
              : 'GUARANTEE_DISPOSITION_DECLINE'
          }
        />{' '}
        Decline
      </Button>
    </div>
  </Modal>
)

export default EscalateCaseModal
