import React, { FC } from 'react'
import GoogleCustomMapBtn from './GoogleCustomMapBtn'
import './GoogleCustomMapPlaceholder.less'

const GoogleCustomMapPlaceholder: FC = () => (
  <div className="GoogleCustomMapPlaceholder">
    <GoogleCustomMapBtn disabled />
    <div className="GoogleCustomMapPlaceholder--map">
      <h1>
        No Map
        <br />
        Available
      </h1>
    </div>
  </div>
)

export default GoogleCustomMapPlaceholder
