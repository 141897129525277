import { FC } from 'react'
import { Icon, Tooltip } from 'antd'
import './NoteFileLink.less'
import { InvestigationNoteFile } from '@signifyd/http'
import ExternalLink from 'core/components/ExternalLink'

const FileLabel: FC<{ fileName: string }> = ({ fileName }) => (
  <span className="label">
    <Icon type="paper-clip" />
    {fileName}
  </span>
)

type NoteFileLinkProps = {
  file: InvestigationNoteFile
}

const NoteFileLink: FC<NoteFileLinkProps> = ({ file }) => {
  if (file.expired) {
    return (
      <div className="expired" data-test-id="expiredFileLabel">
        <Tooltip
          placement="topLeft"
          title="Uploaded files older than 14 days are removed in line with Signifyd's security policies"
        >
          <span>
            <FileLabel fileName={file.fileName} />
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <div>
      <ExternalLink
        url={file.presignedUrl}
        data-test-id="fileLink"
        target="_blank"
        saveVisited
      >
        <FileLabel fileName={file.fileName} />
      </ExternalLink>
    </div>
  )
}

export default NoteFileLink
