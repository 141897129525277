import type { DeeplinkProps } from 'core/components/Deeplink'

const facebookFullName = (
  fullName: string | null | undefined
): DeeplinkProps | null | undefined =>
  fullName
    ? {
        iconType: 'facebook',
        label: 'Full name search',
        url: `https://www.facebook.com/search/people/?q=${fullName}`,
      }
    : null

export default facebookFullName
