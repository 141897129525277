import { Component } from 'react'
import { Icon } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import './CopyBtn.less'

interface Props {
  className?: string
  value: string
  onClick?: () => void
  onReset?: () => void
}

interface State {
  clicked: boolean
}

class CopyBtn extends Component<Props, State> {
  _timer = null

  _textareaNode = null

  constructor(props: Props) {
    super(props)
    this.state = {
      clicked: false,
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer)
  }

  _onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    const { clicked } = this.state
    if (!this._textareaNode) {
      return
    }
    if (clicked) {
      return
    }

    this._copy()

    this._setClicked()
  }

  _copy = () => {
    const range = document.createRange()
    range.selectNodeContents(this._textareaNode)
    const sel = document.getSelection()
    if (!sel) {
      return
    }

    sel.removeAllRanges()
    sel.addRange(range)
    document.execCommand('copy')
    sel.removeAllRanges()
  }

  _setClicked = () => {
    clearTimeout(this._timer)
    const { onClick, onReset } = this.props
    this.setState({
      clicked: true,
    })
    onClick?.()
    this._timer = setTimeout(() => {
      this.setState({
        clicked: false,
      })
      onReset?.()
    }, 2000)
  }

  render() {
    const { value, className } = this.props
    const { clicked } = this.state

    // NOTE: dont use antd Button, it breaks the copy & paste functionality
    return (
      <div
        className={joinClassNames(['CopyBtn', className])}
        onClick={this._onClick}
        role="presentation"
      >
        <Icon type={clicked ? 'check' : 'copy'} />
        <div
          className="CopyBtn--text"
          ref={(node) => {
            this._textareaNode = node
          }}
        >
          {value}
        </div>
      </div>
    )
  }
}

export default CopyBtn
