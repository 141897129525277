import cx from 'classnames'
import useStores from 'useStores'
import { observer } from 'mobx-react'
import { FC } from 'react'

import './RecommendationReasonContainer.less'
import { DECISION_MECHANISM } from '@signifyd/http'

const REASON_TYPE_FOR_UI: Record<
  string,
  { recommendationStatus: string; sectionClass: string }
> = {
  'MODEL APPROVED': {
    recommendationStatus: 'Recommendation Accept',
    sectionClass: 'APPROVED',
  },
  'MODEL DECLINED': {
    recommendationStatus: 'Recommendation Reject',
    sectionClass: 'DECLINED',
  },
  'MANUAL REVIEW': {
    recommendationStatus: 'Manual Review',
    sectionClass: 'REVIEW',
  },
}

const RecommendationReasonContainer: FC<{ className?: string }> = observer(
  (className) => {
    const {
      caseReportStore: { case: investigation },
    } = useStores()

    const isPolicyOnlyAccept =
      investigation?.decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT
    const policyRuleId =
      investigation?.recommendedActionRuleId ||
      investigation?.guaranteeRecommendedActionRuleId

    if (!investigation || (isPolicyOnlyAccept && policyRuleId)) {
      return null
    }

    const {
      recommendedAutoDecisionReasonType,
      recommendedAutoDecisionReasonDescription,
    } = investigation

    if (!recommendedAutoDecisionReasonType) {
      return null
    }

    const { recommendationStatus, sectionClass } =
      REASON_TYPE_FOR_UI[recommendedAutoDecisionReasonType]

    return (
      <section
        className={cx([
          'RecommendationReasonContainer',
          `RecommendationReasonContainer--${sectionClass}`,
          className,
        ])}
        data-test-id="recommendationReasonContainer"
      >
        <header className="RecommendationReasonContainer--reason-type">
          <h6 className="RecommendationReasonContainer--reason-type-text">
            {recommendationStatus}
          </h6>
        </header>

        <p>{recommendedAutoDecisionReasonDescription}</p>
      </section>
    )
  }
)

export default RecommendationReasonContainer
