import React, { FC, ReactNode } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './SigCard.less'

type Props = {
  title?: ReactNode
  extra?: ReactNode
  className?: string
  inner?: boolean
  border?: boolean
  extraAlignment?: 'right' | 'left'
}

const SigCard: FC<Props> = ({
  title,
  extra,
  children,
  inner,
  border,
  className,
  extraAlignment,
}) => {
  const containerClassName = inner ? 'SigInnerCard' : 'SigCard'
  const borderClassName = border ? 'has-border' : ''
  const childrenClassName = children ? 'has-children' : ''

  return (
    <div
      className={joinClassNames([
        containerClassName,
        borderClassName,
        childrenClassName,
        className,
        extraAlignment === 'right' && `${containerClassName}--extra-right`,
      ])}
    >
      {title && (
        <div className={`${containerClassName}--title`}>
          <div>{title}</div>
          {extra && (
            <div className={`${containerClassName}--extra`}>{extra}</div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default SigCard
