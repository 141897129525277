import { observable } from 'mobx'
import type {
  CaseInfo,
  Meta,
} from 'core/http/investigations/CaseSearchHttp.types'

export type CaseMeta = Meta & {
  totalRows: number
}
export type CaseListStore = {
  loading: {
    pending: boolean
    success: boolean
    error: boolean | number
    finished: boolean
    namespace: string | null | undefined
  }
  cases: Array<CaseInfo> | null | undefined
  caseIndexByCaseId: Record<number, number> | null | undefined
  meta: CaseMeta | null | undefined
  state: {
    showCaseQueue: boolean
    selectedCaseId: number | null | undefined
  }
}
const store: CaseListStore = observable({
  loading: {
    pending: false,
    success: false,
    error: false,
    finished: false,
    namespace: null,
  },
  cases: null,
  meta: null,
  caseIndexByCaseId: null,
  state: {
    showCaseQueue: false,
    selectedCaseId: null,
  },
})
export default store
