import { FC } from 'react'
import { Icon } from 'antd'
import './CaseTimeout.less'

type Props = {
  deadlineHours: number
  deadlineMins: number
  timeUnitMins: string
  timeUnitHours: string
}

const CaseTimeout: FC<Props> = (props) => {
  const { deadlineHours, timeUnitHours, deadlineMins, timeUnitMins } = props

  return (
    <span className="CaseTimeout" data-test-id="caseTimeoutLabel">
      <Icon type="clock-circle-o" />
      {deadlineHours} {timeUnitHours} {deadlineMins} {timeUnitMins}
    </span>
  )
}

export default CaseTimeout
