import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getTeamsById, SelectModalEntity } from '@signifyd/legacy-components'
import { message } from 'antd'

const useGetTeamsById = (
  ids: Array<number>
): UseQueryResult<Array<SelectModalEntity>> =>
  useQuery(['getTeamsById', ids], async () => {
    if (!ids.length) {
      return []
    }

    try {
      return getTeamsById(ids.map(String))
    } catch {
      message.error('Error fetching teams - clear filter and try again.')

      return []
    }
  })

export default useGetTeamsById
