type ConvertedTimeObject = {
  seconds: string
  minutes: string
  hours: string
  days: string
} // Start to a nice util. I think we can add options like no padding,m onths, etc, later

export default function convertTimeUnit(seconds: number): ConvertedTimeObject {
  function _pad(val: number) {
    return val > 9 ? val : `0${val}`
  }

  function _convertTimeUnit(val: number, unit: number) {
    return val < unit ? val : val % unit
  }

  function _setConvertedTimeUnits() {
    const timeInSeconds = seconds % 60
    const timeInMinutes = Math.trunc(seconds / 60)
    const timeInHours = Math.floor(timeInMinutes / 60)
    const timeInDays = Math.floor(timeInHours / 24)

    return {
      seconds: _pad(timeInSeconds).toString(),
      minutes: _pad(_convertTimeUnit(timeInMinutes, 60)).toString(),
      hours: _pad(_convertTimeUnit(timeInHours, 24)).toString(),
      days: _pad(timeInDays).toString(),
    }
  }

  return _setConvertedTimeUnits()
}
