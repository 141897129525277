import { $Keys } from 'utility-types'

export const LINK_KEYS = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE_WEB: 'GOOGLE_WEB',
  EKATA: 'EKATA',
  PIPL: 'PIPL',
  TRUE_CALLER: 'TRUE_CALLER',
  _118712: '_118712',
  _118000: '_118000',
  PAGINE_BIANCHE: 'PAGINE_BIANCHE',
  ELENCHI_TELEFONICI: 'ELENCHI_TELEFONICI',
}
export type LinkKey = $Keys<typeof LINK_KEYS>
export type LinkKeysSubset = Array<LinkKey>
const {
  FACEBOOK,
  GOOGLE_WEB,
  EKATA,
  PIPL,
  TRUE_CALLER,
  _118712,
  _118000,
  PAGINE_BIANCHE,
  ELENCHI_TELEFONICI,
} = LINK_KEYS
export const DEFAULT_PHONE_LINKS: LinkKeysSubset = [
  FACEBOOK,
  GOOGLE_WEB,
  EKATA,
  PIPL,
  TRUE_CALLER,
]
export const FR_PHONE_LINKS: LinkKeysSubset = [
  ...DEFAULT_PHONE_LINKS,
  _118712,
  _118000,
]
export const IT_PHONE_LINKS: LinkKeysSubset = [
  ...DEFAULT_PHONE_LINKS,
  PAGINE_BIANCHE,
  ELENCHI_TELEFONICI,
]
