import { observer, inject } from 'mobx-react'
import { compose, mapProps, branch, renderNothing } from 'recompose'
import RecommendationReasonContainer from './RecommendationReasonContainer'

const enhance = compose(
  inject('caseReportStore'),
  observer,
  branch(({ caseReportStore }) => !caseReportStore.case, renderNothing),
  mapProps(({ className, caseReportStore }) => ({
    className,
    recommendedAction: caseReportStore.case.recommendedAction,
  })),
  branch(({ recommendedAction }) => !recommendedAction, renderNothing)
)
export default enhance(RecommendationReasonContainer)
