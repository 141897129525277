import { action } from 'mobx'
import type { LoadingStatus } from './async.store'

type LoadingKeyValue = Record<LoadingStatus, boolean | string>

const asyncActions = (store: any) => {
  const _setLoading = action((keyValue: LoadingKeyValue): void => {
    store.loading = { ...store.loading, ...keyValue }
  })

  const _resetLoadingState = () => {
    _setLoading({
      pending: true,
      success: false,
      finished: false,
      error: false,
    })
  }

  const asyncAction = (func: () => Promise<any>): Promise<any> => {
    _resetLoadingState()

    return func()
      .then((res) => {
        _setLoading({
          success: true,
        })

        return res
      })
      .catch((err) => {
        console.log(err)

        _setLoading({
          error: true,
        })

        return Promise.reject(err)
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const setError = (message: string | boolean) =>
    _setLoading({
      error: message,
    })

  return {
    asyncAction,
    setError,
  }
}

export default asyncActions
