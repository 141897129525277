import React, { FC } from 'react'
import { Tooltip } from 'antd'
import './CasePageSiderMenuTitle.less'

type Props = {
  enabled: boolean
  text: string
  tooltip: string
  onClick: () => any
}

const CasePageSiderMenuTitle: FC<Props> = ({
  enabled,
  text,
  tooltip,
  onClick,
}) => {
  const content = (
    <div
      className={
        enabled ? 'CasePageSiderMenuTitle--clickable' : 'CasePageSiderMenuTitle'
      }
      onClick={() => enabled && onClick()}
      role="presentation"
    >
      {text}
    </div>
  )

  return enabled ? (
    <Tooltip title={tooltip} placement="right" mouseEnterDelay={1}>
      {content}
    </Tooltip>
  ) : (
    content
  )
}

export default CasePageSiderMenuTitle
