import { FC, ReactNode, useState } from 'react'
import SeederIcon from 'core/components/SeederIcon'
import CopyBtn from 'core/components/CopyBtn'
import './SigKeyValue.less'
import { startCase } from 'lodash'
import cx from 'classnames'

export type SigKeyValueProps = {
  name?: string
  nameWidth?: number
  startCaseName?: boolean
  value?: ReactNode
  copyValue?: string | false
  seeder?: boolean | null | undefined
  isTitle?: boolean
  isNewGroup?: boolean
  showCopyOnHover?: boolean
  bordered?: boolean
  alignValueToRight?: boolean
  isNameBold?: boolean
  isValueBold?: boolean
  noHoverColor?: boolean
  hideLastItemBorder?: boolean
}

const SigKeyValue: FC<SigKeyValueProps> = ({
  startCaseName,
  name = ' ',
  nameWidth,
  value,
  copyValue,
  seeder,
  isTitle,
  isNewGroup,
  showCopyOnHover,
  bordered = true,
  alignValueToRight = false,
  isNameBold = true,
  isValueBold = false,
  noHoverColor = false,
  hideLastItemBorder = false,
}) => {
  const [selectValue, setSelectValue] = useState(false)

  const handleSelectValue = (): void => {
    setSelectValue(true)
  }

  const handleUnselectValue = (): void => {
    setSelectValue(false)
  }

  const borderClass = `SigKeyValue--bordered${
    hideLastItemBorder ? `-except-last` : ''
  }`

  return (
    <div
      className={cx({
        SigKeyValue: true,
        [borderClass]: bordered,
        'SigKeyValue--is-title': isTitle,
        'SigKeyValue--is-new-group': isNewGroup,
        'SigKeyValue--select-value': selectValue,
        'SigKeyValue--has-copy-btn': copyValue,
        'SigKeyValue--copy-visible-on-hover-only': showCopyOnHover,
        'SigKeyValue--fixed-name-width': nameWidth,
        'SigKeyValue--align-value-to-right': alignValueToRight,
        'SigKeyValue--no-hover': noHoverColor,
      })}
      style={nameWidth ? { paddingLeft: nameWidth } : undefined}
    >
      <div
        className={cx({
          'SigKeyValue--name': true,
          'SigKeyValue--bold': isNameBold,
        })}
        style={nameWidth ? { width: nameWidth } : undefined}
      >
        {startCaseName ? startCase(name) : name}
      </div>
      <div
        className="SigKeyValue--value-wrapper"
        style={nameWidth ? { width: '100%' } : undefined}
      >
        {seeder && <SeederIcon className="SigKeyValue--seeder-icon" />}
        <div
          className={cx({
            'SigKeyValue--value': true,
            'SigKeyValue--bold': isValueBold,
          })}
        >
          {value}
        </div>
        {!!copyValue && (
          <CopyBtn
            value={copyValue}
            className="SigKeyValue--copy-btn"
            onClick={handleSelectValue}
            onReset={handleUnselectValue}
          />
        )}
      </div>
    </div>
  )
}

export default SigKeyValue
