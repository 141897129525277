import { FC, ReactNode } from 'react'
import { Row, Col } from 'antd'
import SvgIcon from 'core/components/SvgIcon'
import { joinClassNames } from '@signifyd/utils'
import { CLAIM_STATUS_TITLE } from 'caseReport/Constants'
import ClaimStatusDetailText from './ClaimStatusDetailText'
import './ClaimStatus.less'

type ClaimStatusDisplayHeader =
  | 'Canceled'
  | 'Pending'
  | 'Needs More Info'
  | 'Approved'
  | 'Declined'
  | 'On Hold'
  | 'Recalled'
type ClaimStatusDisplayColor = 'pending' | 'success' | 'warning' | 'alert'
export type ClaimStatusDisplay = {
  header: ClaimStatusDisplayHeader
  color: ClaimStatusDisplayColor
  detail: ReactNode
}
type Props = {
  claimStatusDisplay: ClaimStatusDisplay
  className: string | null | undefined
  claimId: number
}

const ClaimStatus: FC<Props> = ({ claimStatusDisplay, className, claimId }) => {
  const { header, color, detail } = claimStatusDisplay

  return (
    <Row
      type="flex"
      className={joinClassNames([
        'ClaimStatus',
        `ClaimStatus--${color}`,
        className,
      ])}
      justify="space-between"
      align="top"
    >
      <Col span={24}>
        <Row type="flex" justify="space-between" align="top">
          <Col span={12} className="ClaimStatus--info">
            <h4 className="ClaimStatus--title">
              {CLAIM_STATUS_TITLE} - (ID {claimId})
            </h4>
            <SvgIcon
              size="small"
              type="CHARGEBACK"
              className="ClaimStatus--icon"
            />
            <span className="ClaimStatus--status">{header}</span>
          </Col>
          <Col span={12} className="ClaimStatus--detail">
            <ClaimStatusDetailText detail={detail} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ClaimStatus
