import React, { FC } from 'react'
import { Row, Col } from 'antd'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import type { OrderResponse } from 'core/http/cases/OrderHttp.types'
import type { EntriesResponse } from 'core/http/cases/EntriesHttp.types'
import CaseMapMarkers from 'core/components/CaseMapMarkers'
import SigCard from 'core/components/SigCard'
import OrderSummary from './OrderSummary'
import PaymentSummary from './PaymentSummary'
import UserAccountSummary from './UserAccountSummary'
import DiscountSummary from './DiscountSummary'
import ShippingSummary from './ShippingSummary'
import './CaseSummaryContainer.less'

type Props = {
  case: CaseInfo
  order: OrderResponse
  entries: EntriesResponse
}

const CaseSummaryContainer: FC<Props> = ({ case: _case, order, entries }) => (
  <SigCard className="CaseSummaryContainer" title="Summary">
    <Row type="flex" gutter={30}>
      <Col sm={24} lg={12} xl={13}>
        <Row type="flex" gutter={30}>
          <Col sm={24} md={12}>
            <PaymentSummary case={_case} order={order} />
            <DiscountSummary case={_case} />
          </Col>
          <Col sm={24} md={12}>
            <ShippingSummary case={_case} />
            <UserAccountSummary case={_case} order={order} />
          </Col>
          <Col span={24}>
            <OrderSummary
              orderChannel={order.orderChannel}
              products={order.products}
            />
          </Col>
        </Row>
      </Col>
      <Col sm={24} lg={12} xl={11}>
        <div className="CaseSummaryContainer--square">
          <div className="CaseSummaryContainer--square-content">
            {/* map is 4:3 ratio */}
            <CaseMapMarkers
              locations={entries.physicalLocations}
              height="75%"
            />
          </div>
        </div>
      </Col>
    </Row>
  </SigCard>
)

export default CaseSummaryContainer
