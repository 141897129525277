import { ReactNode } from 'react'
import { withProps } from 'recompose'
import { get } from 'lodash'
import moment from 'moment-timezone'
import type { CaseNoteProps as PropsOutput, TimelineItemType } from './CaseNote'
import { CASE_NOTE_ATTACHMENT_DOWNLOAD_CUTOFF_DAYS } from 'caseReport/Constants'
import type { Enhance } from 'core/types/HOC.types'
import CaseNote from './CaseNote'
import { CaseNote as CaseNoteType } from '@signifyd/http'

interface PropsInput {
  note: CaseNoteType
  deleteClicked: boolean
  onDelete: () => void
  onUndo: () => void
}

const isOrderReviewChecklistItem = (note: CaseNoteType): boolean => {
  return /^{"OR_CL":{.*}}$/.test(note.text || '')
}

const formatOrderReviewChecklistItem = (text?: string): ReactNode => {
  if (!text) {
    return ''
  }

  try {
    const data = JSON.parse(text)
    const checklist = get(data, 'OR_CL.ck') || []

    const comment = get(data, 'OR_CL.cm')

    return (
      <div>
        <h6>Order Review Checklist</h6>
        {checklist.map((item, index: number) => (
          <div key={index}>
            <strong>{`${item.h}: `}</strong>
            {item.i && <span>{item.i.join(', ')}</span>}
            {item.t && <div>{item.t}</div>}
          </div>
        ))}
        {comment && (
          <div>
            <strong>Comments: </strong>
            <div>{comment}</div>
          </div>
        )}
      </div>
    )
  } catch (e) {
    return 'Invalid JSON'
  }
}

const checkFileDisabled = (createdAt: CaseNoteType['createdAt']): boolean => {
  if (!createdAt) {
    return true
  }
  const cutoffDate = moment
    .utc(new Date())
    .subtract(CASE_NOTE_ATTACHMENT_DOWNLOAD_CUTOFF_DAYS, 'days')
  const createdDate = moment.utc(createdAt)

  return createdDate.isBefore(cutoffDate)
}

const enhance: Enhance<PropsInput, PropsOutput> = withProps(({ note }) => {
  const noteText = note.text ?? ''

  const getMessage = (note: CaseNoteType): ReactNode =>
    isOrderReviewChecklistItem(note)
      ? formatOrderReviewChecklistItem(noteText)
      : noteText

  const getNoteType = (note: CaseNoteType): TimelineItemType => {
    return /signifyd\.com|trustedpatterns\.com$/i.test(note.createdBy || '')
      ? 'note'
      : 'event'
  }

  const fileDisabled = checkFileDisabled(note.createdAt)

  return {
    type: getNoteType(note),
    who: note.createdBy || get(note, 'createdByTeam.teamName'),
    message: getMessage(note),
    fileDisabled,
  }
})
export default enhance(CaseNote)
export type { TimelineItemType }
