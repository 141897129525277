import { Address } from '../../http/investigations/CaseSearchHttp.types'

export const formatAddress = (address: Address): string | null => {
  const { streetAddress, unit, city, postalCode, provinceCode, countryCode } =
    address

  return [streetAddress, unit, city, postalCode, provinceCode, countryCode]
    .filter((str) => !!str)
    .join(', ')
}
