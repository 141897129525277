import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL } from './constants'

export const pagesJaunesLastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `quoiqui=${encodeURIComponent(
    lastName
  )}&ou=${encodeURIComponent(zipCode || '')}&proximite=0`

  return {
    iconType: 'pages-jaunes',
    label,
    url: `https://www.pagesjaunes.fr/pagesblanches/recherche?${query}`,
  }
}
export const pagesJaunesAddress = ({
  street,
  postalCode,
}: PhysicalLocationDetails): DeeplinkProps | null | undefined => {
  if (!street && !postalCode) {
    return
  }
  const query =
    `ou=${encodeURIComponent(street || '')} ` +
    `${encodeURIComponent(postalCode || '')}&proximite=0`

  return {
    iconType: 'pages-jaunes',
    label: ADDRESS_SEARCH_LABEL,
    url: `https://www.pagesjaunes.fr/pagesblanches/recherche?${query}`,
  }
}
