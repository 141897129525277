import { compose, withState, withHandlers } from 'recompose'
import DenylistBtn from './DenylistBtn'

const enhance = compose(
  withState('modalVisible', 'setModalVisible', false),
  withHandlers({
    handleOk:
      ({ onConfirm, denylisted, setModalVisible }) =>
      () => {
        if (denylisted) {
          return
        }

        setModalVisible(false)
        onConfirm()
      },
  })
)
export default enhance(DenylistBtn)
