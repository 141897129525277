import { FC, ReactNode } from 'react'
import './ClaimStatusDetailText.less'

interface Props {
  detail: ReactNode
}

const ClaimStatusDetailText: FC<Props> = ({ detail }: Props) => (
  <p className="ClaimStatusDetailText">{detail}</p>
)

export default ClaimStatusDetailText
