import { observable } from 'mobx'
import type { Customer } from 'core/http/customers/CustomersHttp.types'

export type CustomerStore = {
  customer: Customer | null | undefined
}
const store: CustomerStore = observable({
  customer: null,
})
export default store
