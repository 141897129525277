import { isNil, isEmpty, sumBy, flow } from 'lodash'
import { filter } from 'lodash/fp'
import { compose, withState, withProps, withHandlers } from 'recompose'
import { toJS } from 'mobx'
import ProductsTable from './ProductsTable'

const DEFAULT_VISIBLE_PRODUCTS = 2
const enhance = compose(
  withState('collapsed', 'setCollapsed', true),
  withProps(({ collapsed, products = [] }) => {
    const removeNullProducts = filter(
      ({ itemName, itemQuantity, itemPrice }) =>
        !(isEmpty(itemName) && isNil(itemQuantity) && isNil(itemPrice))
    )

    const sortByPriceDesc = (products) =>
      products.sort((a, b) => b.itemPrice - a.itemPrice)

    const allProducts = flow([removeNullProducts, sortByPriceDesc])(
      toJS(products)
    )

    const collapseButtonCount = allProducts.length - DEFAULT_VISIBLE_PRODUCTS
    const visibleProducts =
      collapsed && collapseButtonCount > 0
        ? allProducts.slice(0, DEFAULT_VISIBLE_PRODUCTS)
        : allProducts

    return {
      products: visibleProducts,
      collapseButtonCount,
    }
  }),
  withProps(({ products, showTotal, collapsed, collapseButtonCount }) => {
    const hasHiddenProducts = collapseButtonCount > 0 && collapsed
    if (hasHiddenProducts || isEmpty(products) || !showTotal) {
      return
    }

    const totalPrice = sumBy(
      products,
      ({ itemPrice, itemQuantity }) => itemPrice * itemQuantity
    )

    const productsWithTotal = [
      ...products,
      {
        itemName: 'Total',
        itemPrice: totalPrice,
        itemId: 'total',
      },
    ]

    return {
      products: productsWithTotal,
    }
  }),
  withHandlers({
    handleToggleCollapse:
      ({ collapsed, setCollapsed }) =>
      () => {
        setCollapsed(!collapsed)
      },
  })
)
export default enhance(ProductsTable)
