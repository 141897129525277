import { action } from 'mobx'
import { get, filter, isEmpty } from 'lodash'
import {
  listCaseNotes as getNotesHttp,
  createCaseNote as addNoteHttp,
  deleteCaseNote as deleteNoteHttp,
  AttachFileRequest,
  attachFileToInvestigationNote,
  CaseNotesResponse,
  CaseNote,
} from '@signifyd/http'
import { NOTES_DELETE_UNDO_DURATION } from 'caseReport/Constants'
import appStore from 'AppStore'
import trainingModeStore from './trainingMode.store'
import getTrainingModeActions from './trainingMode.actions'
import asyncActions from './async.actions'
import { notification } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'

const trainingModeActions = getTrainingModeActions(trainingModeStore)

const caseNotesActions = (store: any) => {
  const { asyncAction, setError } = asyncActions(store)

  const _setNotes = action((notes: Array<CaseNote>) => {
    store.notes = notes
  })

  const _setCaseId = action((caseId: number) => {
    store.caseId = caseId
  })

  const _removeNoteById = action((noteId: number) => {
    store.notes = filter(store.notes, (note) => note.noteId !== noteId)
  })

  const getCaseNotesById = (caseId: number): Promise<CaseNotesResponse> => {
    _setNotes([])
    _setCaseId(caseId)

    return asyncAction(() => {
      const getAction = appStore.isTraining
        ? trainingModeActions.getNotesForAdditionalDecision
        : getNotesHttp

      return getAction(caseId).then((res) => {
        const notes = get(res, 'data.notes', [])
        _setNotes(notes)
      })
    })
  }

  const _addNoteToDeleteTimers = action((noteId: number, timer: number) => {
    store.deleteNoteTimers = { ...store.deleteNoteTimers, [noteId]: timer }
  })

  const _removeNoteFromDeleteTimers = action((noteId: number) => {
    delete store.deleteNoteTimers[noteId]
    store.deleteNoteTimers = { ...store.deleteNoteTimers }
  })

  const focusTextArea = action(() => {
    store.state.textAreaKey += 1
  })

  const _addNote = async (text: string, files: Array<UploadFile> = []) => {
    return asyncAction(async () => {
      const addAction = appStore.isTraining
        ? trainingModeActions.addNoteToAdditionalDecision
        : addNoteHttp

      const res = await addAction(store.caseId, text)

      if (!res) {
        return Promise.resolve()
      }

      const { noteId } = res.data

      await Promise.all(
        files.map(async (file: UploadFile) => {
          const attachFileRequest: AttachFileRequest = {
            investigationId: store.caseId,
            noteId,
            fileName: file.name,
            file,
          }

          await attachFileToInvestigationNote(attachFileRequest)
        })
      ).catch(() => {
        notification.error({
          message: 'Issue uploading file(s). Try again or contact support.',
        })
      })

      return getCaseNotesById(store.caseId)
    })
  }

  const addNote = (text: string, files: Array<UploadFile> = []) => {
    return _addNote(text, files).then(() => {
      focusTextArea()
      setCustomNote('')
    })
  }

  const deleteNote = (noteId: number) => {
    if (appStore.isTraining) {
      return
    }

    return asyncAction(() => deleteNoteHttp(store.caseId, noteId)).then(() => {
      _removeNoteById(noteId)

      _removeNoteFromDeleteTimers(noteId)
    })
  }

  const deleteNoteWithDelay = (noteId: number) => {
    if (appStore.isTraining) {
      return
    }
    let timer = store.deleteNoteTimers[noteId]

    if (timer) {
      clearTimeout(timer)

      _removeNoteFromDeleteTimers(noteId)
    }

    timer = setTimeout(() => deleteNote(noteId), NOTES_DELETE_UNDO_DURATION)

    _addNoteToDeleteTimers(noteId, timer)
  }

  const undoDeleteNote = (noteId: number) => {
    if (appStore.isTraining) {
      return
    }
    const timer = store.deleteNoteTimers[noteId]
    clearTimeout(timer)

    _removeNoteFromDeleteTimers(noteId)
  }

  const appendCannedNote = (cannedNote: string) => {
    const { customNote } = store.state
    const note = isEmpty(customNote)
      ? cannedNote
      : `${customNote} ${cannedNote}`
    setCustomNote(note)
    focusTextArea()
  }

  const setCustomNote = action((value: string) => {
    setError(false)
    store.state.customNote = value
  })

  return {
    getCaseNotesById,
    addNote,
    addReviewChecklistNote: _addNote,
    deleteNoteWithDelay,
    undoDeleteNote,
    setError,
    setCustomNote,
    appendCannedNote,
    focusTextArea,
  }
}

export default caseNotesActions
