import { $Keys } from 'utility-types'
import { observable } from 'mobx'

export type LoadingStatus = $Keys<typeof store.loading>
export type AsyncStore = {
  loading: {
    pending: boolean
    success: boolean
    error: boolean | string
    finished: boolean
  }
}
const store = observable({
  loading: {
    pending: false,
    success: false,
    error: false,
    finished: false,
  },
})
export default store
