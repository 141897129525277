import React, { ReactNode, FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import SigFlyoutToggleIcon from './SigFlyoutToggleIcon'
import './SigFlyoutToggle.less'

type Props = {
  className?: string
  label: ReactNode
  checked: boolean
  icon?: ReactNode
  onChange: (arg0: boolean) => void
}

const SigFlyoutToggle: FC<Props> = ({
  className,
  label,
  checked,
  icon,
  onChange,
}) => (
  <div
    className={joinClassNames([className, 'SigFlyoutToggle'])}
    onClick={() => onChange(!checked)}
  >
    {icon || <SigFlyoutToggleIcon checked={checked} />}
    <span className="SigFlyoutToggle--label">{label}</span>
  </div>
)

export default SigFlyoutToggle
