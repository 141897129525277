import React from 'react'
import { joinClassNames } from '@signifyd/utils'
import './ScoreDisplay.less'
import { SCORE_THRESHOLDS as SCORE } from 'caseReport/Constants'

type Size = 'small' | 'medium'
type Props = {
  score: number
  size?: Size
  className?: string
}

const ScoreDisplay = ({ score, size = 'medium', className }: Props) => {
  const normalizedScore = Math.trunc(score)

  const getScoreClass = (normalizedScore) => {
    if (normalizedScore > SCORE.HIGH) {
      return 'good'
    }

    return normalizedScore > SCORE.MEDIUM ? 'neutral' : 'bad'
  }

  return (
    <div
      className={joinClassNames([
        'ScoreDisplay',
        getScoreClass(normalizedScore),
        `ScoreDisplay--size-${size}`,
        className,
      ])}
    >
      {normalizedScore}
    </div>
  )
}

export default ScoreDisplay
