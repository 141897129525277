import { UNKNOWN } from 'core/Constants'

export default function yesNo(input) {
  if (input === true) {
    return 'Yes'
  }
  if (input === false) {
    return 'No'
  }

  return UNKNOWN
}
