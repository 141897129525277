import { FC, ReactNode } from 'react'
import { toLower, startCase } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import PaymentOrShipmentIcon from 'caseReport/components/PaymentOrShipmentIcon'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import { VALID_DELIVERY_ICONS } from 'core/Constants'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import './ShippingSummary.less'

type Props = {
  case: CaseInfo
}

function toTitleCase(text: string): string {
  return startCase(toLower(text.replace('_', ' ')))
}

const ShippingSummary: FC<Props> = ({ case: _case }) => {
  let allShipments: Array<{
    name: string
    value: string
  }> = []

  if (_case?.shipments) {
    allShipments = _case.shipments.map((shipment) => {
      const method = shipment.shippingMethod
        ? toTitleCase(shipment.shippingMethod)
        : 'Method Unavailable'
      const price = shipment.shippingPrice
        ? `${_case.currency || ''} ${formatCurrencyNumber(
            shipment.shippingPrice
          )}`
        : 'Price unavailable'

      return {
        name: method,
        value: price,
      }
    })
  }

  const shippingIcons: Array<ReactNode> = []

  if (_case?.shipments) {
    const extractedMethods: Array<string> = []

    _case.shipments.forEach((shipment) => {
      if (shipment.shipper) {
        extractedMethods.push(shipment.shipper)
      }

      if (shipment.shippingMethod) {
        extractedMethods.push(shipment.shippingMethod)
      }
    })

    const shippers = [...new Set(extractedMethods)]

    shippers.forEach((shipper, key) => {
      if (VALID_DELIVERY_ICONS[shipper as keyof typeof VALID_DELIVERY_ICONS]) {
        shippingIcons.push(
          <PaymentOrShipmentIcon
            key={key}
            type={shipper}
            tooltip={toTitleCase(shipper)}
          />
        )
      }
    })
  }

  return (
    <SigCard
      title="Shipping"
      extra={shippingIcons}
      inner
      className="ShippingSummary"
    >
      <SigKeyValueGroup
        title="Shipping Method"
        titleValue="Price"
        properties={allShipments}
      />
    </SigCard>
  )
}

export default ShippingSummary
