import { FC } from 'react'
import { get } from 'lodash'
import { DEFAULT_DATE_FORMAT, AGENT_FEEDBACK_TAG_LABELS } from 'core/Constants'
import ScoreDisplay from 'caseReport/components/ScoreDisplay'
import CaseListItemIcon from 'caseReport/components/CaseListItemIcon'
import CaseTimeout from 'caseReport/components/CaseTimeout'
import TrialOrTestCaseTag from 'caseReport/components/TrialOrTestCaseTag'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import { joinClassNames } from '@signifyd/utils'
import FormatDate from 'core/components/FormatDate'
import TrainingMask from 'core/components/TrainingMask'
import './CaseListItem.less'
import useStores from 'useStores'
import { observer } from 'mobx-react'

type Props = {
  caseIndex: number
  onSelect: () => void
}

const CaseListItem: FC<Props> = observer(({ caseIndex, onSelect }) => {
  const { appStore, caseListStore } = useStores()

  const currentCase = caseListStore.cases?.[caseIndex]

  if (!appStore.user || !currentCase) {
    return null
  }

  const selected =
    caseListStore.state.selectedCaseId === currentCase.investigationId

  const getClaimStatusOrDisposition = () => {
    const { claims } = currentCase
    const [firstClaim] = claims

    if (!firstClaim) {
      return null
    }

    const { disposition, status } = firstClaim

    return disposition || status
  }

  const claimDispositionOrStatus = getClaimStatusOrDisposition()

  const agentFeedbackTags = get(currentCase, 'agentFeedbackTags', [])
    .filter((tag) => !!AGENT_FEEDBACK_TAG_LABELS[tag])
    .map((tag) => AGENT_FEEDBACK_TAG_LABELS[tag])
    .join(', ')

  return (
    <div
      className={joinClassNames([
        'CaseListItem',
        selected && 'CaseListItem--selected',
      ])}
      onClick={onSelect}
      role="presentation"
      data-test-id="caseListItem"
    >
      <div className="CaseListItem--left">
        <TrialOrTestCaseTag
          case={currentCase}
          size="small"
          className="CaseListItem--trial-or-test-tag"
        />
        <div className="CaseListItem--headline">
          {currentCase.headline}
          <TrainingMask>
            <CaseListItemIcon
              type="review-disposition"
              value={currentCase.investigationReviewDisposition}
              className="CaseListItem--review-disposition-icon"
            />
          </TrainingMask>
        </div>
        <div
          className="CaseListItem--team-name"
          data-test-id="caseListItemTeamName"
        >
          {currentCase.teamName}
        </div>
        <div
          className="CaseListItem--case-created-at"
          data-test-id="caseListItemCreatedAt"
        >
          <FormatDate
            UTCTimestamp={
              currentCase.normalizedPurchaseCreatedAt || currentCase.createdAt
            }
            format={DEFAULT_DATE_FORMAT}
            showTimeZoneAbbr
          />
        </div>
        <CaseTimeout
          guaranteeReviewDeadline={currentCase.guaranteeReviewDeadline}
        />
      </div>
      <div className="CaseListItem--right">
        <div className="CaseListItem--icons-and-score">
          <TrainingMask>
            <CaseListItemIcon
              type="agent-feedback-tags"
              className="CaseListItem--icon"
              value={agentFeedbackTags}
            />
          </TrainingMask>
          {currentCase.guaranteeReviewEscalationLevel > 0 && (
            <TrainingMask>
              <CaseListItemIcon
                type="review-escalation"
                value={currentCase.guaranteeDisposition}
              />
            </TrainingMask>
          )}
          <TrainingMask>
            <CaseListItemIcon type="claim" value={claimDispositionOrStatus} />
          </TrainingMask>
          <TrainingMask>
            <CaseListItemIcon
              type="guarantee"
              className="CaseListItem--icon"
              value={currentCase.guaranteeDisposition}
            />
          </TrainingMask>
          <TrainingMask>
            <ScoreDisplay
              score={currentCase.signifydScore}
              size="small"
              className="CaseListItem--score"
            />
          </TrainingMask>
        </div>
        <div className="CaseListItem--order-amount">
          {`${currentCase.currency || ''} ${formatCurrencyNumber(
            currentCase.orderTotalAmount
          )}`}
        </div>
      </div>
    </div>
  )
})

export default CaseListItem
