import { inject, observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import { flow, get, toLower, startCase, upperFirst } from 'lodash/fp'
import { compose, mapProps, branch, renderNothing } from 'recompose'
import type { ClaimStatusDisplay } from './ClaimStatus'
import type { ClaimDataForDisplay } from './utils'
import { getAllClaimStatusDisplay, getClaimStatusDisplayKey } from './utils'
import ClaimStatus from './ClaimStatus'

export const getNormalizedChargebackReasonType = flow([
  get('chargebacks[0].normalizedReasonType'),
  startCase,
  toLower,
  upperFirst,
])
const enhance = compose(
  inject('caseReportStore'),
  observer,
  branch(
    ({ caseReportStore }) => isEmpty(caseReportStore.claim),
    renderNothing
  ),
  mapProps(({ caseReportStore, className }) => {
    const { claim } = caseReportStore
    const claimData: ClaimDataForDisplay = {
      normalizedChargebackReasonType: getNormalizedChargebackReasonType(claim),
    }
    const key = getClaimStatusDisplayKey(claim)
    const allClaimStatusDisplay = getAllClaimStatusDisplay(claimData)
    const claimStatusDisplay: ClaimStatusDisplay = allClaimStatusDisplay[key]

    return {
      claimStatusDisplay,
      className,
      claimId: claim.claimId,
    }
  })
)
export default enhance(ClaimStatus)
