import { mapProps } from 'recompose'
import getLinksByCountry from './utils'
import PhoneEntry from './PhoneEntry'

const enhance = mapProps(({ confirmationPhone, accountPhone, countryCode }) => {
  const createDeeplinks = (phone) =>
    phone && { ...phone, deeplinks: getLinksByCountry(phone, countryCode) }

  return {
    confirmationPhone: createDeeplinks(confirmationPhone),
    accountPhone: createDeeplinks(accountPhone),
  }
})
export default enhance(PhoneEntry)
