import { $Keys } from 'utility-types'
import { isNumber, isString } from 'lodash'
import { joinClassNames } from '@signifyd/utils'
import './SvgIcon.less'
import Icons from './Icons'
import { FC } from 'react'

export type SvgIconSize = 'xsmall' | 'small' | 'medium' | 'large'
export type SvgIconType = $Keys<typeof Icons>
type Props = {
  size?: SvgIconSize | number
  className?: string
  type: SvgIconType
  'data-test-id'?: string
}

// If size is not defined, size is the same as the embedded <svg> node
const SvgIcon: FC<Props> = ({ size, className, type, ...props }) => (
  <span
    data-test-id={props['data-test-id'] ?? 'svg-icon'}
    className={joinClassNames([
      'SvgIcon',
      size ? isString(size) && `SvgIcon--${size}` : undefined,
      className,
    ])}
    style={
      isNumber(size)
        ? {
            width: size,
            height: size,
          }
        : undefined
    }
  >
    {Icons[type]}
  </span>
)

export default SvgIcon
