import React, { FC } from 'react'
import { Icon, Tooltip } from 'antd'
import { formatInt } from 'core/utils/formatNumber'
import './ReviewQueueMetric.less'

type Props = {
  title: string
  value: number
  tooltip: string | null | undefined
}

const ReviewQueueMetric: FC<Props> = ({ title, value, tooltip }) => {
  return (
    <div className="ReviewQueueMetric">
      <span className="ReviewQueueMetric--name">
        {title}
        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <Icon
              className="ReviewQueueMetric--icon"
              type="question-circle-o"
            />
          </Tooltip>
        )}
      </span>
      <span className="ReviewQueueMetric--value">{formatInt(value)}</span>
    </div>
  )
}

export default ReviewQueueMetric
