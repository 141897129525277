import { DeeplinkProps } from 'core/components/Deeplink'
import { Membership } from 'core/http/investigations/CaseSearchHttp.types'
import {
  googleWebEmail,
  EkataEmail,
  piplEmail,
  EkataPhone,
  googleWebPhone,
  piplPhone,
} from 'core/utils/deeplinks'

export type MembershipDetails = {
  phoneNumber: StringValueLinks
  emailAddress: StringValueLinks
  membershipId: StringValueLinks
  membershipName: StringValueLinks
}

export interface StringValueLinks {
  value: string
  links: Array<DeeplinkProps>
}

export const getMembershipEmailLinks = (
  membership: Membership
): Array<DeeplinkProps> => {
  if (!membership.emailAddress) {
    return []
  }

  const emailFromGoogle = googleWebEmail(membership.emailAddress)
  const emailFromEkata = EkataEmail(membership.emailAddress)
  const emailFromPipl = piplEmail(membership.emailAddress)

  const links = [emailFromGoogle, emailFromEkata, emailFromPipl]

  return links.filter((link): link is DeeplinkProps => !!link)
}

export const getMembershipPhoneLinks = (
  membership: Membership
): Array<DeeplinkProps> => {
  if (!membership.phoneNumber) {
    return []
  }

  const phoneNumberFromGoogle = googleWebPhone(membership.phoneNumber)
  const phoneNumberFromEkata = EkataPhone(membership.phoneNumber)
  const phoneNumberFromPipl = piplPhone(membership.phoneNumber)

  const links = [
    phoneNumberFromGoogle,
    phoneNumberFromEkata,
    phoneNumberFromPipl,
  ]

  return links.filter((link): link is DeeplinkProps => !!link)
}

export const processMembership = (
  memberships: Array<Membership>
): Array<MembershipDetails> => {
  return memberships.map((membership) => {
    const phoneNumber = getMembershipPhoneLinks(membership)
    const emailAddress = getMembershipEmailLinks(membership)

    return {
      membershipId: {
        value: membership.membershipId,
        links: [],
      },
      phoneNumber: {
        value: membership.phoneNumber,
        links: phoneNumber,
      },
      emailAddress: {
        value: membership.emailAddress,
        links: emailAddress,
      },
      membershipName: {
        value: membership.membershipName,
        links: [],
      },
    }
  })
}
