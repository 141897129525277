import { FC } from 'react'
import { Col, Row } from 'antd'
import { ANALYSIS_STATE } from 'core/http/cases/AnalysisHttp.types'

import './CaseAnalysisError.less'

export type Props = {
  analysisState: ANALYSIS_STATE
}

export const CaseAnalysisError: FC<Props> = ({ analysisState }) => {
  if (analysisState === ANALYSIS_STATE.PROCESSED) {
    return null
  }

  return (
    <Row
      gutter={30}
      type="flex"
      align="stretch"
      className="CaseAnalysisContainer--row"
    >
      <Col sm={24} lg={24} className="CaseAnalysisContainer--col-center">
        {analysisState === ANALYSIS_STATE.PROCESSING && (
          <div
            className="CaseAnalysisContainer--error"
            data-test-id="caseAnalysisErr"
          >
            Apologies — fraud analysis is still loading. Refresh the page.
          </div>
        )}
        {analysisState === ANALYSIS_STATE.POLICY_ONLY && (
          <div
            className="CaseAnalysisContainer--error"
            data-test-id="caseAnalysisErr"
          >
            Nothing to see here! This case was decisioned by merchant policies
            only thus fraud analysis is not available.
          </div>
        )}
        {analysisState === ANALYSIS_STATE.ERROR && (
          <div
            className="CaseAnalysisContainer--error"
            data-test-id="caseAnalysisErr"
          >
            Apologies — our system failed momentarily. We recommend you refresh
            the page.
          </div>
        )}
      </Col>
    </Row>
  )
}

export default CaseAnalysisError
