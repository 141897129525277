import React, { FC } from 'react'
import { get, isEmpty, kebabCase, startCase } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import PaymentOrShipmentIcon from 'caseReport/components/PaymentOrShipmentIcon'
import AvsCvv from 'core/components/AvsCvv'
import type {
  BinData,
  CaseInfo,
} from 'core/http/investigations/CaseSearchHttp.types'
import type { OrderResponse } from 'core/http/cases/OrderHttp.types'
import CardNumber from '../../components/CardNumber/CardNumber'

type Props = {
  case: CaseInfo
  order: OrderResponse
}
const PAYMENT_SUMMARY = {
  CARD_BRANDS: [
    'amex',
    'american-express',
    'china-union-pay',
    'china-unionpay',
    'cirrus',
    'diners-club',
    'discover',
    'jcb',
    'maestro-switch',
    'switch-maestro',
    'maestro',
    'mastercard',
    'solo',
    'visa',
    'laser',
    'ourocard',
    'rbs-gift-card',
    'paypal',
    'amazon-payments',
    'apple',
    'saksfirst-store-card',
  ],
  EXACT_MATCH_PAYMENT_TYPES: {
    'carrier-loan': 'carrier-loan',
    loan: 'loan',
    ach: 'ach',
  },
  PAYMENT_TYPES: [
    'debit',
    'credit',
    'charge',
    'charge-card',
    'prepaid',
    'prepaid-visa-travelmoney',
    'check',
    'paypal',
    'amazon-payments',
    'apple',
    'samsung-pay',
    'alipay',
  ],
  GATEWAYS: ['paypal', 'amazon-payments', 'apple'],
}
const UNKNOWN_REGEX = /^unknown$/i
export const getPaymentValueFromOrderData =
  (order: OrderResponse) =>
  (path: string): string | null => {
    const value = get(order, path)

    return isEmpty(value) || UNKNOWN_REGEX.test(value) ? null : value
  }

export const findMatchedIconType = (
  iconClassNames: Array<string>,
  itemName: string | null | undefined
): string | null => {
  if (!itemName) {
    return null
  }

  const name = kebabCase(itemName)

  // Exact match on icon types
  if (iconClassNames.includes(name)) {
    return name
  }

  const partialMatchItemsOnWords = iconClassNames.filter((item) => {
    // eslint-disable-next-line prefer-template
    const nameRegex = new RegExp('\\b' + name + '\\b', 'g')

    return item.match(nameRegex) || name.match(item)
  })

  if (partialMatchItemsOnWords.length > 0) {
    return partialMatchItemsOnWords.toString()
  }

  return null
}

const PaymentSummary: FC<Props> = ({ case: _case, order }) => {
  const paymentTerms = get(order, 'paymentMethod.paymentTerms')

  const getPaymentValue = getPaymentValueFromOrderData(order)
  const cardBrand =
    getPaymentValue('paymentMethod.cardBrand') ||
    getPaymentValue('paymentMethod.name') ||
    getPaymentValue('paymentMethod.entityName')
  const cardBrandIconType = findMatchedIconType(
    PAYMENT_SUMMARY.CARD_BRANDS,
    cardBrand
  )
  const cardBrandIcon = cardBrandIconType && (
    <PaymentOrShipmentIcon
      type={cardBrandIconType}
      tooltip={startCase(cardBrand || '')}
    />
  )
  const paymentCardType = getPaymentValue('paymentMethod.cardType')

  const exactMatchCardIconType =
    paymentCardType &&
    PAYMENT_SUMMARY.EXACT_MATCH_PAYMENT_TYPES[kebabCase(paymentCardType)]

  const paymentCardTypeIconType =
    exactMatchCardIconType ||
    findMatchedIconType(PAYMENT_SUMMARY.PAYMENT_TYPES, paymentCardType)
  const paymentCardTypeIcon = paymentCardTypeIconType && (
    <PaymentOrShipmentIcon
      type={paymentCardTypeIconType}
      tooltip={`Payment Card Type: ${startCase(paymentCardType || '')}`}
    />
  )
  const paymentType = getPaymentValue('paymentMethod.type')

  const exactMatchIconType =
    paymentType &&
    PAYMENT_SUMMARY.EXACT_MATCH_PAYMENT_TYPES[kebabCase(paymentType)]

  const paymentTypeIconType =
    exactMatchIconType ||
    findMatchedIconType(PAYMENT_SUMMARY.PAYMENT_TYPES, paymentType)
  const paymentTypeIcon = (paymentTypeIconType === 'carrier-loan' ||
    (paymentTypeIconType && !paymentCardTypeIconType)) && (
    <PaymentOrShipmentIcon
      type={paymentTypeIconType}
      tooltip={`Payment Type: ${startCase(paymentType || '')}`}
    />
  )
  const paymentGateway = getPaymentValue('paymentGateway')
  const paymentGatewayIconType = findMatchedIconType(
    PAYMENT_SUMMARY.GATEWAYS,
    paymentGateway
  )
  const paymentGatewayIcon = paymentGatewayIconType && (
    <PaymentOrShipmentIcon
      type={paymentGatewayIconType}
      tooltip={`Payment Gateway: ${startCase(paymentGateway || '')}`}
    />
  )
  const nopPaymentTypeIcon = !paymentTypeIcon && !paymentCardTypeIcon && (
    <PaymentOrShipmentIcon type="no-brand" tooltip="Payment Type: Unknown" />
  )
  const paymentIcons = (
    <div>
      {cardBrandIcon}
      {paymentCardTypeIcon}
      {paymentTypeIcon}
      {nopPaymentTypeIcon}
      {paymentGatewayIcon}
    </div>
  )
  const binData = (get(_case, 'binData') as BinData) || {}
  const paymentProps = [
    {
      name: 'CVV Response',
      value: _case.cvvResponseDescription && (
        <AvsCvv
          code={_case.cvvResponseCode}
          description={_case.cvvResponseDescription}
        />
      ),
    },
    {
      name: 'AVS Response',
      value: _case.avsResponseDescription && (
        <AvsCvv
          code={_case.avsResponseCode}
          description={_case.avsResponseDescription}
        />
      ),
    },
    {
      name: 'Number',
      value: _case.cardBin && (
        <CardNumber
          paymentMethod={order.paymentMethod}
          binData={binData}
          caseInfo={_case}
        />
      ),
    },
    {
      name: 'Expiration Date',
      value:
        get(order, 'paymentMethod.cardExpiryMonth') &&
        get(order, 'paymentMethod.cardExpiryYear')
          ? `${get(order, 'paymentMethod.cardExpiryMonth', '--')}` +
            `/${get(order, 'paymentMethod.cardExpiryYear', '--')}`
          : null,
    },
    {
      name: 'Bank',
      value: binData.bank,
    },
    {
      name: 'Credit Card Issuance Location',
      value: binData.address || binData.isocountry,
    },
    {
      name: 'Auth Failure Reason',
      value: _case.authorizationFailureReason,
    },
    {
      name: 'Auth Gateway Status',
      value: _case.authorizationGatewayStatus,
    },
    {
      name: 'Payment Terms',
      value: paymentTerms ? paymentTerms.join(', ') : undefined,
    },
  ]

  return (
    <SigCard title="Payment" extra={paymentIcons} inner>
      <SigKeyValueGroup properties={paymentProps} />
    </SigCard>
  )
}

export default PaymentSummary
