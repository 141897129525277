import React, { FC } from 'react'
import GoogleCustomMap from 'core/components/GoogleCustomMap'
import './CaseMapMarkers.less'
import type { MarkerDetail } from './CaseMapMarkers.types'

type Props = {
  markerDetails: Array<MarkerDetail>
  height?: number | string
}

const CaseMapMarkers: FC<Props> = ({ markerDetails, height }) => (
  <div
    className="CaseMapMarkers"
    style={{
      height,
    }}
  >
    <GoogleCustomMap markerDetails={markerDetails} />
  </div>
)

export default CaseMapMarkers
