import { FC, ReactNode } from 'react'
import { isEmpty } from 'lodash'
import { Popover } from 'antd'
import Deeplink from 'core/components/Deeplink'
import PopoverTrigger from 'core/components/PopoverTrigger'
import type { DeeplinkProps } from 'core/components/Deeplink'

type Props = {
  deeplinks?: Array<DeeplinkProps>
  customLink?: ReactNode
}

const DeeplinkPopover: FC<Props> = ({
  deeplinks = [],
  children,
  customLink,
}) => {
  if (isEmpty(deeplinks)) {
    return null
  }
  const content: Array<ReactNode> = deeplinks.map(
    (linkProps: DeeplinkProps, index) => <Deeplink key={index} {...linkProps} />
  )

  if (customLink) {
    content.push(customLink)
  }

  return (
    <Popover content={content} trigger="click" placement="bottomLeft">
      <PopoverTrigger onClick={(e) => e.stopPropagation()}>
        {children}
      </PopoverTrigger>
    </Popover>
  )
}

export default DeeplinkPopover
