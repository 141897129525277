import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL, PHONE_NUM_SEARCH_LABEL } from './constants'

const _118712LastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `s=${lastName}+${zipCode || ''}`.replace(/ /g, '+')

  return {
    iconType: '_118712',
    label,
    url: `https://annuaire.118712.fr/?${query}`,
  }
}

const _118712Address = ({
  street,
  postalCode,
}: PhysicalLocationDetails): DeeplinkProps | null | undefined => {
  if (!street && !postalCode) {
    return
  }
  const query = `s=${street || ''}+${postalCode || ''}`.replace(/ /g, '+')

  return {
    iconType: '_118712',
    label: ADDRESS_SEARCH_LABEL,
    url: `https://annuaire.118712.fr/?${query}`,
  }
}

const _118712Phone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined =>
  phoneNum
    ? {
        iconType: '_118712',
        label: PHONE_NUM_SEARCH_LABEL,
        url: `https://annuaire.118712.fr/?s=${phoneNum.replace(/ /g, '+')}`,
      }
    : null

export { _118712LastNameAndZipCode, _118712Address, _118712Phone }
