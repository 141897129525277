import { observable } from 'mobx'
import { getNotesBreakpoint, NOTES_BREAKPOINTS } from 'caseReport/Constants'

export type CaseReportUIStore = {
  siderCollapsed: boolean
  notesModalVisible: boolean
  notesSiderVisible: boolean
  cannedNotesVisible: boolean
}
export const getDefaultCannedNotesVisible = () => {
  const width = getNotesBreakpoint()

  return width !== NOTES_BREAKPOINTS.MEDIUM
}
const store: CaseReportUIStore = observable({
  siderCollapsed: false,
  notesModalVisible: false,
  notesSiderVisible: true,
  cannedNotesVisible: getDefaultCannedNotesVisible(),
})
export default store
