import { FC } from 'react'
import './ShowMore.less'

interface Props {
  showAll: boolean
  setShowAll: (show: boolean) => void
  numberMoreThanMaxItems: number
}

const ShowMore: FC<Props> = ({
  showAll,
  setShowAll,
  numberMoreThanMaxItems,
}) => {
  const buttonText = `Show ${
    showAll ? 'less' : `${numberMoreThanMaxItems} more`
  }...`

  return (
    <div
      data-test-id="showButton"
      className="showButton"
      onClick={() => setShowAll(!showAll)}
    >
      {buttonText}
    </div>
  )
}

export default ShowMore
