import { compose, withProps, withHandlers } from 'recompose'
import { inject, observer } from 'mobx-react'
import caseNotesActions from 'caseReport/stores/caseNotes.actions'
import CaseNotesContainer from './CaseNotesContainer'

const enhance = compose(
  inject('caseNotesStore'),
  observer,
  withProps(({ caseNotesStore }) => ({
    notes: caseNotesStore.notes,
    deleteNoteTimers: caseNotesStore.deleteNoteTimers,
    actions: {
      caseNotes: caseNotesActions(caseNotesStore),
    },
  })),
  withHandlers({
    handleDeleteNote:
      ({ actions }) =>
      (noteId) => {
        actions.caseNotes.deleteNoteWithDelay(noteId)
      },
    handleUndoDeleteNote:
      ({ actions }) =>
      (noteId) => {
        actions.caseNotes.undoDeleteNote(noteId)
      },
  })
)
export default enhance(CaseNotesContainer)
