import { memo, FC, useCallback, useState } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
} from '@react-google-maps/api'
import BA from 'images/ba.svg'
import DA from 'images/da.svg'
import IP from 'images/ip.svg'
import BA_IP from 'images/ba-ip.svg'
import BA_DA from 'images/ba-da.svg'
import DA_IP from 'images/da-ip.svg'
import BA_DA_IP from 'images/ba-da-ip.svg'
import { GoogleCustomMapProps } from './index'
import GoogleCustomMapBtn from './GoogleCustomMapBtn'
import { googleMapsKey } from '@signifyd/sig-keys'
import './GoogleCustomMap.less'

const ICON_BY_TYPE = {
  BA: {
    icon: BA,
    tooltip: 'Billing Address',
  },
  DA: {
    icon: DA,
    tooltip: 'Delivery Address',
  },
  IP: {
    icon: IP,
    tooltip: 'IP Geolocation',
  },
  BA_IP: {
    icon: BA_IP,
    tooltip: 'Billing and IP Geolocation',
  },
  BA_DA: {
    icon: BA_DA,
    tooltip: 'Billing and Delivery Address',
  },
  DA_IP: {
    icon: DA_IP,
    tooltip: 'IP Geolocation and Delivery Address',
  },
  BA_DA_IP: {
    icon: BA_DA_IP,
    tooltip: 'Billing Address, Delivery Address and IP Geolocation',
  },
}

const containerStyle = {
  height: '100%',
  width: '100%',
}

const GoogleCustomMap: FC<GoogleCustomMapProps> = ({ markerDetails }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsKey,
  })

  const [map, setMap] = useState<google.maps.Map>()
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  })
  const [zoom, setZoom] = useState(5)
  const [markerTooltipIndex, setMarkerTooltipIndex] = useState(-1)

  const onZoomChanged = (): void => {
    const zoom = map?.getZoom()

    if (!map || !zoom) {
      return
    }

    setZoom(zoom)
  }

  const fitBounds = useCallback(
    (mapInstance: google.maps.Map) => {
      if (markerDetails.length === 1) {
        const { lat, lng } = markerDetails[0]
        setCenter({
          lat,
          lng,
        })

        return
      }

      const currentBounds = markerDetails.map(
        ({ lat, lng }) => new window.google.maps.LatLng(lat, lng)
      )
      const bounds = new window.google.maps.LatLngBounds()
      currentBounds.forEach((bound) => bounds.extend(bound))
      mapInstance.fitBounds(bounds)
      // set state to force refresh GoogleCustomMap
      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      })
    },
    [markerDetails]
  )

  const onLoad = useCallback(
    (mapInstance) => {
      setMap(mapInstance)
      fitBounds(mapInstance)
    },
    [fitBounds]
  )
  const onUnmount = useCallback(() => {
    setMap(undefined)
  }, [])

  const showMarkerTooltip = (index: number): void => {
    setMarkerTooltipIndex(index)
  }

  const hideMarkerTooltip = (): void => {
    setMarkerTooltipIndex(-1)
  }

  if (!isLoaded) {
    return null
  }

  return (
    <div className="GoogleCustomMap">
      <GoogleMap
        mapContainerClassName="GoogleCustomMap--map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={onZoomChanged}
      >
        {markerDetails.map(({ type, lat, lng }, index) => (
          <Marker
            key={type}
            icon={ICON_BY_TYPE[type].icon}
            position={{
              lat,
              lng,
            }}
            onMouseOver={() => showMarkerTooltip(index)}
            onMouseOut={hideMarkerTooltip}
          >
            {index === markerTooltipIndex && (
              <InfoWindow onCloseClick={hideMarkerTooltip}>
                <strong>{ICON_BY_TYPE[type].tooltip}</strong>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
      {map && <GoogleCustomMapBtn onClick={() => fitBounds(map)} />}
    </div>
  )
}

export default memo(GoogleCustomMap)
