import { toJS, observable } from 'mobx'
import moment from 'moment-timezone'
import { InvestigationSearchParamsBuilder } from 'core/utils/queryParams'
import { CASE_SEARCH_LIMIT } from 'caseReport/Constants'

type SortByOptions =
  | 'SORT_NORMALIZED_CREATED_AT'
  | 'SORT_SIGNIFYD_SCORE'
  | 'SORT_ORDER_TOTAL'
  | 'SORT_GUARANTEE_REVIEW_DEADLINE'
  | ''
  | null
type SortOrderOptions = 'DSC' | 'ASC' | ''
type CaseStatusOptions = 'ACTIVE' | 'CLOSED' | 'SCORING' | 'ALL' | '' | null
type ClaimStatusOptions = Array<string>
type GuaranteeStatusOptions = Array<string>
export type GuaranteeRequestedOptions =
  | 'GUARANTEE_FILTER_REQUESTED'
  | 'GUARANTEE_FILTER_NOT_REQUESTED'
  | 'GUARANTEE_FILTER_BOTH'
export type RangeObject = {
  min: string | number | '' | null | moment.Moment
  max: string | number | '' | null | moment.Moment
}
export type CaseFilters = {
  searchTerms: Array<string>
  sortBy: SortByOptions
  sortOrder: SortOrderOptions
  claimStatus: ClaimStatusOptions
  claimDispositions: Array<string>
  investigationReviewDispositions: Array<string>
  authorizationGatewayStatuses: Array<string>
  guaranteeStatus: GuaranteeStatusOptions
  guaranteeRequested: GuaranteeRequestedOptions
  reReviewOnly: boolean
  caseStatus: CaseStatusOptions
  caseAmountRange: RangeObject
  caseScoreRange: RangeObject
  guaranteeReviewEscalationLevel: RangeObject
  limit: number
  teamsSelected: Array<string>
  wasDecisionedByManualReview?: boolean | ''
  isTrialCase: boolean | null
  isTestInvestigation: boolean | null
  // riskAssessmentRegion doesnt have a UI
  riskAssessmentRegion: ('Europe' | 'IS_NULL') | null | undefined
}
export type CaseFiltersStore = {
  filters: CaseFilters
  caseDateRange: {
    min: moment
    max: moment
  }
  params: string
}
export const getDefaultFilters = (): CaseFilters => ({
  searchTerms: [],
  sortBy: 'SORT_NORMALIZED_CREATED_AT',
  sortOrder: 'DSC',
  caseStatus: 'ALL',
  caseAmountRange: {
    min: '',
    max: '',
  },
  caseScoreRange: {
    min: '',
    max: '',
  },
  limit: CASE_SEARCH_LIMIT,
  guaranteeRequested: 'GUARANTEE_FILTER_REQUESTED',
  guaranteeReviewEscalationLevel: {
    min: null,
    max: null,
  },
  reReviewOnly: false,
  claimStatus: [],
  // PENDING, CANCELLED, NEED_MORE_INFO
  claimDispositions: [],
  // APPROVED, DECLINED
  guaranteeStatus: [],
  investigationReviewDispositions: [],
  authorizationGatewayStatuses: [],
  teamsSelected: [],
  riskAssessmentRegion: null,
  wasDecisionedByManualReview: '',
  isTrialCase: false,
  isTestInvestigation: false,
})
const store: CaseFiltersStore = observable({
  filters: getDefaultFilters(),
  caseDateRange: {
    min: moment().subtract(14, 'days'),
    max: moment(),
  },

  get params() {
    const _filters = toJS(this.filters)

    const _sortOrderToBool = this.filters.sortOrder === 'ASC'

    const investigationSearchParamsBuilder =
      new InvestigationSearchParamsBuilder()

    // computed props won't be in a toJS() returned value (hence this.sortOrderToBool)
    return investigationSearchParamsBuilder
      .fromArray('searchTerms', _filters.searchTerms)
      .fromRange('signifydScore', _filters.caseScoreRange)
      .fromRange('orderTotalAmount', _filters.caseAmountRange)
      .fromArray('claimStatuses', _filters.claimStatus)
      .fromArray('claimDispositions', _filters.claimDispositions)
      .fromArray('guaranteeDispositions', _filters.guaranteeStatus)
      .fromValue('guaranteeRequested', _filters.guaranteeRequested)
      .fromValue(
        'authorizationGatewayStatuses',
        _filters.authorizationGatewayStatuses
      )
      .fromValue(
        'investigationReviewDispositions',
        _filters.investigationReviewDispositions
      )
      .fromValue('orderBy', _filters.sortBy)
      .fromBoolean('ascending', _sortOrderToBool)
      .fromRange(
        'guaranteeReviewEscalationLevel',
        _filters.guaranteeReviewEscalationLevel
      )
      .fromBoolean('reReviewOnly', _filters.reReviewOnly)
      .fromValue('status', _filters.caseStatus)
      .fromValue('teamIds', _filters.teamsSelected)
      .fromBoolean('isTrialCase', _filters.isTrialCase)
      .fromValue(
        'wasDecisionedByManualReview',
        _filters.wasDecisionedByManualReview
      )
      .fromColFilterValue('isTestInvestigation', _filters.isTestInvestigation)
      .fromColFilterValue('riskAssessmentRegion', _filters.riskAssessmentRegion)
      .build()
  },
})
export default store
