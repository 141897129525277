import React from 'react'
import { Button } from 'antd'
import type {
  Loading,
  InternalState,
} from 'caseReport/stores/merchantReport.store'
import MerchantNotesReadonly from './MerchantNotesReadonly'
import MerchantNotesEditable from './MerchantNotesEditable'
import './MerchantNotes.less'

export type Notes = string | null | undefined
type Props = {
  notes: Notes
  stateNotes: Notes
  loading: Loading
  state: InternalState
  onChange: (arg0: string) => void
  onSubmit: () => void
  onCancel: () => void
  onDelete: () => void
  onStartEdit: () => void
}

const MerchantNotes = ({ onStartEdit, notes, state, ...props }: Props) => (
  <div className="MerchantNotes">
    {state.isEditing ? (
      <MerchantNotesEditable
        stateNotes={state.notes}
        notes={notes}
        {...props}
      />
    ) : (
      <div>
        <Button className="MerchantNotes--edit-btn" onClick={onStartEdit}>
          {notes ? 'Edit' : 'Add Notes'}
        </Button>
        <MerchantNotesReadonly notes={notes} />
      </div>
    )}
  </div>
)

export default MerchantNotes
