import { FC } from 'react'
import { toLower } from 'lodash'
import { Tooltip } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import { VALUE_PLACEHOLDER } from 'core/Constants'
import './PaymentOrShipmentIcon.less'
import { TooltipPlacement } from 'antd/lib/tooltip'

type Props = {
  type: string
  tooltip?: string
  text?: string
  textAlign?: 'left' | 'right'
  grayscale?: boolean
  tooltipPlacement?: TooltipPlacement
}

const PaymentOrShipmentIcon: FC<Props> = ({
  type,
  text,
  tooltip,
  textAlign,
  grayscale,
  tooltipPlacement = 'top',
}) => {
  const paymentIcon =
    type !== VALUE_PLACEHOLDER ? (
      <div
        className={joinClassNames([
          'PaymentOrShipmentIcon--icon',
          `PaymentOrShipmentIcon--${toLower(type)}`,
          grayscale && 'PaymentOrShipmentIcon--icon-grayscale',
        ])}
      />
    ) : null

  return (
    <div className="PaymentOrShipmentIcon">
      {tooltip && (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          {paymentIcon}
        </Tooltip>
      )}

      {text && (
        <div
          className={joinClassNames([
            'PaymentOrShipmentIcon--inline-wrapper',
            textAlign === 'left' && 'PaymentOrShipmentIcon--align-left',
          ])}
        >
          {textAlign === 'left' && <div>{text}</div>}
          {paymentIcon}
          {textAlign !== 'left' && <div>{text}</div>}
        </div>
      )}
    </div>
  )
}

export default PaymentOrShipmentIcon
