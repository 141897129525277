import { FC } from 'react'
import { Tooltip } from 'antd'
import SvgIcon from 'core/components/SvgIcon'
import './ArkOwlSocialMediaIcon.less'
import { joinClassNames } from '@signifyd/utils'
import { SvgIconType } from 'core/components/SvgIcon/SvgIcon'

type Props = {
  type: SvgIconType
  registered: boolean
  className: string
}

const ArkOwlSocialMediaIcon: FC<Props> = ({ type, registered, className }) => (
  <Tooltip title={type}>
    <div
      className={joinClassNames([
        'ArkOwlSocialMediaIcon',
        registered && 'ArkOwlSocialMediaIcon--registered',
        className,
      ])}
    >
      <SvgIcon
        size="xsmall"
        className="ArkOwlSocialMediaIcon--icon"
        type={type}
      />
      <span className="ArkOwlSocialMediaIcon--text">
        {` ${registered ? 'Y' : 'N'}`}
      </span>
    </div>
  </Tooltip>
)

export default ArkOwlSocialMediaIcon
