import React from 'react'
import { Collapse } from 'antd'
import SigCardCollapsePanel from './SigCardCollapsePanel'
import './SigCardCollapse.less'

const SigCardCollapse = ({ children, ...rest }) => (
  <Collapse className="SigCardCollapse" bordered={false} {...rest}>
    {children}
  </Collapse>
)

SigCardCollapse.Panel = SigCardCollapsePanel
export default SigCardCollapse
