import React from 'react'
import { get, isEmpty } from 'lodash/fp'
import SigCard from 'core/components/SigCard'
import SigKeyValue from 'core/components/SigKeyValue'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import SigCardCollapse from 'core/components/SigCardCollapse'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import yesNo from 'core/utils/yesNo'
import { UNKNOWN } from 'core/Constants'
import {
  PHONE_ENTRY_NO_DATA,
  PHONE_ENTRY_NO_EKATA_DATA,
} from 'caseReport/Constants'
import './PhoneEntry.less'
import type { Phone } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import { SigKeyValueProps } from 'core/components/SigKeyValue/SigKeyValue'

const { Panel } = SigCardCollapse
type PhoneWithDeeplinks = Phone & {
  deeplinks?: Array<DeeplinkProps>
}
type Props = {
  confirmationPhone: PhoneWithDeeplinks | null | undefined
  accountPhone: PhoneWithDeeplinks | null | undefined
}

const getEkataGeneralInfo = (phone): Array<SigKeyValueProps> => {
  const EkataData = get('details.wppReversePhoneJson', phone)
  const prepaid = yesNo(EkataData.prepaid)

  return [
    {
      name: 'Carrier',
      value: EkataData.carrier || UNKNOWN,
      alignValueToRight: true,
    },
    {
      name: 'Phone Type',
      value: (
        <span
          className={`${
            EkataData.lineType === 'NonFixedVOIP'
              ? 'PhoneEntry--non-fixed-VOIP'
              : ''
          }`}
        >
          {EkataData.lineType || UNKNOWN}
        </span>
      ),
      alignValueToRight: true,
    },
    {
      name: 'Prepaid',
      value: (
        <span
          className={prepaid === 'Yes' ? 'PhoneEntry--prepaid-yes' : undefined}
        >
          {prepaid}
        </span>
      ),
      alignValueToRight: true,
    },
    {
      name: 'Commercial',
      value: yesNo(EkataData.commercial),
      alignValueToRight: true,
    },
  ]
}

const getEkataBelongsTo = (phone) => {
  const belongsTo = get('details.wppReversePhoneJson.belongsTo', phone)
  if (isEmpty(belongsTo)) {
    return null
  }

  return belongsTo.map(({ name, age_range: ageRange, gender, type }, index) => (
    <SigKeyValueGroup
      key={index}
      title={type}
      hideLastItemBorder
      removeUnknown={false}
      properties={[
        {
          name: 'Name',
          value: name,
          alignValueToRight: true,
        },
        {
          name: 'Age Range',
          value: ageRange,
          alignValueToRight: true,
        },
        {
          name: 'Gender',
          value: gender,
          alignValueToRight: true,
        },
      ]}
    />
  ))
}

const getEkataAssociatedPeople = (phone) => {
  const associatedPeople = get(
    'details.wppReversePhoneJson.associatedPeople',
    phone
  )

  return isEmpty(associatedPeople) ? null : (
    <SigKeyValueGroup
      title="Associated People"
      hideLastItemBorder
      properties={associatedPeople.map(({ name, relation }) => ({
        name,
        value: relation && `Relation: ${relation}`,
        alignValueToRight: true,
      }))}
    />
  )
}

const getEkataAddresses = (phone) => {
  const addresses = get('details.wppReversePhoneJson.currentAddresses', phone)
  if (isEmpty(addresses)) {
    return null
  }
  const properties = addresses.map((addr) => {
    const fullAddr = [
      addr.street_line_1,
      addr.city,
      addr.state_code,
      addr.postal_code,
      addr.country_code,
    ]
      .filter((str) => str)
      .join(' ')

    return {
      name: 'Address',
      value: fullAddr,
      alignValueToRight: true,
    }
  })

  return (
    <SigKeyValueGroup
      title="Addresses"
      hideLastItemBorder
      properties={properties}
    />
  )
}

const PhoneEntry = ({ confirmationPhone, accountPhone }: Props) => {
  if (!confirmationPhone && !accountPhone) {
    return (
      <SigCard title="Phones" inner>
        {PHONE_ENTRY_NO_DATA}
      </SigCard>
    )
  }

  const renderPhone = (item, hideDetails = false) => {
    if (!item) {
      return null
    }
    const panelHeader = (
      <SigKeyValue
        name={item.role}
        startCaseName
        alignValueToRight
        bordered={false}
        value={
          item.deeplinks ? (
            <DeeplinkPopover deeplinks={item.deeplinks}>
              {item.entityName}
            </DeeplinkPopover>
          ) : (
            item.entityName
          )
        }
        copyValue={item.entityName}
        seeder={item.seeder}
      />
    )
    if (hideDetails) {
      return panelHeader
    }
    const hasEkataReversePhoneJson = !!get('details.wppReversePhoneJson', item)

    return (
      <SigCardCollapse defaultActiveKey={['root']}>
        <Panel header={panelHeader} key="root">
          {hasEkataReversePhoneJson ? (
            <div>
              <SigKeyValueGroup
                title="Phone"
                properties={getEkataGeneralInfo(item)}
                removeUnknown={false}
                hideLastItemBorder
              />
              {getEkataBelongsTo(item)}
              {getEkataAssociatedPeople(item)}
              {getEkataAddresses(item)}
            </div>
          ) : (
            <SigKeyValue
              name={PHONE_ENTRY_NO_EKATA_DATA}
              bordered={false}
              isNameBold={false}
            />
          )}
        </Panel>
      </SigCardCollapse>
    )
  }

  const hideAccountPhoneDetails =
    get('entityName', confirmationPhone || {}) ===
    get('entityName', accountPhone || {})

  return (
    <SigCard title="Phones" inner>
      {renderPhone(confirmationPhone)}
      {renderPhone(accountPhone, hideAccountPhoneDetails)}
    </SigCard>
  )
}

export default PhoneEntry
