import { isNil, get, isNaN } from 'lodash'

type NotNullNillNaN = number | boolean | string | {} | Array<any>
export default function safeGet<T>(
  object: {},
  path: string,
  defaultValue: T
): T | NotNullNillNaN {
  const value = get(object, path)

  return isNil(value) || isNaN(value) ? defaultValue : value
}
