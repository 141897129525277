import { FC } from 'react'
import Menu from '../Menu'
import MenuItem from '../MenuItem'
import './CaseReportUIMenu.less'

type KEY = 'TOGGLE_NOTES_MODAL' | 'TOGGLE_NOTES_SIDER'

interface Props {
  className?: string
  notesCount: number
  notesModalVisible: boolean
  notesSiderVisible: boolean
  caseLoaded: boolean
  onToggleNotesModal: () => void
  onToggleNotesSider: () => void
}

const CaseReportUIMenu: FC<Props> = ({
  caseLoaded,
  className,
  notesCount,
  notesModalVisible,
  notesSiderVisible,
  onToggleNotesModal,
  onToggleNotesSider,
}) => {
  const selectedKeys: Array<KEY> = []

  if (notesModalVisible) {
    selectedKeys.push('TOGGLE_NOTES_MODAL')
  }
  if (notesSiderVisible) {
    selectedKeys.push('TOGGLE_NOTES_SIDER')
  }

  if (!caseLoaded) {
    return null
  }

  return (
    <Menu
      className={className}
      onClick={({ key }) => {
        const func = {
          TOGGLE_NOTES_MODAL: onToggleNotesModal,
          TOGGLE_NOTES_SIDER: onToggleNotesSider,
        }
        func[key as KEY]()
      }}
      selectedKeys={selectedKeys}
    >
      <MenuItem
        key="TOGGLE_NOTES_MODAL"
        className="CaseReportUIMenu--small"
        iconProps={{
          antIconType: 'file-text',
        }}
        tooltip="Notes"
        badgeCount={notesCount}
      />
      <MenuItem
        key="TOGGLE_NOTES_SIDER"
        className="CaseReportUIMenu--medium"
        iconProps={{
          antIconType: 'file-text',
        }}
        tooltip="Notes"
        badgeCount={notesCount}
        mouseEnterDelay={2}
      />
    </Menu>
  )
}

export default CaseReportUIMenu
