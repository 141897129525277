import { compose, withProps, withHandlers, setDisplayName } from 'recompose'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import caseFilterActions from 'caseReport/stores/caseFilters.actions'
import caseListActions from 'caseReport/stores/caseList.actions'
import CaseFiltersContainer from './CaseFiltersContainer'
import { LOOKBACK_LIMIT } from '../../Constants'

const enhance = compose(
  setDisplayName('CaseFiltersContainer'),
  inject('caseFiltersStore', 'caseListStore'),
  observer,
  withProps(({ caseFiltersStore, caseListStore }) => ({
    filters: toJS(caseFiltersStore.filters),
    dateFilter: toJS(caseFiltersStore.caseDateRange),
    actions: {
      caseFilter: caseFilterActions(caseFiltersStore),
      caseList: caseListActions(caseListStore),
    },
  })),
  withHandlers({
    handleSearchTermChange:
      ({ actions }) =>
      (e) => {
        const splitByComma = e.target.value.split(',')
        const trimmedValues = splitByComma.map((val) => val.trim())
        // return an empty array back to `searchTerms` instead of [""]
        // or the split/trimmed array of values ["a", "b c", "d"]
        const newSearchTerms = isEmpty(e.target.value) ? [] : trimmedValues
        actions.caseFilter.SET('searchTerms', newSearchTerms)
      },
    handleSortByChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('sortBy', value)
      },
    handleSortOrderChange:
      ({ actions }) =>
      (e) => {
        actions.caseFilter.SET('sortOrder', e.target.value)
      },
    handleCaseStatusChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('caseStatus', value)
      },
    handleDateRangeChange:
      ({ actions }) =>
      ([from, to]) => {
        actions.caseFilter.SET('caseDateRange', {
          min: moment.isMoment(from)
            ? from.format()
            : moment().subtract(
                LOOKBACK_LIMIT.DEFAULT_VALUE,
                LOOKBACK_LIMIT.DEFAULT_VALUE_UNIT
              ),
          max: moment.isMoment(to) ? to.format() : moment(),
        })
      },
    handleAmountChange:
      ({ actions, caseFiltersStore }) =>
      (type, e) => {
        actions.caseFilter.SET('caseAmountRange', {
          ...caseFiltersStore.filters.caseAmountRange,
          [type]: e.target.value,
        })
      },
    handleScoreChange:
      ({ actions, caseFiltersStore }) =>
      (type, e) => {
        actions.caseFilter.SET('caseScoreRange', {
          ...caseFiltersStore.filters.caseScoreRange,
          [type]: e.target.value,
        })
      },
    handleClaimStatusChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('claimStatus', value)
      },
    handleClaimDispositionsChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('claimDispositions', value)
      },
    handleGuaranteeStatusChange:
      ({ actions, caseFiltersStore }) =>
      (value) => {
        actions.caseFilter.SET('guaranteeStatus', value)

        const unrequestedSelected = [
          'GUARANTEE_FILTER_NOT_REQUESTED',
          'GUARANTEE_FILTER_BOTH',
        ].includes(caseFiltersStore.filters.guaranteeRequested)

        if (unrequestedSelected) {
          const valToSet =
            value.length > 0
              ? 'GUARANTEE_FILTER_BOTH'
              : 'GUARANTEE_FILTER_NOT_REQUESTED'
          actions.caseFilter.SET('guaranteeRequested', valToSet)
        }
      },
    handleDecisionedByChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('wasDecisionedByManualReview', value)
      },
    handleCaseFlag:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('investigationReviewDispositions', value)
      },
    handleAuthStatus:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('authorizationGatewayStatuses', value)
      },
    handleEscalatedChange:
      ({ actions }) =>
      (e) => {
        e.target.checked
          ? actions.caseFilter.SET('guaranteeReviewEscalationLevel', {
              min: '1',
              max: null,
            })
          : actions.caseFilter.SET('guaranteeReviewEscalationLevel', {
              min: null,
              max: null,
            })
      },
    handleReReviewOnlyChange:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('reReviewOnly', value.target.checked)
      },
    handleGuaranteeRequestedChange:
      ({ actions, caseFiltersStore }) =>
      (value) => {
        if (!value.target.checked) {
          actions.caseFilter.SET(
            'guaranteeRequested',
            'GUARANTEE_FILTER_REQUESTED'
          )
        } else {
          const valToSet =
            caseFiltersStore.filters.guaranteeStatus.length > 0
              ? 'GUARANTEE_FILTER_BOTH'
              : 'GUARANTEE_FILTER_NOT_REQUESTED'
          actions.caseFilter.SET('guaranteeRequested', valToSet)
        }
      },
    handleApplyFilterClick:
      ({ actions, caseFiltersStore }) =>
      () => {
        actions.caseList.getCaseList(caseFiltersStore.params)
      },
    handleDefaultFiltersClick:
      ({ actions, caseFiltersStore }) =>
      () => {
        actions.caseFilter.setDefaultFilters()
        actions.caseList.getCaseList(caseFiltersStore.params)
      },
    handleTeamSelect:
      ({ actions }) =>
      (value) => {
        actions.caseFilter.SET('teamsSelected', value)
      },
    handleCaseTypeChange:
      ({ actions }) =>
      (value) => {
        // if no filters only show live cases (not trial or test)
        if (!value.length) {
          actions.caseFilter.SET('isTrialCase', false)
          actions.caseFilter.SET('isTestInvestigation', false)

          return
        }

        if (value.includes('trial')) {
          actions.caseFilter.SET('isTrialCase', true)
        } else {
          actions.caseFilter.SET('isTrialCase', null)
        }

        if (value.includes('test')) {
          actions.caseFilter.SET('isTestInvestigation', true)
        } else {
          actions.caseFilter.SET('isTestInvestigation', null)
        }
      },
  })
)
export default enhance(CaseFiltersContainer)
