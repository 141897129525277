import {
  compose,
  branch,
  renderNothing,
  withProps,
  withHandlers,
} from 'recompose'
import { inject, observer } from 'mobx-react'
import caseReportUIActions from 'caseReport/stores/caseReportUI.actions'
import caseNotesActions from 'caseReport/stores/caseNotes.actions'
import { getNotesBreakpoint, NOTES_BREAKPOINTS } from 'caseReport/Constants'
import { LOADING_NAMESPACE } from 'caseReport/stores/caseReport.actions'
import NotesContainer from './NotesContainer'

const { SMALL } = NOTES_BREAKPOINTS
let prevBreakpoint = getNotesBreakpoint()
const enhance = compose(
  inject('caseNotesStore', 'caseReportStore', 'caseReportUIStore'),
  observer,
  branch(
    ({ caseNotesStore, caseReportStore }) =>
      !caseNotesStore.notes ||
      (caseReportStore.loading.pending &&
        caseReportStore.loading.namespace ===
          LOADING_NAMESPACE.GET_ALL_CASE_DATA_BY_ID),
    renderNothing
  ),
  withProps(({ caseNotesStore, caseReportUIStore }) => ({
    notesModalVisible: caseReportUIStore.notesModalVisible,
    notesSiderVisible: caseReportUIStore.notesSiderVisible,
    cannedNotesVisible: caseReportUIStore.cannedNotesVisible,
    actions: {
      caseReportUI: caseReportUIActions(caseReportUIStore),
      caseNotes: caseNotesActions(caseNotesStore),
    },
  })),
  withHandlers({
    onHideNotesModal:
      ({ actions }) =>
      () => {
        actions.caseReportUI.toggleNotesModal(false)
      },
    refreshNotesOnResize:
      ({ actions }) =>
      () => {
        const breakpoint = getNotesBreakpoint()
        if (prevBreakpoint === breakpoint) {
          return
        }

        if (prevBreakpoint === SMALL || breakpoint === SMALL) {
          actions.caseReportUI.toggleNotesModal(false)
          prevBreakpoint = breakpoint
        }
      },
    toggleCannedNotes:
      ({ actions }) =>
      (checked) => {
        actions.caseReportUI.toggleCannedNotes(checked)
      },
    handleCannedNoteSelected:
      ({ actions }) =>
      (cannedNote) => {
        actions.caseNotes.appendCannedNote(cannedNote)
      },
  })
)
export default enhance(NotesContainer)
