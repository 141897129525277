import { compose, withProps, withHandlers, lifecycle } from 'recompose'
import { inject, observer } from 'mobx-react'
import NotesAutoScrollContainer from './NotesAutoScrollContainer'

const enhance = compose(
  inject('caseNotesStore'),
  observer,
  withProps(({ caseNotesStore }) => ({
    notes: caseNotesStore.notes,
  })),
  withHandlers(() => {
    let ref

    const scrollToBottom = () => {
      if (!ref) {
        return
      }

      ref.scrollTop = ref.scrollHeight
    }

    return {
      onMounted: () => (elm) => {
        ref = elm
      },
      onNotesChange: () => () => {
        scrollToBottom()
      },
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.notes === this.props.notes) {
        return
      }

      this.props.onNotesChange()
    },
  })
)
export default enhance(NotesAutoScrollContainer)
