import { FC } from 'react'
import { get } from 'lodash'
import { Row, Col } from 'antd'
import SigCard from 'core/components/SigCard'
import SeederIcon from 'core/components/SeederIcon'
import caseReportActions, {
  LOADING_NAMESPACE,
} from 'caseReport/stores/caseReport.actions'
import AccountEntry from './AccountEntry'
import LocationEntry from './LocationEntry'
import PhoneEntry from './PhoneEntry'
import PeopleEntry from './PeopleEntry'
import NetworkEntry from './NetworkEntry'
import OrgnizationEntry from './OrgnizationEntry'
import DeviceEntry from './DeviceEntry'
import SellerEntries from './SellerEntry/SellerEntries'
import './CaseDetailsContainer.less'
import { MembershipEntries } from './MembershipEntries'
import useStores from 'useStores'
import { observer } from 'mobx-react'

const CaseDetailsContainer: FC = observer(() => {
  const { caseReportStore } = useStores()
  const { entries, entriesByRole, case: caseInfo, loading } = caseReportStore

  const { denylistConfirmationEmail, denylistDeliveryAddr } =
    caseReportActions(caseReportStore)

  if (loading.pending || !caseInfo) {
    return null
  }

  const {
    accountHolderTaxId,
    accountHolderTaxIdCountry,
    thirdPartyResearchCountryCode,
    sellers,
    currency,
    memberships,
  } = caseInfo

  return (
    <SigCard>
      <div className="CaseDetailsContainer--title">
        Details
        <span className="CaseDetailsContainer--title-extra">
          <SeederIcon /> Seeder Element (provided by buyer)
        </span>
      </div>
      <Row type="flex" gutter={30}>
        <Col sm={24} lg={12}>
          <PeopleEntry
            accountHolderTaxId={accountHolderTaxId}
            accountHolderTaxIdCountry={accountHolderTaxIdCountry}
            entriesByRole={entriesByRole}
            countryCode={thirdPartyResearchCountryCode}
          />
        </Col>
        <Col sm={24} lg={12}>
          <LocationEntry
            entriesByRole={entriesByRole}
            physicalLocations={entries.physicalLocations}
            countryCode={thirdPartyResearchCountryCode}
            normalizedBillingAddress={get(caseInfo, 'normalizedBillingAddress')}
            normalizedDeliveryAddress={get(
              caseInfo,
              'normalizedRecipients[0].address'
            )}
            denylistDeliveryAddrPending={
              loading.pending &&
              loading.namespace === LOADING_NAMESPACE.DENYLIST_DELIVERY_ADDRESS
            }
            cardHolderLastName={get(
              entriesByRole,
              'cardHolder[0].details.familyName',
              ''
            )}
            deliveryRecipientLastName={get(
              entriesByRole,
              'deliveryRecipient[0].details.familyName',
              ''
            )}
            onDenylistDeliveryAddr={denylistDeliveryAddr}
          />
        </Col>
        <Col sm={24} lg={12}>
          <AccountEntry
            entriesByRole={entriesByRole}
            case={caseInfo}
            denylistConfirmationEmailPending={
              loading.pending &&
              loading.namespace ===
                LOADING_NAMESPACE.DENYLIST_CONFIRMATION_EMAIL
            }
            onDenylistConfirmationEmail={denylistConfirmationEmail}
          />
        </Col>
        <Col sm={24} lg={12}>
          <PhoneEntry
            confirmationPhone={get(entriesByRole, 'confirmationPhone[0]')}
            accountPhone={get(entriesByRole, 'accountPhone[0]')}
            countryCode={thirdPartyResearchCountryCode}
          />
        </Col>
        <Col sm={24} lg={12}>
          <NetworkEntry
            networkLocations={entries.networkLocations}
            case={caseInfo}
          />
        </Col>
        <Col sm={24} lg={12}>
          <OrgnizationEntry organizations={entries.organizations} />
        </Col>
        <Col sm={24} lg={12}>
          <DeviceEntry deviceInfo={entries.deviceInfo} />
        </Col>
      </Row>
      {!!sellers.length && (
        <>
          <div className="CaseDetailsContainer--title">
            Seller Details
            <span className="CaseDetailsContainer--title-extra">
              <SeederIcon /> Seeder Element (provided by merchant)
            </span>
          </div>
          <Row type="flex" gutter={30}>
            <Col sm={24} lg={24}>
              <SellerEntries sellers={sellers} currency={currency || 'USD'} />
            </Col>
          </Row>
        </>
      )}
      {!!memberships?.length && (
        <>
          <div className="CaseDetailsContainer--title">
            Membership Details
            <span className="CaseDetailsContainer--title-extra">
              <SeederIcon /> Seeder Element (provided by merchant)
            </span>
          </div>
          <Row type="flex" gutter={30}>
            <Col sm={24} lg={24}>
              <MembershipEntries memberships={memberships} />
            </Col>
          </Row>
        </>
      )}
    </SigCard>
  )
})

export default CaseDetailsContainer
