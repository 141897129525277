import { FC } from 'react'
import GoogleCustomMap from './GoogleCustomMap'
import GoogleCustomMapPlaceholder from './GoogleCustomMapPlaceholder'

export type MarkerType =
  | 'BA'
  | 'DA'
  | 'IP'
  | 'BA_DA'
  | 'BA_IP'
  | 'DA_IP'
  | 'BA_DA_IP'

export type MarkerDetail = {
  lat: number
  lng: number
  type: MarkerType
}

export type GoogleCustomMapProps = {
  markerDetails: Array<MarkerDetail>
}

const GoogleCustomMapWrapper: FC<GoogleCustomMapProps> = ({
  markerDetails,
}) => {
  if (markerDetails.length === 0) {
    return <GoogleCustomMapPlaceholder />
  }

  return <GoogleCustomMap markerDetails={markerDetails} />
}

export default GoogleCustomMapWrapper
