import { Seller } from 'core/http/investigations/CaseSearchHttp.types'
import { FC, useState } from 'react'
import { Col, Row } from 'antd'
import { processSellers } from './utils/processSellers'
import SellerEntry from './SellerEntry'
import ShowMore from 'core/components/ShowMore/ShowMore'

interface Props {
  sellers: Array<Seller>
  currency: string
}

const DEFAULT_MAX_SELLERS_TO_DISPLAY = 5

const SellerEntries: FC<Props> = ({ sellers, currency }) => {
  const sellerViews = processSellers(sellers, currency)
  const [showAll, setShowAll] = useState<boolean>(false)

  if (!sellerViews.length) {
    return <></>
  }

  const numberMoreThanMaxItems =
    sellerViews.length - DEFAULT_MAX_SELLERS_TO_DISPLAY

  const sellersToShow = showAll
    ? sellerViews
    : sellerViews.slice(0, DEFAULT_MAX_SELLERS_TO_DISPLAY)

  return (
    <Col sm={24} lg={24} data-test-id="seller-entries-column">
      {sellersToShow.map(({ id, sellerDetails, sellerRecord }) => (
        <Row key={id} type="flex" gutter={30} data-test-id="seller-entry-row">
          <Col sm={24} lg={12} data-test-id="seller-entry-info-column">
            <SellerEntry title="Seller Information" seller={sellerDetails} />
          </Col>
          <Col sm={24} lg={12} data-test-id="seller-entry-record-column">
            <SellerEntry title="Seller Record" seller={sellerRecord} />
          </Col>
        </Row>
      ))}
      {sellerViews.length > DEFAULT_MAX_SELLERS_TO_DISPLAY && (
        <ShowMore
          numberMoreThanMaxItems={numberMoreThanMaxItems}
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </Col>
  )
}
export default SellerEntries
