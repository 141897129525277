import { observable } from 'mobx'
import type { AdditionalDecision } from 'core/http/cases/AdditionalDecisionsHttp.types'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'

export type TrainingMode = {
  caseId?: number | null
  decision?: AdditionalDecision | null
  guaranteeDisposition: INV_GUARANTEE_DISPOSITION
  caseReviewStart: string | number
}
const store: TrainingMode = observable({
  decision: null,
  caseId: null,

  get guaranteeDisposition(): INV_GUARANTEE_DISPOSITION {
    return (
      (store.decision?.additionalDecision as INV_GUARANTEE_DISPOSITION) ??
      INV_GUARANTEE_DISPOSITION.PENDING
    )
  },

  get caseReviewStart() {
    return store.decision?.startedAt ?? 0
  },
})

export default store
