import { isEmpty, get, isNil } from 'lodash'
import { Table } from 'antd'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import './MerchantHealthTable.less'
import type { MerchantHealth } from 'core/http/customers/CustomersHttp.types'

type Props = {
  teamMetadata: Array<MerchantHealth>
}

const MerchantHealthTable = ({ teamMetadata }: Props) => {
  if (isEmpty(teamMetadata)) {
    return null
  }

  const parseNumber = (index, keyName) => {
    const meta = get(teamMetadata[index], keyName)

    return isNil(meta) ? '--' : `$${formatCurrencyNumber(meta)}`
  }

  const parseNumberToPercent = (index, keyName) => {
    const meta = get(teamMetadata[index], keyName)

    return isNil(meta) ? '--' : `%${(meta * 100).toFixed(2)}`
  }

  const data = [
    {
      name: 'CB Rate',
      _7days: parseNumberToPercent(0, 'chargebackRate'),
      _30days: parseNumberToPercent(1, 'chargebackRate'),
      _90days: parseNumberToPercent(2, 'chargebackRate'),
    },
    {
      name: 'Approval Rate',
      _7days: parseNumberToPercent(0, 'approvalRate'),
      _30days: parseNumberToPercent(1, 'approvalRate'),
      _90days: parseNumberToPercent(2, 'approvalRate'),
    },
    {
      name: 'CB amount',
      _7days: parseNumber(0, 'chargebackAmount'),
      _30days: parseNumber(1, 'chargebackAmount'),
      _90days: parseNumber(2, 'chargebackAmount'),
    },
  ]

  return (
    <Table
      className="MerchantHealthTable"
      dataSource={data}
      pagination={false}
      rowKey="name"
      showHeader
    >
      <Table.Column
        className="MerchantHealthTable--header-col"
        title=""
        dataIndex="name"
      />
      <Table.Column
        className="MerchantHealthTable--data-col"
        title="7 days"
        dataIndex="_7days"
      />
      <Table.Column
        className="MerchantHealthTable--data-col"
        title="30 Days"
        dataIndex="_30days"
      />
      <Table.Column
        className="MerchantHealthTable--data-col"
        title="90 Days"
        dataIndex="_90days"
      />
    </Table>
  )
}

export default MerchantHealthTable
