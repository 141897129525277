import { Icon } from 'antd'
import { FC } from 'react'
import CasePageMenuIcon from 'caseReport/components/CasePageMenuIcon'
import SvgIcon from 'core/components/SvgIcon'
import './MenuItemIcon.less'
import { IconProps } from 'antd/lib/icon'

export interface MenuItemIconProps {
  antIconType?: string
  antIconProps?: IconProps
  svgIconType?: string
  spriteIconType?: string
}

// TODO FET-1997 use an icon:string and ICON_TYPE enum with a switch statement instead of separate icon properties.
// https://github.com/signifyd/frontend/pull/234#discussion_r773459175
export interface MenuItemIconPropsWithLoading extends MenuItemIconProps {
  loading?: boolean
}

const MenuItemIcon: FC<MenuItemIconPropsWithLoading> = ({
  loading,
  antIconType,
  svgIconType,
  spriteIconType,
  antIconProps,
}) => {
  if (loading) {
    return (
      <Icon
        data-test-id="loading-icon"
        className="CasePageMenuItem--icon"
        type="loading"
      />
    )
  }

  if (antIconType) {
    return (
      <Icon
        {...antIconProps}
        data-test-id={`antIconType-${antIconType}`}
        className="CasePageMenuItem--ant-icon"
        type={antIconType}
      />
    )
  }

  if (svgIconType) {
    return (
      <SvgIcon
        className="CasePageMenuItem--svg-icon"
        type={svgIconType}
        data-test-id={`svgIconType-${svgIconType}`}
      />
    )
  }

  if (spriteIconType) {
    return (
      <CasePageMenuIcon
        type={spriteIconType}
        data-test-id={`spriteIconType-${spriteIconType}`}
      />
    )
  }

  return null
}

export default MenuItemIcon
