import React, { FC } from 'react'
import SigTag from 'core/components/SigTag'

type Props = {
  guaranteeReviewEscalationLevel: number
  className?: string | null | undefined
}

const EscalatedCaseTag: FC<Props> = ({
  guaranteeReviewEscalationLevel,
  className,
}) =>
  guaranteeReviewEscalationLevel === 1 ||
  guaranteeReviewEscalationLevel === 2 ? (
    <SigTag className={className} size="large">
      ESCALATED
    </SigTag>
  ) : null

export default EscalatedCaseTag
