import { PureComponent } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './ButtonWithTimeout.less'

interface Props {
  onClick: () => void
  duration: number
  text: string | null | undefined
}

interface State {
  buttonTimer?: ReturnType<typeof setTimeout>
}

class ButtonWithTimeout extends PureComponent<Props, State> {
  state = {
    buttonTimer: undefined,
  }

  componentWillUnmount() {
    const { buttonTimer } = this.state
    if (buttonTimer) {
      clearTimeout(buttonTimer)
    }
  }

  onClickHandler = () => {
    const { buttonTimer } = this.state
    const { onClick } = this.props
    if (buttonTimer) {
      return
    }

    this._startTimer()

    onClick()
  }

  _startTimer = () => {
    const { duration = 0 } = this.props
    this.setState({
      buttonTimer: setTimeout(this._stopTimer, duration),
    })
  }

  _stopTimer = () => {
    const { buttonTimer } = this.state
    clearTimeout(buttonTimer)
    this.setState({
      buttonTimer: undefined,
    })
  }

  render() {
    const { buttonTimer } = this.state
    const { text } = this.props
    const disabled = !!buttonTimer

    return (
      <div
        className={joinClassNames([
          'ButtonWithTimeout',
          disabled && 'ButtonWithTimeout--disabled',
        ])}
        onClick={this.onClickHandler}
      >
        <span className="ButtonWithTimeout--text">{text}</span>
      </div>
    )
  }
}

export default ButtonWithTimeout
