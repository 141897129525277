import React, { FC } from 'react'
import { formatEntriesAddressLine } from './index'

type Props = {
  street?: string | null | undefined
  unit?: string | null | undefined
  cityName?: string | null | undefined
  regionAlpha?: string | null | undefined
  regionName?: string | null | undefined
  countryIsoCode?: string | null | undefined
  postalCode?: string | null | undefined
  hasLineBreak?: boolean
}

const FormattedEntryAddress: FC<Props> = ({
  street,
  unit,
  cityName,
  regionAlpha,
  regionName,
  countryIsoCode,
  postalCode,
  hasLineBreak,
}) => {
  return hasLineBreak ? (
    <span>
      {street && (
        <span>
          {street} {unit}
          <br />
        </span>
      )}
      <span>
        {formatEntriesAddressLine({
          cityName,
          regionAlpha,
          regionName,
          countryIsoCode,
          postalCode,
        })}
      </span>
    </span>
  ) : (
    <span>
      {formatEntriesAddressLine({
        street,
        unit,
        cityName,
        regionAlpha,
        regionName,
        countryIsoCode,
        postalCode,
      })}
    </span>
  )
}

export default FormattedEntryAddress
