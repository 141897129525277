import React, { FC } from 'react'
import ExternalLink from 'core/components/ExternalLink'
import { CaseDetailsSprite } from 'core/components/Sprite'
import './Deeplink.less'
import type { CaseDetailsIconType } from 'core/components/Sprite'

export type Props = {
  iconType: CaseDetailsIconType
  label: string
  url: string
}

const Deeplink: FC<Props> = ({ iconType, label, url }) => (
  <ExternalLink cssClass="Deeplink" url={url}>
    <CaseDetailsSprite type={iconType} className="Deeplink--icon" />{' '}
    <span className="Deeplink--text">{label}</span>
  </ExternalLink>
)

export default Deeplink
