import type { EntriesByRole } from 'caseReport/stores/caseReport.store'
import type { LinkKey, LinkKeysSubset } from './constants'
import safeGet from 'core/utils/safeGet'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type {
  PhysicalLocationDetails,
  PeopleDetails,
} from 'core/http/cases/EntriesHttp.types'
import {
  searchForOtherCases,
  googleWebFullName,
  googleWebLastName,
  EkataIDCheck,
  linkedinFullName,
  facebookFullName,
  _118712LastNameAndZipCode,
  pagesJaunesLastNameAndZipCode,
  _118000LastNameAndZipCode,
  telFRLastNameAndZipCode,
  pagineBiancheLastNameAndZipCode,
  abcTelefonosLastNameAndZipCode,
  espanaDirectorioLastNameAndZipCode,
  WPAULastNameAndZipCode,
  GOOGLE_FULL_NAME_BILLING_LABEL,
  GOOGLE_LAST_NAME_BILLING_LABEL,
  GOOGLE_FULL_NAME_DELIVERY_LABEL,
  GOOGLE_LAST_NAME_DELIVERY_LABEL,
  LAST_NAME_AND_BILLING_ZIP_CODE,
  LAST_NAME_AND_DELIVERY_ZIP_CODE,
  piplFullName,
  PIPL_FULL_NAME_BILLING_LABEL,
  PIPL_FULL_NAME_DELIVERY_LABEL,
} from 'core/utils/deeplinks'
import { LINK_KEYS } from './constants'

type LinkLabels = {
  googleFullName: string
  googleLastName: string
  lastNameAndZipCode: string
  piplFullName: string
}
type LinkResults = Array<DeeplinkProps | false>

const getPeopleLinks = (
  entriesByRole: EntriesByRole,
  peopleDetailsPath: string,
  addressEntityNamePath: string,
  addressDetailsPath: string,
  labels: LinkLabels,
  linkKeys: Set<LinkKey>
): LinkResults => {
  const people: PeopleDetails = safeGet(entriesByRole, peopleDetailsPath, {})
  const fullAddress = safeGet(entriesByRole, addressEntityNamePath, '')
  const addressDetails: PhysicalLocationDetails = safeGet(
    entriesByRole,
    addressDetailsPath,
    {}
  )
  const { givenName: firstName, fullName, familyName: lastName } = people
  const { cityName, regionAlpha, postalCode } = addressDetails

  return [
    // common
    linkKeys.has(LINK_KEYS.SEARCH_FOR_OTHER_CASES) &&
      searchForOtherCases(fullName),
    linkKeys.has(LINK_KEYS.GOOGLE_FULL_NAME) &&
      googleWebFullName(fullName, cityName, regionAlpha, labels.googleFullName),
    linkKeys.has(LINK_KEYS.GOOGLE_LAST_NAME) &&
      googleWebLastName(lastName, fullAddress, labels.googleLastName),
    linkKeys.has(LINK_KEYS.PIPL_FULL_NAME) &&
      piplFullName(
        firstName,
        lastName,
        cityName,
        regionAlpha,
        labels.piplFullName
      ),
    linkKeys.has(LINK_KEYS.EKATA_ID_CHECK) && EkataIDCheck(entriesByRole),
    linkKeys.has(LINK_KEYS.LINKEDIN_FULL_NAME) && linkedinFullName(fullName),
    linkKeys.has(LINK_KEYS.FACEBOOK_FULL_NAME) && facebookFullName(fullName), // France
    linkKeys.has(LINK_KEYS._118712) &&
      _118712LastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ),
    linkKeys.has(LINK_KEYS.PAGES_JAUNES) &&
      pagesJaunesLastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ),
    linkKeys.has(LINK_KEYS._118000) &&
      _118000LastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ),
    linkKeys.has(LINK_KEYS.TEL_FR) &&
      telFRLastNameAndZipCode(lastName, postalCode, labels.lastNameAndZipCode), // Italy
    linkKeys.has(LINK_KEYS.PAGINE_BIANCHE) &&
      pagineBiancheLastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ), // Spain
    linkKeys.has(LINK_KEYS.ABC_TELEFONOS) &&
      abcTelefonosLastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ),
    linkKeys.has(LINK_KEYS.ESPANA_DIRECTORIO) &&
      espanaDirectorioLastNameAndZipCode(
        lastName,
        postalCode,
        labels.lastNameAndZipCode
      ), // Australia
    linkKeys.has(LINK_KEYS.WHITE_PAGES_AU) &&
      WPAULastNameAndZipCode(lastName, postalCode, labels.lastNameAndZipCode),
  ].filter((item) => item)
}

export const getCardHolderLinks = (
  entriesByRole: EntriesByRole,
  linkKeys: LinkKeysSubset
) =>
  getPeopleLinks(
    entriesByRole,
    'cardHolder[0].details',
    'billingAddress[0].entityName',
    'billingAddress[0].details',
    {
      googleFullName: GOOGLE_FULL_NAME_BILLING_LABEL,
      googleLastName: GOOGLE_LAST_NAME_BILLING_LABEL,
      lastNameAndZipCode: LAST_NAME_AND_BILLING_ZIP_CODE,
      piplFullName: PIPL_FULL_NAME_BILLING_LABEL,
    },
    new Set(linkKeys)
  )
export const getDeliveryRecipientLinks = (
  entriesByRole: EntriesByRole,
  linkKeys: LinkKeysSubset
) =>
  getPeopleLinks(
    entriesByRole,
    'deliveryRecipient[0].details',
    'deliveryAddress[0].entityName',
    'deliveryAddress[0].details',
    {
      googleFullName: GOOGLE_FULL_NAME_DELIVERY_LABEL,
      googleLastName: GOOGLE_LAST_NAME_DELIVERY_LABEL,
      lastNameAndZipCode: LAST_NAME_AND_DELIVERY_ZIP_CODE,
      piplFullName: PIPL_FULL_NAME_DELIVERY_LABEL,
    },
    new Set(linkKeys)
  )
export const getTaxLinks: Array<DeeplinkProps> = [
  {
    iconType: 'ph3a',
    label: 'Tax ID search',
    url: 'https://www.ph3a.com.br/databusca/search/index',
  },
]
