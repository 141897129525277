import { FC, ReactNode } from 'react'
import SigKeyValue from 'core/components/SigKeyValue'
import { SigKeyValueProps } from '../SigKeyValue/SigKeyValue'

type Props = {
  title?: string
  titleValue?: ReactNode
  properties: Array<SigKeyValueProps>
  placeholder?: string
  hideLastItemBorder?: boolean
  removeUnknown?: boolean
}

const filterProperties = (
  properties: Array<SigKeyValueProps>,
  removeUnknown: boolean
): Array<SigKeyValueProps> => {
  return properties.filter((item) => {
    if (!item?.value) {
      return false
    }

    if (typeof item.value !== 'string') {
      return true
    }

    return !removeUnknown || item.value.toLowerCase() !== 'unknown'
  })
}

const SigKeyValueGroup: FC<Props> = ({
  title,
  titleValue,
  properties = [],
  placeholder = 'No information available',
  hideLastItemBorder = false,
  removeUnknown = false,
}) => {
  const filteredProperties = filterProperties(properties, removeUnknown)
  const showPlaceholder = !filteredProperties.length

  if (showPlaceholder) {
    return <div>{placeholder}</div>
  }

  return (
    <div>
      {title && (
        <SigKeyValue name={title} value={titleValue} isTitle isValueBold />
      )}
      {filteredProperties.map(({ ...itemProps }, index) => (
        <SigKeyValue
          key={index}
          {...itemProps}
          bordered
          hideLastItemBorder={hideLastItemBorder}
        />
      ))}
    </div>
  )
}

export default SigKeyValueGroup
