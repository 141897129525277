import { Tooltip, Icon } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import SvgIcon from 'core/components/SvgIcon'
import './CaseListItemIcon.less'

type Props = {
  type:
    | 'guarantee'
    | 'claim'
    | 'review-disposition'
    | 'review-escalation'
    | 'agent-feedback-tags'
  value?: string | null
  className?: string | null
}

const CaseListItemIcon = ({ type, value, className }: Props) => {
  if (!value) {
    return null
  }

  const transformText = (text: string) => text.replace(/_/g, ' ').toLowerCase()

  let icon
  let tooltip

  switch (type) {
    case 'claim':
      icon = (
        <div
          className={joinClassNames([
            'CaseListItemIcon',
            'CaseListItemIcon--claim',
            className,
          ])}
        >
          <SvgIcon
            type="CHARGEBACK"
            className={`CaseListItemIcon--claim-${value}`}
          />
        </div>
      )
      tooltip = `Chargeback claim ${transformText(value)}`
      break

    case 'guarantee':
    case 'review-disposition':
      icon = (
        <div
          className={joinClassNames([
            'CaseListItemIcon',
            `CaseListItemIcon--${type}`,
            `CaseListItemIcon--${type}-${value}`,
            className,
          ])}
        />
      )
      tooltip =
        type === 'guarantee' ? `Guarantee ${transformText(value)}` : null
      break

    case 'review-escalation':
      icon = (
        <div className="CaseListItemIcon">
          <SvgIcon
            type="CASE_ESCALATED"
            className="CaseListItemIcon--case-escalated"
          />
        </div>
      )
      tooltip = 'Case is Escalated'
      break

    case 'agent-feedback-tags':
      icon = (
        <Icon
          className={joinClassNames([
            'CaseListItemIcon',
            `CaseListItemIcon--${type}`,
            className,
          ])}
          type="tags"
        />
      )
      tooltip = type === 'agent-feedback-tags' ? value : null
      break

    default:
      return null
  }

  return (
    <Tooltip title={tooltip} placement="bottom">
      {icon}
    </Tooltip>
  )
}

export default CaseListItemIcon
