import { action } from 'mobx'
import type { TrainingMode } from './trainingMode.store'
import appStore from 'AppStore'
import {
  addDecision,
  getDecisions,
  updateDecision,
  getAdditionalDecisionNotes,
  addAdditionalDecisionNote,
} from 'core/http/cases/AdditionalDecisionsHttp'
import type { AdditionalDecision } from 'core/http/cases/AdditionalDecisionsHttp.types'
import {
  CaseNote,
  CaseNotesResponse,
  INV_GUARANTEE_DISPOSITION,
} from '@signifyd/http'
import asyncActions from './async.actions'
import { AxiosResponse } from 'axios'

const trainingModeActions = (store: TrainingMode) => {
  const { asyncAction } = asyncActions(store)

  const _resetStore = action((caseId: number) => {
    store.decision = null
    store.caseId = caseId
  })

  const _setDecision = action((newdecision: AdditionalDecision) => {
    store.decision = newdecision
  })

  const _getCaseId = () => {
    if (!store.caseId) {
      throw new Error('No caseId for additionalDecisions')
    }

    return store.caseId
  }

  const createAdditionalDecision = () => {
    const caseId = _getCaseId()

    return asyncAction(() =>
      addDecision(caseId).then(({ data: additionalDecision }) => {
        _resetStore(caseId)

        _setDecision(additionalDecision)
      })
    )
  }

  const getAdditionalDecisionData = (caseId: number) => {
    return asyncAction(() =>
      getDecisions(caseId).then(({ data: decisions }) => {
        _resetStore(caseId)

        const prevDecision = decisions.find(
          (decision) => decision.addedBy === appStore.user?.userId
        )

        if (prevDecision) {
          _setDecision(prevDecision)

          return prevDecision
        }

        return null
      })
    )
  }

  const updateAdditionalDecision = (disposition: INV_GUARANTEE_DISPOSITION) => {
    return asyncAction(() => {
      const caseId = _getCaseId()
      const updateAction = store.decision
        ? updateDecision(caseId, store.decision.decisionId, disposition)
        : createAdditionalDecision().then(() =>
            updateAdditionalDecision(disposition)
          )

      return updateAction.then(({ data: decision }) => {
        _setDecision(decision)

        return decision
      })
    })
  }

  const getNotesForAdditionalDecision = (
    caseId: number
  ): Promise<CaseNotesResponse> => {
    const decisionId = store.decision?.decisionId

    return decisionId
      ? getAdditionalDecisionNotes(caseId, decisionId)
      : Promise.resolve({})
  }

  const addNoteToAdditionalDecision = async (
    caseId: number,
    note: string
  ): Promise<Pick<AxiosResponse<CaseNote>, 'data'> | null> => {
    const decisionId = store.decision?.decisionId

    if (!decisionId) {
      return null
    }

    const { data } = await addAdditionalDecisionNote(caseId, decisionId, note)

    const addedNote = data[0]

    return addedNote ? { data: addedNote } : null
  }

  return {
    createAdditionalDecision,
    getAdditionalDecisionData,
    updateAdditionalDecision,
    getNotesForAdditionalDecision,
    addNoteToAdditionalDecision,
  }
}

export default trainingModeActions
