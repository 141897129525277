import { Popover, Icon } from 'antd'
import './CaseSearchHelpHint.less'

/**
 * Help hint text
 */
const helpHintText = (
  <div className="CaseSearchHelpHint">
    <h6>
      To search by any of the following fields enter the value you are searching
      for:
    </h6>
    <ul>
      <li>Cardholder Name</li>
      <li>External Order ID</li>
      <li>Investigation / Case ID</li>
      <li>Order Total Amount</li>
      <li>Platform Order ID</li>
      <li>Recipient Full Name</li>
      <li>Recipient Confirmation Email Address</li>
      <li>Transaction ID</li>
      <li>User Account Email Address</li>
      <li>User Account Phone Number</li>
      <li>User Account Username</li>
      <li>Checkout Token</li>
      <li>Seller ID</li>
      <li>Parent Entity</li>
      <li>Seller Name</li>
    </ul>
  </div>
)

/**
 * Help hint next to "Search Terms" in Solr Search
 */
const CaseSearchHelpHint = (): JSX.Element => (
  <Popover content={helpHintText} placement="bottomLeft">
    <Icon
      data-test-id="searchHelpHint"
      type="question-circle"
      className="CaseSearchHelpHint--icon"
    />
  </Popover>
)

export default CaseSearchHelpHint
