import { Col, Form, Input, Row, Select } from 'antd'
import { FC } from 'react'
import './UpdateAddressForm.less'
import { UpdateAddressFormValues } from './UpdateAddress.utils'
import { Control, Controller } from 'react-hook-form'
import { COUNTRY_ISO_OPTIONS } from '@signifyd/http'

interface Props {
  control: Control<UpdateAddressFormValues>
}

const { Item } = Form
const { Option } = Select

const UpdateAddressForm: FC<Props> = ({ control }) => (
  <Form className="updateAddressForm">
    <Row gutter={16}>
      <Col span={24}>
        <Item
          label={<span className="formLabel">Full Name</span>}
          className="formItem"
        >
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressFullname"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={6}>
        <Item
          label={<span className="formLabel">Unit or Suite</span>}
          className="formItem"
        >
          <Controller
            name="unit"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressUnit"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
      <Col span={18}>
        <Item
          label={<span className="formLabel">Street Address</span>}
          className="formItem"
        >
          <Controller
            name="streetAddress"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressStreetAddress"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Item
          label={<span className="formLabel">State</span>}
          className="formItem"
        >
          <Controller
            name="provinceCode"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressProvinceCode"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          label={<span className="formLabel">City</span>}
          className="formItem"
        >
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressCity"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Item
          label={<span className="formLabel">Zip Code</span>}
          className="formItem"
        >
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <Input
                defaultValue={field.value ?? undefined}
                onChange={field.onChange}
                data-test-id="updateAddressPostalCode"
                className="formInput"
              />
            )}
          />
        </Item>
      </Col>
      <Col span={12}>
        <Item
          label={<span className="formLabel">Country</span>}
          className="formItem"
        >
          <Controller
            name="countryCode"
            control={control}
            render={({ field }) => (
              <Select
                defaultValue={field.value}
                onChange={field.onChange}
                data-test-id="updateAddressCountryCode"
              >
                {COUNTRY_ISO_OPTIONS.map(({ value, label }) => (
                  <Option key={value} value={value} title={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          />
        </Item>
      </Col>
    </Row>
  </Form>
)

export default UpdateAddressForm
