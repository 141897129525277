import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import { inject, observer } from 'mobx-react'
import moment from 'moment-timezone'
import {
  compose,
  withHandlers,
  withState,
  withProps,
  lifecycle,
} from 'recompose'
import CaseReviewTimer from './CaseReviewTimer'

const enhance = compose(
  inject('appStore', 'caseReportStore', 'trainingModeStore'),
  observer,
  withProps(({ appStore, caseReportStore, trainingModeStore }) => ({
    guaranteeDisposition: appStore.isTraining
      ? trainingModeStore.guaranteeDisposition
      : caseReportStore.case.guaranteeDisposition,
    getReviewStartTimeInSeconds: () => {
      const reviewCreatedAt = appStore.isTraining
        ? trainingModeStore.caseReviewStart
        : caseReportStore.caseReviewStart
      const now = moment(new Date())
      const startTime = reviewCreatedAt === 0 ? now : moment(reviewCreatedAt)
      const duration = moment.duration(now.diff(startTime))
      const startTimeToSeconds = Math.round(duration.asSeconds())

      return startTimeToSeconds
    },
  })),
  withState('caseTimer', 'setCaseTimer', null),
  withState('interval', 'setTimerInterval', null),
  withHandlers({
    toggleCaseTimer:
      ({ setCaseTimer, setTimerInterval, getReviewStartTimeInSeconds }) =>
      () => {
        const oneSecondInMilliseconds = 1000
        const oneMinuteInMilliseconds = 60000
        const oneHourInSeconds = 3600
        const caseTimerInterval =
          getReviewStartTimeInSeconds() >= oneHourInSeconds
            ? oneMinuteInMilliseconds
            : oneSecondInMilliseconds
        setTimerInterval(
          setInterval(() => {
            // don't use destructered { caseInReviewStartTimeInSeconds } here,
            // call for a fresh one everytime
            setCaseTimer(getReviewStartTimeInSeconds())
          }, caseTimerInterval)
        )
      },
  }),
  lifecycle({
    componentDidMount() {
      const { getReviewStartTimeInSeconds } = this.props
      // set the initial time os "00m 00s" does not flash
      this.props.setCaseTimer(getReviewStartTimeInSeconds())

      if (
        this.props.guaranteeDisposition === INV_GUARANTEE_DISPOSITION.IN_REVIEW
      ) {
        // update every second if the UI will display the "XXm XXs" ticker (less than an hour)
        this.props.toggleCaseTimer()
      }
    },

    componentWillUnmount() {
      clearInterval(this.props.interval)
    },
  })
)
export default enhance(CaseReviewTimer)
