import { $Keys } from 'utility-types'
import { FC } from 'react'
import { Button, Tooltip } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import ExternalLink from 'core/components/ExternalLink'
import type { CountAttrs } from '@signifyd/http'
import buildUrl from 'core/utils/buildUrl/buildUrl'
import { getCaseSearchUrl } from '@signifyd/legacy-components'
import './VelocityCountButton.less'

export type VelocityType = $Keys<typeof BUTTON_TYPE_MAP>
export interface VelocityCountButtonProps {
  className: string
  type: VelocityType
  loading: boolean
  countData: CountAttrs | null
  caseId: string
}

const BUTTON_TYPE_MAP = {
  billingAddressCount: {
    icon: 'credit-card',
    title: 'Billing Address',
  },
  deliveryAddressCount: {
    icon: 'home',
    title: 'Delivery Address',
  },
  confirmationEmailCount: {
    icon: 'mail',
    title: 'Confirmation Email',
  },
  confirmationPhoneCount: {
    icon: 'mobile',
    title: 'Confirmation Phone',
  },
  deviceIdCount: {
    icon: 'laptop',
    title: 'Device ID',
  },
  ipAddressCount: {
    icon: 'environment',
    title: 'IP Address',
  },
}

const VelocityCountButton: FC<VelocityCountButtonProps> = ({
  className,
  type,
  loading,
  countData,
  caseId,
}) => {
  const url = buildUrl(getCaseSearchUrl(), {
    similarityInvestigationId: caseId,
    similarityAttributes: countData?.similarityAttributes[0] ?? undefined,
    isTestInvestigation: 'false',
  })
  const count = countData?.count

  if (count === undefined || loading) {
    return (
      <Button
        data-test-id="velocityCountLoading"
        loading={loading}
        className={joinClassNames(['VelocityCountButton', className])}
        type="primary"
        icon={`${BUTTON_TYPE_MAP[type].icon}`}
        size="small"
      />
    )
  }

  return (
    <Tooltip
      placement="top"
      title={`${BUTTON_TYPE_MAP[type].title} ${
        count > 0
          ? `seen ${count} time${count > 1 ? 's' : ''}`
          : 'has not been seen'
      }  in the last year. To expand number of results, edit time range in Case Search up to four years.`}
    >
      <Button
        data-test-id="velocityCountButton"
        loading={loading}
        className={joinClassNames(['VelocityCountButton', className])}
        type="primary"
        icon={`${BUTTON_TYPE_MAP[type].icon}`}
        size="small"
      >
        {` ${count}`}
        <ExternalLink url={url} cssClass="VelocityCountButton--link" />
      </Button>
    </Tooltip>
  )
}

export default VelocityCountButton
