import React, { FC } from 'react'
import { Popover, Tooltip } from 'antd'
import type { MenuProps } from '../CasePageSiderMenu.types'
import { enhancePopover } from 'core/components/PopoverContainer'
import type { EnhancePopoverProps } from 'core/components/PopoverContainer'
import { CASE_PAGE_SIDER_TOOLTIPS } from 'caseReport/Constants'
import CaseFiltersContainer from 'caseReport/containers/CaseFiltersContainer'
import CasePageSiderMenuTitle from '../CasePageSiderMenuTitle'
import CasePageSiderMenuIcons, {
  ClickableIcon,
} from '../CasePageSiderMenuIcons'
import './CaseListMenu.less'

type Props = MenuProps & EnhancePopoverProps

const CaseListMenu: FC<Props> = ({
  loading,
  canToggleCaseQueue,
  handleToggleCaseQueue,
  handleRefresh,
  popoverVisible,
  setPopoverVisible,
  getPopoverContainer,
}) => (
  <div className="CaseListMenu">
    <CasePageSiderMenuTitle
      enabled={canToggleCaseQueue && !loading}
      onClick={() => canToggleCaseQueue && handleToggleCaseQueue(true)}
      text="Case List"
      tooltip={CASE_PAGE_SIDER_TOOLTIPS.SWITCH_TO_CASE_QUEUE}
    />
    <CasePageSiderMenuIcons loading={loading} onRefresh={handleRefresh}>
      <Tooltip
        title={CASE_PAGE_SIDER_TOOLTIPS.FILTER}
        placement="bottom"
        mouseEnterDelay={0.5}
      >
        <ClickableIcon
          type="filter"
          onClick={() => setPopoverVisible(true)}
          data-test-id="filterModalButton"
        />
      </Tooltip>
      <Popover
        content={<CaseFiltersContainer />}
        trigger="click"
        placement="rightTop"
        getPopupContainer={getPopoverContainer}
        visible={popoverVisible}
      >
        <span className="CaseListMenu--popover-trigger" />
      </Popover>
    </CasePageSiderMenuIcons>
  </div>
)

export default enhancePopover(CaseListMenu)
