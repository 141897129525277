import { $Keys } from 'utility-types'
import React, { FC } from 'react'
import { Button, Icon, Tooltip, Modal } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import './DenylistBtn.less'

type Props = {
  type: $Keys<typeof DENYLIST_ITEMS>
  denylisted: boolean | null | undefined
  loading: boolean | null | undefined
  modalVisible: boolean
  handleOk: () => void
  setModalVisible: (arg0: boolean) => void
}
const DENYLIST_ITEMS = {
  CONFIRMATION_EMAIL: {
    TOOLTIP: 'Denylist Confirmation Email',
    CONFIRM_MSG: 'Are you sure you want to denylist this Confirmation Email?',
    ICON: 'mail',
  },
  DELIVERY_ADDRESS: {
    TOOLTIP: 'Denylist Delivery Address',
    CONFIRM_MSG: 'Are you sure you want to denylist this Delivery Address?',
    ICON: 'home',
  },
}

const DenylistBtn: FC<Props> = ({
  type,
  denylisted,
  loading,
  modalVisible,
  handleOk,
  setModalVisible,
}) => {
  const { TOOLTIP, ICON, CONFIRM_MSG } = DENYLIST_ITEMS[type]

  return (
    <Tooltip title={TOOLTIP}>
      <Button
        className={joinClassNames([
          'DenylistBtn',
          denylisted && 'DenylistBtn--denylisted',
        ])}
        type="primary"
        loading={loading}
        onClick={() => !denylisted && setModalVisible(true)}
        size="small"
        data-test-id={`denyListButton-${denylisted ? 'Disabled' : 'Enabled'}`}
      >
        {!loading && <Icon type={ICON} />}
      </Button>
      {!denylisted && (
        <Modal
          okText="Yes"
          cancelText="No"
          visible={modalVisible}
          onOk={handleOk}
          onCancel={() => setModalVisible(false)}
        >
          <h6>{CONFIRM_MSG}</h6>
        </Modal>
      )}
    </Tooltip>
  )
}

export default DenylistBtn
