import React, { FC } from 'react'
import './PopoverContainer.less'

type Props = {
  visible: boolean
  onMounted: (arg0: HTMLElement | null | undefined) => void
  setVisible: (arg0: boolean) => void
}

const PopoverContainer: FC<Props> = ({ visible, onMounted, setVisible }) => (
  <div className="PopoverContainer" ref={onMounted}>
    {visible && (
      <div
        className="PopoverContainer--backdrop"
        onClick={() => setVisible(false)}
        role="presentation"
      />
    )}
  </div>
)

export default PopoverContainer
