import React, { FC } from 'react'
import SvgIcon from 'core/components/SvgIcon'
import { joinClassNames } from '@signifyd/utils'
import './SeederIcon.less'

type Props = {
  className?: string
}

const SeederIcon: FC<Props> = ({ className }) => (
  <SvgIcon
    className={joinClassNames(['SeederIcon', className])}
    type="SEEDER_ICON"
  />
)

export default SeederIcon
