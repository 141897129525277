import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { compose, withProps, withHandlers } from 'recompose'
import orderReviewChecklistActions from 'caseReport/stores/orderReviewChecklist.actions'
import caseNotesActions from 'caseReport/stores/caseNotes.actions'
import OrderReviewChecklistContainer from './OrderReviewChecklistContainer'

const enhance = compose(
  inject('orderReviewChecklistStore', 'caseNotesStore', 'appStore'),
  observer,
  withProps(({ orderReviewChecklistStore, caseNotesStore, appStore }) => {
    return {
      selectValues: toJS(orderReviewChecklistStore.selectValues),
      textValues: orderReviewChecklistStore.textValues,
      comments: orderReviewChecklistStore.comments,
      isEmpty: orderReviewChecklistStore.isEmpty,
      cannotAddNotes: appStore.isTraining && caseNotesStore.notes.length > 0,
      loading: caseNotesStore.loading.pending,
      actions: {
        orderReviewChecklist: orderReviewChecklistActions(
          orderReviewChecklistStore
        ),
        caseNotes: caseNotesActions(caseNotesStore),
      },
    }
  }),
  withHandlers({
    handleSelectChange:
      ({ actions }) =>
      (key, value) => {
        actions.orderReviewChecklist.setSelectValue(key, value)
      },
    handleTextChange:
      ({ actions }) =>
      (key, value) => {
        actions.orderReviewChecklist.setTextValue(key, value)
      },
    handleCommentsChange:
      ({ actions }) =>
      (value) => {
        actions.orderReviewChecklist.setComments(value)
      },
    handleReset:
      ({ actions }) =>
      () => {
        actions.orderReviewChecklist.reset()
      },
    handleAddToNote:
      ({ orderReviewChecklistStore, actions, cannotAddNotes }) =>
      () => {
        if (cannotAddNotes) {
          return
        }
        const noteJSON = orderReviewChecklistStore.caseNoteJSON
        actions.caseNotes.addReviewChecklistNote(noteJSON).then(() => {
          actions.orderReviewChecklist.reset()
        })
      },
  })
)
export default enhance(OrderReviewChecklistContainer)
