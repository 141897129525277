import { get } from 'lodash'
import SigKeyValue from 'core/components/SigKeyValue'
import ExternalLink from 'core/components/ExternalLink'
import type { Account } from 'core/http/cases/EntriesHttp.types'

type Props = {
  accounts: Array<Account>
}

const SocialProfileAccounts = ({ accounts }: Props) => (
  <div>
    {accounts.map((account, index) => {
      const url = get(account, 'details.url')

      if (!url) {
        return null
      }

      const accountType = get(account, 'details.accountType')

      return (
        <SigKeyValue // eslint-disable-next-line react/no-array-index-key
          key={index}
          name={accountType ? `${accountType} Account` : 'Social Profile'}
          startCaseName
          value={
            <ExternalLink saveVisited={false} url={url}>
              {account.entityName}
            </ExternalLink>
          }
          seeder={account.seeder}
          alignValueToRight
        />
      )
    })}
  </div>
)

export default SocialProfileAccounts
