import { mapProps } from 'recompose'
import { groupBy, map, isNil, filter, flow } from 'lodash/fp'
import type { PhysicalLocation } from 'core/http/cases/EntriesHttp.types'
import CaseMapMarkers from './CaseMapMarkers'

type OwnerProps = {
  locations: Array<PhysicalLocation>
  height?: number | string
}
export function mapPhysicalLocationsToMarkers(
  locations: Array<PhysicalLocation>
) {
  const LOCATION_ROLE_TO_MARKER_TYPE = {
    billingAddress: 'BA',
    deliveryAddress: 'DA',
    ipGeo: 'IP',
  }

  const mapLocationRoleToMarkerType = ({ role }: PhysicalLocation) =>
    LOCATION_ROLE_TO_MARKER_TYPE[role]

  const removeInvalidLocations = filter(
    ({ details, role }: PhysicalLocation): boolean =>
      !isNil(details.latitude) &&
      !isNil(details.longitude) &&
      LOCATION_ROLE_TO_MARKER_TYPE.hasOwnProperty(role)
  )
  const groupLocations = groupBy(
    ({ details }: PhysicalLocation): string =>
      `${String(details.latitude)}_${String(details.longitude)}`
  )
  const convertLocationsToMarkers = map(
    (locations: Array<PhysicalLocation>) => ({
      lat: locations[0].details.latitude,
      lng: locations[0].details.longitude,
      type: map(mapLocationRoleToMarkerType, locations).sort().join('_'),
    })
  )

  return flow([
    removeInvalidLocations,
    groupLocations,
    convertLocationsToMarkers,
  ])(locations)
}
const enhance = mapProps(({ locations, height }: OwnerProps) => ({
  markerDetails: mapPhysicalLocationsToMarkers(locations),
  height,
}))
export default enhance(CaseMapMarkers)
