import { isEmpty } from 'lodash'
import {
  compose,
  mapProps,
  branch,
  renderComponent,
  renderNothing,
} from 'recompose'
import { inject, observer } from 'mobx-react'
import {
  CASE_LIST_EMPTY_CASE_MSG,
  CASE_QUEUE_EMPTY_CASE_MSG,
} from 'caseReport/Constants'
import { LOADING_NAMESPACE } from 'caseReport/stores/caseList.actions'
import CaseListAlertContainer, {
  CaseListLoader,
} from './CaseListAlertContainer'

const enhance = compose(
  inject('caseListStore'),
  observer,
  branch(({ caseListStore }) => {
    const { pending, namespace } = caseListStore.loading
    const isLoadingNewCases =
      pending && namespace === LOADING_NAMESPACE.LOAD_NEW

    return isLoadingNewCases
  }, renderComponent(CaseListLoader)),
  mapProps(({ caseListStore }) => {
    const { showCaseQueue } = caseListStore.state
    const { error } = caseListStore.loading
    const { errorMessage } = caseListStore.loading
    const info =
      isEmpty(caseListStore.cases) &&
      !error &&
      (showCaseQueue ? CASE_QUEUE_EMPTY_CASE_MSG : CASE_LIST_EMPTY_CASE_MSG)

    return {
      info,
      error,
      errorMessage,
    }
  }),
  branch(({ info, error }) => !(info || error), renderNothing)
)
export default enhance(CaseListAlertContainer)
