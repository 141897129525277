import React from 'react'
import { joinClassNames } from '@signifyd/utils'
import './CaseDetailsSprite.less'

export type IconType =
  | 'google-maps'
  | 'google-web'
  | 'white-pages'
  | 'pipl'
  | 'facebook'
  | 'twitter'
  | 'arkowl'
  | 'linkedin'
  | 'signifyd'
  | 'paypal'
  | 'ekata'
  | 'zillow'
  | 'lexis-nexis'
  | 'true-caller'
  | '_118712'
  | 'pages-jaunes'
  | '_118000'
  | 'tel-fr'
  | 'pagine-bianche'
  | 'elenchi-telefonici'
  | 'abc-telefonos'
  | 'espana-directorio'
  | 'white-pages-au'
  | 'ph3a'
type Props = {
  type: IconType
  className?: string
}

const CaseDetailsSprite = ({ type, className }: Props) => (
  <span className={joinClassNames([`CaseDetailsSprite ${type}`, className])} />
)

export default CaseDetailsSprite
