import { compose, mapProps, withProps, withHandlers } from 'recompose'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import PopoverContainer from './PopoverContainer'

type EnhancePopoverProps = {
  popoverVisible: boolean
  setPopoverVisible: (arg0: boolean) => void
  getPopoverContainer: () => HTMLElement | null | undefined
}
let _popoverContainerElm = null
const popoverContainerState = observable({
  visible: false,
})
const setVisible = action((visible) => {
  popoverContainerState.visible = visible
})
const enhancePopover = compose(
  observer,
  withProps((props) => ({
    popoverVisible: popoverContainerState.visible,
    ...props,
  })),
  withHandlers({
    setPopoverVisible: () => (visible) => {
      setVisible(visible)
    },
    getPopoverContainer: () => () => _popoverContainerElm,
  })
)
const enhance = compose(
  observer,
  mapProps(() => ({
    visible: popoverContainerState.visible,
  })),
  withHandlers({
    onMounted: () => (elm) => {
      _popoverContainerElm = elm
    },
    setVisible: () => (visible) => {
      setVisible(visible)
    },
  })
)
export default enhance(PopoverContainer)
export { enhancePopover }
export type { EnhancePopoverProps }
