import { SelectModalEntity } from '@signifyd/legacy-components'
import { FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import useGetTeamSearch from 'core/queries/useGetTeamsSearch'
import './styles.less'
import { SelectType } from './TeamSelect'

const { Option } = Select

interface Props {
  initialSelectedEntities: Array<SelectModalEntity>
  onSave: (selectedIds: Array<string>) => void
  type: SelectType
}

const TeamSelectHandler: FC<Props> = ({
  initialSelectedEntities,
  onSave,
  type,
}) => {
  const [selectedEntities, setSelectedEntities] = useState<
    Array<SelectModalEntity>
  >(initialSelectedEntities)
  const [options, setOptions] = useState<Array<SelectModalEntity>>(
    initialSelectedEntities
  )

  const [filterValue, setFilterValue] = useState('')
  const { data, isLoading } = useGetTeamSearch(filterValue)

  useEffect(() => {
    if (data) {
      const combinedEntities = [...data, ...selectedEntities]

      const optionMap = new Map<string, SelectModalEntity>(
        combinedEntities.map((entity) => [entity.key, entity])
      )

      setOptions([...optionMap.values()])
    }
  }, [data, selectedEntities])

  // Note: this handles the "clear all" and "search but don't select" use cases.
  // 1. Clear all returns an undefined, so values are set to empty.
  // 2. "Search but don't select" returns the search term, so discard it.
  const handleChange = (changeValue?: string | Array<string>): void => {
    if (!changeValue) {
      setSelectedEntities([])
      onSave([])
    }
  }

  const handleSelect = (id: string) => {
    const selectedOption = options.find((option) => option.id === id)
    if (selectedOption) {
      const newlySelectedValues = [...selectedEntities, selectedOption]
      setSelectedEntities(newlySelectedValues)
      onSave(newlySelectedValues.map((entity) => entity.key))
    }
  }

  const handleDeselect = (id: string) => {
    const newlySelectedEntities = selectedEntities.filter(
      (entity) => entity.id !== id
    )
    setSelectedEntities(newlySelectedEntities)
    onSave(newlySelectedEntities.map((entity) => entity.key))
  }

  const handleSearch = (filterValue: string) => setFilterValue(filterValue)

  return (
    <Select
      mode={type === 'single' ? 'default' : 'tags'}
      value={selectedEntities.map((entity) => entity.key)}
      className="teamSelect"
      optionFilterProp="children"
      loading={isLoading}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      onSearch={handleSearch}
      onChange={handleChange}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      showSearch
      dropdownMatchSelectWidth
      allowClear
      data-test-id="teamSelect"
    >
      {options.map(({ name, id, key }) => (
        <Option value={id} key={key} className="teamSelectDropdown">
          {name} ({id})
        </Option>
      ))}
    </Select>
  )
}

export default TeamSelectHandler
