import { FC } from 'react'
import { Icon } from 'antd'
import { CANNED_NOTES } from 'caseReport/Constants'
import './CannedNotes.less'

type Props = {
  onItemSelected: (arg0: string) => void
}

const CannedNotes: FC<Props> = ({ onItemSelected }) => (
  <ul className="CannedNotes">
    {CANNED_NOTES.map((cannedNote, index) => (
      <li
        key={index}
        className="CannedNotes--item"
        onClick={() => onItemSelected(cannedNote)}
      >
        {cannedNote}
        <div className="CannedNotes--item-icon-wrapper">
          <Icon type="arrow-right" className="CannedNotes--item-icon" />
        </div>
      </li>
    ))}
  </ul>
)

export default CannedNotes
