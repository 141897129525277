import { FC } from 'react'
import { Checkbox, Button } from 'antd'
import { isEqual } from 'lodash'
import { toJS } from 'mobx'
import { AGENT_FEEDBACK_TAG_LABELS, AGENT_FEEDBACK_TAGS } from 'core/Constants'
import { CASE_REPORT_MENU_TEXT as TEXT } from 'caseReport/Constants'
import MenuItem from '../MenuItem'
import './AgentFeedbackBtn.less'
import { CaseReportMenuButtonProps } from '../CaseReportMenu/CaseReportMenu.types'

const AgentFeedbackButton: FC<CaseReportMenuButtonProps> = ({
  handleAgentFeedbackTagsPopoverCancel,
  handleAgentFeedbackTagsPopoverVisibleChange,
  handleAgentFeedbackTagsSubmit,
  setAgentFeedbackTagsForm,
  state,
  currentCase,
  agentFeedbackTagsForm,
}) => {
  const disableSubmit = isEqual(
    toJS(agentFeedbackTagsForm),
    toJS(currentCase.agentFeedbackTags)
  )
  const popoverContent = (
    <div>
      <div
        className="agentFeedbackPopoverContent"
        data-test-id="agentFeedbackPopover"
      >
        <Checkbox
          data-agent-feedback-tag={AGENT_FEEDBACK_TAGS.SIGNIFYD_OBVIOUS_FRAUD}
          checked={agentFeedbackTagsForm.includes(
            AGENT_FEEDBACK_TAGS.SIGNIFYD_OBVIOUS_FRAUD
          )}
          onChange={(event) => setAgentFeedbackTagsForm(event)}
        >
          {
            AGENT_FEEDBACK_TAG_LABELS[
              AGENT_FEEDBACK_TAGS.SIGNIFYD_OBVIOUS_FRAUD
            ]
          }
        </Checkbox>
      </div>
      <div className="agentFeedbackPopoverButtons">
        <Button
          onClick={handleAgentFeedbackTagsPopoverCancel}
          className="cancelAgentFeedbackPopover"
        >
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={handleAgentFeedbackTagsSubmit}
          type="primary"
          data-test-id="agentFeedbackSubmitBtn"
        >
          Submit
        </Button>
      </div>
    </div>
  )

  const agentFeedbackTagsPopoverProps = {
    overlayClassName: 'CaseReportMenu--popover',
    title: 'Case Label',
    content: popoverContent,
    visible: state.agentFeedbackTagsPopoverVisible,
    onVisibleChange: handleAgentFeedbackTagsPopoverVisibleChange,
    placement: 'bottomRight',
    trigger: 'click',
  }

  const agentFeedbackTagsCount = currentCase.agentFeedbackTags
    ? currentCase.agentFeedbackTags.length
    : 0

  return (
    <MenuItem
      badgeCount={agentFeedbackTagsCount}
      iconProps={{ antIconType: 'tags', antIconProps: { theme: 'filled' } }}
      tooltip={TEXT.AGENT_FEEDBACK_TOOLTIP}
      popoverProps={agentFeedbackTagsPopoverProps}
      className="AgentFeedbackButton"
      data-test-id="agentFeedbackBtn"
    />
  )
}

export default AgentFeedbackButton
