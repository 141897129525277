import React from 'react'
import type { Notes } from './MerchantNotes'
import './MerchantNotesReadonly.less'

type Props = {
  notes: Notes
}

const MerchantNotesReadonly = ({ notes }: Props) => (
  <div>
    <pre className="MerchantNotesReadonly--pre">
      {notes || 'No notes for merchant.'}
    </pre>
  </div>
)

export default MerchantNotesReadonly
