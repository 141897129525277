import { isFinite } from 'lodash'

// This code is copied from console:
// https://github.com/signifyd/frontend/tree/main/console/react/core/utils/formatNumber
function formatNumber(num: number, fractionDigits: number): string {
  if (!isFinite(num)) {
    return ''
  }
  const parts = num.toFixed(fractionDigits).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

function formatInt(num: number): string {
  return formatNumber(num, 0)
}

export { formatNumber, formatInt }
