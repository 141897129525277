import React, { FC } from 'react'
import { Input, Button, Alert, Icon, Modal, Tooltip } from 'antd'
import { getNotesBreakpoint, NOTES_BREAKPOINTS } from 'caseReport/Constants'
import { joinClassNames } from '@signifyd/utils'
import LinkButton from 'core/components/LinkButton'
import './CaseNotesEditor.less'

type Props = {
  className: string | null | undefined
  loading: boolean
  customNote: string
  disableAddNote: boolean
  error: string | null | undefined
  remainingChars: number
  handleCustomNoteChange: (arg0: string) => void
  handleClearNotes: () => void
  handleAddNote: () => void
  showFullScreen: boolean
  toggleFullScreen: () => void
  onTextAreaMounted: () => void
  textAreaKey: number
}
const { TextArea } = Input
const { SMALL } = NOTES_BREAKPOINTS

const CaseNotesEditor: FC<Props> = ({
  className,
  loading,
  customNote,
  disableAddNote,
  error,
  remainingChars,
  handleCustomNoteChange,
  handleClearNotes,
  handleAddNote,
  showFullScreen,
  toggleFullScreen,
  onTextAreaMounted,
  textAreaKey,
}) => {
  const id = `CaseNotesEditor--${new Date().getTime()}`
  const smallScreen = getNotesBreakpoint() === SMALL

  const getRows = () => {
    if (!showFullScreen) {
      return 4
    }
    if (smallScreen) {
      return 10
    }

    return 25
  }

  const node = (
    <div className={joinClassNames(['CaseNotesEditor', className])} id={id}>
      <TextArea
        className="CaseNotesEditor--textarea"
        placeholder="Enter notes"
        disabled={loading}
        rows={getRows()}
        value={customNote}
        ref={onTextAreaMounted}
        key={textAreaKey}
        onChange={(e) => handleCustomNoteChange(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            handleAddNote()
          }
        }}
        data-test-id="CaseNotesEditor--textarea"
      />
      <div className="CaseNotesEditor--remaining-chars">
        <span>
          {remainingChars} character(s) remaining{' '}
          <Tooltip
            placement="top"
            title={
              <span>
                Press ENTER to add note.
                <br />
                Press SHIFT+ENTER to add a new line.
              </span>
            }
          >
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
        <Icon
          type={showFullScreen ? 'shrink' : 'arrows-alt'}
          className="CaseNotesEditor--toggle-fullscreen-button"
          onClick={toggleFullScreen}
        />
      </div>
      {error && (
        <Alert
          className="CaseNotesEditor--alert"
          description={error}
          type="error"
        />
      )}
      <div className="CaseNotesEditor--button-wrapper">
        <LinkButton disabled={disableAddNote} onClick={handleClearNotes}>
          Clear Text
        </LinkButton>
        <Button
          loading={loading}
          disabled={disableAddNote}
          type="primary"
          onClick={handleAddNote}
          data-test-id="CaseNotesEditor--add-note-button"
        >
          Add Note
        </Button>
      </div>
    </div>
  )

  return showFullScreen ? (
    <Modal
      width={smallScreen ? 600 : 1000}
      title="New Note"
      visible
      footer={null}
      closable={false}
      className="CaseNotesEditor--fullscreen-modal"
    >
      {node}
      <Icon
        type="close"
        className="CaseNotesEditor--fullscreen-close-icon"
        onClick={toggleFullScreen}
      />
    </Modal>
  ) : (
    node
  )
}

export default CaseNotesEditor
