import React, { FC } from 'react'
import { Menu, Badge, Tooltip, Popover } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import MenuItemIcon from './MenuItemIcon'
import './MenuItem.less'
import { MenuItemIconProps } from './MenuItemIcon/MenuItemIcon'

type PopoverProps = {
  title?: string
  content?: string
  visible?: boolean
  onVisibleChange?: boolean
  placement?: string
}
export type MenuItemProps = {
  className?: string
  children?: React.ReactNode
  badgeCount?: number
  inlineText?: string
  recommendedAction?: string
  tooltip?: string
  tooltipPlacement?: string
  guaranteeDisposition?: string
  reviewDisposition?: string
  selected?: boolean
  notClickable?: boolean
  bordered?: boolean
  loading?: boolean
  style?: React.CSSProperties
  mouseEnterDelay?: number
  popoverProps?: PopoverProps
  iconProps?: MenuItemIconProps
  disabled?: boolean
}

const MenuItem: FC<MenuItemProps> = ({
  className,
  children,
  badgeCount,
  inlineText,
  tooltip,
  tooltipPlacement = 'bottom',
  guaranteeDisposition,
  reviewDisposition,
  recommendedAction,
  selected,
  bordered,
  style,
  notClickable,
  mouseEnterDelay = 1,
  popoverProps,
  iconProps,
  loading,
  ...props
}) => {
  const icon = <MenuItemIcon {...iconProps} loading={loading} />

  return (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement}
      mouseEnterDelay={mouseEnterDelay}
    >
      <Menu.Item
        disabled={loading}
        className={joinClassNames([
          'CasePageMenuItem', // notClickable just changes the mouse cursor
          // To disable the MenuItem, do it in Menu's onClick handler
          // Cannot use MenuItem's disable prop because it also
          // disables the tooltip
          notClickable && 'CasePageMenuItem--not-clickable',
          bordered && 'CasePageMenuItem--bordered',
          !loading &&
            guaranteeDisposition &&
            `CasePageMenuItem--${guaranteeDisposition}`,
          !loading &&
            recommendedAction &&
            `CasePageMenuItem--${recommendedAction}`,
          !loading &&
            reviewDisposition &&
            `CasePageMenuItem--${reviewDisposition}`,
          selected && 'CasePageMenuItem--selected',
          loading && 'CasePageMenuItem--loading',
          inlineText && 'CasePageMenuItem--has-text',
          className,
        ])}
        style={style}
        {...props}
      >
        <div
          data-test-id={tooltip ? `menuItem${tooltip}` : undefined}
          className="CasePageMenuItem--content"
        >
          <Badge
            className="CasePageMenuItem--badge"
            count={badgeCount}
            overflowCount={9}
          >
            {popoverProps ? (
              <Popover {...popoverProps}>
                {/* An HTML element needs to wrap the icon so that Popover has 
              a child to pass the props to. Otherwise it doesn't work */}
                <div>{icon}</div>
              </Popover>
            ) : (
              icon
            )}
          </Badge>
          {inlineText && (
            <span className="CasePageMenuItem--inline-text">{inlineText}</span>
          )}
          {children && (
            <span className="CasePageMenuItem--inline-text">{children}</span>
          )}
        </div>
      </Menu.Item>
    </Tooltip>
  )
}

export default MenuItem
