import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL } from './constants'

export const abcTelefonosLastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `q=${lastName}&l=${zipCode || ''}&t=persona&country=espana`

  return {
    iconType: 'abc-telefonos',
    label,
    url: `http://en.abctelefonos.com/search?${query.replace(/ /g, '+')}`,
  }
}
export const abcTelefonosAddess = ({
  street,
  postalCode,
}: PhysicalLocationDetails) => {
  if (!street && !postalCode) {
    return null
  }
  const query =
    `q=${street || ''}&l=${postalCode || ''}&` + 't=direccion&country=espana'

  return {
    iconType: 'abc-telefonos',
    label: ADDRESS_SEARCH_LABEL,
    url: `http://en.abctelefonos.com/search?${query.replace(/ /g, '+')}`,
  }
}
