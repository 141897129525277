import React from 'react'
import SigCard from 'core/components/SigCard'
import SigKeyValue from 'core/components/SigKeyValue'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import ExternalLink from 'core/components/ExternalLink'
import type { NetworkLocation } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'

type NetworkLocationWithDeeplinks = NetworkLocation & {
  deeplinks?: Array<DeeplinkProps>
}
type Props = {
  networkLocations: Array<NetworkLocationWithDeeplinks>
}

const NetworkEntry = ({ networkLocations }: Props) => (
  <SigCard title="Network" inner>
    {networkLocations.map((item, index) => (
      <SigKeyValue
        key={index}
        name={item.role}
        startCaseName
        value={
          item.deeplinks ? (
            <DeeplinkPopover deeplinks={item.deeplinks}>
              {item.entityName}
            </DeeplinkPopover>
          ) : (
            <ExternalLink url={`http://www.${item.entityName}`}>
              {item.entityName}
            </ExternalLink>
          )
        }
        copyValue={item.entityName}
        seeder={item.seeder}
        alignValueToRight
      />
    ))}
  </SigCard>
)

export default NetworkEntry
