import React from 'react'
import { Collapse } from 'antd'

const SigCardCollapsePanel = ({ children, ...rest }) => (
  <Collapse.Panel className="SigCardCollapsePanel" {...rest}>
    {children}
  </Collapse.Panel>
)

export default SigCardCollapsePanel
