import CaseReviewTimer from 'caseReport/components/CaseReviewTimer'
import { ReactNode } from 'react'
import ConfirmMenuItem from '../ConfirmMenuItem'
import MenuItem from '../MenuItem'
import { MenuItemProps } from '../MenuItem/MenuItem'
import {
  GUARANTEE_DISPOSITION_ACTIONS,
  INVESTIGATION_REVIEW_DISPOSITION,
  CASE_REPORT_MENU_TEXT as TEXT,
} from 'caseReport/Constants'
import type { CaseReportMenuButtonProps } from './CaseReportMenu.types'
import {
  INV_GUARANTEE_DISPOSITION,
  RECOMMENDED_DECISION_DISPOSITION,
} from '@signifyd/http'

type ButtonRenderer = (
  props: Partial<CaseReportMenuButtonProps>,
  menuItemKey?: string
) => ReactNode

const { APPROVED, DECLINED, CANCELED, IN_REVIEW, PENDING } =
  INV_GUARANTEE_DISPOSITION

const { GOOD, FRAUDULENT } = INVESTIGATION_REVIEW_DISPOSITION

const { APPROVE, DECLINE, REVIEW, CANCEL, RE_SUBMIT, THUMBS_DOWN, THUMBS_UP } =
  GUARANTEE_DISPOSITION_ACTIONS

export const getIsResubmitBtn: ButtonRenderer = ({
  isReReviewSubmitted,
  guaranteeDisposition,
}) => {
  const showReReviewSubmittedBtn =
    isReReviewSubmitted &&
    (guaranteeDisposition === PENDING || guaranteeDisposition === IN_REVIEW)

  if (!showReReviewSubmittedBtn) {
    return null
  }

  return (
    <MenuItem
      iconProps={{ spriteIconType: 're-review' }}
      tooltip={TEXT.IS_RE_SUBMIT_TOOLTIP}
      selected
    />
  )
}

export const getApproveBtn: ButtonRenderer = (
  { guaranteeDisposition, state, toggleApproveConfirmation, handleApproveCase },
  menuItemKey
) => {
  if (guaranteeDisposition !== IN_REVIEW || state?.confirmDeclinePending) {
    return null
  }

  if (state?.confirmApprovePending) {
    return (
      <ConfirmMenuItem
        type="approve"
        loading={state.loading === APPROVE}
        onConfirm={() => handleApproveCase?.()}
        onCancel={() => toggleApproveConfirmation?.(false)}
        data-test-id="confirmApproveButton"
      />
    )
  }

  return (
    <MenuItem
      key={menuItemKey}
      iconProps={{ spriteIconType: 'approve' }}
      tooltip={TEXT.APPROVE_TOOLTIP}
    />
  )
}

export const getDeclineBtn: ButtonRenderer = (
  { guaranteeDisposition, state, handleDeclineCase, toggleDeclineConfirmation },
  menuItemKey
) => {
  if (guaranteeDisposition !== IN_REVIEW || state?.confirmApprovePending) {
    return null
  }

  return state?.confirmDeclinePending ? (
    <ConfirmMenuItem
      type="decline"
      loading={state?.loading === DECLINE}
      onConfirm={() => handleDeclineCase?.()}
      onCancel={() => toggleDeclineConfirmation?.(false)}
      data-test-id="confirmDeclineButton"
    />
  ) : (
    <MenuItem
      key={menuItemKey}
      iconProps={{ spriteIconType: 'decline' }}
      tooltip={TEXT.DECLINE_TOOLTIP}
    />
  )
}

export const getEscalateCaseBtn: ButtonRenderer = (
  { canSeeEscalationBtn, currentCase, guaranteeDisposition, state },
  menuItemKey
) => {
  if (
    !canSeeEscalationBtn ||
    guaranteeDisposition !== IN_REVIEW ||
    state?.confirmApprovePending ||
    state?.confirmDeclinePending ||
    !currentCase
  ) {
    return null
  }

  const renderMap: Record<number, ReactNode> = {
    0: (
      <MenuItem
        iconProps={{ svgIconType: 'ESCALATE_CASE' }}
        key={menuItemKey}
        tooltip={TEXT.ESCALATE_CASE_TOOLTIP}
      />
    ),
    1: (
      <MenuItem
        iconProps={{ svgIconType: 'ESCALATE_CASE' }}
        key={menuItemKey}
        tooltip={TEXT.CASE_ESCALATED_TOOLTIP}
        inlineText="Escalated ( 1 )"
        bordered
      />
    ),
    2: (
      <MenuItem
        iconProps={{ svgIconType: 'ESCALATE_CASE' }}
        key="DISABLED_ESCALATE_CASE_BTN"
        tooltip={TEXT.CASE_ESCALATED_TOOLTIP}
        inlineText="Escalated ( 2 )"
        notClickable
        bordered
      />
    ),
  }

  return renderMap[currentCase.guaranteeReviewEscalationLevel] ?? null
}

export const getCancelBtn: ButtonRenderer = (
  {
    guaranteeDisposition,
    state,
    chargebackClaimSubmitted,
    canSeeAdvancedActionButtons,
  },
  menuItemKey
) => {
  if (
    !guaranteeDisposition ||
    !canSeeAdvancedActionButtons ||
    chargebackClaimSubmitted ||
    guaranteeDisposition === CANCELED
  ) {
    return null
  }

  return (
    <MenuItem
      loading={state?.loading === CANCEL}
      key={menuItemKey}
      iconProps={{ svgIconType: 'CANCEL_BUTTON_ICON' }}
      tooltip={TEXT.CANCEL_TOOLTIP}
    />
  )
}

export const getResubmitBtn: ButtonRenderer = (
  {
    guaranteeDisposition,
    state,
    chargebackClaimSubmitted,
    canSeeAdvancedActionButtons,
  },
  menuItemKey
) => {
  if (
    !guaranteeDisposition ||
    !canSeeAdvancedActionButtons ||
    chargebackClaimSubmitted ||
    [APPROVED, IN_REVIEW, PENDING].includes(guaranteeDisposition)
  ) {
    return null
  }

  return (
    <MenuItem
      loading={state?.loading === RE_SUBMIT}
      key={menuItemKey}
      iconProps={{ svgIconType: 'RE_SUBMIT_CASE' }}
      tooltip={TEXT.RE_SUBMIT_TOOLTIP}
    />
  )
}

export const getReviewBtn: ButtonRenderer = (
  { guaranteeDisposition, state },
  menuItemKey
) => {
  if (guaranteeDisposition !== PENDING) {
    return null
  }

  return (
    <MenuItem
      key={menuItemKey}
      loading={state?.loading === REVIEW}
      iconProps={{ spriteIconType: 'review' }}
      tooltip={TEXT.REVIEW_TOOLTIP}
    />
  )
}

export const getDispositionResult: ButtonRenderer = ({
  guaranteeDisposition,
}) => {
  if (guaranteeDisposition === APPROVED) {
    return (
      <MenuItem
        iconProps={{
          svgIconType: 'GUARANTEE_ICON',
        }}
        inlineText="Approved"
        guaranteeDisposition={APPROVED}
        disabled
      />
    )
  }

  if (guaranteeDisposition === DECLINED) {
    return (
      <MenuItem
        iconProps={{
          svgIconType: 'GUARANTEE_ICON',
        }}
        inlineText="Declined"
        guaranteeDisposition={DECLINED}
        disabled
      />
    )
  }

  if (guaranteeDisposition === CANCELED) {
    return (
      <MenuItem
        iconProps={{
          svgIconType: 'GUARANTEE_ICON',
        }}
        inlineText="Canceled"
        guaranteeDisposition={CANCELED}
        disabled
      />
    )
  }

  return null
}

const recommendationResult: Record<
  RECOMMENDED_DECISION_DISPOSITION,
  MenuItemProps
> = {
  APPROVED: {
    inlineText: 'Accept',
    recommendedAction: 'ACCEPT',
  },
  DECLINED: {
    inlineText: 'Reject',
    recommendedAction: 'REJECT',
  },
  PENDING: {
    inlineText: 'Hold',
    recommendedAction: 'HOLD',
  },
}

export const getRecommendationResult = (
  recommendedDecisionDisposition: RECOMMENDED_DECISION_DISPOSITION | null
): ReactNode => {
  if (!recommendedDecisionDisposition) {
    return null
  }

  return (
    <MenuItem
      {...recommendationResult[recommendedDecisionDisposition]}
      iconProps={{ svgIconType: 'RECOMMENDATION_ICON' }}
      disabled
    />
  )
}

export const getThumbsDownBtn: ButtonRenderer = (
  { currentCase, chargebackClaimSubmitted, state },
  menuItemKey
) => {
  if (chargebackClaimSubmitted) {
    return null
  }

  return (
    <MenuItem
      loading={state?.loading === THUMBS_DOWN}
      key={menuItemKey}
      iconProps={{ spriteIconType: 'thumbs-down' }}
      tooltip={TEXT.THUMBS_DOWN_TOOLTIP}
      reviewDisposition={
        currentCase?.investigationReviewDisposition === FRAUDULENT
          ? FRAUDULENT
          : undefined
      }
    />
  )
}

export const getThumbsUpBtn: ButtonRenderer = (
  { currentCase, chargebackClaimSubmitted, state },
  menuItemKey
) => {
  if (chargebackClaimSubmitted) {
    return null
  }

  return (
    <MenuItem
      loading={state?.loading === THUMBS_UP}
      key={menuItemKey}
      iconProps={{ spriteIconType: 'thumbs-up' }}
      tooltip={TEXT.THUMBS_UP_TOOLTIP}
      reviewDisposition={
        currentCase?.investigationReviewDisposition === GOOD ? GOOD : undefined
      }
    />
  )
}

export const getCaseTimer = (): ReactNode => {
  return (
    <MenuItem
      key="CASE_TIMER"
      className="CaseReportMenu--case-timer"
      data-test-id="caseReportMenuTimer"
      selected
    >
      <CaseReviewTimer />
    </MenuItem>
  )
}

export const getUpdateAddressButton: ButtonRenderer = (_props, menuItemKey) => (
  <MenuItem
    key={menuItemKey}
    tooltip="Update address"
    iconProps={{
      antIconType: 'home',
      antIconProps: {
        theme: 'filled',
        style: { color: 'white' },
      },
    }}
  />
)
