import type { EntriesByRole } from 'caseReport/stores/caseReport.store'
import { linkedinFullName, facebookFullName } from 'core/utils/deeplinks'
import type { People as PeopleEntry } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import { getCardHolderLinks, getDeliveryRecipientLinks } from './getLinks'
import {
  US_PEOPLE_LINKS,
  FR_PEOPLE_LINKS,
  IT_PEOPLE_LINKS,
  ES_PEOPLE_LINKS,
  AU_PEOPLE_LINKS,
  CA_PEOPLE_LINKS,
  DEFAULT_PEOPLE_LINKS,
} from './constants'

type LinkResults = Array<DeeplinkProps | false>

const getLinksByCountry = (
  entriesByRole: EntriesByRole,
  countryCode: string,
  isCardHolder: boolean
): LinkResults => {
  const getLinks = isCardHolder ? getCardHolderLinks : getDeliveryRecipientLinks

  switch (countryCode) {
    case 'US':
      return getLinks(entriesByRole, US_PEOPLE_LINKS)

    case 'FR':
      return getLinks(entriesByRole, FR_PEOPLE_LINKS)

    case 'IT':
      return getLinks(entriesByRole, IT_PEOPLE_LINKS)

    case 'ES':
      return getLinks(entriesByRole, ES_PEOPLE_LINKS)

    case 'AU':
      return getLinks(entriesByRole, AU_PEOPLE_LINKS)

    case 'CA':
      return getLinks(entriesByRole, CA_PEOPLE_LINKS)

    default:
      return getLinks(entriesByRole, DEFAULT_PEOPLE_LINKS)
  }
}

export const getCardHolderLinksByCountry = (
  entriesByRole: EntriesByRole,
  countryCode: string
): LinkResults => {
  const isCardHolder = true

  return getLinksByCountry(entriesByRole, countryCode, isCardHolder)
}
export const getDeliveryRecipientLinksByCountry = (
  entriesByRole: EntriesByRole,
  countryCode: string
) => {
  const isCardHolder = false

  return getLinksByCountry(entriesByRole, countryCode, isCardHolder)
}
export const getDAOccupantLinks = (
  people: PeopleEntry | null | undefined
): LinkResults | null | undefined => {
  if (!people) {
    return
  }

  return [
    linkedinFullName(people.entityName),
    facebookFullName(people.entityName),
  ].filter((item) => item)
}
