import { isEmpty } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigKeyValue from 'core/components/SigKeyValue'
import { ORGANIZATIONS_ENTRY_NO_DATA } from 'caseReport/Constants'
import { Organizations } from 'core/http/cases/EntriesHttp.types'
import { FC } from 'react'

interface Props {
  organizations: Array<Organizations>
}
const OrgnizationEntry: FC<Props> = ({ organizations }) => (
  <SigCard title="Organizations" inner>
    {isEmpty(organizations) && ORGANIZATIONS_ENTRY_NO_DATA}

    {organizations.map((item, index: number) => {
      return (
        <SigKeyValue
          key={index}
          name={item.role}
          startCaseName
          value={item.entityName}
          copyValue={item.entityName}
          seeder={item.seeder}
          alignValueToRight
        />
      )
    })}
  </SigCard>
)

export default OrgnizationEntry
