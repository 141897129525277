import moment from 'moment-timezone'
import { Tooltip } from 'antd'
import { get, pickBy, isEmpty } from 'lodash/fp'
import { getCaseSearchUrl } from '@signifyd/legacy-components'
import type { AccountWithDeeplinks } from './utils'
import ExternalLink from 'core/components/ExternalLink'
import SigKeyValue from 'core/components/SigKeyValue'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import SigCardCollapse from 'core/components/SigCardCollapse'
import ArkOwlSocialMediaIcon from 'caseReport/components/ArkOwlSocialMediaIcon'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import {
  ACCOUNT_ENTRY_NO_ARKOWL_DATA,
  ACCOUNT_ENTRY_NO_EMAIL_HOST,
  CASE_SEARCH_GENERAL_QUERY,
} from 'caseReport/Constants'
import './EmailAccounts.less'
import type {
  Account,
  ArkOwlDataBreach,
} from 'core/http/cases/EntriesHttp.types'
import type { SigKeyValueProps } from 'core/components/SigKeyValue'
import buildUrl from 'core/utils/buildUrl/buildUrl'

type Props = {
  accounts: Array<AccountWithDeeplinks>
  normalizedEmailTitle: string
  normalizedEmail?: string | null | undefined
  showDetails?: boolean | null | undefined
}
type ItemProps = {
  account: AccountWithDeeplinks
  normalizedEmailTitle: string
  normalizedEmail?: string | null | undefined
  showDetails?: boolean | null | undefined
}
type ArkOwlEmailDateProps = {
  date: number | null | undefined
}
const { Panel } = SigCardCollapse
const UNIX_TIMESTAMP_MULTIPLIER = 1000

const getArkOwlSocialMedia = (account: Account): Array<SigKeyValueProps> => {
  const allSocialMedia = get('details.arkOwl.socialMedia', account)
  if (isEmpty(allSocialMedia)) {
    return []
  }
  const registeredSocialMedia = pickBy(
    (socialMedia) => socialMedia && socialMedia.status === 'registered',
    allSocialMedia
  )
  const listView = Object.keys(registeredSocialMedia).reduce(
    (agg, socialAccountName) => {
      const { metadata } = registeredSocialMedia[socialAccountName]
      const itemProps = []

      if (metadata.member_since) {
        itemProps.push({
          name: `${socialAccountName} Registered`,
          value: <ArkOwlEmailDate date={metadata.member_since} />,
          startCaseName: true,
          alignValueToRight: true,
        })
      }

      if (metadata.full_name) {
        itemProps.push({
          name: `${socialAccountName} Full Name`,
          value: metadata.full_name,
          startCaseName: true,
          alignValueToRight: true,
        })
      }

      if (metadata.profile_url) {
        itemProps.push({
          name: `${socialAccountName} Profile`,
          value: (
            <ExternalLink url={metadata.profile_url}>
              {metadata.full_name || 'Link'}
            </ExternalLink>
          ),
          startCaseName: true,
          alignValueToRight: true,
        })
      }

      if (metadata.nickname) {
        itemProps.push({
          name: `${socialAccountName} Nickname`,
          value: metadata.nickname,
          startCaseName: true,
          alignValueToRight: true,
        })
      }

      agg = [...agg, ...itemProps]

      return agg
    },
    []
  )
  const icons = Object.keys(allSocialMedia).map((key) => (
    <ArkOwlSocialMediaIcon
      className="EmailAccountItem--social-media-icon"
      key={key}
      type={key}
      registered={get(`${key}.status`, allSocialMedia) === 'registered'}
    />
  ))

  return [
    {
      name: 'Facebook Profile',
      value: (
        <ExternalLink
          url={`https://www.facebook.com/search/people/?q=${account.entityName}`}
        >
          <Tooltip title="Search Facebook for Email">Search</Tooltip>
        </ExternalLink>
      ),
      alignValueToRight: true,
    },
    ...listView,
    {
      name: 'Registered Accounts',
      value: (
        <div className="EmailAccountItem--social-media-icon-wrapper">
          {icons}
        </div>
      ),
      alignValueToRight: true,
    },
  ]
}

// CaseModelService _setArkOwlEmailHost
const ArkOwlEmailDate = ({ date }: ArkOwlEmailDateProps) => {
  if (!date) {
    return null
  }

  date *= UNIX_TIMESTAMP_MULTIPLIER

  return (
    <span>
      <strong>{moment(date).format('YYYY-MM-DD')} </strong>(
      {moment(date).fromNow()})
    </span>
  )
}

const getArkOwlEmailHost = (account: Account): Array<SigKeyValueProps> => {
  const emailHost = get('details.arkOwl.emailHost', account) || {}
  const registrant = get('registrant', emailHost) || {}
  if (isEmpty(emailHost)) {
    return []
  }

  return [
    {
      name: 'ID',
      value: emailHost.id,
      alignValueToRight: true,
    },
    {
      name: 'Registrar',
      value: registrant.organization,
      alignValueToRight: true,
    },
    {
      name: 'Registered',
      value: emailHost.registered && (
        <ArkOwlEmailDate date={emailHost.registered} />
      ),
      alignValueToRight: true,
    },
    {
      name: 'Expires',
      value: emailHost.expires && <ArkOwlEmailDate date={emailHost.expires} />,
      alignValueToRight: true,
    },
    {
      name: 'Registered To',
      value: registrant.name,
      alignValueToRight: true,
    },
    {
      name: 'Address',
      value:
        (registrant.street1 ? `${registrant.street1},` : '') +
        (registrant.city ? ` ${registrant.city},` : '') +
        (registrant.state ? ` ${registrant.state},` : '') +
        (registrant.postal_code ? ` ${registrant.postal_code},` : '') +
        (registrant.country ? ` ${registrant.country}` : ''),
      alignValueToRight: true,
    },
    {
      name: 'Email',
      value: registrant.email && (
        <ExternalLink url={`https://arkowl.com/email/${registrant.email}`}>
          <Tooltip title="Search ArkOwl">{registrant.email}</Tooltip>
        </ExternalLink>
      ),
      alignValueToRight: true,
    },
    {
      name: 'Phone',
      value: registrant.phone && (
        <ExternalLink
          url={buildUrl(getCaseSearchUrl(), {
            [CASE_SEARCH_GENERAL_QUERY]: registrant.phone,
          })}
        >
          <Tooltip title="Search Admin Cases">{registrant.phone}</Tooltip>
        </ExternalLink>
      ),
      alignValueToRight: true,
    },
    {
      name: 'Fax',
      value: registrant.fax && (
        <ExternalLink
          url={buildUrl(getCaseSearchUrl(), {
            [CASE_SEARCH_GENERAL_QUERY]: registrant.fax,
          })}
        >
          <Tooltip title="Search Admin Cases">{registrant.fax}</Tooltip>
        </ExternalLink>
      ),
      alignValueToRight: true,
    },
  ]
}

const getOldestArkOwlBreach = (account: Account): Array<SigKeyValueProps> => {
  const breaches: Array<ArkOwlDataBreach> = get(
    'details.arkOwl.dataBreaches',
    account
  )
  if (isEmpty(breaches)) {
    return []
  }
  const UNIX_TIMESTAMP_MULTIPLIER = 1000

  const sortDESCByTimestamp = (a, b) => (a.breach_date < b.breach_date ? 1 : -1)

  const oldestBreach = breaches.sort(sortDESCByTimestamp).pop()
  const oldestBreachDate = moment(
    oldestBreach.breach_date * UNIX_TIMESTAMP_MULTIPLIER
  ).format('MMM Do, YYYY')

  return [
    {
      name: 'Oldest Breach Date',
      value: `${oldestBreach.name}: ${oldestBreachDate}`,
      alignValueToRight: true,
    },
  ]
}

const EmailAccountItem = ({
  normalizedEmailTitle,
  normalizedEmail,
  account,
  showDetails,
}: ItemProps) => {
  const panelHeader = (
    <SigKeyValue
      bordered={false}
      name={account.role}
      startCaseName
      alignValueToRight
      value={
        <DeeplinkPopover deeplinks={account.deeplinks}>
          {account.entityName}
        </DeeplinkPopover>
      }
      copyValue={account.entityName}
      seeder={account.seeder}
    />
  )
  let arkOwlDataNode = null

  if (showDetails) {
    const arkOwlData = get('details.arkOwl', account)
    const doesEmailExist = get('details.arkOwl.exists', account) === 'no'
    arkOwlDataNode = arkOwlData ? (
      <div>
        <SigKeyValueGroup
          title="Account Data"
          titleValue={
            doesEmailExist && (
              <span className="EmailAccountItem--not-exist">
                Email does not appear to exist.
              </span>
            )
          }
          properties={[
            ...getArkOwlSocialMedia(account),
            ...getOldestArkOwlBreach(account),
          ]}
          hideLastItemBorder
        />
        <SigKeyValueGroup
          title="Email Host"
          properties={getArkOwlEmailHost(account)}
          placeholder={ACCOUNT_ENTRY_NO_EMAIL_HOST}
          hideLastItemBorder
        />
      </div>
    ) : (
      <SigKeyValue
        name={ACCOUNT_ENTRY_NO_ARKOWL_DATA}
        bordered={false}
        isNameBold={false}
      />
    )
  }

  return (
    <SigCardCollapse defaultActiveKey={['root']}>
      <Panel header={panelHeader} key="root">
        {arkOwlDataNode}
        {normalizedEmail && account.entityName !== normalizedEmail && (
          <SigKeyValueGroup
            title={normalizedEmailTitle}
            properties={[
              {
                name: '',
                value: normalizedEmail,
                copyValue: normalizedEmail,
                alignValueToRight: true,
              },
            ]}
            hideLastItemBorder
          />
        )}
      </Panel>
    </SigCardCollapse>
  )
}

const EmailAccounts = ({
  normalizedEmailTitle,
  normalizedEmail,
  accounts,
  showDetails,
}: Props) => (
  <div>
    {accounts.map((account, index) => (
      <EmailAccountItem
        key={index}
        account={account}
        normalizedEmailTitle={normalizedEmailTitle}
        normalizedEmail={normalizedEmail}
        showDetails={showDetails}
      />
    ))}
  </div>
)

export default EmailAccounts
