import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL, PHONE_NUM_SEARCH_LABEL } from './constants'

export const pagineBiancheLastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `qs=${lastName}&dv=${zipCode || ''}`.replace(/ /g, '+')

  return {
    iconType: 'pagine-bianche',
    label,
    url: `https://www.paginebianche.it/ricerca?${query}`,
  }
}
export const pagineBiancheAddress = ({
  street,
  postalCode,
}: PhysicalLocationDetails): DeeplinkProps | null | undefined => {
  if (!street && !postalCode) {
    return
  }
  const query = `dv=${street || ''} ${postalCode || ''}`.replace(/ /g, '+')
  console.log(query)

  return {
    iconType: 'pagine-bianche',
    label: ADDRESS_SEARCH_LABEL,
    url: `https://www.paginebianche.it/ricerca-da-indirizzo?${query}`,
  }
}
export const pagineBianchePhone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined => {
  if (!phoneNum) {
    return
  }
  const query = `qs=${phoneNum.replace(/ /g, '+')}`

  return {
    iconType: 'pagine-bianche',
    label: PHONE_NUM_SEARCH_LABEL,
    url: `https://www.paginebianche.it/ricerca-da-numero?${query}`,
  }
}
