import { $Keys } from 'utility-types'
import React from 'react'
import { Tooltip, Icon } from 'antd'
import ExternalLink from 'core/components/ExternalLink'
import SigTag from 'core/components/SigTag'
import type { SigTagSize } from 'core/components/SigTag'
import {
  GUARANTEE_PLAN_TYPES,
  TEST_CASE_TOOLTIP,
  TEST_CASE_TEXT,
  TEST_CASE_LINK,
  TRIAL_CASE_TOOLTIP,
  TRIAL_CASE_TEXT,
} from 'caseReport/Constants'
import './TrialOrTestCaseTag.less'

type GuaranteePlanTypes = $Keys<typeof GUARANTEE_PLAN_TYPES>
type Props = {
  case: {
    isTestInvestigation: boolean
    guaranteePlanType: GuaranteePlanTypes
  }
  size: SigTagSize
  hasTooltip?: boolean
  className?: string | null | undefined
}

const TrialOrTestCaseTag = ({
  case: _case,
  size,
  className,
  hasTooltip,
}: Props) => {
  const isTest = _case.isTestInvestigation
  const isTrialButNotTest =
    !_case.isTestInvestigation &&
    _case.guaranteePlanType === GUARANTEE_PLAN_TYPES.TRIAL
  if (!isTest && !isTrialButNotTest) {
    return null
  }
  const content = (
    <SigTag className={className} size={size}>
      {isTest && TEST_CASE_TEXT}
      {isTrialButNotTest && TRIAL_CASE_TEXT}
      {hasTooltip && (
        <Icon type="question-circle-o" className="TrialOrTestCaseTag--icon" />
      )}
    </SigTag>
  )

  return hasTooltip ? (
    <Tooltip
      title={
        isTest ? (
          <span>
            {`${TEST_CASE_TOOLTIP} `}
            Click <ExternalLink url={TEST_CASE_LINK}>here</ExternalLink> to
            learn more.
          </span>
        ) : (
          TRIAL_CASE_TOOLTIP
        )
      }
      placement="rightTop"
    >
      {content}
    </Tooltip>
  ) : (
    content
  )
}

export default TrialOrTestCaseTag
