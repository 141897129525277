import { some } from 'lodash'
import { GUARANTEE_PLAN_TYPES, STATIC_NOTES } from 'core/Constants'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import { CaseNote } from '@signifyd/http'

export function isTrialOrTestCase(_case: CaseInfo | null | undefined): boolean {
  if (!_case) {
    return false
  }

  return (
    _case.isTestInvestigation ||
    _case.guaranteePlanType === GUARANTEE_PLAN_TYPES.TRIAL
  )
}
export function reReviewSubmitted(
  notes: Array<CaseNote> | null | undefined
): boolean {
  return some(notes, ({ text }) => text === STATIC_NOTES.CASE_RE_REVIEW)
}
