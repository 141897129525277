import React from 'react'
import { Alert } from 'antd'
import './CaseReportError.less'

const CaseReportError = ({
  message = 'Error',
  description = 'There is no case with that id.',
  type = 'error',
  showIcon = true,
}) => (
  <Alert
    className="CaseReportError"
    message={message}
    description={description}
    type={type}
    showIcon={showIcon}
  />
)

export default CaseReportError
