import { observable, action, computed, makeObservable } from 'mobx'
import { MESSAGE_TYPES, DEFAULT_TIMEZONE } from './core/Constants'
import { User, USER_ROLE } from '@signifyd/http'
import { UserConfigs } from '@signifyd/app-feature-configs'

const DEFAULT_MESSAGE_STATE: { type: MESSAGE_TYPES; message: string } = {
  type: MESSAGE_TYPES.SUCCESS,
  message: '',
}

class AppStore {
  @observable
  user: User | null = null

  @observable
  userConfigs: UserConfigs = {} as UserConfigs

  @observable
  messages = { ...DEFAULT_MESSAGE_STATE }

  constructor() {
    makeObservable(this)
  }

  @action
  setUserInfo = (userInfo: User): void => {
    this.user = userInfo
  }

  @action
  setUserConfigs = (userConfigs: UserConfigs): void => {
    this.userConfigs = userConfigs
  }

  @action
  setFlashMessage = (type: MESSAGE_TYPES, message: string): void => {
    this.messages = {
      type,
      message,
    }

    if (type === MESSAGE_TYPES.SUCCESS) {
      window.setTimeout(this.resetMessageState, 6000)
    }
  }

  @action
  resetMessageState = (): void => {
    this.messages = { ...DEFAULT_MESSAGE_STATE }
  }

  userHasRole = (role: USER_ROLE): boolean => {
    return this.userRolesSet.has(role)
  }

  userHasRoles = (roles: Array<USER_ROLE>): boolean => {
    return roles.every((role) => this.userRolesSet.has(role))
  }

  @computed
  get isTraining(): boolean {
    return this.userHasRole(USER_ROLE.TRAINING)
  }

  @computed
  get isManualReviewer(): boolean {
    return this.userHasRole(USER_ROLE.MANUAL_REVIEWER)
  }

  @computed
  get userTimeZone(): string {
    return this.user?.uiState?.timeZone?.name ?? DEFAULT_TIMEZONE
  }

  @computed
  get userRolesSet(): Set<USER_ROLE> {
    return this.user ? new Set(this.user.roles) : new Set([])
  }
}

const appStore = new AppStore()
export { AppStore }
export default appStore
