import { action } from 'mobx'
import type { CaseReportUIStore } from 'caseReport/stores/caseReportUI.store'
import { getDefaultCannedNotesVisible } from './caseReportUI.store'

export type CaseReportUIActions = {
  toggleNotesModal: (isVisible?: boolean) => void
  toggleNotesSider: (isVisible?: boolean) => void
  toggleSiderCollapsed: () => void
  toggleCannedNotes: (checked: boolean) => void
  resetCannedNotesVisible: () => void
}

const caseReportUIActions = (store: CaseReportUIStore): CaseReportUIActions => {
  const toggleNotesModal = action((isVisible?: boolean): void => {
    store.notesModalVisible =
      typeof isVisible === 'undefined' ? !store.notesModalVisible : isVisible
  })
  const toggleNotesSider = action((visible?: boolean): void => {
    store.notesSiderVisible =
      typeof visible === 'undefined' ? !store.notesSiderVisible : visible
  })
  const toggleSiderCollapsed = action((): void => {
    store.siderCollapsed = !store.siderCollapsed
  })
  const toggleCannedNotes = action((checked: boolean) => {
    store.cannedNotesVisible = checked
  })
  const resetCannedNotesVisible = action(() => {
    store.cannedNotesVisible = getDefaultCannedNotesVisible()
  })

  return {
    toggleNotesModal,
    toggleNotesSider,
    toggleSiderCollapsed,
    toggleCannedNotes,
    resetCannedNotesVisible,
  }
}

export default caseReportUIActions
