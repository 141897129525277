import type { DeeplinkProps } from 'core/components/Deeplink'
import { Seller } from 'core/http/investigations/CaseSearchHttp.types'
import {
  EkataAddress,
  EkataEmail,
  EkataPhone,
  googleWebEmail,
  googleWebFullName,
  googleWebPhone,
  googleWebAddress,
  googleMapsAddress,
  piplEmail,
  piplPhone,
  searchForOtherCases,
} from 'core/utils/deeplinks'

export type LinkResults = Array<DeeplinkProps | null | undefined>

export const getSellerIdLinks = (seller: Seller): LinkResults =>
  [searchForOtherCases(seller.sellerId)].filter((link) => link)

export const getParentEntityLinks = (seller: Seller): LinkResults =>
  [searchForOtherCases(seller.parentEntity)].filter((link) => link)

export const getSellerNameLinks = (seller: Seller): LinkResults => {
  if (!seller.name) {
    return []
  }

  const webFullnameFromGoogle = googleWebFullName(
    seller.name,
    seller.address.fullAddress,
    null,
    'Seller name, address, city and state search'
  )

  return [webFullnameFromGoogle, searchForOtherCases(seller.name)].filter(
    (link) => link
  )
}

export const getSellerAddressLinks = (seller: Seller): LinkResults => {
  const webAddressFromGoogle = googleWebAddress(seller.address.fullAddress)
  const mapAddressFromGoogle = googleMapsAddress(
    seller.address.fullAddress,
    null
  )
  const ekataAddress = EkataAddress({
    street: seller.address.streetAddress,
    cityName: seller.address.city,
    postalCode: seller.address.postalCode,
    countryIsoCode: seller.address.countryCode,
    fullAddress: seller.address.fullAddress,
    latitude: seller.address.latitude,
    longitude: seller.address.longitude,
    unit: seller.address.unit,
    countryFullName: null,
    countryShortName: null,
    deliveryPoint: null,
    geopoint: null,
    isDenyListed: null,
    isReceivingMail: null,
    isValid: null,
    regionAlpha: null,
    regionName: null,
    streetName: null,
    streetNumber: null,
    wpReverseAddressV3Model: null,
  })

  return [webAddressFromGoogle, mapAddressFromGoogle, ekataAddress].filter(
    (link) => link
  )
}

export const getSellerEmailLinks = (seller: Seller): LinkResults => {
  if (!seller.email) {
    return []
  }
  const emailFromGoogle = googleWebEmail(seller.email)
  const emailFromEkata = EkataEmail(seller.email)
  const emailFromPipl = piplEmail(seller.email)

  return [emailFromGoogle, emailFromEkata, emailFromPipl].filter((link) => link)
}

export const getSellerPhoneNumberLinks = (seller: Seller): LinkResults => {
  if (!seller.phone) {
    return []
  }
  const phoneNumberFromGoogle = googleWebPhone(seller.phone)
  const phoneNumberFromEkata = EkataPhone(seller.phone)
  const phoneNumberFromPipl = piplPhone(seller.phone)

  return [
    phoneNumberFromGoogle,
    phoneNumberFromEkata,
    phoneNumberFromPipl,
  ].filter((link) => link)
}
