import appStore from 'AppStore'
import { observer } from 'mobx-react'
import { FC } from 'react'

const TrainingMask: FC = observer(({ children }) => {
  const { isTraining } = appStore

  if (isTraining) {
    return null
  }

  return <>{children}</>
})

export default TrainingMask
