import React, { Component } from 'react'
import type { Node } from 'react'

type Props = {
  className?: string
  prefix?: string
  suffix?: string
  totalSeconds: number
  render: (arg0: State) => Node
  onFinish: () => void
}
type State = {
  time: string
}

class CountdownTimer extends Component<Props, State> {
  _timer = null

  _secondsLeft = 0

  constructor(props: Props) {
    super(props)
    this.state = {
      time: `${props.totalSeconds}S`,
    }
  }

  componentDidMount() {
    const { totalSeconds } = this.props
    this._secondsLeft = totalSeconds

    this._updateTime()

    this._timer = setInterval(this._updateTime, 1000)
  }

  componentWillUnmount() {
    clearInterval(this._timer)
  }

  _updateTime = () => {
    const { onFinish } = this.props
    this.setState({
      time: `${this._secondsLeft}S`,
    })

    if (this._secondsLeft === 0) {
      onFinish()
    } else {
      this._secondsLeft -= 1
    }
  }

  render() {
    const { className, prefix, render, suffix } = this.props

    return (
      <span className={className}>
        {prefix}
        {render(this.state)}
        {suffix}
      </span>
    )
  }
}

export default CountdownTimer
