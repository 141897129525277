export type Detail = {
  itemName: string | null | undefined
  longDescription: string | null | undefined
  meaning: string | null | undefined
  score: number | null | undefined
  shortDescription: string | null | undefined
}
export type AnalysisInfo = {
  score: number
  details: Array<Detail>
}
export type AnalysisResponse = {
  locationAnalysis: AnalysisInfo
  deviceAnalysis: AnalysisInfo
  emailAnalysis: AnalysisInfo
  investigationId: number
  adjustedScore: number
}
export enum ANALYSIS_STATE {
  POLICY_ONLY = 'POLICY_ONLY',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}
