import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL } from './constants'

export const espanaDirectorioLastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query =
    `name_search_field=${encodeURIComponent(lastName)}&` +
    `city_search=${encodeURIComponent(zipCode || '')}`

  return {
    iconType: 'espana-directorio',
    label,
    url: `http://www.espana-directorio.com/buscar-en-el-sitio.html?${query}`,
  }
}
export const espanaDirectorioAddress = ({
  street,
  postalCode,
}: PhysicalLocationDetails): DeeplinkProps | null | undefined => {
  if (!street && !postalCode) {
    return
  }
  const query =
    `name_search_field=${encodeURIComponent(street || '')}&` +
    `city_search=${encodeURIComponent(postalCode || '')}`

  return {
    iconType: 'espana-directorio',
    label: ADDRESS_SEARCH_LABEL,
    url: `http://www.espana-directorio.com/buscar-en-el-sitio.html?${query}`,
  }
}
