import { $Keys } from 'utility-types'

export const LINK_KEYS = {
  SEARCH_FOR_OTHER_CASES: 'SEARCH_FOR_OTHER_CASES',
  GOOGLE_FULL_NAME: 'GOOGLE_FULL_NAME',
  GOOGLE_LAST_NAME: 'GOOGLE_LAST_NAME',
  EKATA_ID_CHECK: 'EKATA_ID_CHECK',
  LINKEDIN_FULL_NAME: 'LINKEDIN_FULL_NAME',
  FACEBOOK_FULL_NAME: 'FACEBOOK_FULL_NAME',
  _118712: '_118712',
  PAGES_JAUNES: 'PAGES_JAUNES',
  _118000: '_118000',
  TEL_FR: 'TEL_FR',
  PAGINE_BIANCHE: 'PAGINE_BIANCHE',
  ABC_TELEFONOS: 'ABC_TELEFONOS',
  ESPANA_DIRECTORIO: 'ESPANA_DIRECTORIO',
  WHITE_PAGES_AU: 'WHITE_PAGES_AU',
  PIPL_FULL_NAME: 'PIPL_FULL_NAME',
}
export type LinkKey = $Keys<typeof LINK_KEYS>
export type LinkKeysSubset = Array<LinkKey>
const {
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  _118712,
  PAGES_JAUNES,
  _118000,
  TEL_FR,
  PAGINE_BIANCHE,
  ABC_TELEFONOS,
  ESPANA_DIRECTORIO,
  WHITE_PAGES_AU,
  PIPL_FULL_NAME,
} = LINK_KEYS
export const US_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  PIPL_FULL_NAME,
]
export const FR_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  _118712,
  PAGES_JAUNES,
  _118000,
  TEL_FR,
  PIPL_FULL_NAME,
]
export const IT_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  PAGINE_BIANCHE,
  PIPL_FULL_NAME,
]
export const ES_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  ABC_TELEFONOS,
  ESPANA_DIRECTORIO,
  PIPL_FULL_NAME,
]
export const AU_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  WHITE_PAGES_AU,
  PIPL_FULL_NAME,
]
export const DEFAULT_PEOPLE_LINKS: LinkKeysSubset = [
  SEARCH_FOR_OTHER_CASES,
  GOOGLE_FULL_NAME,
  GOOGLE_LAST_NAME,
  EKATA_ID_CHECK,
  LINKEDIN_FULL_NAME,
  FACEBOOK_FULL_NAME,
  PIPL_FULL_NAME,
]
export const CA_PEOPLE_LINKS: LinkKeysSubset = DEFAULT_PEOPLE_LINKS
