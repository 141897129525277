import { FC } from 'react'
import { get } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigCardCollapse from 'core/components/SigCardCollapse'
import SigKeyValue from 'core/components/SigKeyValue'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import DenylistBtn from 'caseReport/components/DenylistBtn'
import yesNo from 'core/utils/yesNo'
import TrainingMask from 'core/components/TrainingMask'
import './LocationEntry.less'
import LexisNexisDeeplink from 'caseReport/components/LexisNexisDeeplink'
import type { PhysicalLocation } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import FormattedEntriesAddress from 'caseReport/components/FormattedEntryAddress'

type PhysicalLocationWithDeeplinks = PhysicalLocation & {
  deeplinks?: Array<DeeplinkProps>
}
type NormalizedAddress = {
  streetAddress?: string | null | undefined
  unit?: string | null | undefined
  city?: string | null | undefined
  countryCode?: string | null | undefined
  postalCode?: string | null | undefined
  provinceCode?: string | null | undefined
  latitude?: number | null | undefined
  longitude?: number | null | undefined
  fullAddress?: string | null | undefined
}
type Props = {
  cardHolderLastName: string
  deliveryRecipientLastName: string
  isDeliveryAddrDenylisted: boolean
  physicalLocations: Array<PhysicalLocationWithDeeplinks>
  normalizedBillingAddress?: NormalizedAddress | null | undefined
  normalizedDeliveryAddress?: NormalizedAddress | null | undefined
  denylistDeliveryAddrPending: boolean
  onDenylistDeliveryAddr: () => void
}
const { Panel } = SigCardCollapse
const NORMALIZED_ADDRESS_TITLE = {
  billingAddress: 'Normalized Billing Address',
  deliveryAddress: 'Normalized Delivery Address',
}

const getAddressEntry = (
  location: PhysicalLocationWithDeeplinks,
  normalizedAddress: NormalizedAddress | null | undefined,
  index: number,
  lastName: string
) => {
  const deliverable = get(location, 'details.deliverable')

  const isReceivingMail = get(location, 'details.isReceivingMail')

  const type = get(location, 'details.type')

  const deliveryPoint = get(location, 'details.deliveryPoint')

  const node = (
    <SigKeyValue
      key={index}
      name={location.role}
      startCaseName
      alignValueToRight
      value={
        <div className="LocationEntry--value">
          <DeeplinkPopover
            deeplinks={location.deeplinks}
            customLink={
              <LexisNexisDeeplink
                key="LexisNexisDeeplink"
                postMessageData={{ ...location.details, lastName }}
              />
            }
          >
            {location.details ? (
              <FormattedEntriesAddress {...location.details} hasLineBreak />
            ) : (
              location.entityName
            )}
          </DeeplinkPopover>
          {deliverable && (
            <div className="LocationEntry--details">
              <span className="LocationEntry--details-label">
                Deliverable:{' '}
              </span>
              {yesNo(deliverable)}
            </div>
          )}
          {isReceivingMail && (
            <div className="LocationEntry--details">
              <span className="LocationEntry--details-label">
                Receiving Mail:{' '}
              </span>
              {yesNo(isReceivingMail)}
            </div>
          )}
          {type && (
            <div className="LocationEntry--details">
              <span className="LocationEntry--details-label">Type: </span>
              {type}
            </div>
          )}
          {deliveryPoint && (
            <div className="LocationEntry--details">
              <span className="LocationEntry--details-label">
                Delivery Point:{' '}
              </span>
              {deliveryPoint}
            </div>
          )}
        </div>
      }
      copyValue={
        location.details ? (
          <FormattedEntriesAddress {...location.details} />
        ) : (
          location.entityName
        )
      }
      seeder={location.seeder}
      bordered={!normalizedAddress}
    />
  )

  return normalizedAddress ? (
    <SigCardCollapse key={index}>
      <Panel header={node} key="root">
        <SigKeyValueGroup
          title={NORMALIZED_ADDRESS_TITLE[location.role]}
          properties={[
            {
              name: '',
              value: (
                <FormattedEntriesAddress
                  {...{
                    cityName: normalizedAddress.city,
                    unit: normalizedAddress.unit,
                    street: normalizedAddress.streetAddress,
                    regionAlpha: normalizedAddress.provinceCode,
                    countryIsoCode: normalizedAddress.countryCode,
                    postalCode: normalizedAddress.postalCode,
                    hasLineBreak: true,
                  }}
                />
              ),
              copyValue: FormattedEntriesAddress({
                cityName: normalizedAddress.city,
                unit: normalizedAddress.unit,
                street: normalizedAddress.streetAddress,
                regionAlpha: normalizedAddress.provinceCode,
                countryIsoCode: normalizedAddress.countryCode,
                postalCode: normalizedAddress.postalCode,
              }),
              alignValueToRight: true,
            },
          ]}
          hideLastItemBorder
        />
      </Panel>
    </SigCardCollapse>
  ) : (
    node
  )
}

const getMiscEntry = (location, index) => (
  <SigKeyValue
    key={index}
    name={location.role}
    startCaseName
    alignValueToRight
    value={
      location.details ? (
        <FormattedEntriesAddress {...location.details} hasLineBreak />
      ) : (
        location.entityName
      )
    }
    copyValue={
      location.details ? (
        <FormattedEntriesAddress {...location.details} />
      ) : (
        location.entityName
      )
    }
    seeder={location.seeder}
  />
)

const LocationEntry: FC<Props> = ({
  cardHolderLastName,
  deliveryRecipientLastName,
  physicalLocations,
  normalizedBillingAddress,
  normalizedDeliveryAddress,
  isDeliveryAddrDenylisted,
  denylistDeliveryAddrPending,
  onDenylistDeliveryAddr,
}) => (
  <SigCard
    inner
    title="Locations"
    extra={
      <TrainingMask>
        <DenylistBtn
          type="DELIVERY_ADDRESS"
          denylisted={isDeliveryAddrDenylisted}
          loading={denylistDeliveryAddrPending}
          onConfirm={onDenylistDeliveryAddr}
        />
      </TrainingMask>
    }
  >
    {physicalLocations.map((location, index) => {
      if (location.role === 'billingAddress') {
        return getAddressEntry(
          location,
          normalizedBillingAddress,
          index,
          cardHolderLastName
        )
      }
      if (location.role === 'deliveryAddress') {
        return getAddressEntry(
          location,
          normalizedDeliveryAddress,
          index,
          deliveryRecipientLastName
        )
      }

      return getMiscEntry(location, index)
    })}
  </SigCard>
)

export default LocationEntry
