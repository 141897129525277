import { FC, useState } from 'react'
import { Icon, Input } from 'antd'
import { getCaseSearchUrl } from '@signifyd/legacy-components'
import CasesearchHelpHint from 'core/components/CaseSearchHelpHint'
import './CaseSearchOmni.less'
import { CASE_SEARCH_GENERAL_QUERY } from 'caseReport/Constants'

import buildUrl from 'core/utils/buildUrl/buildUrl'

const { Search } = Input

const CaseSearchOmni: FC = () => {
  const [searchValue, setSearchValue] = useState(() => {
    const searchParams = new URLSearchParams(location.search)
    const searchTerms = searchParams.get('searchTerms') ?? ''

    return searchTerms
  })

  const clearInput = (): void => {
    setSearchValue('')
  }

  const handleSubmit = (): void => {
    window.location.href = buildUrl(getCaseSearchUrl(), {
      [CASE_SEARCH_GENERAL_QUERY]: searchValue,
    })
  }

  return (
    <div id="CaseSearchOmni">
      <Search
        autoComplete="off"
        type="text"
        placeholder="Search cases or modify date"
        addonBefore={<CasesearchHelpHint />}
        enterButton
        suffix={
          !!searchValue && (
            <Icon
              type="close-circle"
              onClick={clearInput}
              data-test-id="clearInputButton"
            />
          )
        }
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value)
        }}
        onPressEnter={handleSubmit}
        onSearch={handleSubmit}
        autoFocus
      />
    </div>
  )
}

export default CaseSearchOmni
