import React, { FC, ReactNode } from 'react'
import { Tabs } from 'antd'
import TrainingMask from 'core/components/TrainingMask'
import MerchantInfoContainer from 'caseReport/containers/MerchantInfoContainer'
import GuaranteeReasonContainer from 'caseReport/containers/GuaranteeReasonContainer'
import RecommendationReasonContainer from 'caseReport/containers/RecommendationReasonContainer'
import PolicyReasonContainer from 'caseReport/containers/PolicyReasonContainer'
import NotesAutoScrollContainer from 'caseReport/containers/NotesAutoScrollContainer'
import CaseNotesEditor from 'caseReport/containers/CaseNotesEditor'
import SigFlyoutToggle from 'core/components/SigFlyoutToggle'
import CannedNotes from 'caseReport/components/CannedNotes'
import SigCard from 'core/components/SigCard'
import './NotesContainerMD.less'

type Props = {
  caseNotes: ReactNode
  cannedNotesVisible: boolean
  onToggleCannedNotes: (arg0: boolean) => void
  onCannedNoteSelect: (arg0: string) => void
}
const { TabPane } = Tabs

const NotesContainerMD: FC<Props> = ({
  caseNotes,
  cannedNotesVisible,
  onToggleCannedNotes,
  onCannedNoteSelect,
}) => (
  <Tabs defaultActiveKey="1" className="NotesContainerMD" animated={false}>
    <TabPane className="NotesContainerMD--notes-outer-wrap" tab="Notes" key="1">
      <RecommendationReasonContainer />
      <TrainingMask>
        <GuaranteeReasonContainer />
      </TrainingMask>
      <PolicyReasonContainer />
      <NotesAutoScrollContainer className="NotesContainerMD--notes-inner-wrap">
        {caseNotes}
      </NotesAutoScrollContainer>
      <SigFlyoutToggle
        className="NotesContainerMD--toggle-canned-notes"
        label={`${cannedNotesVisible ? 'Hide' : 'Show'} Canned Notes`}
        checked={cannedNotesVisible}
        onChange={(checked) => onToggleCannedNotes(checked)}
      />
      <CaseNotesEditor />
      {cannedNotesVisible && (
        <div className="NotesContainerMD--canned-notes-shadow" />
      )}
      {cannedNotesVisible && (
        <div className="NotesContainerMD--canned-notes-wrapper">
          <SigCard
            className="NotesContainerMD--canned-notes-card"
            title="Canned Notes"
            inner
          >
            <CannedNotes onItemSelected={onCannedNoteSelect} />
          </SigCard>
        </div>
      )}
    </TabPane>
    <TabPane className="NotesContainerMD--merchant-info" tab="Merchant" key="2">
      <MerchantInfoContainer />
    </TabPane>
  </Tabs>
)

export default NotesContainerMD
