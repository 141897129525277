import { FC } from 'react'
import moment from 'moment-timezone'
import appStore from 'AppStore'

type FormatDateProps = {
  UTCTimestamp: string
  format?: string
  showTimeZoneAbbr?: boolean
  timeZone?: string
}

const FormatDate: FC<FormatDateProps> = ({
  UTCTimestamp,
  format = 'MM-DD-YY',
  showTimeZoneAbbr = false,
  timeZone,
}) => {
  const userTimeZone = appStore.user?.uiState?.timeZone?.name
  const zone = timeZone || userTimeZone
  const localTime = moment.utc(UTCTimestamp).tz(zone || moment.tz.guess())
  const formattedTime = localTime.format(format)
  const zoneAbbr = showTimeZoneAbbr ? localTime.zoneAbbr() : ''

  return (
    <span>
      {UTCTimestamp ? formattedTime : ''}
      {zoneAbbr ? ` ${zoneAbbr}` : ''}
    </span>
  )
}

export default FormatDate
