import React from 'react'
import { Button } from 'antd'
import OrderReviewChecklistItem from 'caseReport/components/OrderReviewChecklistItem'
import {
  CHECKLIST_ITEMS_BY_KEY,
  CHECKLIST_ITEM_KEYS,
} from 'caseReport/stores/orderReviewChecklist.store'
import './OrderReviewChecklistContainer.less'

const OrderReviewChecklistContainer = ({
  selectValues,
  textValues,
  cannotAddNotes,
  comments,
  isEmpty,
  loading,
  handleSelectChange,
  handleTextChange,
  handleCommentsChange,
  handleReset,
  handleAddToNote,
}) => {
  return (
    <div className="OrderReviewChecklistContainer">
      {CHECKLIST_ITEM_KEYS.map((key) => {
        const { heading, options } = CHECKLIST_ITEMS_BY_KEY[key]

        return (
          <OrderReviewChecklistItem
            key={key}
            disabled={loading}
            className="OrderReviewChecklistContainer--item"
            selectValue={selectValues[key]}
            textValue={textValues[key]}
            heading={heading}
            options={options}
            onSelectChange={(value) => handleSelectChange(key, value)}
            onTextChange={(value) => handleTextChange(key, value)}
          />
        )
      })}
      <OrderReviewChecklistItem
        className="OrderReviewChecklistContainer--item"
        disabled={loading}
        heading="Comments"
        isSelect={false}
        commentsValue={comments}
        onCommentsChange={handleCommentsChange}
      />
      <div className="OrderReviewChecklistContainer--button-wrapper">
        <Button disabled={isEmpty} onClick={handleReset}>
          Reset Items
        </Button>
        <Button
          type="primary"
          disabled={isEmpty || cannotAddNotes}
          loading={loading}
          onClick={handleAddToNote}
        >
          Add to Note
        </Button>
      </div>
    </div>
  )
}

export default OrderReviewChecklistContainer
