import { observer, inject } from 'mobx-react'
import { isEmpty } from 'lodash'
import { compose, withProps, withHandlers, withState } from 'recompose'
import caseNotesActions from 'caseReport/stores/caseNotes.actions'
import { MAX_NOTE_CHARACTERS } from 'caseReport/Constants'
import CaseNotesEditor from './CaseNotesEditor'

const enhance = compose(
  inject('caseNotesStore', 'appStore'),
  observer,
  withProps(({ caseNotesStore, appStore }) => {
    const { state, loading } = caseNotesStore
    const { customNote, textAreaKey } = state
    const remainingChars = MAX_NOTE_CHARACTERS - customNote.length

    return {
      customNote,
      textAreaKey,
      disableAddNote:
        !!loading.error ||
        remainingChars < 0 ||
        isEmpty(customNote) ||
        (appStore.isTraining && caseNotesStore.notes.length > 0),
      remainingChars,
      loading: loading.pending,
      error: loading.error,
      actions: {
        caseNotes: caseNotesActions(caseNotesStore),
      },
    }
  }),
  withState('showFullScreen', 'setShowFullScreen', false),
  withHandlers({
    onTextAreaMounted: () => (elm) => {
      if (elm) {
        elm.focus()
      }
    },
    handleAddNote:
      ({ customNote, disableAddNote, setShowFullScreen, actions }) =>
      () => {
        if (disableAddNote) {
          return
        }

        const overflowChars = customNote.length - MAX_NOTE_CHARACTERS

        if (overflowChars > 0) {
          const error =
            'We had to convert some characters in your note. ' +
            `This caused the characters to exceed ${MAX_NOTE_CHARACTERS}. ` +
            `Please remove ${overflowChars} character(s).`
          actions.caseNotes.setError(error)

          return
        }

        actions.caseNotes.addNote(customNote)
        // close fullscreen after note has been added
        setShowFullScreen(false)
      },
    handleClearNotes:
      ({ actions }) =>
      () => {
        actions.caseNotes.setCustomNote('')
        actions.caseNotes.focusTextArea()
      },
    handleCustomNoteChange:
      ({ actions }) =>
      (value) => {
        actions.caseNotes.setCustomNote(value)
      },
    toggleFullScreen:
      ({ showFullScreen, setShowFullScreen }) =>
      () => {
        setShowFullScreen(!showFullScreen)
      },
  })
)
export default enhance(CaseNotesEditor)
