import { FC } from 'react'
import SigCard from 'core/components/SigCard'
import DenylistBtn from 'caseReport/components/DenylistBtn'
import TrainingMask from 'core/components/TrainingMask'
import type { Account, EntriesByRole } from 'core/http/cases/EntriesHttp.types'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import EmailAccounts from './EmailAccounts'
import SocialProfileAccounts from './SocialProfileAccounts'
import isEntryDenyListed from '../utils'
import { AccountWithDeeplinks, createDeeplinks } from './utils'

export type Props = {
  entriesByRole: EntriesByRole
  case: CaseInfo
  denylistConfirmationEmailPending: boolean
  onDenylistConfirmationEmail: () => void
}

const AccountEntry: FC<Props> = ({
  entriesByRole,
  case: _case,
  denylistConfirmationEmailPending,
  onDenylistConfirmationEmail,
}) => {
  const isConfirmationEmailDenylisted = isEntryDenyListed(
    entriesByRole,
    'confirmationEmail'
  )
  const confirmationEmailAccounts: Array<AccountWithDeeplinks> =
    entriesByRole.confirmationEmail?.map((account: Account) =>
      createDeeplinks({ account, entriesByRole, case: _case })
    ) || []
  const accountEmailAccounts: Array<AccountWithDeeplinks> =
    entriesByRole.accountEmail?.map((account: Account) =>
      createDeeplinks({ account, entriesByRole, case: _case })
    ) || []

  const socialProfileAccounts: Array<Account> =
    entriesByRole.socialProfile || []
  const normalizedAccountEmail = _case?.normalizedUserAccountEmail
  const normalizedConfirmationEmail =
    _case?.normalizedRecipients?.[0]?.confirmationEmail

  const showAccountEmailDetails =
    confirmationEmailAccounts?.[0]?.entityName !==
    accountEmailAccounts?.[0]?.entityName

  return (
    <SigCard
      inner
      title="Accounts"
      extra={
        <TrainingMask>
          <DenylistBtn
            type="CONFIRMATION_EMAIL"
            denylisted={isConfirmationEmailDenylisted}
            loading={denylistConfirmationEmailPending}
            onConfirm={onDenylistConfirmationEmail}
          />
        </TrainingMask>
      }
    >
      <SocialProfileAccounts accounts={socialProfileAccounts} />
      <EmailAccounts
        normalizedEmailTitle="Normalized Confirmation Email"
        normalizedEmail={normalizedConfirmationEmail}
        accounts={confirmationEmailAccounts}
        showDetails
      />
      <EmailAccounts
        normalizedEmailTitle="Normalized Account Email"
        normalizedEmail={normalizedAccountEmail}
        accounts={accountEmailAccounts}
        showDetails={showAccountEmailDetails}
      />
    </SigCard>
  )
}

export default AccountEntry
