import { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './CasePageMenuIcon.less'

type IconType =
  | 'approve'
  | 'decline'
  | 'review'
  | 'cancel'
  | 're-review'
  | 'thumbs-up'
  | 'thumbs-down'

type Props = {
  type: IconType
  'data-test-id'?: string
}

const CasePageMenuIcon: FC<Props> = ({ type, ...props }) => (
  <span
    data-test-id={props['data-test-id'] ?? 'casePageMenuIcon'}
    className={joinClassNames([
      'CasePageMenuIcon',
      `CasePageMenuIcon--${type}`,
    ])}
  />
)

export default CasePageMenuIcon
