import moment from 'moment-timezone'
import { isNil, isNumber, isBoolean, get, isEmpty } from 'lodash'

type RangeObject = {
  min: string | number | '' | null
  max: string | number | '' | null
}
type ParsedColFiterRange = RangeObject
type InvBuilder = typeof InvestigationSearchParamsBuilder
type IInvestigationSearchParamsBuilder = {
  get: () => Array<any>
  set: (arg0: Array<any>) => InvBuilder
  fromBoolean: (arg0: string, arg1: boolean) => InvBuilder
  fromValue: (arg0: string, arg1: any) => InvBuilder
  fromArray: (arg0: string, arg1: Array<any>) => InvBuilder
  fromRange: (arg0: string, arg1: RangeObject) => InvBuilder
  fromDateRange: (arg0: string, arg1: RangeObject, arg2: string) => InvBuilder
  fromColFilterValue: (arg0: string, arg1: string) => InvBuilder
  build: () => string
  __PRIVATE__isEmptyOrNil: (arg0: any) => boolean
}

/**
 * InvestigationSearchParamsBuilder - A builder for taking raw values and returning a
 * formatted query paramerter string
 *
 * @example
 * // from*() methods ignore empty/nil values
 * const investigationSearchParamsBuilder = new InvestigationSearchParamsBuilder();
 * const params = investigationSearchParamsBuilder()
 *  .fromValue('param1', 'cheese')
 *  .fromValue('param2', 'burger')
 *  .fromValue('param3', '') //empty is ignored
 *  .fromArray('param4', [1,2,3])
 *  .fromArray('param5', []) //empty is ignored
 *  .fromRange('param6', {min: 10, max: 99}
 *  .fromRange('param7', {}) //empty is ignored
 *  .fromBoolean('param8', false)
 *  .build();
 * @returns {String} 'param1=cheese&param2=burger&param4=1,2,3&colFilters=param6::(10,99)&param8=fasle'
 */
export function InvestigationSearchParamsBuilder(): IInvestigationSearchParamsBuilder {
  let _queryParams = []

  function get() {
    return _queryParams
  }

  function set(newArray: Array<any>) {
    if (isNil(newArray)) {
      console.error('Method: set error - parameter `newArray` can not be nil')

      return this
    }

    _queryParams = newArray

    return this
  }

  function fromBoolean(paramName, value) {
    if (value === 'true' || value === 'false') {
      console.error(
        'fromBoolean requires a Boolean type and String "%s" was passed in',
        value
      )

      return this
    }

    if (isBoolean(value)) {
      _queryParams.push(`${paramName}=${value.toString()}`)
    }

    return this
  }

  function fromValue(paramName, value) {
    if (!_isEmptyOrNil(value)) {
      _queryParams.push(`${paramName}=${value}`)
    }

    return this
  }

  function fromArray(paramName, array) {
    if (!_isEmptyOrNil(array)) {
      _queryParams.push(`${paramName}=${array.join()}`)
    }

    return this
  }

  function fromRange(paramName, rangeObject) {
    const hasMin = !_isEmptyOrNil(rangeObject.min)
    const hasMax = !_isEmptyOrNil(rangeObject.max)

    if (hasMin || hasMax) {
      const min = hasMin ? rangeObject.min : ''
      const max = hasMax ? rangeObject.max : ''

      // $FlowFixMe - Flow thinks null could be passed into `${value}` but !_isEmptyOrNil blocks it.
      _queryParams.push(`colFilters[]=${paramName}::(${min},${max})`)
    }

    return this
  }

  function fromDateRange(paramName, rangeObject, dateFormat = 'YYYY-MM-DD') {
    if (!_isEmptyOrNil(rangeObject.min) || !_isEmptyOrNil(rangeObject.max)) {
      const min = rangeObject.min || ''
      const max = rangeObject.max || ''

      const formatDate = (date) => (date ? moment(date).format(dateFormat) : '')

      _queryParams.push(
        `colFilters[]=${paramName}::(${formatDate(min)},${formatDate(max)})`
      )
    }

    return this
  }

  function fromColFilterValue(paramName, value) {
    if (_isEmptyOrNil(value)) {
      return this
    }

    _queryParams.push(`colFilters[]=${paramName}::${value}`)

    return this
  }

  function build() {
    return _queryParams.join('&')
  }

  function _isEmptyOrNil(value): boolean {
    // null or undefined
    if (isNil(value)) {
      return true
    }
    // number
    if (isNumber(value)) {
      return false
    }
    if (isBoolean(value)) {
      return false
    }

    // array or string
    return isEmpty(value)
  }

  return {
    get,
    set,
    fromBoolean,
    fromValue,
    fromArray,
    fromRange,
    fromDateRange,
    fromColFilterValue,
    build,
    __PRIVATE__isEmptyOrNil: _isEmptyOrNil, // namespace for unit testing
  }
}
export function parseColFilterRange(
  value: string
): ParsedColFiterRange | null | undefined {
  const pattern = /^\(([^,]*),([^,]*)\)$/
  const filterStringRangeSplit = value.match(pattern)

  const min = get(filterStringRangeSplit, '[1]', '')

  const max = get(filterStringRangeSplit, '[2]', '')

  return {
    min,
    max,
  }
}
// extract a single value from window.location.search
// parseColFilterValue('?colFilters=param1::1234&asdf=cheese', 'param1') // returns '1234'

/**
 * parseColFilterValue - return a single value from colFilter query string by param name
 *
 * @example
 * parseColFilterValue('?colFilters=param1::1234&asdf=cheese', 'param1');
 * //return '1234'
 *
 * @example
 * parseColFilterValue('there_is_no_param1', 'param1');
 * // returns null
 *
 * @returns {String | null} null
 */
export function parseColFilterValue(
  location: string,
  paramName: string
): string | null | undefined {
  const regex = new RegExp(`colFilters\\[\\]=${paramName}::([^&]+)&*`)
  const res = location.match(regex)

  return res?.[1]
}
