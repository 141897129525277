import {
  useGetCurrentUser,
  useGetUserConfigs,
} from '@signifyd/legacy-components'
import { FC } from 'react'
import { CUSTOMER_APP_URL } from './core/Constants'
import { User } from '@signifyd/http'
import { UserConfigs } from '@signifyd/app-feature-configs'

interface Props {
  children: (user: User, userConfigs: UserConfigs) => JSX.Element
}

const AppUserProvider: FC<Props> = ({ children }) => {
  const { data: user, isLoading: isUserLoading } = useGetCurrentUser()
  const { data: userConfigs, isLoading: isUserConfigsLoading } =
    useGetUserConfigs(user)

  const isLoading = isUserLoading || isUserConfigsLoading

  if (isLoading) {
    return null
  }

  if (!user?.isAdmin || !userConfigs) {
    const location = window.location.pathname
    window.location.replace(`${CUSTOMER_APP_URL}${location}`)

    return null
  }

  return children(user, userConfigs)
}

export default AppUserProvider
