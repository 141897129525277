import { FC } from 'react'
import { Button, Tooltip, Row, Col, Modal, Table } from 'antd'
import { get } from 'lodash'
import { getOrdersUrl } from '@signifyd/legacy-components'
import ExternalLink from 'core/components/ExternalLink'
import FormatDate from 'core/components/FormatDate'
import ScoreDisplay from 'caseReport/components/ScoreDisplay'
import CaseTimeout from 'caseReport/components/CaseTimeout'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import TrialOrTestCaseTag from 'caseReport/components/TrialOrTestCaseTag'
import EscalatedCaseTag from 'caseReport/components/EscalatedCaseTag'
import TrainingMask from 'core/components/TrainingMask'
import './CaseReportHeadline.less'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import { AGENT_FEEDBACK_TAG_LABELS } from 'core/Constants'
import SigTag from 'core/components/SigTag'

type Props = {
  case: CaseInfo
  viewJSONUrl: string
  TMXData: string
  featureData: Array<any>
  featureModalVisible: boolean
  setFeatureModalVisible: (value: boolean) => void
  tmxModalVisible: boolean
  setTmxModalVisible: (value: boolean) => void
}

const CaseReportHeadline: FC<Props> = ({
  case: currentCase,
  viewJSONUrl,
  TMXData,
  featureData,
  tmxModalVisible,
  setTmxModalVisible,
  featureModalVisible,
  setFeatureModalVisible,
}) => {
  const ordersUrl = getOrdersUrl()
  const agentFeedbackTags = get(currentCase, 'agentFeedbackTags', [])
    .filter((tag) => !!AGENT_FEEDBACK_TAG_LABELS[tag])
    .map((tag) => AGENT_FEEDBACK_TAG_LABELS[tag])

  return (
    <div>
      <Modal
        className="ModalJsonWrapper"
        title="ThreatMetrix JSON"
        onOk={() => setTmxModalVisible(false)}
        onCancel={() => setTmxModalVisible(false)}
        visible={tmxModalVisible}
      >
        <pre className="ModalJsonContent">
          {JSON.stringify(TMXData, null, 2)}
        </pre>
      </Modal>
      <Modal
        className="ModalJsonWrapper"
        title="Feature Data"
        onOk={() => setFeatureModalVisible(false)}
        onCancel={() => setFeatureModalVisible(false)}
        visible={featureModalVisible}
      >
        <pre className="ModalJsonContent">
          <Table
            dataSource={featureData}
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
              },
            ]}
            pagination={false}
          />
        </pre>
      </Modal>
      <Row
        className="CaseReportHeadline"
        type="flex"
        justify="space-between"
        align="top"
      >
        <Col span={18}>
          <TrialOrTestCaseTag
            case={currentCase}
            size="large"
            hasTooltip
            className="CaseListItem--trial-or-test-tag"
          />
          <div>
            <span className="CaseReportHeadline--headline _lr-hide">
              {currentCase.headline}
            </span>
            <Tooltip title="View JSON" placement="top">
              <Button
                type="primary"
                className="CaseReportHeadline--json-button"
              >
                <ExternalLink url={viewJSONUrl}>{'{...}'}</ExternalLink>
              </Button>
            </Tooltip>
            {TMXData && (
              <Tooltip title="View ThreatMetrix's JSON" placement="top">
                <Button
                  onClick={() => setTmxModalVisible(true)}
                  type="primary"
                  className="CaseReportHeadline--json-button CaseReportHeadline--tm-button"
                >
                  ThreatMetrix
                </Button>
              </Tooltip>
            )}
            {featureData && (
              <Tooltip title="View Feature Data" placement="top">
                <Button
                  aria-label="View Feature Data"
                  onClick={() => setFeatureModalVisible(true)}
                  type="primary"
                  className="CaseReportHeadline--json-button CaseReportHeadline--tm-button"
                >
                  Features
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title="View case in merchant facing console"
              placement="top"
            >
              <Button
                type="primary"
                className="CaseReportHeadline--json-button CaseReportHeadline--tm-button"
              >
                <ExternalLink
                  url={`${ordersUrl}/${currentCase.investigationId}`}
                >
                  Merchant view
                </ExternalLink>
              </Button>
            </Tooltip>
            <EscalatedCaseTag
              className="CaseReportHeadline--escalated-case-tag"
              guaranteeReviewEscalationLevel={
                currentCase.guaranteeReviewEscalationLevel
              }
            />
          </div>
          <div className="CaseReportHeadline--headline-detail">
            <CaseTimeout
              guaranteeReviewDeadline={currentCase.guaranteeReviewDeadline}
            />
            <span>
              Order External ID: <strong>{currentCase.orderExternalId}</strong>
            </span>
            <span className="CaseReportHeadline--divider">|</span>
            <span>
              Created At:{' '}
              <strong>
                <FormatDate
                  UTCTimestamp={currentCase.normalizedPurchaseCreatedAt}
                  format="M/D/YYYY h:mmA"
                  showTimeZoneAbbr
                />
              </strong>
            </span>
          </div>
          <TrainingMask>
            {agentFeedbackTags.length > 0 && (
              <div className="CaseReportHeadline--agent-feedback-tags">
                {agentFeedbackTags.map((tag) => (
                  <SigTag size="large" key={tag}>
                    {tag}
                  </SigTag>
                ))}
              </div>
            )}
          </TrainingMask>
        </Col>
        <Col span={6} className="CaseReportHeadline--right-col">
          <div className="CaseReportHeadline--inline-col">
            <div className="CaseReportHeadline--order-amount">
              {currentCase.currency}{' '}
              {formatCurrencyNumber(currentCase.orderTotalAmount)}
            </div>
            {currentCase.currency !== 'USD' && (
              <p>USD {formatCurrencyNumber(currentCase.orderAmountInUsd)}</p>
            )}
          </div>
          <TrainingMask>
            <ScoreDisplay
              className="CaseReportHeadline--inline-col CaseReportHeadline--score"
              score={currentCase.signifydScore}
            />
          </TrainingMask>
        </Col>
      </Row>
    </div>
  )
}

export default CaseReportHeadline
