import { AppStore } from 'AppStore'
import { CaseFiltersStore } from 'caseReport/stores/caseFilters.store'
import { CaseListStore } from 'caseReport/stores/caseList.store'
import { CaseReport } from 'caseReport/stores/caseReport.store'
import { CaseReportUIStore } from 'caseReport/stores/caseReportUI.store'
import { CustomerStore } from 'caseReport/stores/customer.store'
import { MerchantReportStore } from 'caseReport/stores/merchantReport.store'
import { ReviewQueueStore } from 'caseReport/stores/reviewQueue.store'
import { TrainingMode } from 'caseReport/stores/trainingMode.store'
import { MobXProviderContext } from 'mobx-react'
import { useContext } from 'react'

// This is used to replace the old inject paradigm
// This isn't even used in newer mobx but because these apps are so old, this is the best migration path without a full rewrite
// See: https://mobx-react.js.org/recipes-migration

type UntypedStore = any

export interface Stores {
  appStore: AppStore
  caseReportUIStore: CaseReportUIStore
  caseReportStore: CaseReport
  merchantReportStore: MerchantReportStore
  caseNotesStore: UntypedStore
  orderReviewChecklistStore: UntypedStore
  customerStore: CustomerStore
  caseFiltersStore: CaseFiltersStore
  caseListStore: CaseListStore
  trainingModeStore: TrainingMode
  reviewQueueStore: ReviewQueueStore
}

const useStores = (): Stores => {
  return useContext(MobXProviderContext) as Stores
}

export default useStores
