import { observer, inject } from 'mobx-react'
import {
  compose,
  withProps,
  withHandlers,
  renderNothing,
  branch,
} from 'recompose'
import { get } from 'lodash'
import moment from 'moment-timezone'
import merchantReportActions from 'caseReport/stores/merchantReport.actions'
import MerchantInfoContainer from './MerchantInfoContainer'

const enhance = compose(
  inject('merchantReportStore', 'caseReportStore', 'customerStore'),
  observer,
  branch(
    ({ merchantReportStore, caseReportStore, customerStore }) =>
      !merchantReportStore.meta ||
      !customerStore.customer ||
      !caseReportStore.case,
    renderNothing
  ),
  withProps(({ merchantReportStore, caseReportStore, customerStore }) => ({
    case: caseReportStore.case,
    teamMetadata: get(
      merchantReportStore.meta.teamMetadata,
      `${caseReportStore.case.teamId}`
    ),
    loading: merchantReportStore.loading,
    notes: merchantReportStore.meta.notes,
    state: merchantReportStore.state,
    customerId: caseReportStore.case.customerId,
    customerCreatedAt: moment(customerStore.customer.createdAt).format(
      'YYYY-MM-DD'
    ),
    actions: merchantReportActions(merchantReportStore),
  })),
  withHandlers({
    getMerchantMetadata:
      ({ actions, customerId }) =>
      () => {
        actions.getMerchantMetadataById(customerId)
      },
    createMerchantMetadata:
      ({ actions, customerId }) =>
      () => {
        actions.createMerchantMetadataById(customerId)
      },
    updatetMerchantMetadata:
      ({ actions, customerId }) =>
      () => {
        actions.updateMerchantMetadataById(customerId)
      },
    deleteMerchantMetadata:
      ({ actions, customerId }) =>
      () => {
        actions.deleteMerchantMetadataById(customerId)
      },
  })
)
export default enhance(MerchantInfoContainer)
