import React from 'react'
import { Table, Icon, Tooltip } from 'antd'
import ExternalLink from 'core/components/ExternalLink'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import CollapseButton from 'core/components/CollapseButton'
import './ProductsTable.less'
import type { Product } from 'core/http/cases/OrderHttp.types'

type Props = {
  products: Array<Product> | null | undefined
  collapseButtonCount: number
  collapsed: boolean
  hasHeaderCol?: boolean
  headerColWidth?: number
  style?: {}
  handleToggleCollapse: () => void
}
const { Column } = Table

const ProductsTable = ({
  products,
  collapseButtonCount,
  collapsed,
  hasHeaderCol = true,
  headerColWidth = 160,
  style,
  handleToggleCollapse,
}: Props) => {
  const renderHeader = (itemName, item) =>
    item.itemId === 'total' ? (
      <span>{item.itemName}</span>
    ) : (
      <span>
        <ExternalLink
          url={`https://www.google.com/search?q=${encodeURIComponent(
            itemName
          )}`}
        >
          {itemName}
        </ExternalLink>
        {item.itemUrl && item.itemUrl.indexOf('http') > -1 && (
          <ExternalLink cssClass="ProductsTable--icon" url={item.itemUrl}>
            <Tooltip title="Item URL" placement="right">
              <Icon type="shop" />
            </Tooltip>
          </ExternalLink>
        )}
      </span>
    )

  return (
    <div className="ProductsTable" style={style} data-test-id="productsTable">
      <Table
        className="ProductsTable--table"
        dataSource={products}
        pagination={false}
        rowKey={(record, index) => index}
        showHeader
      >
        {hasHeaderCol && (
          <Column
            title="Purchased Items"
            key="headerCol"
            className="ProductsTable--header-col"
            width={headerColWidth}
          />
        )}
        <Column
          title="Product Name"
          dataIndex="itemName"
          render={renderHeader}
        />
        <Column
          title="Price"
          dataIndex="itemPrice"
          className="ProductsTable--data-col"
          render={(text) => formatCurrencyNumber(text)}
        />
        <Column
          title="Qty"
          dataIndex="itemQuantity"
          className="ProductsTable--data-col"
        />
      </Table>
      {collapseButtonCount > 0 && (
        <CollapseButton
          collapsed={collapsed}
          count={collapseButtonCount}
          onClick={handleToggleCollapse}
        />
      )}
    </div>
  )
}

export default ProductsTable
