const buildUrl = (
  base: string,
  queryParams: Record<string, string | undefined>
): string => {
  const url = new URL(base)
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value)
    }
  })

  return url.toString()
}

export default buildUrl
