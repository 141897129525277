import { FC, useEffect, useState } from 'react'
import { Button, Upload } from 'antd'
import {
  CASE_REPORT_MENU_TEXT as TEXT,
  ALLOWED_UPLOAD_FILE_TYPES,
} from 'caseReport/Constants'
import './ResubmitMenuItem.less'
import { UploadFile } from 'antd/lib/upload/interface'

type Props = {
  onFileChange: (files: { fileList: Array<UploadFile> }) => void
}

const ResubmitFileSelector: FC<Props> = ({ onFileChange }) => {
  const [fileList, setFileList] = useState<Array<UploadFile>>([])

  useEffect(() => {
    if (fileList) {
      onFileChange({ fileList })
    }
    // Keep the onFileChange out of this dependency array
    // Calls it infinitely because of recompose
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const onRemove = (fileToRemove: UploadFile): void => {
    const newFileList = fileList.filter((file) => file.uid !== fileToRemove.uid)

    setFileList(newFileList)
  }

  const beforeUpload = (
    _file: UploadFile,
    files: Array<UploadFile>
  ): boolean => {
    setFileList(fileList.concat(files))

    return false
  }

  return (
    <Upload
      accept={ALLOWED_UPLOAD_FILE_TYPES}
      multiple
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      fileList={fileList}
    >
      <Button icon="paper-clip">{TEXT.RE_SUBMIT_MODAL_ATTACH_FILES}</Button>
    </Upload>
  )
}

export default ResubmitFileSelector
