import { FC } from 'react'
import { Tooltip } from 'antd'
import ExternalLink from 'core/components/ExternalLink'
import { UNKNOWN } from 'core/Constants'
import './CaseCustomerAndTeam.less'

type Props = {
  customerName?: string
  teamName?: string
  teamId?: number
}

const CaseCustomerAndTeam: FC<Props> = ({ customerName, teamName, teamId }) => (
  <div className="CaseCustomerAndTeam">
    <span className="CaseCustomerAndTeam--customer">
      Merchant:{' '}
      <Tooltip
        title={
          <span className="CaseCustomerAndTeam--tooltip">{customerName}</span>
        }
        placement="bottomLeft"
      >
        <strong>{customerName || UNKNOWN}</strong>
      </Tooltip>
    </span>
    <span className="CaseCustomerAndTeam--team">
      Team:{' '}
      {teamName && teamId ? (
        <ExternalLink url={`/cases?teamIds=${teamId}`}>
          <Tooltip
            title={
              <span className="CaseCustomerAndTeam--tooltip">{teamName}</span>
            }
            placement="bottomLeft"
          >
            <strong className="CaseCustomerAndTeam--team-name">
              {teamName}
            </strong>
          </Tooltip>
        </ExternalLink>
      ) : (
        <strong>{UNKNOWN}</strong>
      )}
    </span>
  </div>
)

export default CaseCustomerAndTeam
