import { action } from 'mobx'
import type { CustomerStore } from './customer.store'
import { getCustomer } from '@signifyd/http'

const customerActions = (store: CustomerStore) => {
  const _set = action((key, value) => {
    store[key] = value
  })

  const getCustomerById = (teamId: number) => {
    getCustomer(teamId)
      .then((res) => {
        _set('customer', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return {
    getCustomerById,
  }
}

export default customerActions
