import React, { FC } from 'react'
import { Col, Row, Modal } from 'antd'
import TrainingMask from 'core/components/TrainingMask'
import CaseNotesContainer from 'caseReport/containers/CaseNotesContainer'
import MerchantInfoContainer from 'caseReport/containers/MerchantInfoContainer'
import OrderReviewChecklistContainer from 'caseReport/containers/OrderReviewChecklistContainer'
import GuaranteeReasonContainer from 'caseReport/containers/GuaranteeReasonContainer'
import RecommendationReasonContainer from 'caseReport/containers/RecommendationReasonContainer'
import PolicyReasonContainer from 'caseReport/containers/PolicyReasonContainer'
import NotesAutoScrollContainer from 'caseReport/containers/NotesAutoScrollContainer'
import CaseNotesEditor from 'caseReport/containers/CaseNotesEditor'
import SigCard from 'core/components/SigCard'
import SigFlyoutToggle, {
  SigFlyoutToggleIcon,
} from 'core/components/SigFlyoutToggle'
import CannedNotes from 'caseReport/components/CannedNotes'
import './NotesContainerSM.less'

type Props = {
  notesModalVisible: boolean
  cannedNotesVisible: boolean
  onHideNotesModal: () => void
  onToggleCannedNotes: (arg0: boolean) => void
  onCannedNoteSelect: (arg0: string) => void
}

const NotesContainerSM: FC<Props> = ({
  notesModalVisible,
  cannedNotesVisible,
  onHideNotesModal,
  onToggleCannedNotes,
  onCannedNoteSelect,
}) => (
  <Modal
    className="NotesContainerSM"
    visible={notesModalVisible}
    footer={false}
    onCancel={onHideNotesModal}
  >
    <Row gutter={40}>
      <Col span={8} className="NotesContainerSM--col-divider">
        <div className="NotesContainerSM--heading">Merchant</div>
      </Col>
      <Col span={8} className="NotesContainerSM--col-divider">
        <div className="NotesContainerSM--heading">Notes</div>
      </Col>
    </Row>
    <Row className="NotesContainerSM--row" gutter={40}>
      <Col
        className="NotesContainerSM--col NotesContainerSM--col-divider"
        span={8}
      >
        <MerchantInfoContainer />
      </Col>
      <Col
        className="NotesContainerSM--col NotesContainerSM--col-divider NotesContainerSM--rtl"
        span={8}
      >
        <SigCard
          className="NotesContainerSM--ltr"
          title={cannedNotesVisible ? 'Canned Notes' : 'Order Review Checklist'}
          inner
        >
          {cannedNotesVisible ? (
            <CannedNotes onItemSelected={onCannedNoteSelect} />
          ) : (
            <OrderReviewChecklistContainer />
          )}
        </SigCard>
      </Col>
      <Col className="NotesContainerSM--notes-outer-wrap" span={8}>
        <SigCard title="Case Notes" inner />
        <NotesAutoScrollContainer className="NotesContainerSM--notes-inner-wrap">
          <RecommendationReasonContainer />
          <TrainingMask>
            <GuaranteeReasonContainer />
          </TrainingMask>
          <PolicyReasonContainer />
          <CaseNotesContainer />
        </NotesAutoScrollContainer>
        <SigFlyoutToggle
          className="NotesContainerSM--toggle"
          label={
            cannedNotesVisible ? 'Show Review Checklist' : 'Show Canned Notes'
          }
          checked={cannedNotesVisible}
          icon={<SigFlyoutToggleIcon checked={false} />}
          onChange={(checked) => onToggleCannedNotes(checked)}
        />
        <CaseNotesEditor />
      </Col>
    </Row>
  </Modal>
)

export default NotesContainerSM
