import type { PaymentMethod } from 'core/http/cases/OrderHttp.types'
import type {
  BinData,
  CaseInfo,
} from 'core/http/investigations/CaseSearchHttp.types'
import EnrichedCardNumber from './EnrichedCardNumber'
import { formatCreditCardNumber } from 'core/utils/formatCardNumber/formatCardNumber'
import SeederIcon from 'core/components/SeederIcon'
import './CardNumber.less'

interface Props {
  paymentMethod?: PaymentMethod | null
  binData: BinData
  caseInfo: CaseInfo
}

const CardNumber = ({ paymentMethod, binData, caseInfo }: Props) => {
  // The bin will be present on the payment method as part of the orders response
  // when the third party API we use for enriched BIN data is successful.
  if (paymentMethod?.bin) {
    return (
      <EnrichedCardNumber binData={binData} paymentMethod={paymentMethod} />
    )
  }

  // If the condition above isn't met, we use the raw bin and last 4 digit data supplied raw by the client.
  const cardNumber = formatCreditCardNumber({
    bin: caseInfo.cardBin,
    lastFourDigits: caseInfo.cardLastFourDigits,
  })

  if (cardNumber) {
    return (
      <div data-test-id="raw-card-number-text">
        <SeederIcon className="CardNumber--seeder" />
        {cardNumber}
      </div>
    )
  }

  return null
}

export default CardNumber
