import { Seller } from 'core/http/investigations/CaseSearchHttp.types'
import { compact, startCase, uniqueId } from 'lodash'
import { SellerDetails, SellerRecord, SellerView } from '../types'
import {
  getParentEntityLinks,
  getSellerAddressLinks,
  getSellerEmailLinks,
  getSellerIdLinks,
  getSellerNameLinks,
  getSellerPhoneNumberLinks,
} from './getLinks'

import { formatAddress } from 'core/utils/formatAddress/formatAddress'

/**
 * This function takes a sellers array, converts each seller to a new object that partitions the seller
 * based on display requirements. It also adds a unique ID to be used solely as an ordering key.
 * If both seller record and seller details are empty, the entire seller will be omitted.
 * @param sellers
 * @param currency
 */
export const processSellers = (
  sellers: Array<Seller>,
  currency: string
): Array<SellerView> => {
  return compact(sellers.map((seller) => splitSeller(seller, currency)))
}

const splitSeller = (
  seller: Seller,
  currency: string
): SellerView | undefined => {
  const sellerAddress =
    seller.address.fullAddress !== ''
      ? {
          value: formatAddress(seller.address) || null,
          links: getSellerAddressLinks(seller),
        }
      : {
          value: null,
          links: [],
        }

  const sellerTag = {
    value:
      seller.tags.length > 0
        ? seller.tags.map((tag) => startCase(tag)).join(', ')
        : null,
    links: [],
  }

  const aggregateOrderAmount = {
    value: seller.aggregateOrderAmount
      ? `${currency} ${seller.aggregateOrderAmount}`
      : null,
    links: [],
  }

  const sellerDetails: SellerDetails = {
    sellerID: {
      value: seller.sellerId,
      links: getSellerIdLinks(seller),
    },
    parentEntity: {
      value: seller.parentEntity,
      links: getParentEntityLinks(seller),
    },
    sellerName: {
      value: seller.name,
      links: getSellerNameLinks(seller),
    },
    sellerEmail: {
      value: seller.email,
      links: getSellerEmailLinks(seller),
    },
    sellerPhoneNumber: {
      value: seller.phone,
      links: getSellerPhoneNumberLinks(seller),
    },
    sellerAddress,
  }

  const sellerRecord: SellerRecord = {
    enrolmentDate: {
      value: seller.createdDate,
      links: [],
    },
    aggregateOrderCount: {
      value: seller.aggregateOrderCount,
      links: [],
    },
    aggregateOrderAmount,
    sellerTag,
  }

  const processedSellerView = [
    ...Object.values(sellerDetails),
    ...Object.values(sellerRecord),
  ]

  if (processedSellerView.some(({ value }) => value !== null)) {
    const id = uniqueId('seller')

    return { id, sellerDetails, sellerRecord }
  }

  return undefined
}
