import isNotaFiniteNumber from '../isNotaFiniteNumber'

export default function formatCurrencyNumber(num: string | number): string {
  // num is an empty string
  if (typeof num === 'string' && !num.length) {
    return ''
  }

  // convert non-empty strings to numbers
  num = Number(num)

  if (isNotaFiniteNumber(num)) {
    return ''
  }

  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
