import React, { FC } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CaseNote from 'caseReport/components/CaseNote'
import './CaseNotesContainer.less'
import { CaseNote as CaseNoteType } from '@signifyd/http'

type DeleteNoteTimers = Record<string, number>
type Props = {
  notes: Array<CaseNoteType> | null | undefined
  deleteNodeTimers: DeleteNoteTimers
  handleDeleteNote: (arg0: string | number) => void
  handleUndoDeleteNote: (arg0: string | number) => void
  deleteNoteTimers: { [key in any]?: number }
  handleAddNote: () => void
  handleClearNotes: () => void
  handleCustomNoteChange: () => void
  handleInvalidNote: () => void
}

const CaseNotesContainer: FC<Props> = ({
  notes,
  deleteNoteTimers,
  handleDeleteNote,
  handleUndoDeleteNote,
}) => (
  <div className="CaseNotesContainer" data-test-id="caseNotesList">
    <TransitionGroup>
      {notes?.map((note) => (
        <CSSTransition
          key={note.noteId}
          classNames="CaseNotesContainerAnim"
          timeout={500}
        >
          <CaseNote
            note={note}
            deleteClicked={!!deleteNoteTimers[note.noteId]}
            onDelete={() => handleDeleteNote(note.noteId)}
            onUndo={() => handleUndoDeleteNote(note.noteId)}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
)

export default CaseNotesContainer
