import type { DeeplinkProps } from 'core/components/Deeplink'

const linkedinFullName = (
  fullName: string | null | undefined
): DeeplinkProps | null | undefined =>
  fullName
    ? {
        iconType: 'linkedin',
        label: 'Full name search',
        url: `https://www.linkedin.com/search/results/all/?keywords=${fullName}`,
      }
    : null

export default linkedinFullName
