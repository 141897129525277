import React from 'react'
import { joinClassNames } from '@signifyd/utils'
import './Spinner.less'

const Spinner = ({ noBackground }) => (
  <div
    className={joinClassNames([
      'Spinner',
      noBackground && 'Spinner--no-background',
    ])}
  >
    <div className="Spinner--inner-wrapper">
      <span />
      <span />
      <span />
    </div>
  </div>
)

export default Spinner
