import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { ADDRESS_SEARCH_LABEL } from './constants'

const zillowAddress = ({
  street,
  cityName,
  regionAlpha,
  postalCode,
}: PhysicalLocationDetails): DeeplinkProps => ({
  iconType: 'zillow',
  label: ADDRESS_SEARCH_LABEL,
  url:
    'https://www.zillow.com/homes/' +
    `${street || ''}-` +
    `${cityName || ''}-` +
    `${regionAlpha || ''}-` +
    `${postalCode || ''}` +
    '_rb/?fromHomePage=true',
})

export default zillowAddress
