import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'

const WPAULastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `name=${lastName}&location=${zipCode || ''}`

  return {
    iconType: 'white-pages-au',
    label,
    url: `https://www.whitepages.com.au/residential/results?${query}`,
  }
}

export default WPAULastNameAndZipCode
