import {
  createCustomerMeta,
  deleteCustomerMeta,
  getCustomerMeta,
  updateCustomerMeta,
} from '@signifyd/http'
import { action } from 'mobx'

const merchantReportActions = (store) => {
  const _setMeta = action((keyValue) => {
    store.meta = { ...store.meta, ...keyValue }
  })

  const _setState = action((keyValue) => {
    store.state = { ...store.state, ...keyValue }
  })

  const _setLoading = action((keyValue) => {
    store.loading = { ...store.loading, ...keyValue }
  })

  const _resetLoading = (namespace) => {
    _setLoading({
      pending: true,
      success: false,
      finished: false,
      error: false,
      namespace,
    })
  }

  const getMerchantMetadataById = (customerId) => {
    _resetLoading('get')

    return getCustomerMeta(customerId)
      .then(({ data }) => {
        _setLoading({
          success: true,
        })

        _setMeta(data)

        _setState({
          notes: data.notes,
          isEditing: false,
        })
      })
      .catch(() => {
        _setLoading({
          error: true,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const createMerchantMetadataById = (customerId) => {
    _resetLoading('create')

    return createCustomerMeta(customerId, store.state.notes)
      .then(({ data }) => {
        _setLoading({
          success: true,
        })

        _setMeta({
          notes: data.notes,
        })

        _setState({
          notes: data.notes,
          isEditing: false,
        })
      })
      .catch((err) => {
        _setLoading({
          error: err,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const updateMerchantMetadataById = (customerId) => {
    _resetLoading('update')

    return updateCustomerMeta(customerId, store.state.notes)
      .then(({ data }) => {
        _setLoading({
          success: true,
        })

        _setMeta({
          notes: data.notes,
        })

        _setState({
          notes: data.notes,
          isEditing: false,
        })
      })
      .catch((err) => {
        console.log(err)

        _setLoading({
          error: err,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const deleteMerchantMetadataById = (customerId) => {
    _resetLoading('delete')

    return deleteCustomerMeta(customerId)
      .then(() => {
        _setLoading({
          success: true,
        })

        _setMeta({
          notes: null,
        })

        _setState({
          notes: null,
          isEditing: false,
        })
      })
      .catch((err) => {
        _setLoading({
          error: err,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const startEdit = () => {
    _setState({
      isEditing: true,
    })
  }

  const cancelEdit = () => {
    _setState({
      isEditing: false,
      notes: store.meta.notes,
    })
  }

  const changeNotes = (value) => {
    _setState({
      notes: value,
    })
  }

  const deleteNotes = () => {
    _setState({
      isEditing: false,
    })
  }

  const submitNotes = () => {
    _setState({
      isEditing: false,
    })

    _setMeta({
      notes: store.state.notes,
    })
  }

  return {
    getMerchantMetadataById,
    createMerchantMetadataById,
    updateMerchantMetadataById,
    deleteMerchantMetadataById,
    startEdit,
    cancelEdit,
    changeNotes,
    deleteNotes,
    submitNotes,
  }
}

export default merchantReportActions
