import { extendObservable } from 'mobx'
import { get } from 'lodash'
import { reReviewSubmitted } from 'core/utils/computedCaseData'
import asyncStore from './async.store'

const store = extendObservable(asyncStore, {
  caseId: null,
  notes: null,
  deleteNoteTimers: {},
  state: {
    customNote: '',
    textAreaKey: 1,
  },

  get notesCount() {
    return get(this, 'notes.length')
  },

  get reReviewSubmitted() {
    return reReviewSubmitted(this.notes)
  },
})
export default store
