import { Tooltip } from 'antd'
import SvgIcon from 'core/components/SvgIcon'
import cx from 'classnames'
import useStores from 'useStores'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { DecisionReasonType } from 'core/http/investigations/CaseSearchHttp.types'
import { SvgIconType } from 'core/components/SvgIcon/SvgIcon'

import './GuaranteeReasonContainer.less'
import { DECISION_MECHANISM } from '@signifyd/http'

type ReasonTypes = Record<
  DecisionReasonType,
  {
    icon: SvgIconType
    cssClassName: string
  }
>
const REASON_TYPE_FOR_UI: ReasonTypes = {
  'MODEL APPROVED': {
    icon: 'GUARANTEE_DISPOSITION_APPROVED_AUTO',
    cssClassName: 'model-approved',
  },
  'MODEL DECLINED': {
    icon: 'GUARANTEE_DISPOSITION_DECLINED_AUTO',
    cssClassName: 'model-declined',
  },
  'MODEL CANCELLED': {
    icon: 'GUARANTEE_DISPOSITION_CANCELLED_AUTO',
    cssClassName: 'model-cancelled',
  },
  'MODEL CANCELED': {
    icon: 'GUARANTEE_DISPOSITION_CANCELLED_AUTO',
    cssClassName: 'model-cancelled',
  },
  'MANUAL REVIEW': {
    icon: 'GUARANTEE_DISPOSITION_MR',
    cssClassName: 'manual-review',
  },
}

const GuaranteeReasonContainer: FC<{ className?: string }> = observer(
  (className) => {
    const {
      caseReportStore: { case: investigation },
    } = useStores()

    const isPolicyOnlyAccept =
      investigation?.decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT

    if (
      !investigation?.guaranteeAutoDecisionReason ||
      !investigation?.guaranteeAutoDecisionReasonType ||
      isPolicyOnlyAccept
    ) {
      return null
    }

    const {
      guaranteeAutoDecisionReasonDescription,
      guaranteeAutoDecisionReasonType,
      guaranteeAutoDecisionReason,
    } = investigation

    const reasonTypeUI = REASON_TYPE_FOR_UI[guaranteeAutoDecisionReasonType]
    const decisionReason =
      guaranteeAutoDecisionReasonDescription || guaranteeAutoDecisionReason

    return (
      <div
        className={cx([
          'GuaranteeReasonContainer',
          `GuaranteeReasonContainer--${reasonTypeUI.cssClassName}`,
          className,
        ])}
        data-test-id="guaranteeReasonContainer"
      >
        {guaranteeAutoDecisionReasonType && (
          <Tooltip
            overlayClassName="GuaranteeReasonContainer--reason-tooltip"
            title={guaranteeAutoDecisionReason}
            placement="bottomLeft"
          >
            <div className="GuaranteeReasonContainer--reason-type">
              <SvgIcon
                size="small"
                type={reasonTypeUI.icon}
                className="GuaranteeReasonContainer--reason-type-icon"
              />
              <h6 className="GuaranteeReasonContainer--reason-type-text">
                {guaranteeAutoDecisionReasonType}
              </h6>
            </div>
          </Tooltip>
        )}
        <p>{decisionReason}</p>
      </div>
    )
  }
)

export default GuaranteeReasonContainer
