import { withProps, compose, withState, withHandlers } from 'recompose'
import {
  getCardHolderLinksByCountry,
  getDeliveryRecipientLinksByCountry,
  getDAOccupantLinks,
} from './utils'
import PeopleEntry from './PeopleEntry'

const DEFAULT_VISIBLE_DA_OCCUPANTS = 3
const enhance = compose(
  withState('collapsed', 'setCollapsed', true),
  withHandlers({
    handleToggleCollapse:
      ({ collapsed, setCollapsed }) =>
      () => {
        setCollapsed(!collapsed)
      },
  }),
  withProps(({ collapsed, entriesByRole, countryCode }) => {
    const allDAOccupants = entriesByRole.deliveryAddressOccupant || []
    const collapseButtonCount =
      allDAOccupants.length - DEFAULT_VISIBLE_DA_OCCUPANTS
    const DAOccupantsForDisplay =
      collapsed && collapseButtonCount > 0
        ? allDAOccupants.slice(0, DEFAULT_VISIBLE_DA_OCCUPANTS)
        : allDAOccupants
    const cardHolders = entriesByRole.cardHolder
      ? entriesByRole.cardHolder.map((cardHolder) => ({
          ...cardHolder,
          deeplinks: getCardHolderLinksByCountry(entriesByRole, countryCode),
        }))
      : []
    const deliveryRecipients = entriesByRole.deliveryRecipient
      ? entriesByRole.deliveryRecipient.map((deliveryRecipient) => ({
          ...deliveryRecipient,
          deeplinks: getDeliveryRecipientLinksByCountry(
            entriesByRole,
            countryCode
          ),
        }))
      : []
    const DAOccupants = DAOccupantsForDisplay.map((occupant) => ({
      ...occupant,
      deeplinks: getDAOccupantLinks(occupant),
    }))

    return {
      people: [...cardHolders, ...deliveryRecipients, ...DAOccupants],
      collapseButtonCount,
    }
  })
)
export default enhance(PeopleEntry)
