import type { AxiosPromise } from 'axios'
import type { AdditionalDecision } from './AdditionalDecisionsHttp.types'
import {
  API,
  CaseNote,
  CaseNotesResponse,
  INV_GUARANTEE_DISPOSITION,
} from '@signifyd/http'

export const getDecisions = (
  caseId: number
): AxiosPromise<Array<AdditionalDecision>> => {
  return API.get(`/v2/cases/${caseId}/additionalDecisions`)
}
export const addDecision = (
  caseId: number
): AxiosPromise<AdditionalDecision> => {
  return API.post(`/v2/cases/${caseId}/additionalDecisions`)
}
export const updateDecision = (
  caseId: number,
  decisionId: number,
  disposition: INV_GUARANTEE_DISPOSITION
): AxiosPromise<AdditionalDecision> => {
  return API.patch(`/v2/cases/${caseId}/additionalDecisions/${decisionId}`, {
    updateMask: ['additionalDecision'],
    additionalDecision: disposition,
  })
}
export const getAdditionalDecisionNotes = (
  caseId: number,
  decisionId: number
): AxiosPromise<CaseNotesResponse> => {
  return API.get(`/v2/cases/${caseId}/additionalDecisions/${decisionId}/notes`)
}

export const addAdditionalDecisionNote = (
  caseId: number,
  decisionId: number,
  note: string
): AxiosPromise<Array<CaseNote>> => {
  return API.post(
    `/v2/cases/${caseId}/additionalDecisions/${decisionId}/notes`,
    {
      text: note,
    }
  )
}
