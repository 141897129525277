import type { LinkKey, LinkKeysSubset } from './constants'
import {
  googleWebAddress,
  googleMapsAddress,
  zillowAddress,
  EkataAddress,
  _118712Address,
  pagesJaunesAddress,
  pagineBiancheAddress,
  espanaDirectorioAddress,
  abcTelefonosAddess,
} from 'core/utils/deeplinks'
import type { PhysicalLocation as PhysicalLocationEntry } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import {
  LINK_KEYS,
  US_ADDRESS_LINKS,
  FR_ADDRESS_LINKS,
  IT_ADDRESS_LINKS,
  ES_ADDRESS_LINKS,
  AU_ADDRESS_LINKS,
  CA_ADDRESS_LINKS,
  DEFAULT_ADDRESS_LINKS,
} from './constants'

type LinkResults = Array<DeeplinkProps | false | null>

const getLinks = (
  address: PhysicalLocationEntry,
  linkKeys: LinkKeysSubset
): LinkResults => {
  const linkKeysSet: Set<LinkKey> = new Set(linkKeys)
  const { entityName: fullAddress, details } = address

  return [
    linkKeysSet.has(LINK_KEYS.GOOGLE_WEB) && googleWebAddress(fullAddress),
    linkKeysSet.has(LINK_KEYS.GOOGLE_MAP) &&
      googleMapsAddress(fullAddress, details),
    linkKeysSet.has(LINK_KEYS.ZILLOW) && zillowAddress(details),
    linkKeysSet.has(LINK_KEYS.EKATA) && EkataAddress(details),
    linkKeysSet.has(LINK_KEYS._118712) && _118712Address(details),
    linkKeysSet.has(LINK_KEYS.PAGES_JAUNES) && pagesJaunesAddress(details),
    linkKeysSet.has(LINK_KEYS.PAGINE_BIANCHE) && pagineBiancheAddress(details),
    linkKeysSet.has(LINK_KEYS.ESPANA_DIRECTORIO) &&
      espanaDirectorioAddress(details),
    linkKeysSet.has(LINK_KEYS.ABC_TELEFONOS) && abcTelefonosAddess(details),
  ].filter((item) => item)
}

const getLinksByCountry = (
  address: PhysicalLocationEntry,
  countryCode: string
): LinkResults => {
  switch (countryCode) {
    case 'US':
      return getLinks(address, US_ADDRESS_LINKS)

    case 'FR':
      return getLinks(address, FR_ADDRESS_LINKS)

    case 'IT':
      return getLinks(address, IT_ADDRESS_LINKS)

    case 'ES':
      return getLinks(address, ES_ADDRESS_LINKS)

    case 'AU':
      return getLinks(address, AU_ADDRESS_LINKS)

    case 'CA':
      return getLinks(address, CA_ADDRESS_LINKS)

    default:
      return getLinks(address, DEFAULT_ADDRESS_LINKS)
  }
}

export default getLinksByCountry
