import type { DeeplinkProps } from 'core/components/Deeplink'
import type { PhysicalLocationDetails } from 'core/http/cases/EntriesHttp.types'
import { EMAIL_SEARCH_LABEL, PHONE_NUM_SEARCH_LABEL } from './constants'

export const googleWebFullName = (
  fullName: string | null | undefined,
  cityName: string | null | undefined,
  regionAlpha: string | null | undefined,
  label: string
): DeeplinkProps | null | undefined => {
  if (!fullName || (!cityName && !regionAlpha)) {
    return null
  }

  return {
    iconType: 'google-web',
    label,
    url:
      'https://www.google.com/search?q=' +
      `${encodeURIComponent(fullName)} ` +
      `${encodeURIComponent(cityName || '')} ` +
      `${encodeURIComponent(regionAlpha || '')}`,
  }
}

export const googleWebLastName = (
  lastName: string | null | undefined,
  fullAddress: string | null | undefined,
  label: string
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return null
  }

  return {
    iconType: 'google-web',
    label,
    url:
      'https://www.google.com/search?q=' +
      `${encodeURIComponent(lastName)} ` +
      `${encodeURIComponent(fullAddress || '')} `,
  }
}

export const googleWebAddress = (
  fullAddress: string | null | undefined
): DeeplinkProps | null | undefined => {
  if (!fullAddress) {
    return null
  }

  return {
    iconType: 'google-web',
    label: 'Address Search',
    url: `https://www.google.com/search?q=${fullAddress}`,
  }
}

export const googleMapsAddress = (
  fullAddress: string | null | undefined,
  details: PhysicalLocationDetails | null | undefined
): DeeplinkProps | null | undefined => {
  const query = []
  if (fullAddress) {
    query.push(fullAddress)
  }

  if (details) {
    const { countryIsoCode, latitude, longitude } = details
    const str =
      (countryIsoCode || '') +
      (latitude && longitude ? ` @${latitude}+${longitude}` : '')
    query.push(str)
  }

  if (!query.length) {
    return null
  }

  return {
    iconType: 'google-maps',
    label: 'Address Map View',
    url: `https://maps.google.com/maps?z=20&t=k&q=${query.join(', ')}`,
  }
}

export const googleWebPhone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined => {
  if (!phoneNum) {
    return null
  }

  return {
    iconType: 'google-web',
    label: PHONE_NUM_SEARCH_LABEL,
    url: `https://www.google.com/search?q=${encodeURIComponent(phoneNum)}`,
  }
}

export const googleWebEmail = (
  email: string | null | undefined
): DeeplinkProps | null | undefined => {
  if (!email) {
    return null
  }

  return {
    iconType: 'google-web',
    label: EMAIL_SEARCH_LABEL,
    url: `https://www.google.com/search?q=${encodeURIComponent(email)}`,
  }
}
