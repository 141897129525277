import React from 'react'

const SIGNIFYD_ICONS = {
  RECOMMENDATION_ICON: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="white"
      />
      <path
        d="M13.6564 16H15.2764L13.3204 12.856C14.2804 12.7 15.2164 11.932 15.2164 10.504C15.2164 9.028 14.1844 7.996 12.6004 7.996H9.08442V16H10.4884V13.012H11.8804L13.6564 16ZM13.7764 10.504C13.7764 11.272 13.2004 11.776 12.4084 11.776H10.4884V9.232H12.4084C13.2004 9.232 13.7764 9.736 13.7764 10.504Z"
        fill="white"
      />
    </svg>
  ),
  GUARANTEE_ICON: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="white"
      />
      <path
        d="M8.19122 12.004C8.19122 14.512 10.0752 16.156 12.3912 16.156C13.7832 16.156 14.8992 15.58 15.6912 14.704V11.704H11.8992V12.94H14.2872V14.188C13.9272 14.524 13.2072 14.908 12.3912 14.908C10.8072 14.908 9.63122 13.684 9.63122 12.004C9.63122 10.324 10.8072 9.112 12.3912 9.112C13.2912 9.112 14.0472 9.58 14.4672 10.18L15.6192 9.532C14.9712 8.608 13.9512 7.864 12.3912 7.864C10.0752 7.864 8.19122 9.496 8.19122 12.004Z"
        fill="white"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_APPROVED_AUTO: (
    <svg width="30" height="30" viewBox="0 0 30 30">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M25.32 6a.68.68 0 0 1 .68.68v12.95a.68.68 0 0 1-.68.68H16.76c.057.866.312 2.366 1.395 2.474.027.002.048.005.066.008h.129a.604.604 0 1 1 0 1.208H11.65a.604.604 0 0 1 0-1.208h.13c.017-.003.038-.006.065-.008 1.083-.108 1.338-1.608 1.395-2.473H4.679A.68.68 0 0 1 4 19.63V6.68A.68.68 0 0 1 4.68 6h20.64zm-14.668 7.353c-.21.222-.199.665.011.88l2.561 2.596c.216.222.647.228.87.018l.407-.391.881-.846 4.281-4.083c.222-.21.228-.641.012-.857l-.496-.507c-.216-.216-.647-.216-.863-.006l-4.188 4.182c-.216.216-.647.21-.857-.006l-1.394-1.406c-.21-.221-.63-.216-.84.012l-.385.414z"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_DECLINED_AUTO: (
    <svg width="30" height="30" viewBox="0 0 30 30">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M25.32 6a.68.68 0 0 1 .68.68v12.95a.68.68 0 0 1-.68.68H16.76c.057.866.312 2.366 1.395 2.474.027.002.048.005.066.008h.129a.604.604 0 1 1 0 1.208H11.65a.604.604 0 0 1 0-1.208h.13c.017-.003.038-.006.065-.008 1.083-.108 1.338-1.608 1.395-2.473H4.679A.68.68 0 0 1 4 19.63V6.68A.68.68 0 0 1 4.68 6h20.64zM11.5 10.917l2.333 2.333-2.333 2.333 1.167 1.167L15 14.417l2.333 2.333 1.167-1.167-2.333-2.333 2.333-2.333-1.167-1.167L15 12.083 12.667 9.75 11.5 10.917z"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_CANCELLED_AUTO: (
    <svg width="22" height="18" viewBox="0 0 22 18">
      <path
        d="M21.32 0H.68A.68.68 0 0 0 0 .68v12.95c0 .376.304.68.68.68H9.24c-.057.866-.312 2.366-1.395 2.474a5.055 5.055 0 0 1-.195.008.604.604 0 1 0 0 1.208h6.698a.604.604 0 0 0 0-1.208h-.13c-1.148-.116-1.403-1.616-1.46-2.481h8.562a.68.68 0 0 0 .679-.68V.68a.68.68 0 0 0-.68-.68z"
        fill="#FFF"
      />
      <path
        d="M15.625 7.25A4.639 4.639 0 0 1 11 11.875 4.639 4.639 0 0 1 6.375 7.25 4.639 4.639 0 0 1 11 2.625a4.639 4.639 0 0 1 4.625 4.625zm-1.156 0c0-1.92-1.55-3.469-3.469-3.469a3.46 3.46 0 0 0-2.023.648l4.844 4.844a3.46 3.46 0 0 0 .648-2.023zm-1.446 2.821L8.18 5.227A3.46 3.46 0 0 0 7.53 7.25c0 1.92 1.55 3.469 3.469 3.469a3.46 3.46 0 0 0 2.023-.648z"
        fill="#EE4300"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_MR: (
    <svg width="16" height="22" viewBox="0 0 16 22">
      <path
        style={{
          transformOrigin: '50% 50%',
        }}
        transform="scale(0.75)"
        d="M2.761 20.607s.11-4.09-.773-6.01c0 0-.43.444-.908 0-.48-.445-2.184-4.154.025-5.018 0 0-1.443-5.28 2.59-7.634.077-.102-.2-1.35 5.847-1.945 0 0 .126.67 1.622 1.647 4.742 2.168 3.168 7.932 3.168 7.932 2.208.865.504 4.573.025 5.017-.48.446-.908 0-.908 0-.884 1.922-.71 6.325-.71 6.325.11 1.633-10.089 1.32-9.978-.314z"
        fill="#FFF"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_APPROVED_MR: (
    <svg width="30" height="30" viewBox="0 0 30 30">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.761 24.607s.11-4.09-.773-6.01c0 0-.43.444-.908 0-.48-.445-2.184-4.154.025-5.018 0 0-1.443-5.28 2.59-7.634.077-.102-.2-1.35 5.847-1.945 0 0 .126.67 1.622 1.647 4.742 2.168 3.168 7.932 3.168 7.932 2.208.865.504 4.573.025 5.017-.48.446-.908 0-.908 0-.884 1.922-.71 6.325-.71 6.325.11 1.633-10.089 1.32-9.978-.314zm.39-9.254c-.21.222-.198.665.012.88l2.561 2.596c.216.222.647.228.87.018l.407-.391.881-.846 4.281-4.083c.222-.21.228-.641.012-.857l-.496-.507c-.216-.216-.647-.216-.863-.006l-4.188 4.182c-.216.216-.647.21-.857-.006l-1.394-1.406c-.21-.221-.63-.216-.84.012l-.385.414z"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_DECLINED_MR: (
    <svg width="30" height="30" viewBox="0 0 30 30">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M9.761 24.607s.11-4.09-.773-6.01c0 0-.43.444-.908 0-.48-.445-2.184-4.154.025-5.018 0 0-1.443-5.28 2.59-7.634.077-.102-.2-1.35 5.847-1.945 0 0 .126.67 1.622 1.647 4.742 2.168 3.168 7.932 3.168 7.932 2.208.865.504 4.573.025 5.017-.48.446-.908 0-.908 0-.884 1.922-.71 6.325-.71 6.325.11 1.633-10.089 1.32-9.978-.314zm1.489-12.44l2.333 2.333-2.333 2.333L12.417 18l2.333-2.333L17.083 18l1.167-1.167-2.333-2.333 2.333-2.333L17.083 11l-2.333 2.333L12.417 11l-1.167 1.167z"
      />
    </svg>
  ),
  GUARANTEE_DISPOSITION_APPROVE: (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#CCD3DC"
          d="M10.33 3.61L12 1.095l1.669 2.514 2.504-1.683.58 2.96 2.956-.596-.596 2.956 2.96.581-1.682 2.503L22.904 12l-2.513 1.669 1.682 2.503-2.96.58.596 2.957-2.956-.597-.58 2.96-2.504-1.683-1.67 2.514-1.669-2.513-2.503 1.682-.58-2.96-2.956.597.596-2.957-2.96-.58 1.682-2.503L1.096 12l2.513-1.669-1.682-2.503 2.96-.581-.596-2.956 2.956.596.58-2.96 2.503 1.682z"
        />
        <path
          fill="#00BB5E"
          d="M8.286 12.605l2.11 2.139c.178.183.534.187.717.014l.336-.322.726-.697 3.528-3.365c.183-.173.188-.529.01-.706l-.409-.419c-.177-.177-.533-.177-.711-.004l-3.452 3.446c-.177.178-.533.173-.706-.005l-1.15-1.158c-.172-.183-.518-.178-.691.01l-.318.34c-.173.184-.163.549.01.727z"
        />
      </g>
    </svg>
  ),
  GUARANTEE_DISPOSITION_APPROVED: (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#00BB5E"
          stroke="#00BB5E"
          d="M10.33 3.61L12 1.095l1.669 2.514 2.504-1.683.58 2.96 2.956-.596-.596 2.956 2.96.581-1.682 2.503L22.904 12l-2.513 1.669 1.682 2.503-2.96.58.596 2.957-2.956-.597-.58 2.96-2.504-1.683-1.67 2.514-1.669-2.513-2.503 1.682-.58-2.96-2.956.597.596-2.957-2.96-.58 1.682-2.503L1.096 12l2.513-1.669-1.682-2.503 2.96-.581-.596-2.956 2.956.596.58-2.96 2.503 1.682z"
        />
        <path
          fill="#FFF"
          d="M8.286 12.605l2.11 2.139c.178.183.534.187.717.014l.336-.322.726-.697 3.528-3.365c.183-.173.188-.529.01-.706l-.409-.419c-.177-.177-.533-.177-.711-.004l-3.452 3.446c-.177.178-.533.173-.706-.005l-1.15-1.158c-.172-.183-.518-.178-.691.01l-.318.34c-.173.184-.163.549.01.727z"
        />
      </g>
    </svg>
  ),
  GUARANTEE_DISPOSITION_DECLINE: (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#CCD3DC"
          d="M10.33 3.61L12 1.095l1.669 2.514 2.504-1.683.58 2.96 2.956-.596-.596 2.956 2.96.581-1.682 2.503L22.904 12l-2.513 1.669 1.682 2.503-2.96.58.596 2.957-2.956-.597-.58 2.96-2.504-1.683-1.67 2.514-1.669-2.513-2.503 1.682-.58-2.96-2.956.597.596-2.957-2.96-.58 1.682-2.503L1.096 12l2.513-1.669-1.682-2.503 2.96-.581-.596-2.956 2.956.596.58-2.96 2.503 1.682z"
        />
        <path
          fill="#EE4300"
          d="M8.854 10.02l1.98 1.98-1.98 1.98a.5.5 0 0 0 0 .707l.46.46a.5.5 0 0 0 .706 0l1.98-1.98 1.98 1.98a.5.5 0 0 0 .707 0l.46-.46a.5.5 0 0 0 0-.707L13.166 12l1.98-1.98a.5.5 0 0 0 0-.707l-.46-.46a.5.5 0 0 0-.707 0L12 10.834l-1.98-1.98a.5.5 0 0 0-.707 0l-.46.46a.5.5 0 0 0 0 .707z"
        />
      </g>
    </svg>
  ),
  GUARANTEE_DISPOSITION_DECLINED: (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EE4300"
          stroke="#EE4300"
          d="M10.33 3.61L12 1.095l1.669 2.514 2.504-1.683.58 2.96 2.956-.596-.596 2.956 2.96.581-1.682 2.503L22.904 12l-2.513 1.669 1.682 2.503-2.96.58.596 2.957-2.956-.597-.58 2.96-2.504-1.683-1.67 2.514-1.669-2.513-2.503 1.682-.58-2.96-2.956.597.596-2.957-2.96-.58 1.682-2.503L1.096 12l2.513-1.669-1.682-2.503 2.96-.581-.596-2.956 2.956.596.58-2.96 2.503 1.682z"
        />
        <path
          fill="#FFF"
          d="M8.854 10.02l1.98 1.98-1.98 1.98a.5.5 0 0 0 0 .707l.46.46a.5.5 0 0 0 .706 0l1.98-1.98 1.98 1.98a.5.5 0 0 0 .707 0l.46-.46a.5.5 0 0 0 0-.707L13.166 12l1.98-1.98a.5.5 0 0 0 0-.707l-.46-.46a.5.5 0 0 0-.707 0L12 10.834l-1.98-1.98a.5.5 0 0 0-.707 0l-.46.46a.5.5 0 0 0 0 .707z"
        />
      </g>
    </svg>
  ),
  POLICY_REJECT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height="1em"
    >
      <path
        stroke="#FF431C"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5.5v13M7 9.5v6M17 8.5v6"
      />
      <circle cx="17" cy="14.5" r="1" fill="#FF431C" stroke="#FF431C" />
      <circle cx="7" cy="9.5" r="1" fill="#FF431C" stroke="#FF431C" />
      <circle cx="12" cy="11.5" r="1" fill="#FF431C" stroke="#FF431C" />
    </svg>
  ),
  POLICY_ACCEPT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height="1em"
    >
      <path
        stroke="#00BB5E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5.5v13M7 9.5v6M17 8.5v6"
      />
      <circle cx="17" cy="14.5" r="1" fill="#00BB5E" stroke="#00BB5E" />
      <circle cx="7" cy="9.5" r="1" fill="#00BB5E" stroke="#00BB5E" />
      <circle cx="12" cy="11.5" r="1" fill="#00BB5E" stroke="#00BB5E" />
    </svg>
  ),
  POLICY_HOLD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height="1em"
    >
      <path
        stroke="#63758F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5.5v13M7 9.5v6M17 8.5v6"
      />
      <circle cx="17" cy="14.5" r="1" fill="#63758F" stroke="#63758F" />
      <circle cx="7" cy="9.5" r="1" fill="#63758F" stroke="#63758F" />
      <circle cx="12" cy="11.5" r="1" fill="#63758F" stroke="#63758F" />
    </svg>
  ),
  ESCALATE_CASE: (
    <svg width="21" height="20" viewBox="0 0 21 20">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M8.911 0a1.358 1.358 0 0 0 0 2.717h6.517L.575 17.567a1.356 1.356 0 0 0 .96 2.319c.349 0 .694-.133.96-.398L17.46 4.523v6.465a1.358 1.358 0 0 0 2.716 0V1.355A1.353 1.353 0 0 0 18.821 0h-9.91z"
      />
    </svg>
  ),
  RE_SUBMIT_CASE: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46881 3.3049L7.17308 1.76163L6.6406 4.47652L3.92988 3.92988L4.47652 6.6406L1.76163 7.17391L3.3049 9.46965L1 11.0004L3.3049 12.5312L1.76163 14.8269L4.47652 15.3594L3.92988 18.071L6.6406 17.5235L7.17308 20.2384L9.46881 18.6951L10.9996 21L12.5304 18.6951L14.8269 20.2384L15.3594 17.5235L18.0701 18.071L17.5235 15.3594L20.2384 14.8269L18.6951 12.5312L21 11.0004L18.6951 9.46965L20.2384 7.17391L17.5235 6.6406L18.0701 3.92988L15.3594 4.47652L14.8269 1.76163L12.5304 3.3049L10.9996 1L9.46881 3.3049Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M13.9901 7.83955L13.4944 8.22715C12.8186 7.36318 11.7674 6.80859 10.587 6.80859C8.54883 6.80859 6.89912 8.45654 6.89649 10.4956C6.89385 12.5364 8.54707 14.1914 10.587 14.1914C12.1805 14.1914 13.5384 13.1807 14.0552 11.7647C14.0684 11.7278 14.049 11.6865 14.0121 11.6742L13.5138 11.5028C13.4964 11.4969 13.4774 11.498 13.4608 11.5058C13.4442 11.5137 13.4314 11.5278 13.425 11.545C13.4092 11.589 13.3916 11.6329 13.3731 11.676C13.2211 12.0363 13.0031 12.3598 12.7254 12.6375C12.4499 12.9135 12.1237 13.1338 11.7647 13.2861C11.393 13.4435 10.9966 13.5234 10.5888 13.5234C10.1801 13.5234 9.78457 13.4435 9.4128 13.2861C9.05348 13.1344 8.72718 12.9141 8.45215 12.6375C8.17591 12.362 7.95591 12.0355 7.8044 11.676C7.64707 11.3033 7.56709 10.9078 7.56709 10.4991C7.56709 10.0904 7.64707 9.69492 7.8044 9.32227C7.95645 8.96191 8.17442 8.63848 8.45215 8.36074C8.72989 8.08301 9.05332 7.86504 9.4128 7.71211C9.78457 7.55479 10.181 7.4748 10.5888 7.4748C10.9975 7.4748 11.393 7.55479 11.7647 7.71211C12.1241 7.86385 12.4504 8.08417 12.7254 8.36074C12.8124 8.44775 12.8941 8.54004 12.9697 8.63672L12.4406 9.0498C12.4302 9.0579 12.4222 9.06879 12.4176 9.08122C12.4131 9.09365 12.4121 9.10711 12.4149 9.12005C12.4176 9.133 12.424 9.1449 12.4332 9.1544C12.4424 9.16389 12.4541 9.1706 12.467 9.17373L14.0104 9.55166C14.0543 9.56221 14.0974 9.52881 14.0974 9.48398L14.1044 7.89404C14.1035 7.83604 14.0358 7.80352 13.9901 7.83955Z"
        fill="#003087"
      />
    </svg>
  ),
  CASE_ESCALATED: (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#9FADBF"
          d="M7.494 4a.543.543 0 0 0 0 1.087H10.1l-5.942 5.94a.543.543 0 1 0 .768.768l5.986-5.986v2.586a.543.543 0 0 0 1.087 0V4.542A.541.541 0 0 0 11.458 4H7.494z"
        />
        <rect width="15" height="15" x=".5" y=".5" stroke="#9FADBF" rx="3" />
      </g>
    </svg>
  ),
  GUARANTEE_BADGE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        fill="#FFF"
        d="M17.856 9.227L20 6l2.143 3.227 3.216-2.16.745 3.8 3.795-.765-.765 3.795 3.8.746-2.16 3.215L34 20l-3.227 2.143 2.16 3.214-3.8.745.765 3.796-3.795-.766-.745 3.8-3.216-2.16L20 34l-2.143-3.227-3.214 2.16-.745-3.8-3.795.766.765-3.796-3.8-.745 2.16-3.214L6 20l3.227-2.143-2.16-3.215 3.8-.746-.765-3.795 3.795.765.745-3.8 3.214 2.16zm5.33 12.023v3.105c-.108.037-.231.075-.37.113a9.32 9.32 0 0 1-.728.163c-.266.049-.55.09-.853.121-.298.03-.6.046-.906.046-.77 0-1.433-.114-1.996-.341a3.802 3.802 0 0 1-1.392-.933 3.84 3.84 0 0 1-.829-1.427 5.832 5.832 0 0 1-.28-1.847c0-.718.096-1.361.286-1.932.187-.56.465-1.032.836-1.42.37-.385.834-.683 1.397-.892.567-.213 1.238-.32 2.015-.32a4.946 4.946 0 0 1 1.968.395c.288.121.553.262.797.424.26.171.496.346.71.527l.46.387V15.43l-.146-.08a14.844 14.844 0 0 0-.882-.445 7.579 7.579 0 0 0-.918-.349 6.944 6.944 0 0 0-2.102-.306c-.527 0-1.035.055-1.525.164-.49.11-.949.273-1.373.487a5 5 0 0 0-1.175.831 4.842 4.842 0 0 0-.904 1.178c-.248.447-.439.948-.575 1.504a7.773 7.773 0 0 0-.201 1.836c0 1.002.145 1.88.436 2.629.292.752.699 1.381 1.219 1.884.52.503 1.135.877 1.842 1.12.7.245 1.456.367 2.269.367.38 0 .756-.02 1.125-.058.36-.039.705-.095 1.042-.17.34-.076.664-.168.975-.277.313-.11.622-.236.93-.38l.162-.076V21.25h-1.315z"
        fillRule="evenodd"
      />
    </svg>
  ),
  SEEDER_ICON: (
    <svg width="11" height="12" viewBox="0 0 11 12">
      <path
        fill="#ccd3dc"
        d="M8.506 7.822c-.044.083-.09.166-.137.248-1.478 2.579-4.029 3.935-5.894 3.22-.253.181-.453.245-.583.172-.13-.075-.175-.28-.147-.59C.186 9.622.069 6.736 1.548 4.158c.047-.082.095-.162.144-.242.966.765 2.06 1.508 3.255 2.194a26.507 26.507 0 0 0 3.56 1.71zm-.29-6.318c.518-.418 1.22-.63 1.77-.688L9.957.789c-.254-.24-.4-.535-.412-.785-.69-.053-1.384.42-1.837 1.187-2.27-1.276-4.803-1.08-5.68.446-.285.499-.359 1.079-.25 1.68a26.328 26.328 0 0 0 3.441 2.342A26.298 26.298 0 0 0 8.98 7.447c.573-.21 1.036-.566 1.322-1.065.823-1.434-.108-3.537-2.087-4.878z"
      />
    </svg>
  ),
  CHARGEBACK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M2.924 12.01c0 4.554 3.284 8.33 7.797 8.974 4.513.644 8.712-2.068 9.986-6.442 1.275-4.374-.824-8.914-4.978-10.786A9.01 9.01 0 0 0 4.35 7.14L1.873 5.568C5.114.49 11.456-1.398 16.944 1.09c5.488 2.487 8.247 8.494 6.567 14.262-1.68 5.783-7.242 9.378-13.21 8.524C4.348 23.021 0 18.047 0 12.025l2.924-.015z" />
      <path d="M1.5 3l6 6H0V1.5zM11.496 10.079c0-.446.33-.908 1.348-.908 1.133 0 1.87.367 2.26.558l.472-1.801c-.548-.254-1.255-.494-2.293-.526V6h-1.538v1.514c-1.678.32-2.65 1.418-2.65 2.836 0 1.545 1.145 2.341 2.823 2.915 1.178.398 1.68.781 1.68 1.386 0 .621-.611.988-1.523.988-1.018 0-1.945-.334-2.605-.702L9 16.801c.595.335 1.616.638 2.669.686V19h1.551v-1.64c1.808-.304 2.78-1.514 2.78-2.932 0-1.45-.737-2.327-2.605-2.996-1.35-.493-1.9-.843-1.9-1.353z" />
    </svg>
  ),
  CANCEL_BUTTON_ICON: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 9.91406 4.01484 7.99688 5.23828 6.49688L17.5031 18.7617C16.0031 19.9852 14.0859 20.7188 12 20.7188ZM18.7617 17.5031L6.49688 5.23828C7.99688 4.01484 9.91406 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 14.0859 19.9852 16.0031 18.7617 17.5031Z"
        fill="white"
      />
    </svg>
  ),
}
const SOCIAL_MEDIA_ICONS = {
  twitter: (
    <svg width="15" height="14" viewBox="-107 -192 15 14">
      <g fill="none" fillRule="evenodd">
        <path d="M-107-192h14v14h-14z" />
        <path
          d="M-101.597-179.624c5.283 0 8.172-4.377 8.172-8.173 0-.124 0-.248-.008-.371A5.844 5.844 0 0 0-92-189.655a5.733 5.733 0 0 1-1.65.452 2.882 2.882 0 0 0 1.263-1.589c-.564.335-1.18.57-1.824.698a2.875 2.875 0 0 0-4.895 2.62 8.155 8.155 0 0 1-5.92-3.002 2.874 2.874 0 0 0 .89 3.834 2.851 2.851 0 0 1-1.304-.36v.037c0 1.368.964 2.545 2.304 2.816a2.868 2.868 0 0 1-1.297.05 2.876 2.876 0 0 0 2.684 1.994 5.764 5.764 0 0 1-4.251 1.19 8.132 8.132 0 0 0 4.403 1.289"
          fill="#333"
          fillRule="nonzero"
        />
      </g>
    </svg>
  ),
  yahoo: (
    <svg width="12" height="12" viewBox="-109 -121 12 12">
      <g fill="none" fillRule="evenodd">
        <path d="M-109-121h12v12h-12z" />
        <path
          d="M-98.633-121h.001c-.57.13-1.085.134-1.595 0-.45.838-2.11 3.55-3.166 5.288-1.072-1.775-2.34-3.824-3.167-5.288-.655.14-.929.149-1.583 0 1.298 1.955 3.377 5.68 4.085 6.902l-.095 5.098s.456-.076.76-.076c.339 0 .76.076.76.076l-.096-5.098c1.32-2.315 3.5-6.094 4.098-6.902h-.002"
          fill="#333"
        />
      </g>
    </svg>
  ),
  microsoft: (
    <svg width="15" height="14" viewBox="-110 -146 15 14">
      <path
        d="M-102.894-144.948c2.412-.369 4.829-.717 7.246-1.052l.001 6.353c-2.415.01-4.83.046-7.247.054-.002-1.786-.002-3.57 0-5.355zm-6.606.855c1.817-.28 3.64-.522 5.465-.75l.003 5.268c-1.823-.001-3.645.026-5.468.022v-4.54zm0 5.756c1.821-.006 3.642.023 5.464.02 0 1.761.004 3.523.001 5.284-1.82-.27-3.642-.506-5.465-.752v-4.552zm13.961-.001c.003 2.112 0 4.224 0 6.338-2.412-.361-4.83-.697-7.246-1.025-.003-1.77-.008-3.54-.01-5.313h7.256z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  ),
  google: (
    <svg width="14" height="14" viewBox="30 74 14 14">
      <path
        d="M37.17 80.373v1.905h4.593c-.227 2.672-2.469 3.812-4.585 3.812-2.708 0-5.071-2.113-5.071-5.073 0-2.885 2.252-5.106 5.077-5.106 2.179 0 3.463 1.377 3.463 1.377l1.345-1.381S40.265 74 37.115 74C33.103 74 30 77.357 30 80.983 30 84.535 32.92 88 37.217 88c3.78 0 6.547-2.567 6.547-6.364 0-.801-.117-1.264-.117-1.264H37.17z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  ),
  apple: (
    <svg width="14" height="14" viewBox="-109 -171 14 14">
      <g fill="none" fillRule="evenodd">
        <path d="M-109-171h14v14h-14z" />
        <path
          d="M-98.161-164.1l1.748 2.628c-.015.046-.272.933-.898 1.85-.54.793-1.1 1.582-1.987 1.599-.87.018-1.151-.514-2.146-.513-.995.002-1.305.502-2.129.535-.855.033-1.506-.854-2.053-1.642-1.12-1.612-1.976-4.557-.833-6.548.567-.988 1.583-1.615 2.687-1.632.839-.017 1.633.562 2.146.562.513 0 1.475-.7 2.487-.6.424.018 1.613.17 2.379 1.286-.061.038-1.419.83-1.401 2.476zm-1.635-4.825c-.454.55-1.199.977-1.927.92-.1-.746.268-1.523.689-2.01.47-.55 1.26-.958 1.914-.985.084.762-.221 1.526-.676 2.075z"
          fill="#333"
        />
      </g>
    </svg>
  ),
  gravatar: (
    <svg width="15" height="15" viewBox="62 92 15 15">
      <path
        d="M63.503 99.503a6.006 6.006 0 0 0 3.384 5.405l-2.864-7.848a5.982 5.982 0 0 0-.52 2.443zM73.56 99.2c0-.742-.266-1.256-.495-1.656-.304-.494-.59-.913-.59-1.408 0-.552.42-1.066 1.009-1.066.026 0 .052.004.078.005a5.983 5.983 0 0 0-4.055-1.576 5.998 5.998 0 0 0-5.018 2.706c.141.005.274.008.387.008.628 0 1.6-.077 1.6-.077.324-.019.362.457.039.495 0 0-.326.038-.688.057l2.187 6.505 1.314-3.942-.935-2.563c-.324-.019-.63-.057-.63-.057-.324-.02-.286-.514.038-.495 0 0 .992.076 1.582.076.628 0 1.6-.076 1.6-.076.324-.019.362.457.038.495 0 0-.326.038-.687.057l2.17 6.455.6-2.001c.259-.831.456-1.428.456-1.942zm-3.948.829l-1.802 5.235a6.005 6.005 0 0 0 3.69-.096.527.527 0 0 1-.042-.082l-1.846-5.057zm5.164-3.407c.025.192.04.397.04.618 0 .61-.114 1.294-.457 2.15l-1.834 5.304a6.002 6.002 0 0 0 2.25-8.072zm-5.27-4.118c-3.859 0-7 3.14-7 7s3.141 7 7 7c3.86 0 7-3.14 7-7s-3.14-7-7-7zm0 13.679a6.687 6.687 0 0 1-6.678-6.68 6.686 6.686 0 0 1 6.679-6.678 6.686 6.686 0 0 1 6.678 6.678 6.686 6.686 0 0 1-6.678 6.68z"
        fill="#333"
      />
    </svg>
  ),
  facebook: (
    <svg width="14" height="15" viewBox="270 104 14 15">
      <path
        d="M279.826 118h3.086c.6 0 1.088-.488 1.088-1.088v-11.824c0-.6-.488-1.088-1.088-1.088h-11.824c-.6 0-1.088.488-1.088 1.088v11.824c0 .6.488 1.088 1.088 1.088h6.797v-5.694h-1.981v-1.658h1.981v-2.11c0-.835.44-1.514.898-1.966.439-.434 1.286-.663 2.056-.663.541 0 1.009.027 1.373.053v1.483h-.828c-1.541 0-1.558.943-1.558 1.32v1.883h2.234l-.192 1.658h-2.042V118z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  ),
  instagram: (
    <svg width="14" height="14" viewBox="190 145 14 14">
      <path
        d="M202.385 159h-10.77a1.616 1.616 0 0 1-1.615-1.616v-10.769c0-.892.723-1.615 1.615-1.615h10.77c.892 0 1.615.723 1.615 1.615v10.77c0 .892-.723 1.615-1.615 1.615zM197 149.308a2.692 2.692 0 1 0 0 5.384 2.692 2.692 0 0 0 0-5.384zm5.385-2.155a.539.539 0 0 0-.54-.538h-1.614a.538.538 0 0 0-.538.538v1.616c0 .297.24.539.538.539h1.615a.539.539 0 0 0 .539-.539v-1.616zm0 3.77h-1.219c.089.344.141.704.141 1.077a4.307 4.307 0 1 1-8.615 0c0-.373.053-.733.142-1.078h-1.219v5.924c0 .297.241.538.54.538h9.69a.539.539 0 0 0 .54-.538v-5.924z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  ),
}
const ICONS = { ...SIGNIFYD_ICONS, ...SOCIAL_MEDIA_ICONS }
export default ICONS
