import {
  branch,
  renderNothing,
  withProps,
  withState,
  compose,
  lifecycle,
} from 'recompose'
import { isNil, isEmpty } from 'lodash'
import { flow, toLower, startCase, upperFirst } from 'lodash/fp'
import CaseReportHeadline from './CaseReportHeadline'
import { DEVTOOLS_URL } from 'core/Constants'
import { API } from '@signifyd/http'

const requestTMXData = (caseId) => API.get(`/v2/cases/${caseId}/intelligence`)

const requestFeatureData = (caseId) => API.get(`/v2/cases/${caseId}/features`)

const enhance = compose(
  branch(({ case: _case }) => isNil(_case), renderNothing),
  withProps(({ case: _case, userCanAccessJSONViewer }) => {
    return {
      _case,
      viewJSONUrl: userCanAccessJSONViewer
        ? `${DEVTOOLS_URL}/json/${_case.investigationId}`
        : `https://app.signifyd.com/v2/cases/${_case.investigationId}/requests/creation`,
    }
  }),
  withState('TMXData', 'setTMXData', false),
  withState('tmxModalVisible', 'setTmxModalVisible', false),
  withState('featureData', 'setFeatureData', false),
  withState('featureModalVisible', 'setFeatureModalVisible', false),
  lifecycle({
    componentDidMount() {
      requestTMXData(this.props.case.investigationId)
        .then(({ data }) => {
          if (!data || isEmpty(data.deviceFingerprint)) {
            return false
          }

          this.props.setTMXData(data)
        })
        .catch(() => {
          this.props.setTMXData(false)
        })
      requestFeatureData(this.props.case.investigationId)
        .then(({ data }) => {
          const cleanFeatureData = data.map(({ value, name }) => {
            const featureName = flow(startCase, toLower, upperFirst)(name)

            return {
              name: featureName,
              value: String(value),
            }
          })
          this.props.setFeatureData(cleanFeatureData)
        })
        .catch(() => {
          this.props.setFeatureData(false)
        })
    },
  })
)
export default enhance(CaseReportHeadline)
