import { FC } from 'react'
import { Col, Row } from 'antd'
import SigCard from 'core/components/SigCard'
import AnalysisCard from 'caseReport/components/AnalysisCard'
import VelocityCountButton from 'caseReport/components/VelocityCountButton'
import './CaseAnalysisContainer.less'
import type { VelocityType } from 'caseReport/components/VelocityCountButton'
import CaseAnalysisError from 'caseReport/components/CaseAnalysisError'
import useStores from 'useStores'
import { observer } from 'mobx-react'

export const CaseAnalysisContainer: FC = observer(() => {
  const {
    caseReportStore: { analysis, analysisState, case: _case, counts },
  } = useStores()

  const getVelocityCountItemByKey = (key: VelocityType) => (
    <VelocityCountButton
      loading={!counts}
      className="CaseAnalysisContainer--icon"
      countData={counts ? counts.investigationCounts[key] : null}
      type={key}
      caseId={_case?.investigationId}
    />
  )

  const addressCounts = (
    <div>
      {getVelocityCountItemByKey('billingAddressCount')}
      {getVelocityCountItemByKey('deliveryAddressCount')}
    </div>
  )
  const deviceCounts = (
    <div>
      {getVelocityCountItemByKey('deviceIdCount')}
      {getVelocityCountItemByKey('ipAddressCount')}
    </div>
  )
  const confirmationCounts = (
    <div>
      {getVelocityCountItemByKey('confirmationPhoneCount')}
      {getVelocityCountItemByKey('confirmationEmailCount')}
    </div>
  )

  return (
    <div className="CaseAnalysisContainer">
      <SigCard
        title={
          <span className="CaseAnalysisContainer--title">Risk Evaluation</span>
        }
      >
        <CaseAnalysisError analysisState={analysisState} />

        <Row
          gutter={30}
          type="flex"
          align="stretch"
          className="CaseAnalysisContainer--row"
        >
          <Col sm={24} lg={8} className="CaseAnalysisContainer--col-left">
            <AnalysisCard
              analysis={analysis?.locationAnalysis}
              extra={addressCounts}
              title="Address"
            />
          </Col>
          <Col sm={24} lg={8}>
            <AnalysisCard
              analysis={analysis?.deviceAnalysis}
              extra={deviceCounts}
              title="Device"
            />
          </Col>
          <Col sm={24} lg={8} className="CaseAnalysisContainer--col-right">
            <AnalysisCard
              analysis={analysis?.emailAnalysis}
              extra={confirmationCounts}
              title="Email"
            />
          </Col>
        </Row>
      </SigCard>
    </div>
  )
})

export default CaseAnalysisContainer
