import React, { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import { Row, Col, Select, Input } from 'antd'
import './OrderReviewChecklistItem.less'
import { SelectValue } from 'antd/lib/select'

type OnChangeHandler = (value: SelectValue) => void

interface Props extends SelectAndTextItemProps {
  className?: string
  isSelect?: boolean
  commentsValue?: string
  onCommentsChange: OnChangeHandler
}

interface SelectAndTextItemProps {
  disabled: boolean
  options: Array<string>
  selectValue?: Array<string>
  textValue?: string
  onSelectChange: OnChangeHandler
  onTextChange: OnChangeHandler
  heading: string
}

interface CommentsItemProps {
  disabled: boolean
  heading: string
  value: any
  onChange: any
}

const { Option } = Select
const { TextArea } = Input

const SelectAndTextItem: FC<SelectAndTextItemProps> = ({
  disabled,
  options,
  selectValue,
  textValue,
  onSelectChange,
  onTextChange,
}) => (
  <div>
    <Select
      className="OrderReviewChecklistItem--select"
      disabled={disabled}
      size="small"
      allowClear
      mode="multiple"
      notFoundContent="Not found"
      placeholder="Select Item"
      value={selectValue}
      onChange={onSelectChange}
      filterOption={(inputValue, option) => {
        return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      }}
    >
      {options.map((option) => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
    <Input
      value={textValue}
      disabled={disabled}
      onChange={(e) => onTextChange(e.target.value)}
      className="OrderReviewChecklistItem--input"
      size="small"
      placeholder="Enter text"
    />
  </div>
)

const CommentsItem: FC<CommentsItemProps> = ({ value, disabled, onChange }) => (
  <TextArea
    disabled={disabled}
    className="OrderReviewChecklistItem--textarea"
    value={value}
    rows={3}
    onChange={(e) => onChange(e.target.value)}
  />
)

const OrderReviewChecklistItem: FC<Props> = ({
  className,
  disabled,
  isSelect = true,
  heading,
  options,
  selectValue,
  textValue,
  commentsValue,
  onSelectChange,
  onTextChange,
  onCommentsChange,
}) => (
  <Row
    className={joinClassNames(['OrderReviewChecklistItem', className])}
    gutter={15}
  >
    <Col span={10}>
      <span className="OrderReviewChecklistItem--heading">{heading}</span>
    </Col>
    <Col span={14}>
      {isSelect ? (
        <SelectAndTextItem
          disabled={disabled}
          heading={heading}
          options={options}
          selectValue={selectValue}
          textValue={textValue}
          onSelectChange={onSelectChange}
          onTextChange={onTextChange}
        />
      ) : (
        <CommentsItem
          disabled={disabled}
          heading={heading}
          value={commentsValue}
          onChange={onCommentsChange}
        />
      )}
    </Col>
  </Row>
)

export default OrderReviewChecklistItem
