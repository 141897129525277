import { Icon, Tooltip } from 'antd'
import SigCard from 'core/components/SigCard'
import ProductsTable from 'caseReport/components/ProductsTable'
import { ORDER_CHANNEL } from 'caseReport/Constants'
import PaymentOrShipmentIcon from 'caseReport/components/PaymentOrShipmentIcon'
import './OrderSummary.less'
import type { Product } from 'core/http/cases/OrderHttp.types'

type Props = {
  orderChannel: string
  products: Array<Product> | null | undefined
}

// TODO FET-1994 add type for products in ProductsTable
const OrderSummary = ({ orderChannel = '', products }: Props) => {
  const orderChannelMap = {
    [ORDER_CHANNEL.WEB]: {
      icon: 'global',
      tooltip: 'Ordered via web',
    },
    [ORDER_CHANNEL.PHONE]: {
      icon: 'phone',
      tooltip: 'Ordered via phone',
    },
    [ORDER_CHANNEL.OFFLINE]: {
      icon: 'offline',
      tooltip: 'Ordered via offline',
    },
  }
  const orderChannelUI = orderChannelMap[orderChannel]

  return (
    <SigCard
      inner
      title="Order"
      className="OrderSummary"
      extra={
        orderChannelUI &&
        (orderChannelUI.icon === 'offline' ? (
          <PaymentOrShipmentIcon
            type="offline"
            tooltip={orderChannelUI.tooltip}
            tooltipPlacement="right"
          />
        ) : (
          <Tooltip title={orderChannelUI.tooltip} placement="right">
            <Icon type={orderChannelUI.icon} className="OrderSummary--icon" />
          </Tooltip>
        ))
      }
    >
      <ProductsTable products={products} hasHeaderCol={false} />
    </SigCard>
  )
}

export default OrderSummary
