import { PaymentMethod } from '../../http/cases/OrderHttp.types'

interface CardNumberParts {
  bin?: string
  lastFourDigits?: string
  cardBrand?: string
}

export const formatCreditCardNumber = ({
  bin,
  lastFourDigits = 'xxxx',
  cardBrand,
}: CardNumberParts): string | undefined => {
  if (!bin) {
    return undefined
  }

  if (cardBrand === 'amex') {
    return `${bin.substring(0, 4)} ${bin.substring(4)}xxxx x${lastFourDigits}`
  }

  return `${bin.substring(0, 4)} ${bin.substring(4)}xx xxxx ${lastFourDigits}`
}

export const extractAndFormatCreditCardNumber = (
  paymentMethod: PaymentMethod
): string | undefined => {
  const bin = paymentMethod.bin?.toString()
  const lastFourDigits = paymentMethod.cardLastFourDigits
    ?.toString()
    .padStart(4, '0')
  const cardBrand = paymentMethod.cardBrand?.toLowerCase()

  return formatCreditCardNumber({
    bin,
    lastFourDigits,
    cardBrand,
  })
}
