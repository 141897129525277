import React, { FC } from 'react'
import { Icon } from 'antd'
import './SigFlyoutToggleIcon.less'

type Props = {
  checked: boolean
}

const SigFlyoutToggle: FC<Props> = ({ checked }) => (
  <Icon
    type="download"
    className={
      checked
        ? 'SigFlyoutToggleIcon SigFlyoutToggleIcon--checked'
        : 'SigFlyoutToggleIcon SigFlyoutToggleIcon--unchecked'
    }
  />
)

export default SigFlyoutToggle
