import { FC } from 'react'
import { get } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import FormatDate from 'core/components/FormatDate'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import type { OrderResponse } from 'core/http/cases/OrderHttp.types'

type Props = {
  case: CaseInfo
  order: OrderResponse
}

const UserAccountSummary: FC<Props> = ({ case: _case, order }) => {
  const membershipNumbers = get(order, 'buyerAccount.membershipNumbers')

  const userAccountProps = [
    {
      name: 'Account Number',
      value: _case.userAccountNumber,
    },
    {
      name: 'Aggregate Order Count',
      value: _case.userAccountAggregateOrderCount,
    },
    {
      name: 'Aggregate Order Amount',
      value: _case.userAccountAggregateOrderDollars
        ? `${_case.currency} ${formatCurrencyNumber(
            _case.userAccountAggregateOrderDollars
          )}`
        : null,
    },
    {
      name: 'Creation Date',
      value: _case.userAccountCreatedDate && (
        <FormatDate
          UTCTimestamp={_case.userAccountCreatedDate}
          format="MM/DD/YY hh:mm A"
          showTimeZoneAbbr
        />
      ),
    },
    {
      name: 'Last Update Date',
      value: _case.userAccountLastUpdateDate && (
        <FormatDate
          UTCTimestamp={_case.userAccountLastUpdateDate}
          format="MM/DD/YY hh:mm A"
          showTimeZoneAbbr
        />
      ),
    },
    {
      name: 'Last Order Ext. ID',
      value: _case.userAccountLastOrderExternalId,
    },
    {
      name: 'Membership Numbers',
      value: membershipNumbers ? membershipNumbers.join(', ') : undefined,
    },
  ]

  return (
    <SigCard title="Account" inner>
      <SigKeyValueGroup properties={userAccountProps} />
    </SigCard>
  )
}

export default UserAccountSummary
