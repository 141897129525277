export const ADDRESS_SEARCH_LABEL = 'Address Search'
export const PHONE_NUM_SEARCH_LABEL = 'Phone number search'
export const GOOGLE_FULL_NAME_BILLING_LABEL =
  'Full name, billing city and state search'
export const GOOGLE_FULL_NAME_DELIVERY_LABEL =
  'Full name, delivery city and state search'
export const GOOGLE_LAST_NAME_BILLING_LABEL =
  'Last name and billing address search'
export const GOOGLE_LAST_NAME_DELIVERY_LABEL =
  'Last name and delivery address search'
type LastNameAndBillingZipCodeValue = 'Last name and billing zip code'
type LastNameAndDeliveryZipCodeValue = 'Last name and delivery zip code'
export type LastNameAndZipCodeLabel =
  | LastNameAndBillingZipCodeValue
  | LastNameAndDeliveryZipCodeValue
export const LAST_NAME_AND_BILLING_ZIP_CODE: LastNameAndBillingZipCodeValue =
  'Last name and billing zip code'
export const LAST_NAME_AND_DELIVERY_ZIP_CODE: LastNameAndDeliveryZipCodeValue =
  'Last name and delivery zip code'
export const PIPL_FULL_NAME_BILLING_LABEL =
  'Full name, billing city and state search'
export const PIPL_FULL_NAME_DELIVERY_LABEL =
  'Full name, delivery city and state search'
export const FULL_NAME_SEARCH = 'Full name search'
export const EMAIL_SEARCH_LABEL = 'Email search'
