import React, { FC, ReactNode } from 'react'
import { Row, Col } from 'antd'
import TrainingMask from 'core/components/TrainingMask'
import MerchantInfoContainer from 'caseReport/containers/MerchantInfoContainer'
import GuaranteeReasonContainer from 'caseReport/containers/GuaranteeReasonContainer'
import RecommendationReasonContainer from 'caseReport/containers/RecommendationReasonContainer'
import PolicyReasonContainer from 'caseReport/containers/PolicyReasonContainer'
import NotesAutoScrollContainer from 'caseReport/containers/NotesAutoScrollContainer'
import CaseNotesEditor from 'caseReport/containers/CaseNotesEditor'
import SigFlyoutToggle from 'core/components/SigFlyoutToggle'
import CannedNotes from 'caseReport/components/CannedNotes'
import SigCard from 'core/components/SigCard'
import './NotesContainerLG.less'

type Props = {
  caseNotes: ReactNode
  cannedNotesVisible: boolean
  onToggleCannedNotes: (arg0: boolean) => void
  onCannedNoteSelect: (arg0: string) => void
}

const NotesContainerLG: FC<Props> = ({
  caseNotes,
  cannedNotesVisible,
  onToggleCannedNotes,
  onCannedNoteSelect,
}) => (
  <Row className="NotesContainerLG">
    <Col span={cannedNotesVisible ? 8 : 12} className="NotesContainerLG--title">
      Merchant
    </Col>
    <Col
      span={cannedNotesVisible ? 16 : 12}
      className="NotesContainerLG--title-with-border"
    >
      Notes
    </Col>
    <Col span={cannedNotesVisible ? 8 : 12} className="NotesContainerLG--col">
      <MerchantInfoContainer />
    </Col>
    {cannedNotesVisible && (
      <Col span={8} className="NotesContainerLG--col NotesContainerLG--rtl">
        <SigCard className="NotesContainerLG--ltr" title="Canned Notes" inner>
          <CannedNotes onItemSelected={onCannedNoteSelect} />
        </SigCard>
      </Col>
    )}
    <Col
      span={cannedNotesVisible ? 8 : 12}
      className="NotesContainerLG--notes-outer-wrap"
    >
      <RecommendationReasonContainer />
      <TrainingMask>
        <GuaranteeReasonContainer />
      </TrainingMask>
      <PolicyReasonContainer />
      <NotesAutoScrollContainer className="NotesContainerLG--notes-inner-wrap">
        {caseNotes}
      </NotesAutoScrollContainer>
      <SigFlyoutToggle
        className="NotesContainerLG--toggle-canned-notes"
        label={`${cannedNotesVisible ? 'Hide' : 'Show'} Canned Notes`}
        checked={cannedNotesVisible}
        onChange={(checked) => onToggleCannedNotes(checked)}
      />
      <CaseNotesEditor />
    </Col>
  </Row>
)

export default NotesContainerLG
