import React from 'react'
import SigCard from 'core/components/SigCard'
import TrainingMask from 'core/components/TrainingMask'
import MerchantHealthTable from 'caseReport/components/MerchantHealthTable'
import SigKeyValue from 'core/components/SigKeyValue'
import MerchantNotes from 'caseReport/components/MerchantNotes'

const MerchantInfoContainer = ({
  case: _case,
  customerCreatedAt,
  teamMetadata,
  loading,
  state,
  notes,
  hasMerchantNotes,
  actions,
  deleteMerchantMetadata,
  updatetMerchantMetadata,
  createMerchantMetadata,
}) => (
  <div className="MerchantInfoContainer">
    <SigCard title="Merchant Health" inner>
      <MerchantHealthTable teamMetadata={teamMetadata} />
    </SigCard>

    <SigCard title="Merchant Details" inner>
      <SigKeyValue
        name="Customer"
        value={_case.customerName}
        isNameBold={false}
        isValueBold
        nameWidth={120}
      />
      <SigKeyValue
        name="Customer ID"
        value={_case.customerId}
        isNameBold={false}
        isValueBold
        nameWidth={120}
      />
      <SigKeyValue
        name="Customer Since"
        value={customerCreatedAt}
        isNameBold={false}
        isValueBold
        nameWidth={120}
      />
      <SigKeyValue
        name="Team"
        value={_case.teamName}
        isNameBold={false}
        isValueBold
        nameWidth={120}
      />
      <SigKeyValue
        name="Team ID"
        value={_case.teamId}
        isNameBold={false}
        isValueBold
        nameWidth={120}
      />
    </SigCard>

    <TrainingMask>
      <SigCard title="Merchant Notes" inner>
        <MerchantNotes
          hasMerchantNotes={hasMerchantNotes}
          notes={notes}
          state={state}
          loading={loading}
          onStartEdit={actions.startEdit}
          onCancel={actions.cancelEdit}
          onChange={actions.changeNotes}
          onDelete={deleteMerchantMetadata}
          onSubmit={notes ? updatetMerchantMetadata : createMerchantMetadata}
        />
      </SigCard>
    </TrainingMask>
  </div>
)

export default MerchantInfoContainer
