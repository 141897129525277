import { observable } from 'mobx'
import type { IObservableObject } from 'mobx'
import type { CustomerMeta } from 'core/http/customers/CustomersHttp.types'

export type Notes = string | null | undefined
export type Loading = {
  pending: boolean
  success: boolean
  error: boolean | string
  finished: boolean
  namespace: string | null | undefined
}
export type InternalState = {
  notes: Notes
  isEditing: boolean
}
export type MerchantReportStore = IObservableObject & {
  meta: CustomerMeta | null | undefined
  loading: Loading
  state: InternalState
}
const merchantReportStore: MerchantReportStore = observable({
  meta: null,
  loading: {
    pending: false,
    success: false,
    error: false,
    finished: false,
    namespace: null,
  },
  state: {
    notes: null,
    isEditing: false,
  },
})
export default merchantReportStore
