import { get, isString } from 'lodash'
import type { LinkKey, LinkKeysSubset } from './constants'
import {
  googleWebPhone,
  EkataPhone,
  piplPhone,
  trueCallerPhone,
  _118712Phone,
  _118000Phone,
  pagineBianchePhone,
  elenchiTelefoniciPhone,
} from 'core/utils/deeplinks'
import type { Phone as PhoneEntry } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import {
  LINK_KEYS,
  FR_PHONE_LINKS,
  IT_PHONE_LINKS,
  DEFAULT_PHONE_LINKS,
} from './constants'

type LinkResults = Array<DeeplinkProps | false | null>

const removePluses = (str) => {
  return isString(str) ? str.replace(/\+/g, '') : ''
}

const getLinks = (
  phone: PhoneEntry,
  linkKeys: LinkKeysSubset,
  countryCode: string
): LinkResults => {
  const linkKeysSet: Set<LinkKey> = new Set(linkKeys)
  const { entityName: phoneNum } = phone
  const phoneNumWithoutPluses = removePluses(phoneNum)
  const phoneNumWithoutCountryCode =
    get(phone, 'details.wppReversePhoneJson.phoneNumber') || phoneNum

  return [
    linkKeysSet.has(LINK_KEYS.GOOGLE_WEB) && googleWebPhone(phoneNum),
    linkKeysSet.has(LINK_KEYS.EKATA) && EkataPhone(phoneNumWithoutPluses),
    linkKeysSet.has(LINK_KEYS.PIPL) && piplPhone(phoneNumWithoutPluses),
    linkKeysSet.has(LINK_KEYS.TRUE_CALLER) &&
      trueCallerPhone(phoneNum, countryCode),
    linkKeysSet.has(LINK_KEYS._118712) && _118712Phone(phoneNum),
    linkKeysSet.has(LINK_KEYS._118000) && _118000Phone(phoneNum),
    linkKeysSet.has(LINK_KEYS.PAGINE_BIANCHE) &&
      pagineBianchePhone(phoneNumWithoutCountryCode),
    linkKeysSet.has(LINK_KEYS.ELENCHI_TELEFONICI) &&
      elenchiTelefoniciPhone(phoneNumWithoutCountryCode),
  ].filter((item) => item)
}

const getLinksByCountry = (
  phone: PhoneEntry,
  countryCode: string
): LinkResults => {
  switch (countryCode) {
    case 'FR':
      return getLinks(phone, FR_PHONE_LINKS, countryCode)

    case 'IT':
      return getLinks(phone, IT_PHONE_LINKS, countryCode)

    default:
      return getLinks(phone, DEFAULT_PHONE_LINKS, countryCode)
  }
}

export default getLinksByCountry
