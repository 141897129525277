import { startCase, isEmpty } from 'lodash'
import SigCard from 'core/components/SigCard'
import SigKeyValue from 'core/components/SigKeyValue'
import {
  DEVICE_ENTRY_NO_DATA,
  DEVICE_ENTRY_NO_INFO,
} from 'caseReport/Constants'
import SigKeyValueGroup from '../../../../core/components/SigKeyValueGroup/SigKeyValueGroup'
import SigCardCollapse from '../../../../core/components/SigCardCollapse'
import { FC } from 'react'
import { Device, DeviceDetails } from 'core/http/cases/EntriesHttp.types'

const { Panel } = SigCardCollapse

interface Props {
  deviceInfo: Array<Device>
}

const DeviceEntry: FC<Props> = ({ deviceInfo }) => {
  const deviceProperties = (deviceDetails: DeviceDetails) => {
    const output = []

    for (const [key, value] of Object.entries(deviceDetails)) {
      if (value) {
        output.push({
          name: startCase(key),
          value,
          copyValue: value,
          alignValueToRight: true,
        })
      }
    }

    return output
  }

  return (
    <SigCard title="Device" inner>
      {isEmpty(deviceInfo) && DEVICE_ENTRY_NO_DATA}
      {deviceInfo?.map((item) => {
        return (
          <SigCardCollapse key={item.uuid} defaultActiveKey={['root']}>
            <Panel
              header={
                <SigKeyValue
                  bordered={false}
                  name={item.role}
                  startCaseName
                  alignValueToRight
                  value={item.entityName}
                  copyValue={item.entityName}
                  seeder={item.seeder}
                />
              }
              key="root"
            >
              {deviceProperties(item.details).length >= 1 ? (
                <SigKeyValueGroup
                  title="Device Info"
                  properties={deviceProperties(item.details)}
                  hideLastItemBorder
                />
              ) : (
                <SigKeyValue
                  name={DEVICE_ENTRY_NO_INFO}
                  bordered={false}
                  isNameBold={false}
                />
              )}
            </Panel>
          </SigCardCollapse>
        )
      })}
    </SigCard>
  )
}

export default DeviceEntry
