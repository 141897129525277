import { get } from 'lodash'
import type { DeeplinkProps } from 'core/components/Deeplink'
import type {
  EntriesByRole,
  PhysicalLocationDetails,
} from 'core/http/cases/EntriesHttp.types'
import {
  ADDRESS_SEARCH_LABEL,
  EMAIL_SEARCH_LABEL,
  FULL_NAME_SEARCH,
  PHONE_NUM_SEARCH_LABEL,
} from './constants'

export const EkataIDCheck = (entriesByRole: EntriesByRole): DeeplinkProps => {
  const query = ['https://app.ekata.com/identity_checks?']

  if (entriesByRole.cardHolder) {
    query.push(`billing_name=${entriesByRole.cardHolder[0].entityName}`)
  } else {
    query.push('billing_name="null"')
  }

  if (entriesByRole.accountPhone) {
    query.push(`&billing_phone=${entriesByRole.accountPhone[0].entityName}`)
  }

  const billingAddressDetails = get(entriesByRole, 'billingAddress[0].details')

  if (billingAddressDetails) {
    query.push(
      `&billing_address_street_line_1=${billingAddressDetails.street}`,
      `&billing_address_city=${billingAddressDetails.cityName}`,
      `&billing_address_state_code=${billingAddressDetails.regionAlpha}`,
      `&billing_address_country_code=${billingAddressDetails.countryIsoCode}`
    )
  }

  if (entriesByRole.confirmationPhone) {
    query.push(
      `&shipping_phone=${entriesByRole.confirmationPhone[0].entityName}`
    )
  }

  if (entriesByRole.deliveryRecipient) {
    query.push(
      `&shipping_name=${entriesByRole.deliveryRecipient[0].entityName}`
    )
  }

  const deliveryAddressDetails = get(
    entriesByRole,
    'deliveryAddress[0].details'
  )

  if (deliveryAddressDetails) {
    query.push(
      `&shipping_address_street_line_1=${deliveryAddressDetails.street}`,
      `&shipping_address_city=${deliveryAddressDetails.cityName}`,
      `&shipping_address_state_code=${deliveryAddressDetails.regionAlpha}`,
      `&shipping_address_country_code=${deliveryAddressDetails.countryIsoCode}`
    )
  }

  const confirmationEmailDetails = get(
    entriesByRole,
    'confirmationEmail[0].details'
  )

  if (confirmationEmailDetails) {
    query.push(`&email_address=${confirmationEmailDetails.emailAddress}`)
  }

  const purchaseIPDetails = get(entriesByRole, 'purchaseIP[0].details')

  if (purchaseIPDetails) {
    query.push(`&ip_address=${purchaseIPDetails.ipAddress}`)
  }

  return {
    iconType: 'ekata',
    label: 'Ekata Identity Check',
    url: encodeURI(query.join('')),
  }
}

export const EkataAddress = ({
  street,
  cityName,
  regionAlpha,
  countryIsoCode,
}: PhysicalLocationDetails): DeeplinkProps => {
  const query =
    `street=${street || ''}&` +
    `where=${cityName || ''} ` +
    `${regionAlpha || ''}&` +
    `country_code=${countryIsoCode || ''}`

  return {
    iconType: 'ekata',
    label: ADDRESS_SEARCH_LABEL,
    url: `https://app.ekata.com/addresses?${query}`,
  }
}

export const EkataPhone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined =>
  phoneNum
    ? {
        iconType: 'ekata',
        label: PHONE_NUM_SEARCH_LABEL,
        url: `https://app.ekata.com/phones?number=${phoneNum}`,
      }
    : null

export const EkataFullname = (fullname: string): DeeplinkProps => ({
  iconType: 'ekata',
  label: FULL_NAME_SEARCH,
  url: `https://app.ekata.com/identity_checks?shipping_name=${encodeURI(
    fullname
  )}`,
})

export const EkataEmail = (email: string): DeeplinkProps => ({
  iconType: 'ekata',
  label: EMAIL_SEARCH_LABEL,
  url: `https://app.ekata.com/identity_checks?email_address=${encodeURI(
    email
  )}`,
})
