import React, { FC } from 'react'
import { Tooltip, Icon } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import { CASE_PAGE_SIDER_TOOLTIPS } from 'caseReport/Constants'
import './CasePageSiderMenuIcons.less'
import { IconProps } from 'antd/lib/icon'

type Props = {
  loading: boolean
  onRefresh: () => void
}

const ClickableIcon: FC<IconProps> = ({ className, ...props }) => (
  <Icon
    className={joinClassNames([
      className,
      'CasePageSiderMenuIcons--icon-clickable',
    ])}
    {...props}
  />
)

const CasePageSiderMenuIcons: FC<Props> = ({
  loading,
  onRefresh,
  children,
}) => (
  <div>
    {loading ? (
      <Icon type="loading" className="CasePageSiderMenuIcons--icon" />
    ) : (
      <Tooltip
        title={CASE_PAGE_SIDER_TOOLTIPS.RELOAD}
        placement="bottom"
        mouseEnterDelay={0.5}
      >
        <ClickableIcon type="reload" onClick={onRefresh} />
      </Tooltip>
    )}
    {children}
  </div>
)

export default CasePageSiderMenuIcons
export { ClickableIcon }
