import { Component, ReactNode } from 'react'
import { isEmpty } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import List from 'react-virtualized/dist/es/List'
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader'
import AutoSizer from 'react-virtualized/dist/es/AutoSizer'
import type { CaseListStore } from 'caseReport/stores/caseList.store'
import caseListActions from 'caseReport/stores/caseList.actions'
import CaseListItem from 'caseReport/components/CaseListItem'
import { isTrialOrTestCase } from 'core/utils/computedCaseData'
import { normalizeDeadline } from 'caseReport/components/CaseTimeout'
import { formatInt } from 'core/utils/formatNumber'
import { CASE_LIST_LOADER_TEXT } from 'caseReport/Constants'
import './CaseListContainer.less'
import { USER_ROLE } from '@signifyd/http'
import { ErrorBoundary } from '@signifyd/legacy-components'

type Props = {
  caseListStore: CaseListStore
  appStore: any
}
// Setting threshold to 1 makes InfiniteLoader to load new cases
// only when it scrolls to the last case. default threshold is 15
const THRESHOLD = 1
// heights
const CASE_ITEM_HEIGHT = 86 // min height of CaseListItem, must be the same as .CaseListItem

const CASE_ITEM_LINE_HEIGHT = 24
const LOADER_HEIGHT = 40 // must be the same as .CaseListContainer--loader

@inject('caseListStore', 'appStore')
@observer
class CaseListContainer extends Component<Props> {
  _actions = null

  constructor(props: Props) {
    super(props)
    const isManualReviewerLead = props.appStore.userHasRoles([
      USER_ROLE.MANUAL_REVIEWER,
      USER_ROLE.MANUAL_REVIEWER_LEAD,
    ])
    this._actions = caseListActions(props.caseListStore, isManualReviewerLead)
  }

  _getRowHeight = ({ index }): number => {
    const {
      caseListStore: { cases },
    } = this.props

    if (index < cases.length) {
      const _case = cases[index]
      let height = CASE_ITEM_HEIGHT
      if (isTrialOrTestCase(_case)) {
        height += CASE_ITEM_LINE_HEIGHT
      }
      if (normalizeDeadline(_case)) {
        height += CASE_ITEM_LINE_HEIGHT
      }

      return height
    }

    return LOADER_HEIGHT
  }

  _loadMoreRows = ({ stopIndex }): boolean => {
    const {
      caseListStore: { cases, meta, loading },
      appStore: {
        user: { userId },
      },
    } = this.props

    if (
      !loading.pending &&
      stopIndex >= cases.length - THRESHOLD &&
      stopIndex < meta.totalRows
    ) {
      return this._actions.getMoreCaseListOrCaseQueue(userId)
    }
  }

  _isRowLoaded = ({ index }): boolean => {
    const {
      caseListStore: { cases },
    } = this.props

    return index < cases.length
  }

  _rowRenderer = ({ index, key, style }): ReactNode => {
    const {
      caseListStore: { cases },
    } = this.props

    const _case =
      this._isRowLoaded({
        index,
      }) && cases[index]

    return _case ? (
      <Link
        className="CaseListContainer--case-link"
        key={key}
        to={`/cases/${_case.investigationId}${window.location.search}`}
        style={style}
      >
        <CaseListItem
          caseIndex={index}
          onSelect={() => this._actions.selectCase(_case.investigationId)}
        />
      </Link>
    ) : (
      <div className="CaseListContainer--loader" key={key} style={style}>
        {CASE_LIST_LOADER_TEXT}
      </div>
    )
  }

  render() {
    const {
      caseListStore: { cases, meta },
    } = this.props
    if (isEmpty(cases) || isEmpty(meta)) {
      return null
    }
    // add one additional row for loader
    // $FlowFixMe
    const rowCount = meta.hasMoreRows ? cases.length + 1 : cases.length

    return (
      <div className="CaseListContainer">
        <ErrorBoundary message="Cases List failed to load.">
          <InfiniteLoader
            isRowLoaded={this._isRowLoaded}
            loadMoreRows={this._loadMoreRows}
            rowCount={rowCount}
            threshold={THRESHOLD}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    rowRenderer={this._rowRenderer}
                    height={height}
                    width={width}
                    rowHeight={this._getRowHeight}
                    rowCount={rowCount}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
          {cases && meta && (
            <div className="CaseListContainer--case-count">
              {formatInt(meta.totalRows)} total cases in queue
            </div>
          )}
        </ErrorBoundary>
      </div>
    )
  }
}

export default CaseListContainer
