import { FC, ReactNode } from 'react'
import { Collapse } from 'antd'
import SigCard from 'core/components/SigCard'
import './CaseNotesCollapse.less'

type PanelType = {
  key: string
  header: string
  children: ReactNode
  disabled?: boolean
}
type Props = {
  defaultActiveKey: string
  panels: Array<PanelType>
}
const { Panel } = Collapse

const CaseNotesCollpase: FC<Props> = ({ defaultActiveKey, panels }) => (
  <Collapse
    defaultActiveKey={defaultActiveKey}
    bordered={false}
    className="CaseNotesCollpase"
  >
    {panels.map(({ key, header, children, disabled }) => (
      <Panel
        key={key}
        header={<SigCard title={header} inner />}
        disabled={disabled}
      >
        {children}
      </Panel>
    ))}
  </Collapse>
)

export default CaseNotesCollpase
