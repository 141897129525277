import { action } from 'mobx'
import type { ReviewQueueStore } from './reviewQueue.store'
import appStore from 'AppStore'
import { getQueueSummary, getReviewerQueueConfig } from '@signifyd/http'

const sixtySecs = 60 * 1000
let _pollingTimer: TimeoutID | null = null

const reviewQueueActions = (store: ReviewQueueStore) => {
  const _setQueueMetrics = action((metrics) => {
    store.metrics = metrics
  })

  const _setQueueConfig = action((config) => {
    store.config = config
  })

  const _getQueueConfig = () => {
    return getReviewerQueueConfig(appStore.user.userId)
      .then(({ data }) => _setQueueConfig(data))
      .catch(console.error)
  }

  const stopPolling = action(() => {
    !!_pollingTimer && clearTimeout(_pollingTimer)
    _pollingTimer = null
  })

  const _setPollingTimer = action(() => {
    !!_pollingTimer && stopPolling()
    _pollingTimer = setTimeout(refreshMetrics, sixtySecs)
  })

  const refreshMetrics = () => {
    if (!appStore.isManualReviewer) {
      return Promise.resolve()
    }

    stopPolling()

    return getQueueSummary(appStore.user.userId)
      .then(({ data }) => _setQueueMetrics(data))
      .catch(console.error)
      .finally(_setPollingTimer)
  }

  const initQueueMetrics = action(() => {
    return Promise.all([_getQueueConfig(), refreshMetrics()]).catch(
      console.error
    )
  })

  return {
    initQueueMetrics,
    refreshMetrics,
    stopPolling,
  }
}

export default reviewQueueActions
