import 'images/sprite-case-details.png'
import 'images/sprite-case-report-icons.png'
import 'images/sprite-payment-icons.png'
import 'images/favicon.png'
import App from './App'
import 'react-virtualized/styles.css'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import 'react-image-crop/dist/ReactCrop.css'
import './index.less'
import {
  AuthProvider,
  PageSpinner,
  defaultQueryClientConfig,
} from '@signifyd/legacy-components'
import AppUserProvider from 'AppUserProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { render } from 'react-dom'

require('promise.prototype.finally').shim()

const queryClient = new QueryClient(defaultQueryClientConfig)

render(
  <AuthProvider LoadingComponent={<PageSpinner />}>
    <QueryClientProvider client={queryClient}>
      <AppUserProvider>
        {(user, userConfigs) => <App user={user} userConfigs={userConfigs} />}
      </AppUserProvider>
    </QueryClientProvider>
  </AuthProvider>,
  document.getElementById('console-admin')
)
