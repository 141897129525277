import { mapProps } from 'recompose'
import getLinksByCountry from './utils'
import isEntryDenyListed from '../utils'
import LocationEntry from './LocationEntry'

const enhance = mapProps(
  ({ physicalLocations, entriesByRole, countryCode, ...props }) => {
    const createDeeplinks = (address) => {
      if (
        address.role !== 'deliveryAddress' &&
        address.role !== 'billingAddress'
      ) {
        return address
      }
      const deeplinks = getLinksByCountry(address, countryCode)

      return { ...address, deeplinks }
    }

    return {
      physicalLocations: physicalLocations.map(createDeeplinks),
      isDeliveryAddrDenylisted: isEntryDenyListed(
        entriesByRole,
        'deliveryAddress'
      ),
      entriesByRole,
      ...props,
    }
  }
)
export default enhance(LocationEntry)
