import type { DeeplinkProps } from 'core/components/Deeplink'
import { EMAIL_SEARCH_LABEL, PHONE_NUM_SEARCH_LABEL } from './constants'

export const piplPhone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined =>
  phoneNum
    ? {
        iconType: 'pipl',
        label: PHONE_NUM_SEARCH_LABEL,
        url: `https://pipl.com/search/?q=${phoneNum}`,
      }
    : null

export const piplEmail = (
  email: string | null | undefined
): DeeplinkProps | null | undefined =>
  email
    ? {
        iconType: 'pipl',
        label: EMAIL_SEARCH_LABEL,
        url: `https://pipl.com/search/?q=${email}`,
      }
    : null

export const piplFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  cityName: string | null | undefined,
  regionAlpha: string | null | undefined,
  label: string
): DeeplinkProps | null | undefined => {
  return firstName && lastName
    ? {
        iconType: 'pipl',
        label,
        url:
          'https://pipl.com/search/?q=' +
          `${encodeURIComponent(firstName)}` +
          `+${encodeURIComponent(lastName)}` +
          `&l=${encodeURIComponent(cityName || '')}` +
          `+${encodeURIComponent(regionAlpha || '')}`,
      }
    : null
}
