import { action } from 'mobx'
import moment from 'moment-timezone'
import { get, isEmpty, isNil, set } from 'lodash'
import type { CaseListStore } from './caseList.store'
import { CASE_SEARCH_LIMIT } from 'caseReport/Constants'
import { parseColFilterValue } from 'core/utils/queryParams'
import caseFilterStore from 'caseReport/stores/caseFilters.store'
import caseFilterActions from 'caseReport/stores/caseFilters.actions'
import { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import { getReviewerCaseQueue, listInvestigations } from '@signifyd/http'

export const LOADING_NAMESPACE = {
  LOAD_NEW: 'LOAD_NEW',
  LOAD_MORE: 'LOAD_MORE',
}

// TODO FET-1999 Replace with react-query
const caseQueueActions = (
  store: CaseListStore,
  isManualReviewerLead = false
) => {
  const _setCases = action(({ investigations, meta }, loadMore = false) => {
    store.cases =
      loadMore && store.cases
        ? store.cases.concat(investigations)
        : investigations
    store.meta = Object.assign(meta, {
      totalRows: parseInt(meta.totalRows, 10),
    })

    store.caseIndexByCaseId = store.cases
      ? store.cases.reduce((agg, { investigationId }, index) => {
          agg[investigationId] = index

          return agg
        }, {})
      : {}
  })

  const _resetCases = () =>
    _setCases({
      cases: [],
      caseIndexByCaseId: {},
      meta: {},
    })

  const _setLoading = action((keyValue) => {
    store.loading = { ...store.loading, ...keyValue }
  })

  const _resetLoading = (namespace) => {
    _setLoading({
      pending: true,
      success: false,
      finished: false,
      error: false,
      namespace,
    })
  }

  const _setState = action((state) => {
    store.state = Object.assign(store.state, state)
  })

  const toggleShowCaseQueue = (showCaseQueue: boolean) => {
    _setState({
      showCaseQueue,
    })
  }

  const getCaseListOrCaseQueue = (userId: number) => {
    store.state.showCaseQueue ? getCaseQueueForMR(userId) : getCaseList()
  }

  const getMoreCaseListOrCaseQueue = (userId: number) => {
    const offset = get(store, 'meta.offset', 0) + CASE_SEARCH_LIMIT
    store.state.showCaseQueue
      ? getCaseQueueForMR(userId, offset, true)
      : getCaseList(null, offset, true)
  }

  const getCaseQueueForMR = (userId: number, offset = 0, loadMore = false) => {
    let queryUserId
    let visibleURL

    if (isManualReviewerLead) {
      const userIdFromURL = parseColFilterValue(
        window.location.search,
        'guaranteeReviewedBy'
      )
      queryUserId = userIdFromURL || userId
      visibleURL = `?colFilters[]=guaranteeReviewedBy::${queryUserId}`
    } else {
      queryUserId = userId
      visibleURL = window.location.pathname
    }

    const params =
      `colFilters[]=guaranteeReviewedBy::${queryUserId}&` +
      `colFilters[]=normalizedPurchaseCreatedAt::(${moment()
        .utc()
        .subtract(2, 'months')
        .format('YYYY-MM-DD')},)&` +
      `offset=${offset}&` +
      'guaranteeDispositions=IN_REVIEW&' +
      'guaranteeRequested=GUARANTEE_FILTER_REQUESTED&' +
      'orderBy=SORT_GUARANTEE_REVIEW_DEADLINE&' +
      'reReviewOnly=false&' +
      'status=ALL&' +
      'colFilters[]=isTestInvestigation::false'

    if (loadMore) {
      _resetLoading(LOADING_NAMESPACE.LOAD_MORE)
    } else {
      _resetLoading(LOADING_NAMESPACE.LOAD_NEW)

      _resetCases()
    }

    listInvestigations(params)
      .then((res) => {
        window.history.pushState(null, null, visibleURL)

        _setLoading({
          success: true,
        })

        _setCases(res.data, loadMore)
      })
      .catch((err) => {
        console.log(err)

        _setLoading({
          error: err.response.status,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const getNextCasesForMR = (userId: number) => {
    _resetLoading(LOADING_NAMESPACE.LOAD_NEW)

    _resetCases()

    getReviewerCaseQueue(userId)
      .then((res) => {
        _setLoading({
          success: true,
        })

        if (isEmpty(res.data.investigations)) {
          _setLoading({
            error: 500,
          })
        } else {
          _setCases(res.data)
        }
      })
      .catch((err) => {
        console.log(err)

        _setLoading({
          error: err.response.status,
          errorMessage: err.response.data.messages,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const getCaseList = (
    caseFilterParams: string | null | undefined,
    offset = 0,
    loadMore = false
  ) => {
    // if no `caseFilterParams` are passed in check the URL
    // and set the CaseFiltersStore with those values
    // this would indicate a new page load from a pasted in URL
    !caseFilterParams &&
      caseFilterActions(caseFilterStore).setCaseFiltersStoreFromQueryParams()
    let params = caseFilterParams || caseFilterStore.params
    params += `&offset=${offset}`

    if (loadMore) {
      _resetLoading(LOADING_NAMESPACE.LOAD_MORE)
    } else {
      _resetLoading(LOADING_NAMESPACE.LOAD_NEW)

      _resetCases()
    }

    const { min, max } = caseFilterStore.caseDateRange
    listInvestigations(
      `${params}&colFilters[]=normalizedPurchaseCreatedAt::(${min
        .utc()
        .format('YYYY-MM-DD')},${max.utc().format('YYYY-MM-DD')})`
    )
      .then((res) => {
        // caseList controls the URL for caseFilters. This allows the caseReport to be
        // independent of the case list.
        window.history.pushState(null, null, `?${params}`)

        _setLoading({
          success: true,
        })

        _setCases(res.data, loadMore)
      })
      .catch((err) => {
        console.log(err)

        _setLoading({
          error: err.response.status,
        })
      }) // $FlowFixMe
      .finally(() => {
        _setLoading({
          finished: true,
          pending: false,
        })
      })
  }

  const selectCase = (selectedCaseId: number) => {
    _setState({
      selectedCaseId,
    })
  }

  const updateCase = action((caseData: CaseInfo) => {
    if (!store.caseIndexByCaseId || !store.cases) {
      return
    }

    const index = store.caseIndexByCaseId[caseData.investigationId]

    if (isNil(index)) {
      return
    }

    set(store, `cases[${index}]`, caseData)
  })

  return {
    getCaseListOrCaseQueue,
    getMoreCaseListOrCaseQueue,
    getCaseList,
    getCaseQueueForMR,
    getNextCasesForMR,
    toggleShowCaseQueue,
    selectCase,
    updateCase,
  }
}

export default caseQueueActions
