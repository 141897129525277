import FormattedEntryAddress from './FormattedEntryAddress'

type Props = {
  street?: string | null | undefined
  unit?: string | null | undefined
  cityName?: string | null | undefined
  regionAlpha?: string | null | undefined
  regionName?: string | null | undefined
  countryIsoCode?: string | null | undefined
  postalCode?: string | null | undefined
}
export const formatEntriesAddressLine = ({
  street,
  unit,
  cityName,
  regionAlpha,
  regionName,
  countryIsoCode,
  postalCode,
}: Props) => {
  return [
    street,
    unit,
    cityName,
    regionAlpha || regionName,
    postalCode,
    countryIsoCode,
  ]
    .filter((str) => !!str)
    .join(', ')
}
export default FormattedEntryAddress
