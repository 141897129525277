import { compose, withHandlers, withProps, lifecycle } from 'recompose'
import { inject, observer } from 'mobx-react'
import caseListActions from 'caseReport/stores/caseList.actions'
import reviewQueueActions from 'caseReport/stores/reviewQueue.actions'
import CasePageSiderMenu from './CasePageSiderMenu'
import { USER_ROLE } from '@signifyd/http'

const enhance = compose(
  inject('appStore', 'caseListStore', 'reviewQueueStore'),
  observer,
  withProps(({ appStore, caseListStore, reviewQueueStore }) => {
    const isManualReviewer = appStore.userHasRole(USER_ROLE.MANUAL_REVIEWER)
    const isManualReviewerLead = appStore.userHasRoles([
      USER_ROLE.MANUAL_REVIEWER,
      USER_ROLE.MANUAL_REVIEWER_LEAD,
    ])

    return {
      userId: appStore.user.userId,
      canSeeCaseQueue: isManualReviewer,
      canToggleCaseQueue: isManualReviewer && isManualReviewerLead,
      showCaseQueue: caseListStore.state.showCaseQueue,
      loading: caseListStore.loading.pending,
      actions: {
        caseList: caseListActions(caseListStore, isManualReviewerLead),
        reviewQueue: reviewQueueActions(reviewQueueStore),
      },
    }
  }),
  withHandlers({
    handleToggleCaseQueue:
      ({ userId, canToggleCaseQueue, actions }) =>
      (value) => {
        if (!canToggleCaseQueue) {
          return
        }

        actions.caseList.toggleShowCaseQueue(value)
        actions.caseList.getCaseListOrCaseQueue(userId)
        actions.reviewQueue.refreshMetrics()
      },
    handleRefresh:
      ({ userId, actions }) =>
      () => {
        actions.caseList.getCaseListOrCaseQueue(userId)
        actions.reviewQueue.refreshMetrics()
      },
    handleGetNextCasesForMR:
      ({ userId, actions }) =>
      () => {
        actions.caseList.getNextCasesForMR(userId)
        actions.reviewQueue.refreshMetrics()
      },
  }),
  lifecycle({
    componentDidMount() {
      const { userId, canSeeCaseQueue, canToggleCaseQueue, actions } =
        this.props

      if (canSeeCaseQueue) {
        if (canToggleCaseQueue) {
          // MANUAL_REVIEWER_LEAD
          const param = `=guaranteeReviewedBy::${userId}`
          const showCaseQueue = window.location.search.indexOf(param) > -1
          actions.caseList.toggleShowCaseQueue(showCaseQueue)
        } else {
          // MANUAL_REVIEWER
          actions.caseList.toggleShowCaseQueue(true)
        }
      } else {
        // Other ADMIN user
        actions.caseList.toggleShowCaseQueue(false)
      }

      actions.caseList.getCaseListOrCaseQueue(userId)
    },
  })
)
export default enhance(CasePageSiderMenu)
