import React, { FC } from 'react'
import { Button } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import CountdownTimer from 'core/components/CountdownTimer'
import { CASE_DECISION_CONFIRMATION_SECONDS } from 'caseReport/Constants'
import './ConfirmMenuItem.less'

type Props = {
  type: 'approve' | 'decline'
  loading: boolean
  onConfirm: () => void
  onCancel: () => void
  'data-test-id'?: string
}

const ConfirmMenuItem: FC<Props> = ({
  type,
  loading,
  onConfirm,
  onCancel,
  ...props
}) => (
  <div
    className={joinClassNames(['ConfirmMenuItem', `ConfirmMenuItem--${type}`])}
    data-test-id={props['data-test-id'] ?? 'confirmMenuItem'}
  >
    <Button
      className="ConfirmMenuItem--button"
      type="primary"
      loading={loading}
      onClick={onConfirm}
    >
      Yes
      {!loading && (
        <CountdownTimer
          className="ConfirmMenuItem--timer"
          prefix="("
          suffix=")"
          totalSeconds={CASE_DECISION_CONFIRMATION_SECONDS}
          render={({ time }) => time}
          onFinish={onConfirm}
        />
      )}
    </Button>
    <Button
      className="ConfirmMenuItem--button"
      onClick={onCancel}
      disabled={loading}
    >
      No
    </Button>
    <span className="ConfirmMenuItem--label">Confirm {type}?</span>
  </div>
)

export default ConfirmMenuItem
