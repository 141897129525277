import { getCaseSearchUrl } from '@signifyd/legacy-components'
import type { Account, EntriesByRole } from 'core/http/cases/EntriesHttp.types'
import { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import buildUrl from 'core/utils/buildUrl/buildUrl'
import type { DeeplinkProps } from 'core/components/Deeplink'
import { CASE_SEARCH_GENERAL_QUERY } from 'caseReport/Constants'

export type AccountWithDeeplinks = Account & {
  deeplinks?: Array<DeeplinkProps>
}

const buildPhoneQuery = (
  entriesByRole: EntriesByRole,
  account: Account
): string => {
  const confirmationPhone =
    entriesByRole?.confirmationPhone?.[0]?.details?.phoneNumber
  if (account.role === 'confirmationEmail' && confirmationPhone) {
    return `&phone=${confirmationPhone}`
  }

  const accountPhone = entriesByRole?.accountPhone?.[0]?.details?.phoneNumber
  if (account.role === 'accountEmail' && accountPhone) {
    return `&phone=${accountPhone}`
  }

  return ''
}

export const createDeeplinks = ({
  account,
  entriesByRole,
  case: _case,
}: {
  account: Account
  entriesByRole: EntriesByRole
  case: CaseInfo
}): AccountWithDeeplinks => {
  const encodedEntityName = encodeURIComponent(account.entityName)

  const sigSearchLinkByRole = {
    accountEmail: {
      iconType: 'signifyd',
      label: 'Search for other cases',
      url: buildUrl(getCaseSearchUrl(), {
        [CASE_SEARCH_GENERAL_QUERY]: account.entityName,
      }),
    },
    confirmationEmail: {
      iconType: 'signifyd',
      label: 'Search for other cases',
      url: buildUrl(getCaseSearchUrl(), {
        similarityInvestigationId: _case.investigationId.toString(),
        similarityAttributes: 'SIM_CONFIRMATION_EMAIL',
      }),
    },
  }

  const details = account.details || {}
  const phoneQuery = buildPhoneQuery(entriesByRole, account)

  const deeplinks = [
    sigSearchLinkByRole[account.role],
    {
      iconType: 'google-web',
      label: 'Email search',
      url: `https://www.google.com/search?q=${encodedEntityName}`,
    },
    {
      iconType: 'twitter',
      label: 'Username search',
      url: `https://twitter.com/search?q=${details.username}&f=user`,
    },
    {
      iconType: 'pipl',
      label: 'Email search',
      url: `https://pipl.com/search/?q=${account.entityName}&l=&sloc=&in=6`,
    },
    {
      iconType: 'arkowl',
      label: phoneQuery ? 'Email and Phone number search' : 'Email search',
      url: `https://arkowl.com/query?email=${account.entityName}${phoneQuery}`,
    },
    {
      iconType: 'ekata',
      label: 'Email search',
      url: `https://app.ekata.com/emails?email=${account.entityName}`,
    },
  ]

  return { ...account, deeplinks }
}
