import React from 'react'
import { joinClassNames } from '@signifyd/utils'
import './CaseListMessage.less'

type Props = {
  title: string
  body: string
  type: 'error' | 'info'
}

const CaseListMessage = ({ title, body, type }: Props) => (
  <div
    className={joinClassNames(['CaseListMessage', `CaseListMessage--${type}`])}
  >
    <div className="CaseListMessage--title">{title}</div>
    <span className="CaseListMessage--body">{body}</span>
  </div>
)

export default CaseListMessage
