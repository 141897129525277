import React from 'react'
import { CaseDetailsSprite } from 'core/components/Sprite'

const handleClick = (event, data) => {
  event.preventDefault()
  window.postMessage(
    {
      type: 'OPEN_TAB_REQUEST_LEXIS_NEXIS',
      ...data,
    },
    '*'
  )
}

const MRChromeExtensionInstalled = () => {
  return window.SIG_MR_CHROME_EXTENSION
}

const LexisNexisDeeplink = ({ postMessageData }) => {
  return MRChromeExtensionInstalled() ? ( // eslint-disable-next-line
    <a
      className="Deeplink"
      onClick={(event) => handleClick(event, postMessageData)}
      title="Last Name and Address Search"
    >
      <CaseDetailsSprite type="lexis-nexis" className="Deeplink--icon" />
      Last Name and Address Search
    </a>
  ) : null
}

export default LexisNexisDeeplink
