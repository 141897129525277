import React from 'react'
import { joinClassNames } from '@signifyd/utils'
import './AvsCvv.less'

type Props = {
  code: string | null | undefined
  description: string | null | undefined
}

const AvsCvv = ({ code, description }: Props) => {
  if (!code || !description) {
    return null
  }

  return (
    <span
      className={joinClassNames(['AvsCvv', code ? `AvsCvv--${code}` : null])}
    >
      {description}
      {code ? ` (${code})` : ''}
    </span>
  )
}

export default AvsCvv
