import { action } from 'mobx'
import { filter, get, pick, reduce } from 'lodash'
import moment from 'moment-timezone'
import type { CaseReport, LoadingStatus } from './caseReport.store'
import { updateAgentFeedbackTags } from 'core/http/cases/AgentFeedbackHttp'
import type { AgentFeedbackData } from 'core/http/cases/AgentFeedbackHttp.types'
import getAPIErrorMessage from 'core/utils/getAPIErrorMessage'
import appStore from 'AppStore'
import {
  AnalysisResponse,
  ANALYSIS_STATE,
} from 'core/http/cases/AnalysisHttp.types'
import caseNotesStore from './caseNotes.store'
import merchantReportStore from './merchantReport.store'
import customerStore from './customer.store'
import caseListStore from './caseList.store'
import trainingModeStore from './trainingMode.store'
import getCaseNotesActions from './caseNotes.actions'
import getMerchantReportActions from './merchantReport.actions'
import getCustomerActions from './customer.actions'
import getCaseListActions from './caseList.actions'
import getTrainingModeActions from './trainingMode.actions'
import {
  CaseAnalysis,
  CaseEntries,
  CaseOrder,
  createReviewEscalation,
  denylistAddress,
  denylistEmailAddress,
  getCaseAnalysis,
  getCaseEntries,
  getCaseOrder,
  getInvestigationCounts,
  getRule,
  INV_GUARANTEE_DISPOSITION,
  INV_REVIEW_DISPOSITION,
  InvestigationCounts,
  InvestigationInfo,
  listInvestigations,
  replaceGuaranteeDisposition,
  RuleResponse,
  updateCase,
} from '@signifyd/http'
import { AxiosError } from 'axios'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'
import { RecipientToUpdate } from '../containers/CasePageMenu/UpdateAddress/UpdateAddress.utils'

export type CaseReportActionsType = {
  getCaseById: () => Promise<CaseReport>
}

type LoadingNamespace = keyof typeof LOADING_NAMESPACE

const caseNotesActions = getCaseNotesActions(caseNotesStore)
const merchantReportActions = getMerchantReportActions(merchantReportStore)
const customerActions = getCustomerActions(customerStore)
const caseListActions = getCaseListActions(caseListStore)
const trainingModeActions = getTrainingModeActions(trainingModeStore)

export const LOADING_NAMESPACE = {
  GET_ALL_CASE_DATA_BY_ID: 'GET_ALL_CASE_DATA_BY_ID',
  DENYLIST_CONFIRMATION_EMAIL: 'DENYLIST_CONFIRMATION_EMAIL',
  DENYLIST_DELIVERY_ADDRESS: 'DENYLIST_DELIVERY_ADDRESS',
}

function isCustomerPolicy(
  reasons: Array<{ category: string }>,
  ruleId?: number
): boolean {
  if (!ruleId) {
    return false
  }

  return reasons.some((reason) => reason.category === 'CUSTOMER_POLICY')
}

// TODO FET-2000 add return type
export const blockAnalysisDataForTraining = (
  analysisData: AnalysisResponse
) => {
  const removeReg =
    /blacklisted|denylisted|chargeback|order as bad|fraud|fraudulent/i

  return reduce(
    analysisData,
    (agg, item, key) => {
      if (key !== 'investigationId' && key !== 'adjustedScore') {
        item.details = filter(
          item.details,
          ({ shortDescription, longDescription }) =>
            !(
              removeReg.test(shortDescription) ||
              removeReg.test(longDescription)
            )
        )
      }

      agg[key] = item

      return agg
    },
    {}
  )
}

interface SetCaseReportArgs {
  caseData: InvestigationInfo
  analysisState: ANALYSIS_STATE
  analysisData: CaseAnalysis | null
  entriesData: CaseEntries
  orderData: CaseOrder
  countsData: InvestigationCounts | null
  ruleData: RuleResponse | null
}

const CaseReportActions = (store: CaseReport) => {
  const _setLoading = action((keyValue: Partial<LoadingStatus>) => {
    store.loading = { ...store.loading, ...keyValue }
  })

  const _setCaseReport = action(
    ({
      caseData,
      analysisData,
      analysisState,
      entriesData,
      countsData,
      orderData,
      ruleData,
    }: SetCaseReportArgs): void => {
      if (caseData?.guaranteeReviewedAt === '1970-01-01T00:00:00+0000') {
        caseData.guaranteeReviewedAt = '0'
      }

      if (caseData) {
        store.agentFeedbackTagsForm = caseData.agentFeedbackTags
      }

      if (caseData) {
        store.case = caseData
      }

      if (analysisData) {
        store.analysis = appStore.isTraining
          ? blockAnalysisDataForTraining(analysisData)
          : analysisData
      }
      if (analysisState) {
        store.analysisState = analysisState
      }
      if (entriesData) {
        store.entries = entriesData
      }
      if (orderData) {
        store.order = orderData
      }
      if (ruleData) {
        store.rule = ruleData
      }

      store.counts = countsData
    }
  )

  const _resetLoadingState = (
    namespace: LoadingNamespace[keyof LoadingNamespace]
  ) => {
    _setLoading({
      pending: true,
      success: false,
      finished: false,
      error: false,
      namespace,
    })

    action(() => {
      store.noCaseFound = false
    })
  }

  const _setCase = action((caseData: CaseInfo) => {
    const currentCase = store.case ? { ...store.case, ...caseData } : caseData

    store.case = currentCase
    caseListActions.updateCase(currentCase)
  })

  const _getCaseReportData = async (id: number): Promise<any> => {
    const analysisCallWrapper = async () => {
      try {
        const response = await getCaseAnalysis(id)

        return response
      } catch (err: any) {
        return {
          status: err.response.status,
          data: null,
        }
      }
    }

    const analysisCall = await analysisCallWrapper()

    const [caseRes, analysisRes, entriesRes, orderRes] = await Promise.all([
      listInvestigations(`investigationIds=${id}&content=CASES`),
      analysisCall,
      getCaseEntries(id),
      getCaseOrder(id),
    ])

    const analysisStatus = get(analysisRes, 'status', 400)
    let analysisData: {
      analysisState: ANALYSIS_STATE
      analysisData: CaseAnalysis | null
    } = {
      analysisState: ANALYSIS_STATE.ERROR,
      analysisData: null,
    }
    if (analysisStatus === 200) {
      analysisData = {
        analysisState: ANALYSIS_STATE.PROCESSED,
        analysisData: analysisRes.data,
      }
    } else if (analysisStatus === 202) {
      analysisData = {
        analysisState: ANALYSIS_STATE.PROCESSING,
        analysisData: null,
      }
    } else if (analysisStatus === 204) {
      analysisData = {
        analysisState: ANALYSIS_STATE.POLICY_ONLY,
        analysisData: null,
      }
    }

    const caseReport = {
      caseData: caseRes.data.investigations[0],
      analysisState: analysisData.analysisState,
      analysisData: analysisData.analysisData,
      entriesData: entriesRes.data,
      orderData: orderRes.data,
      countsData: null,
    }

    const {
      caseData: {
        recommendedActionReasons,
        recommendedActionRuleId,
        guaranteeRecommendedActionReasons,
        guaranteeRecommendedActionRuleId,
      },
    } = caseReport

    const isRecommendedCustomerPolicy = isCustomerPolicy(
      recommendedActionReasons,
      recommendedActionRuleId
    )
    const isGuaranteeCustomerPolicy = isCustomerPolicy(
      guaranteeRecommendedActionReasons,
      guaranteeRecommendedActionRuleId
    )

    const getRules = async () => {
      if (!(isRecommendedCustomerPolicy || isGuaranteeCustomerPolicy)) {
        return Promise.resolve({ data: null })
      }

      try {
        return await getRule(
          isGuaranteeCustomerPolicy
            ? guaranteeRecommendedActionRuleId!
            : recommendedActionRuleId!
        )
      } catch (err: any) {
        console.error(err)

        return {
          data: null,
          status: err.response.status,
        }
      }
    }

    const { data: ruleData } = await getRules()

    _setCaseReport({
      ...caseReport,
      ruleData,
    })

    return pick(store.case, ['customerId'])
  }

  const _trainingModeTasks = (caseId: number) => {
    return trainingModeActions.getAdditionalDecisionData(caseId)
  }

  const getReportCaseData = async (caseId: number): Promise<void> => {
    const { customerId } = await _getCaseReportData(caseId)

    if (appStore.isTraining) {
      await _trainingModeTasks(caseId)
    }

    const caseCountRequestArguments: { caseId: number; endDate?: string } = {
      caseId,
    }

    const endDate = store.case?.normalizedPurchaseCreatedAt

    const dateFormat = 'YYYY-MM-DD'

    if (appStore.isTraining && endDate) {
      caseCountRequestArguments.endDate = moment.utc(endDate).format(dateFormat)
    }

    caseNotesActions.getCaseNotesById(caseId)
    merchantReportActions.getMerchantMetadataById(customerId)
    customerActions.getCustomerById(customerId)

    const { data } = await getInvestigationCounts(
      caseCountRequestArguments.caseId,
      caseCountRequestArguments.endDate
    )

    _setCaseReport({
      countsData: data,
    })
  }

  const getAllCaseDataById = async (caseId: number): Promise<any> => {
    _resetLoadingState(LOADING_NAMESPACE.GET_ALL_CASE_DATA_BY_ID)

    try {
      await getReportCaseData(caseId)
      _setLoading({
        success: true,
      })
    } catch (error) {
      console.log(error)

      _setLoading({
        error: getAPIErrorMessage(error as AxiosError),
      })
    }

    _setLoading({
      finished: true,
      pending: false,
    })
  }

  const setGuaranteeDisposition = async (
    guaranteeDisposition: INV_GUARANTEE_DISPOSITION
  ): Promise<void> => {
    if (!store.case) {
      return Promise.resolve()
    }

    try {
      const { data } = await replaceGuaranteeDisposition(
        store.case.investigationId,
        guaranteeDisposition
      )
      const caseInfo = store.case!

      _setCase({
        ...caseInfo,
        guaranteeReviewedAt: data.reviewedAt,
        guaranteeDisposition,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const _updateCase = async (
    reviewDisposition: INV_REVIEW_DISPOSITION
  ): Promise<void> => {
    if (!store.case) {
      return Promise.resolve()
    }

    try {
      await updateCase(store.case.investigationId, {
        reviewDisposition,
      })

      await caseNotesActions.getCaseNotesById(store.case.investigationId)

      _setCase({
        ...store.case,
        investigationReviewDisposition: reviewDisposition,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const toggleUpdateCase = async (
    inputDisposition: INV_REVIEW_DISPOSITION
  ): Promise<void> => {
    if (!store.case) {
      return
    }

    const reviewDisposition =
      inputDisposition === store.case.investigationReviewDisposition
        ? INV_REVIEW_DISPOSITION.NONE
        : inputDisposition

    try {
      await _updateCase(reviewDisposition)
    } catch (error) {
      console.error(error)

      _setLoading({
        error: getAPIErrorMessage(error as AxiosError),
      })
    }
  }

  const setNoCaseFound = action(() => {
    store.noCaseFound = true
  })

  const setAgentFeedbackTagsForm = action((event: CheckboxChangeEvent) => {
    if (!store.agentFeedbackTagsForm) {
      return
    }
    const agentFeedbackTag = event.target['data-agent-feedback-tag']
    let agentFeedbackTagsForm = store.agentFeedbackTagsForm
      ? store.agentFeedbackTagsForm
      : []
    agentFeedbackTagsForm = agentFeedbackTagsForm.filter(
      (tag) => tag !== agentFeedbackTag
    )

    if (event.target.checked) {
      agentFeedbackTagsForm.push(agentFeedbackTag)
    }

    store.agentFeedbackTagsForm = agentFeedbackTagsForm
  })
  const submitAgentFeedbackTags = action(async () => {
    if (!store.case) {
      return
    }

    const { agentFeedbackTagsForm, case: currentCase } = store
    const { investigationId: caseId } = currentCase
    const agentFeedbackTags = agentFeedbackTagsForm
    const agentFeedbackData: AgentFeedbackData = {
      signifydId: caseId,
      agentFeedbackTags,
    }
    try {
      await updateAgentFeedbackTags(agentFeedbackData)
      _setCase({
        ...store.case,
        agentFeedbackTags,
      })
    } catch (err) {
      console.log(err)

      _setLoading({
        error: getAPIErrorMessage(err as AxiosError),
      })
    }
  })

  const _denylist = async (data: any, namespace: LoadingNamespace) => {
    if (!store.case || !store.entriesByRole) {
      return
    }
    const apiFunc =
      namespace === LOADING_NAMESPACE.DENYLIST_CONFIRMATION_EMAIL
        ? denylistEmailAddress
        : denylistAddress
    const { teamId, investigationId: caseId } = store.case

    _resetLoadingState(namespace)

    try {
      await apiFunc({
        teamId,
        caseId,
        ...data,
      })

      const { data: entries } = await getCaseEntries(caseId)

      action(() => {
        store.entries = entries

        _setLoading({
          success: true,
        })
      })
    } catch (err) {
      console.log(err)

      _setLoading({
        error: getAPIErrorMessage(err as AxiosError),
      })
    }

    _setLoading({
      finished: true,
      pending: false,
    })
  }

  const denylistConfirmationEmail = () => {
    const data = {
      emailAddress: get(store.entriesByRole, 'confirmationEmail[0].entityName'),
    }

    return _denylist(data, LOADING_NAMESPACE.DENYLIST_CONFIRMATION_EMAIL)
  }

  const denylistDeliveryAddr = () => {
    const addressDetails = get(
      store.entriesByRole,
      'deliveryAddress[0].details'
    )

    const data = {
      ...pick(addressDetails, [
        'streetNumber',
        'streetName',
        'unit',
        'cityName',
        'postalCode',
        'regionName',
        'regionAlpha',
        'latitude',
        'longitude',
      ]),
      countryCode: addressDetails.countryIsoCode,
    }

    return _denylist(data, LOADING_NAMESPACE.DENYLIST_DELIVERY_ADDRESS)
  }

  const escalateCase = async (
    caseId: number,
    data:
      | INV_GUARANTEE_DISPOSITION.APPROVED
      | INV_GUARANTEE_DISPOSITION.DECLINED
  ) => {
    await createReviewEscalation(caseId, data)

    const res = await Promise.all([
      listInvestigations(`investigationIds=${caseId}&content=CASES`),
      caseNotesActions.getCaseNotesById(caseId),
    ])

    _setCase(get(res[0], 'data.investigations[0]', null))
  }

  const updateAddress = async (
    caseId: number,
    recipient: RecipientToUpdate
  ) => {
    await updateCase(caseId, { recipient })

    const [investigationResponse] = await Promise.all([
      listInvestigations(`investigationIds=${caseId}&content=CASES`),
      caseNotesActions.getCaseNotesById(caseId),
    ])

    _setCase(investigationResponse.data.investigations[0] as CaseInfo)
    _setLoading({ success: true })
  }

  return {
    getAllCaseDataById,
    setGuaranteeDisposition,
    toggleUpdateCase,
    setNoCaseFound,
    denylistConfirmationEmail,
    denylistDeliveryAddr,
    escalateCase,
    setAgentFeedbackTagsForm,
    submitAgentFeedbackTags,
    updateAddress,
  }
}

export default CaseReportActions
