import { FC } from 'react'
import { Icon } from 'antd'
import './DataUnavailable.less'

interface Props {
  dataTitle?: string
  message?: string
}

const DataUnavailable: FC<Props> = ({ dataTitle, message }) => {
  return (
    <div
      className="DataUnavailable"
      data-test-id={`data-unavailable-${dataTitle || 'generic'}`}
    >
      <Icon type="file-unknown" />
      <p>{message || `${dataTitle || 'Data'} unavailable`}</p>
    </div>
  )
}

export default DataUnavailable
