import { isNil } from 'lodash'
import { Button, Input } from 'antd'
import { SigPopconfirm as Popconfirm } from 'core/components/antd'
import LinkButton from 'core/components/LinkButton'
import './MerchantNotesEditable.less'
import type { Loading } from 'caseReport/stores/merchantReport.store'
import type { Notes } from './MerchantNotes'

type Props = {
  notes: Notes
  stateNotes: Notes
  loading: Loading
  onChange: (arg0: string) => void
  onSubmit: () => void
  onCancel: () => void
  onDelete: () => void
}
const { TextArea } = Input

const MerchantNotesEditable = ({
  notes,
  stateNotes,
  loading,
  onChange,
  onSubmit,
  onCancel,
  onDelete,
}: Props) => {
  const hasMerchantNotes = !isNil(notes)
  const notesValid = stateNotes && stateNotes.length > 0 && stateNotes !== notes
  const deleteBtn = hasMerchantNotes && (
    <Popconfirm
      okText="Yes"
      cancelText="No"
      placement="topLeft"
      title={
        <span>
          This action will delete the merchant notes <br />
          for this case only. This cannot be undone!
        </span>
      }
      onConfirm={onDelete}
    >
      <LinkButton
        className="MerchantNotesEditable--delete"
        type="danger"
        disabled={loading.pending}
        loading={loading.pending && loading.namespace === 'delete'}
      >
        Delete notes
      </LinkButton>
    </Popconfirm>
  )
  const cancelBtn = (
    <Button
      className="MerchantNotesEditable--cancel"
      disabled={loading.pending}
      onClick={onCancel}
    >
      Cancel
    </Button>
  )
  const submitBtn = hasMerchantNotes ? (
    <Popconfirm
      okText="Yes"
      cancelText="No"
      placement="topRight"
      title={
        <div className="MerchantNotesEditable--confirm">
          <div>Please confirm the change to the merchant notes:</div>
          <div>
            <strong>Existing notes</strong>
          </div>
          <div className="MerchantNotesEditable--confirm-text">{notes}</div>
          <div>
            <strong>New Notes</strong>
          </div>
          <div className="MerchantNotesEditable--confirm-text">
            {stateNotes}
          </div>
        </div>
      }
      onConfirm={onSubmit}
    >
      <Button
        className="MerchantNotesEditable--submit-update"
        type="primary"
        disabled={!notesValid || loading.pending}
        loading={loading.pending && loading.namespace === 'update'}
      >
        Update
      </Button>
    </Popconfirm>
  ) : (
    <Button
      className="MerchantNotesEditable--submit-create"
      type="primary"
      disabled={!notesValid || loading.pending}
      loading={loading.pending && loading.namespace === 'create'}
      onClick={onSubmit}
    >
      Create
    </Button>
  )

  return (
    <div>
      <TextArea
        className="MerchantNotesEditable--textarea"
        rows={5}
        disabled={loading.pending}
        value={stateNotes ?? []}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="MerchantNotesEditable--btn-wrapper">
        {deleteBtn}
        {cancelBtn}
        {submitBtn}
      </div>
    </div>
  )
}

export default MerchantNotesEditable
