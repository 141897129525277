import { Component } from 'react'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import { configure } from 'mobx'
import { observer, Provider } from 'mobx-react'
import {
  AdminNavBar,
  AppAnalytics,
  ErrorBoundary,
  Layout,
  QAInfoPopover,
} from '@signifyd/legacy-components'
import { User } from '@signifyd/http'
import { UserConfigs } from '@signifyd/app-feature-configs'
import appStore from 'AppStore'
import AppMessages from 'core/components/AppMessages/AppMessages'
import CaseSearchOmni from 'core/components/CaseSearchOmni'
import PopoverContainer from 'core/components/PopoverContainer'
import caseFiltersStore from 'caseReport/stores/caseFilters.store'
import caseListStore from 'caseReport/stores/caseList.store'
import caseNotesStore from 'caseReport/stores/caseNotes.store'
import caseReportStore from 'caseReport/stores/caseReport.store'
import caseReportUIStore from 'caseReport/stores/caseReportUI.store'
import customerStore from 'caseReport/stores/customer.store'
import merchantReportStore from 'caseReport/stores/merchantReport.store'
import orderReviewChecklistStore from 'caseReport/stores/orderReviewChecklist.store'
import reviewQueueStore from 'caseReport/stores/reviewQueue.store'
import trainingModeStore from 'caseReport/stores/trainingMode.store'
import { sharedAdminTokens } from '@signifyd/sig-keys'
import { Stores } from 'useStores'
import AppRoutes from './AppRoutes'
import 'index.less'
import { BrowserRouter } from 'react-router-dom'

configure({ enforceActions: 'observed' })

interface Props {
  user: User
  userConfigs: UserConfigs
}
interface State {
  stores: Stores
}

@observer
class App extends Component<Props, State> {
  stores: Stores

  constructor(props: Props) {
    super(props)

    appStore.setUserInfo(props.user)
    appStore.setUserConfigs(props.userConfigs)

    this.stores = {
      appStore,
      caseReportUIStore,
      caseReportStore,
      merchantReportStore,
      caseNotesStore,
      orderReviewChecklistStore,
      customerStore,
      caseFiltersStore,
      caseListStore,
      trainingModeStore,
      reviewQueueStore,
    }
  }

  render(): JSX.Element {
    const { user, userConfigs } = this.props

    return (
      <BrowserRouter>
        <Provider {...this.stores}>
          <div className="app">
            <PopoverContainer />
            <ConfigProvider locale={enUS}>
              <AppAnalytics
                user={user}
                logRocketId={sharedAdminTokens.logRocketId}
                heapAppId={sharedAdminTokens.heapAppId}
                enabled={process.env.BUILD_ENV === 'production'}
              />
              <Layout>
                <Layout.NavBar>
                  {appStore.messages.message && <AppMessages />}
                  <AdminNavBar
                    user={user}
                    title={<CaseSearchOmni />}
                    innerMaxWidth={null}
                    userConfigs={userConfigs}
                  />
                </Layout.NavBar>
                <Layout.Content
                  className="app-content"
                  innerMaxWidth={null}
                  innerStyle={{
                    padding: 'none',
                  }}
                >
                  <ErrorBoundary message="Admin Case Review failed to load.">
                    <AppRoutes />
                    <QAInfoPopover />
                  </ErrorBoundary>
                </Layout.Content>
              </Layout>
            </ConfigProvider>
          </div>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
