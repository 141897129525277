import React from 'react'
import { Alert } from 'antd'
import {
  CASE_QUEUE_ERROR_TITLE,
  CASE_QUEUE_ERROR_BODY,
  CASE_QUEUE_NO_PENDING_TITLE,
  CASE_QUEUE_NO_PENDING_BODY,
} from 'caseReport/Constants'
import Spinner from 'core/components/Spinner'
import CaseListMessage from './CaseListMessage'
import './CaseListAlertContainer.less'

type Props = {
  info: string | null | undefined
  error: string | null | undefined
  errorMessage: Array<string>
}

const CaseListAlertContainer = ({ info, error, errorMessage = [] }: Props) => (
  <div className="CaseListAlertContainer" data-test-id="caseListAlert">
    {info && <Alert message={info} type="info" banner closable={false} />}
    {!!error &&
      (error === 404 ? (
        <CaseListMessage
          title={CASE_QUEUE_NO_PENDING_TITLE}
          body={CASE_QUEUE_NO_PENDING_BODY}
          type="info"
        />
      ) : (
        <CaseListMessage
          title={CASE_QUEUE_ERROR_TITLE}
          body={errorMessage.join(' ') || CASE_QUEUE_ERROR_BODY}
          type="error"
        />
      ))}
  </div>
)

const CaseListLoader = () => (
  <div className="CaseListAlertContainer">
    <Spinner />
  </div>
)

export default CaseListAlertContainer
export { CaseListLoader }
