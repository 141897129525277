import React, { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './SigTag.less'

export type SigTagSize = 'small' | 'large'
type Props = {
  size: SigTagSize
  className?: string | null | undefined
}

const SigTag: FC<Props> = ({ size, className, children }) => (
  <div className={joinClassNames(['SigTag', `SigTag--${size}`, className])}>
    {children}
  </div>
)

export default SigTag
