import { FC } from 'react'
import { Modal, notification } from 'antd'
import UpdateAddressForm from './UpdateAddressForm'
import { T4 } from '@signifyd/legacy-components'
import {
  convertFormValuesToUpdatableRecipient,
  convertRecipientToFormValues,
  UpdateAddressFormValues,
} from './UpdateAddress.utils'
import { RecipientToUpdate } from '@signifyd/http'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'

interface Props {
  investigation: CaseInfo
  visible: boolean
  setVisible: (isVisible: boolean) => void
  updateAddress: (caseId: number, recipient: RecipientToUpdate) => Promise<void>
}

const UpdateAddressModal: FC<Props> = ({
  investigation,
  updateAddress,
  visible,
  setVisible,
}) => {
  const {
    recipients: [recipient],
    investigationId,
  } = investigation

  const {
    control,
    handleSubmit: submitHandler,
    reset,
    formState: { isDirty: hasChanges, isSubmitting, isLoading },
  } = useForm<UpdateAddressFormValues>({
    defaultValues: convertRecipientToFormValues(recipient),
    mode: 'onChange',
  })

  const handleCancel = () => {
    reset()
    setVisible(false)
  }

  const handleSubmit: SubmitHandler<UpdateAddressFormValues> = async (
    formValues
  ) => {
    const finalisedRecipient = convertFormValuesToUpdatableRecipient(
      recipient,
      formValues
    )
    try {
      await updateAddress(investigationId, finalisedRecipient)
      notification.success({ message: 'Successfully updated address' })
    } catch {
      notification.error({
        message: 'Issue updating address. Try again or contact support.',
      })
    } finally {
      setVisible(false)
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={submitHandler(handleSubmit)}
      okText="Update Address"
      okButtonProps={{
        loading: isLoading || isSubmitting,
        disabled: !hasChanges,
      }}
      title={<T4>Update Recipient Address</T4>}
      destroyOnClose
    >
      <UpdateAddressForm control={control} />
    </Modal>
  )
}

export default UpdateAddressModal
