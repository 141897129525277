import { getCaseSearchUrl } from '@signifyd/legacy-components'
import { CASE_SEARCH_GENERAL_QUERY } from 'caseReport/Constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import buildUrl from '../buildUrl/buildUrl'

const searchForOtherCases = (
  term: string | null | undefined
): DeeplinkProps | null | undefined =>
  term
    ? {
        iconType: 'signifyd',
        label: 'Search for other cases',
        url: buildUrl(getCaseSearchUrl(), {
          [CASE_SEARCH_GENERAL_QUERY]: `"${term}"`,
        }),
      }
    : null

export default searchForOtherCases
