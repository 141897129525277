import { FC, useState } from 'react'
import { Button, Modal, notification } from 'antd'
import EscalateCaseModal from 'caseReport/components/EscalateCaseModal'
import {
  CASE_REPORT_MENU_TEXT as TEXT,
  CASE_REPORT_MENU_TEXT,
  GUARANTEE_DISPOSITION_ACTIONS,
  MAX_NOTE_CHARACTERS,
} from 'caseReport/Constants'
import Menu from '../Menu'
import MenuItemDivider from '../MenuItemDivider'
import ResubmitMenuItem from '../ResubmitMenuItem'
import AgentFeedbackButton from '../AgentFeedbackBtn'
import {
  getApproveBtn,
  getCancelBtn,
  getCaseTimer,
  getDeclineBtn,
  getDispositionResult,
  getEscalateCaseBtn,
  getIsResubmitBtn,
  getRecommendationResult,
  getResubmitBtn,
  getReviewBtn,
  getThumbsDownBtn,
  getThumbsUpBtn,
  getUpdateAddressButton,
} from './CaseReportMenu.buttons'
import {
  CaseReportMenuButtonProps,
  CaseReportMenuState,
} from './CaseReportMenu.types'
import { observer } from 'mobx-react'
import caseReportActions from 'caseReport/stores/caseReport.actions'
import caseNotesActions from 'caseReport/stores/caseNotes.actions'
import trainingModeActions from 'caseReport/stores/trainingMode.actions'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import getAPIErrorMessage from 'core/utils/getAPIErrorMessage'
import useStores from 'useStores'
import {
  DECISION_MECHANISM,
  INV_GUARANTEE_DISPOSITION,
  INV_REVIEW_DISPOSITION,
  USER_ROLE,
} from '@signifyd/http'
import { UploadFile } from 'antd/lib/upload/interface'
import { UpdateAddressModal } from '../UpdateAddress'

const {
  APPROVE,
  DECLINE,
  REVIEW,
  CANCEL,
  RE_SUBMIT,
  THUMBS_DOWN,
  THUMBS_UP,
  ESCALATE,
  UPDATE_ADDRESS,
} = GUARANTEE_DISPOSITION_ACTIONS

/*
 * NOTE: We can't use trainingMask in here as antd does not allow for Menu.Item to be wrapped
 * https://github.com/ant-design/ant-design/issues/4853
 */
const CaseReportMenu: FC = () => {
  const { caseReportStore, caseNotesStore, appStore, trainingModeStore } =
    useStores()

  const [state, setState] = useState<CaseReportMenuState>({
    confirmApprovePending: false,
    confirmDeclinePending: false,
    confirmCancelPending: false,
    confirmResubmitPending: false,
    escalateCaseModalVisible: false,
    proposedDisposition: null,
    resubmitNote: null,
    // TODO FET-1995 Can probably make this just an array
    resubmitFiles: { fileList: [] },
    loading: null,
    agentFeedbackTagsPopoverVisible: false,
    updateAddressModalVisible: false,
  })

  const updateState = (updatedState: Partial<CaseReportMenuState>): void => {
    setState({ ...state, ...updatedState })
  }

  if (
    !caseReportStore.case ||
    !caseNotesStore.notes ||
    caseReportStore.loading.pending ||
    caseReportStore.loading.error
  ) {
    return null
  }

  const guaranteeDisposition = appStore.isTraining
    ? trainingModeStore.guaranteeDisposition
    : caseReportStore.case.guaranteeDisposition

  const { case: currentCase, agentFeedbackTagsForm, claim } = caseReportStore
  const { isTraining } = appStore

  const recipient = currentCase?.recipients[0]

  const canUpdateAddress =
    !!recipient && appStore.userHasRole(USER_ROLE.SUPER_ADMIN)

  const actions = {
    caseReport: caseReportActions(caseReportStore),
    trainingMode: trainingModeActions(trainingModeStore),
    caseNotes: caseNotesActions(caseNotesStore),
  }

  const { recommendedDecisionDisposition, decisionMechanism } = currentCase

  const canSeeEscalationBtn = appStore.userHasRole(USER_ROLE.MANUAL_REVIEWER)
  const canSeeAdvancedActionButtons =
    appStore.userHasRole(USER_ROLE.MANUAL_REVIEWER_LEAD) ||
    appStore.userHasRole(USER_ROLE.MANUAL_REVIEWER_ADMIN) ||
    (appStore.userHasRole(USER_ROLE.ADMIN) &&
      !appStore.userHasRole(USER_ROLE.MANUAL_REVIEWER))

  const isReReviewSubmitted =
    caseReportStore.case.investigationId === caseNotesStore.caseId &&
    caseNotesStore.reReviewSubmitted

  const chargebackClaimSubmitted =
    !!claim && guaranteeDisposition === INV_GUARANTEE_DISPOSITION.APPROVED

  const toggleApproveConfirmation = (isToggled: boolean): void => {
    updateState({ confirmApprovePending: isToggled })
  }

  const toggleDeclineConfirmation = (isToggled: boolean): void => {
    updateState({ confirmDeclinePending: isToggled })
  }

  const handleApproveCase = (): void => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.APPROVE,
    })

    const approveAction = isTraining
      ? actions.trainingMode.updateAdditionalDecision
      : actions.caseReport.setGuaranteeDisposition

    approveAction(INV_GUARANTEE_DISPOSITION.APPROVED).then(() => {
      updateState({
        loading: null,
        confirmApprovePending: false,
      })
    })
  }

  const handleDeclineCase = (): void => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.DECLINE,
    })

    const declineAction = isTraining
      ? actions.trainingMode.updateAdditionalDecision
      : actions.caseReport.setGuaranteeDisposition

    declineAction(INV_GUARANTEE_DISPOSITION.DECLINED).then(() => {
      updateState({
        loading: null,
        confirmDeclinePending: false,
      })
    })
  }

  const toggleCancelConfirmation = (isCancelPending: boolean): void => {
    updateState({ confirmCancelPending: isCancelPending })
  }

  const handleCancelCase = async (): Promise<void> => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.CANCEL,
    })

    await actions.caseReport.setGuaranteeDisposition(
      INV_GUARANTEE_DISPOSITION.CANCELED
    )

    updateState({
      confirmCancelPending: false,
      loading: null,
    })
  }

  const toggleResubmitConfirmation = (isResubmitPending: boolean): void => {
    updateState({
      confirmResubmitPending: isResubmitPending,
    })
  }

  const handleResubmitNote = (note?: string | null): void => {
    updateState({ resubmitNote: note })
  }

  const handleResubmitFiles = (files: Array<UploadFile>): void => {
    updateState({ resubmitFiles: { fileList: files } })
  }

  const handleResubmitCase = async (): Promise<void> => {
    updateState({
      confirmResubmitPending: false,
      loading: GUARANTEE_DISPOSITION_ACTIONS.RE_SUBMIT,
    })

    if (state.resubmitNote || state.resubmitFiles.fileList.length) {
      actions.caseNotes.addNote(
        `Case Re-Review Reason: ${state.resubmitNote}`,
        state.resubmitFiles.fileList
      )
    }

    await actions.caseReport.setGuaranteeDisposition(
      INV_GUARANTEE_DISPOSITION.PENDING
    )

    updateState({
      confirmResubmitPending: false,
      loading: null,
    })
  }

  const handleReview = (): void => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.REVIEW,
    })

    const reviewAction = isTraining
      ? actions.trainingMode.createAdditionalDecision
      : actions.caseReport.setGuaranteeDisposition
    reviewAction(INV_GUARANTEE_DISPOSITION.IN_REVIEW).then(() => {
      updateState({
        loading: null,
      })
    })
  }

  const toggleThumbsUp = (): void => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.THUMBS_UP,
    })

    actions.caseReport
      .toggleUpdateCase(INV_REVIEW_DISPOSITION.GOOD)
      .then(() => {
        updateState({
          loading: null,
        })
      })
  }

  const toggleThumbsDown = (): void => {
    updateState({
      loading: GUARANTEE_DISPOSITION_ACTIONS.THUMBS_DOWN,
    })

    actions.caseReport.toggleUpdateCase(INV_REVIEW_DISPOSITION.BAD).then(() => {
      updateState({
        loading: null,
      })
    })
  }

  const setProposedDisposition = (
    proposedDisposition:
      | INV_GUARANTEE_DISPOSITION.APPROVED
      | INV_GUARANTEE_DISPOSITION.DECLINED
  ): void => {
    updateState({ proposedDisposition })
  }

  const toggleEscalateCaseModal = (isVisible: boolean): void => {
    updateState({ escalateCaseModalVisible: isVisible })
  }

  const toggleUpdateAddressModal = (isVisible: boolean): void => {
    updateState({ updateAddressModalVisible: isVisible })
  }

  const handleEscalateCaseModalCancel = (): void => {
    updateState({ escalateCaseModalVisible: false, proposedDisposition: null })
  }

  const handleAgentFeedbackTagsPopoverCancel = (): void => {
    updateState({ agentFeedbackTagsPopoverVisible: false })
  }

  const handleAgentFeedbackTagsPopoverVisibleChange = (
    isVisible: boolean
  ): void => {
    updateState({ agentFeedbackTagsPopoverVisible: isVisible })
  }

  const handleAgentFeedbackTagsSubmit = (): void => {
    actions.caseReport.submitAgentFeedbackTags()

    updateState({ agentFeedbackTagsPopoverVisible: false })
  }

  const setAgentFeedbackTagsForm = (e: CheckboxChangeEvent): void => {
    actions.caseReport.setAgentFeedbackTagsForm(e)
  }

  const handleEscalateCaseModalOk = async (): Promise<void> => {
    if (state.proposedDisposition) {
      updateState({
        loading: GUARANTEE_DISPOSITION_ACTIONS.ESCALATE,
      })

      try {
        await actions.caseReport.escalateCase(
          currentCase.investigationId,
          state.proposedDisposition
        )

        notification.success({
          message: CASE_REPORT_MENU_TEXT.CASE_ESCALATED_SUCCESS_MSG,
        })
      } catch (e: any) {
        notification.error({
          message: getAPIErrorMessage(e),
        })
      }

      updateState({
        loading: null,
        escalateCaseModalVisible: false,
        proposedDisposition: null,
      })
    }
  }

  // TODO FET-1996 This will be removed when each button handles its own props
  const buttonProps: CaseReportMenuButtonProps = {
    isReReviewSubmitted,
    chargebackClaimSubmitted,
    guaranteeDisposition,
    state,
    toggleApproveConfirmation,
    handleApproveCase,
    handleDeclineCase,
    toggleDeclineConfirmation,
    canSeeEscalationBtn,
    canSeeAdvancedActionButtons,
    currentCase,
    handleAgentFeedbackTagsPopoverCancel,
    handleAgentFeedbackTagsPopoverVisibleChange,
    handleAgentFeedbackTagsSubmit,
    setAgentFeedbackTagsForm,
    agentFeedbackTagsForm,
  }

  const isPolicyOnlyAccept =
    decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT

  return (
    <div className="CaseReportMenu" data-test-id="caseReportMenu">
      <Menu
        selectedKeys={[]}
        onClick={({ key }) => {
          const onClickByKey = {
            [APPROVE]: () => toggleApproveConfirmation(true),
            [DECLINE]: () => toggleDeclineConfirmation(true),
            [CANCEL]: () => toggleCancelConfirmation(true),
            [RE_SUBMIT]: () => toggleResubmitConfirmation(true),
            [REVIEW]: handleReview,
            [THUMBS_DOWN]: toggleThumbsDown,
            [THUMBS_UP]: toggleThumbsUp,
            [ESCALATE]: () => toggleEscalateCaseModal(true),
            [UPDATE_ADDRESS]: () => toggleUpdateAddressModal(true),
          }
          onClickByKey[key as GUARANTEE_DISPOSITION_ACTIONS]?.()
        }}
      >
        {canUpdateAddress &&
          getUpdateAddressButton(buttonProps, UPDATE_ADDRESS)}
        {!isTraining && getIsResubmitBtn(buttonProps)}
        {!isTraining && getResubmitBtn(buttonProps, RE_SUBMIT)}
        {!isTraining && getCancelBtn(buttonProps, CANCEL)}
        {!chargebackClaimSubmitted && <MenuItemDivider />}
        {getApproveBtn(buttonProps, APPROVE)}
        {getDeclineBtn(buttonProps, DECLINE)}
        {!isTraining && getEscalateCaseBtn(buttonProps, ESCALATE)}
        {getReviewBtn(buttonProps, REVIEW)}
        {!isPolicyOnlyAccept && getDispositionResult(buttonProps)}
        {recommendedDecisionDisposition && guaranteeDisposition && (
          <MenuItemDivider />
        )}
        {!isPolicyOnlyAccept &&
          getRecommendationResult(recommendedDecisionDisposition)}
        {!chargebackClaimSubmitted && <MenuItemDivider />}
        {!isTraining && getThumbsDownBtn(buttonProps, THUMBS_DOWN)}
        {!isTraining && getThumbsUpBtn(buttonProps, THUMBS_UP)}
        {!chargebackClaimSubmitted && !isTraining && <MenuItemDivider />}
        {!chargebackClaimSubmitted &&
          !isTraining &&
          AgentFeedbackButton(buttonProps)}
        {!chargebackClaimSubmitted && !isTraining && <MenuItemDivider />}
        {guaranteeDisposition === INV_GUARANTEE_DISPOSITION.IN_REVIEW &&
          getCaseTimer()}
      </Menu>
      <Modal
        cancelText={TEXT.CANCEL_CONFIRM_CANCEL}
        okText={TEXT.CANCEL_CONFIRM_OK}
        title={<h3>{TEXT.CANCEL_CONFIRM_TITLE}</h3>}
        visible={state.confirmCancelPending}
        onOk={handleCancelCase}
        onCancel={() => toggleCancelConfirmation(false)}
      >
        <p>{TEXT.CANCEL_CONFIRM_BODY}</p>
      </Modal>
      <Modal
        maskClosable={false}
        title={<h5>{TEXT.RE_SUBMIT_MODAL_TITLE}</h5>}
        visible={state.confirmResubmitPending}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => toggleResubmitConfirmation(false)}
          >
            {TEXT.RE_SUBMIT_MODAL_CANCEL}
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            disabled={
              !!(
                state.resubmitNote &&
                state.resubmitNote.length > MAX_NOTE_CHARACTERS
              )
            }
            onClick={handleResubmitCase}
          >
            {TEXT.RE_SUBMIT_MODAL_OK}
          </Button>,
        ]}
      >
        <ResubmitMenuItem
          onNoteChange={(note) => handleResubmitNote(note)}
          onFileChange={(files) => handleResubmitFiles(files.fileList)}
        />
      </Modal>
      <EscalateCaseModal
        loading={state.loading === ESCALATE}
        visible={state.escalateCaseModalVisible}
        proposedDisposition={state.proposedDisposition}
        onCancel={handleEscalateCaseModalCancel}
        onOk={handleEscalateCaseModalOk}
        setProposedDisposition={setProposedDisposition}
      />
      {canUpdateAddress && (
        <UpdateAddressModal
          investigation={currentCase}
          updateAddress={actions.caseReport.updateAddress}
          visible={state.updateAddressModalVisible}
          setVisible={(isVisible: boolean) =>
            updateState({ updateAddressModalVisible: isVisible })
          }
        />
      )}
    </div>
  )
}

export default observer(CaseReportMenu)
