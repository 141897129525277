import type { DeeplinkProps } from 'core/components/Deeplink'
import { PHONE_NUM_SEARCH_LABEL } from './constants'

const trueCallerPhone = (
  phoneNum: string | null | undefined,
  countryCode: string | null | undefined
): DeeplinkProps | null | undefined =>
  countryCode && phoneNum
    ? {
        iconType: 'true-caller',
        label: PHONE_NUM_SEARCH_LABEL,
        url: encodeURI(
          `https://www.truecaller.com/search/${countryCode}/${phoneNum}`
        ),
      }
    : null

export default trueCallerPhone
