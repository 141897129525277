import { FC } from 'react'
import moment, { Moment } from 'moment-timezone'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from 'antd'
import './CaseFiltersContainer.less'
import type {
  CaseFilters,
  RangeObject,
} from 'caseReport/stores/caseFilters.store'
import {
  AUTH_FILTER_OPTIONS,
  CASE_FLAG_FILTER_OPTIONS,
  CASE_TYPE_OPTIONS,
  LOOKBACK_LIMIT,
} from '../../Constants'
import TeamSelect from './TeamSelects/TeamSelect'

const FormItem = Form.Item
const { Option } = Select
const RadioGroup = Radio.Group
const { RangePicker } = DatePicker
type InputEventType = {
  target: {
    value: string
  }
}
type CheckboxEventType = {
  target: {
    checked: boolean
  }
}
export type Props = {
  filters: CaseFilters
  dateFilter: RangeObject
  handleSortByChange: (arg0: string) => void
  handleSortOrderChange: (arg0: InputEventType) => void
  handleCaseStatusChange: (arg0: string) => void
  handleDateRangeChange: (
    arg0: [Moment | null | undefined, Moment | null | undefined]
  ) => void
  handleAmountChange: (arg0: 'min' | 'max', arg1: InputEventType) => void
  handleScoreChange: (arg0: 'min' | 'max', arg1: InputEventType) => void
  handleSearchTermChange: (arg0: InputEventType) => void
  handleClaimStatusChange: (arg0: string) => void
  handleClaimDispositionsChange: (arg0: string) => void
  handleGuaranteeStatusChange: (arg0: string) => void
  handleEscalatedChange: (arg0: CheckboxEventType) => void
  handleGuaranteeRequestedChange: (arg0: boolean) => void
  handleReReviewOnlyChange: (arg0: CheckboxEventType) => void
  handleCaseFlag: (arg0: CheckboxEventType) => void
  handleAuthStatus: (arg0: CheckboxEventType) => void
  handleTeamSelect: (ids: Array<string>) => void
  handleApplyFilterClick: () => void
  handleDefaultFiltersClick: () => void
  handleDecisionedByChange: () => void
  handleCaseTypeChange: () => void
}

const CaseFiltersContainer: FC<Props> = ({
  filters,
  dateFilter,
  handleSortByChange,
  handleSortOrderChange,
  handleCaseStatusChange,
  handleDateRangeChange,
  handleAmountChange,
  handleScoreChange,
  handleSearchTermChange,
  handleClaimStatusChange,
  handleClaimDispositionsChange,
  handleGuaranteeStatusChange,
  handleApplyFilterClick,
  handleGuaranteeRequestedChange,
  handleReReviewOnlyChange,
  handleDefaultFiltersClick,
  handleTeamSelect,
  handleCaseFlag,
  handleAuthStatus,
  handleEscalatedChange,
  handleDecisionedByChange,
  handleCaseTypeChange,
}) => {
  const {
    searchTerms,
    sortBy,
    caseStatus,
    guaranteeStatus,
    sortOrder,
    claimStatus,
    claimDispositions,
    caseScoreRange,
    caseAmountRange,
    guaranteeRequested,
    reReviewOnly,
    investigationReviewDispositions,
    authorizationGatewayStatuses,
    teamsSelected,
    guaranteeReviewEscalationLevel,
    wasDecisionedByManualReview,
    isTrialCase,
    isTestInvestigation,
  } = filters

  const disabledDateTime = (current) => {
    return (
      current &&
      current <
        moment().subtract(
          LOOKBACK_LIMIT.MAX_VALUE,
          LOOKBACK_LIMIT.MAX_VALUE_UNIT
        )
    )
  }

  const caseType = []
  if (isTrialCase) {
    caseType.push('trial')
  }

  if (isTestInvestigation) {
    caseType.push('test')
  }

  return (
    <div className="CaseFiltersContainer">
      <h4>Filter, Sort, &amp; Search Cases</h4>
      <Form>
        <Row>
          <Col span={24}>
            {/* Search */}
            <FormItem label="Search">
              <Input
                value={searchTerms.join(',')}
                onChange={handleSearchTermChange}
                data-test-id="searchInput"
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {/* Sort By */}
            <FormItem label="Sort By">
              <Select
                value={sortBy}
                onSelect={handleSortByChange}
                getPopupContainer={(trigger) => trigger.parentNode}
                data-test-id="sortBySelect"
              >
                <Option value="SORT_NORMALIZED_CREATED_AT">Order Date</Option>
                <Option value="SORT_SIGNIFYD_SCORE">Score</Option>
                <Option value="SORT_ORDER_TOTAL">Amount</Option>
                <Option value="SORT_GUARANTEE_REVIEW_DEADLINE">Timeout</Option>
              </Select>
            </FormItem>

            {/* Case Status */}
            <FormItem label="Case Status">
              <Select
                value={caseStatus}
                onChange={handleCaseStatusChange}
                getPopupContainer={(trigger) => trigger.parentNode}
                data-test-id="caseStatusSelect"
              >
                <Option value="ACTIVE">Open</Option>
                <Option value="CLOSED">Closed</Option>
                <Option value="SCORING">Scoring</Option>
                <Option value="ALL">All</Option>
              </Select>
            </FormItem>

            {/* Score */}
            <FormItem label="Score">
              <Col span={11}>
                <Input
                  data-test-id="minScoreInput"
                  placeholder="0"
                  value={caseScoreRange.min}
                  onChange={(e) => handleScoreChange('min', e)}
                />
              </Col>
              <Col span={2}>
                <span
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  to
                </span>
              </Col>
              <Col span={11}>
                <Input
                  data-test-id="maxScoreInput"
                  placeholder="999"
                  value={caseScoreRange.max}
                  onChange={(e) => handleScoreChange('max', e)}
                />
              </Col>
            </FormItem>
          </Col>

          <Col span={12}>
            {/* Sort Order */}
            <FormItem label="Sort Order">
              <RadioGroup
                value={sortOrder}
                onChange={handleSortOrderChange}
                data-test-id="sortOrderRadio"
              >
                <Radio value="ASC" data-test-id="sortOnAsc">
                  ASC
                </Radio>
                <Radio value="DSC" data-test-id="sortOnDsc">
                  DSC
                </Radio>
              </RadioGroup>
            </FormItem>

            {/* Amount */}
            <FormItem label="Amount">
              <Col span={11}>
                <Input
                  placeholder="Min"
                  value={caseAmountRange.min}
                  onChange={(e) => handleAmountChange('min', e)}
                />
              </Col>
              <Col span={2}>
                <span
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  to
                </span>
              </Col>
              <Col span={11}>
                <Input
                  placeholder="Max"
                  value={caseAmountRange.max}
                  onChange={(e) => handleAmountChange('max', e)}
                />
              </Col>
            </FormItem>

            {/* Date(yyyy-mm-dd) */}
            <FormItem label="Date(yyyy-mm-dd)">
              <RangePicker
                format="YYYY/MM/DD"
                value={[dateFilter.min, dateFilter.max]}
                onChange={handleDateRangeChange}
                getCalendarContainer={(trigger) => trigger.parentNode}
                disabledDate={disabledDateTime}
                ranges={{
                  'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                  'Last Year': [moment().subtract(1, 'year'), moment()],
                  'Last Two Years': [moment().subtract(2, 'years'), moment()],
                }}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="Decisioned By">
              <Select
                value={wasDecisionedByManualReview}
                onChange={handleDecisionedByChange}
                getPopupContainer={(trigger) => trigger.parentNode}
                data-test-id="decisionedBySelect"
              >
                <Option value="">ALL</Option>
                <Option value="true">Manual Review</Option>
                <Option value="false">Model</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            {/* Claim Status */}
            <FormItem label="Claim Status">
              <Row>
                <Col span={13}>
                  <Checkbox.Group
                    className="CaseFiltersContainer--checkboxGroup"
                    value={claimDispositions}
                    onChange={handleClaimDispositionsChange}
                    options={[
                      {
                        label: 'Approved',
                        value: 'APPROVED',
                      },
                      {
                        label: 'Declined',
                        value: 'DECLINED',
                      },
                    ]}
                  />
                  <Checkbox.Group
                    className="CaseFiltersContainer--checkboxGroup"
                    value={claimStatus}
                    onChange={handleClaimStatusChange}
                    options={[
                      {
                        label: 'Pending',
                        value: 'PENDING',
                      },
                      {
                        label: 'Canceled',
                        value: 'CANCELLED',
                      },
                      {
                        label: 'Needs More Info',
                        value: 'NEED_MORE_INFO',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col span={12}>
            {/* Guaranteed Status */}
            <FormItem
              label="Guaranteed Status"
              className="CaseFiltersContainer--Guaranteed-Status"
            >
              <Row>
                <Col span={13}>
                  <Checkbox.Group
                    value={guaranteeStatus}
                    onChange={handleGuaranteeStatusChange}
                    options={[
                      {
                        label: 'Approved',
                        value: 'APPROVED',
                      },
                      {
                        label: 'Pending',
                        value: 'PENDING',
                      },
                      {
                        label: 'Declined',
                        value: 'DECLINED',
                      },
                      {
                        label: 'Canceled',
                        value: 'CANCELED',
                      },
                      {
                        label: 'In Review',
                        value: 'IN_REVIEW',
                      },
                    ]}
                  />
                  <div>
                    <Checkbox
                      checked={[
                        'GUARANTEE_FILTER_NOT_REQUESTED',
                        'GUARANTEE_FILTER_BOTH',
                      ].includes(guaranteeRequested)}
                      onChange={handleGuaranteeRequestedChange}
                    >
                      Unrequested
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={
                        guaranteeReviewEscalationLevel &&
                        guaranteeReviewEscalationLevel.min === '1'
                      }
                      onChange={handleEscalatedChange}
                    >
                      Escalated
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={reReviewOnly}
                      onChange={handleReReviewOnlyChange}
                    >
                      Resubmits Only
                    </Checkbox>
                  </div>
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col span={12}>
            {/* Case Flag */}
            <FormItem label="Case Flag:">
              <Row>
                <Col span={8}>
                  <Checkbox.Group
                    value={investigationReviewDispositions}
                    onChange={handleCaseFlag}
                    options={CASE_FLAG_FILTER_OPTIONS}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col span={12}>
            {/* Authorization Status */}
            <FormItem label="Authorization Status:">
              <Row>
                <Col span={9}>
                  <Checkbox.Group
                    value={authorizationGatewayStatuses}
                    onChange={handleAuthStatus}
                    options={AUTH_FILTER_OPTIONS}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Case Type">
              <Row>
                <Col span={8}>
                  <Checkbox.Group
                    value={caseType}
                    onChange={handleCaseTypeChange}
                    options={CASE_TYPE_OPTIONS}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
          <Col span={24}>
            {/* Team Select */}
            <FormItem label="Team">
              <span data-test-id="teamSelectContainer">
                <TeamSelect
                  onSave={handleTeamSelect}
                  selectedIds={teamsSelected.map(Number)}
                  type="multi"
                />
              </span>
            </FormItem>
          </Col>

          <Col span={10}>
            <Button
              className="CaseFiltersContainer--default-filters-btn"
              size="large"
              onClick={handleDefaultFiltersClick}
              data-test-id="defaultFiltersBtn"
            >
              Default Filters
            </Button>
          </Col>

          {/* Apply Filters */}
          <Col span={13} offset={1}>
            <Button
              className="CaseFiltersContainer--apply-filters-btn"
              type="primary"
              size="large"
              onClick={handleApplyFilterClick}
              data-test-id="applyFiltersBtn"
            >
              Apply filters
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default CaseFiltersContainer
