export enum MESSAGE_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
}
export const UNKNOWN = 'Unknown'
export const DEFAULT_DATE_FORMAT = 'M/D/YYYY h:mmA'
export const DEFAULT_TIMEZONE = 'US/Pacific'
export const SIG_KEY_VALUE_PLACEHOLDER = '---'
export const VALUE_PLACEHOLDER = '---'
export const AVS_CODE_DESCRIPTIONS = {
  x: 'Full match',
  y: 'Full match',
  a: 'Street-only match',
  w: 'Zip-only match',
  z: 'Zip-only match',
  n: 'Complete failure',
  u: 'Unavailable',
  r: 'Retry',
  e: 'Invalid data',
  s: 'Not supported',
  d: 'Full match',
  m: 'Full match',
  b: 'Street-only match',
  p: 'Postal-code-only match',
  c: 'Complete failure',
  i: 'Not supported',
  g: 'Not supported',
  f: 'Full match',
  cs1: 'Cybersource unsupported',
  cs2: 'Cybersource unrecognized',
  h: 'Full Match',
  fde4_q: 'FirstData no match',
  fde4_3: 'FirstData zip-only match',
  fde4_4: 'FirstData street address match',
  fde4_5: 'FirstData full-match',
  fde4_6: 'FirstData zip-only match',
  fde4_7: 'FirstData street-only match',
  fde4_8: 'Complete failure',
  unknown: 'unknown',
}
export const CVV_CODE_DESCRIPTIONS = {
  m: 'CVV2 Match',
  n: 'CVV2 Mismatch',
  p: 'CVV2 Not Processed',
  s: 'CVV2 data should be on the card, but was not',
  u: 'Issuer has not certified for CVV2',
  y: 'Invalid CVV1 Track Present',
  empty: 'No CVV2 was entered',
  e: 'CVV2 Error',
  i: 'CVV2 Invalid or Null',
  x: 'CVV2 No response',
  cs1: 'Cybersource code. CVV2 not supported for this processor or card type',
  cs2: 'Cybersource code. Unrecognized result code returned by processor',
  cs3: 'Cybersource code. No result code returned by processor',
}
export const INVESTIGATION_REVIEW_DISPOSITION = {
  FRAUDULENT: 'FRAUDULENT',
  GOOD: 'GOOD',
  UNSET: 'UNSET',
}
export const AGENT_FEEDBACK_TAGS = {
  SIGNIFYD_OBVIOUS_FRAUD: 'SIGNIFYD_OBVIOUS_FRAUD',
}
export const AGENT_FEEDBACK_TAG_LABELS = {
  [AGENT_FEEDBACK_TAGS.SIGNIFYD_OBVIOUS_FRAUD]: 'Obvious Fraud',
}
export const CLAIM_DISPOSITION = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
}
export const CLAIM_STATUS = {
  PENDING: 'PENDING',
  PENDING_DOCS: 'PENDING_DOCS',
  IN_REVIEW: 'IN_REVIEW',
  NEED_MORE_INFO: 'NEED_MORE_INFO',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
  ON_HOLD: 'ON_HOLD',
}
export const GUARANTEE_PLAN_TYPES = {
  DEFAULT: 'DEFAULT',
  LIVE: 'LIVE',
  TEST: 'TEST',
  TRIAL: 'TRIAL',
  UNRECOGNIZED: 'UNRECOGNIZED',
}
export const VALID_DELIVERY_ICONS = {
  DELIVERY: 'DELIVERY',
  COUNTER_PICKUP: 'COUNTER_PICKUP',
  CURBSIDE_PICKUP: 'CURBSIDE_PICKUP',
  LOCKER_PICKUP: 'LOCKER_PICKUP',
  PICKUP: 'PICKUP',
  FEDEX: 'FEDEX',
  USPS: 'USPS',
  UPS: 'UPS',
  DHL: 'DHL',
  SHIPWIRE: 'SHIPWIRE',
}
export const STATIC_NOTES = {
  CASE_RE_REVIEW: 'CASE_RE_REVIEW',
}
export const CUSTOMER_APP_URL =
  process.env.BACKEND_ENV === 'production'
    ? 'https://app.signifyd.com'
    : 'https://app.staging.signifyd.com'

export const DEVTOOLS_URL =
  process.env.BACKEND_ENV === 'production'
    ? 'https://devtools.signifyd.com'
    : 'https://devtools.staging.signifyd.com'
