import CaseReportPage from 'caseReport/containers/CaseReportPage/CaseReportPage'
import { Unauthorized } from '@signifyd/legacy-components'
import { Navigate, Route, Routes } from 'react-router-dom'
import * as React from 'react'
import NotFound from 'core/components/NotFound/NotFound'

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/cases/:id?" element={<CaseReportPage />} />
    <Route path="/404" element={<NotFound />} />
    <Route path="/unauthorized" element={<Unauthorized />} />
    <Route path="/" element={<Navigate to="/cases" />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default AppRoutes
