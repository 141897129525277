import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import PopoverTrigger from 'core/components/PopoverTrigger'
import { Popover } from 'antd'
import { FC } from 'react'
import { PaymentMethod } from 'core/http/cases/OrderHttp.types'
import { BinData } from 'core/http/investigations/CaseSearchHttp.types'
import { extractAndFormatCreditCardNumber } from 'core/utils/formatCardNumber/formatCardNumber'
import './CardNumber.less'

interface Props {
  paymentMethod: PaymentMethod
  binData: BinData
}

const EnrichedCardNumber: FC<Props> = ({ paymentMethod, binData }) => {
  const cardNumber = extractAndFormatCreditCardNumber(paymentMethod)
  const binDataArr = Object.entries(binData).map(([name, value]) => ({
    name,
    value,
  }))

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      content={<SigKeyValueGroup properties={binDataArr} />}
      overlayClassName="CardNumber--content"
      data-test-id="bin-data-popover"
    >
      <PopoverTrigger data-test-id="card-number-text">
        {cardNumber}
      </PopoverTrigger>
    </Popover>
  )
}

export default EnrichedCardNumber
