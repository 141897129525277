import { withProps } from 'recompose'
import { joinClassNames } from '@signifyd/utils'

const withClassName = (sigClassName: string) => {
  return withProps(({ className }) => ({
    className: joinClassNames([sigClassName, className]),
  }))
}

export default withClassName
