import { some, get } from 'lodash'
import type { EntriesByRole } from 'core/http/cases/EntriesHttp.types'

const isEntryDenyListed = (
  entriesByRole: EntriesByRole,
  roleName: string
): boolean => {
  return some(entriesByRole[roleName], (entry) => {
    return get(entry, 'details.isDenyListed', false)
  })
}

export default isEntryDenyListed
