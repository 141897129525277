import { capitalize } from 'lodash'
import SvgIcon from 'core/components/SvgIcon'
import { Text } from '@signifyd/legacy-components'
import cx from 'classnames'
import useStores from 'useStores'
import { observer } from 'mobx-react'
import { FC } from 'react'

import './PolicyReasonContainer.less'

const PolicyReasonContainer: FC<{ className?: string }> = observer(
  (className) => {
    const {
      caseReportStore: { case: investigation, rule },
    } = useStores()

    if (
      !rule?.name ||
      !(
        investigation?.recommendedAction ||
        investigation?.guaranteeRecommendedAction
      )
    ) {
      return null
    }

    const {
      recommendedAction,
      recommendedActionRuleId,
      guaranteeRecommendedAction,
      guaranteeRecommendedActionRuleId,
    } = investigation

    const action = guaranteeRecommendedAction ?? recommendedAction

    return (
      <div
        className={cx([
          'PolicyReasonContainer',
          `PolicyReasonContainer--${action}`,
          className,
        ])}
        data-test-id="policyReasonContainer"
      >
        <div className="PolicyReasonContainer--reason-type">
          <SvgIcon
            size="small"
            type={`POLICY_${action}`}
            className="PolicyReasonContainer--reason-type-icon"
          />
          <h6 className="PolicyReasonContainer--reason-type-text">
            POLICY {action}
          </h6>
        </div>

        <Text>
          Order set to <strong>{capitalize(action)}</strong> due to customer
          policy ID{' '}
          {guaranteeRecommendedActionRuleId ?? recommendedActionRuleId} &quot;
          {rule.name}&quot;.
        </Text>
      </div>
    )
  }
)
export default PolicyReasonContainer
