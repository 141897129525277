import { observable, toJS } from 'mobx'
import { get } from 'lodash'
import type {
  AgentFeedbackTags,
  CaseInfo,
  Membership,
} from 'core/http/investigations/CaseSearchHttp.types'
import type { BaseEntry } from 'core/http/cases/EntriesHttp.types'
import { AUTO_DECISION_REVIEWER_IDS } from 'caseReport/Constants'
import {
  CaseAnalysis,
  CaseEntries,
  CaseOrder,
  InvestigationCounts,
  RuleResponse,
} from '@signifyd/http'
import { ANALYSIS_STATE } from 'core/http/cases/AnalysisHttp.types'

export type EntriesByRole = Record<string, BaseEntry>
export type LoadingStatus = {
  pending: boolean
  success: boolean
  error: boolean | string
  finished: boolean
  namespace: string | null | undefined
}
export type CaseReport = {
  noCaseFound: boolean
  loading: Partial<LoadingStatus>
  case: CaseInfo | null
  analysis: CaseAnalysis | null
  analysisState: ANALYSIS_STATE
  entries: CaseEntries | null
  entriesByRole?: EntriesByRole | null
  counts: InvestigationCounts | null
  order: CaseOrder | null
  claim?: Claim | null
  rule?: RuleResponse | null
  agentFeedbackTagsForm: Array<AgentFeedbackTags>
  memberships: Array<Membership>
}
type Claim = {
  status: string | null | undefined
  disposition: string | null | undefined
  dispositionReason: string | null | undefined
}
const store: CaseReport = observable({
  loading: {
    pending: false,
    success: false,
    error: false,
    finished: false,
    namespace: null,
  },
  noCaseFound: false,
  case: null,
  analysis: null,
  analysisState: ANALYSIS_STATE.ERROR,
  entries: null,
  counts: null,
  order: null,
  agentFeedbackTagsForm: [],
  memberships: [],

  get caseReport() {
    return {
      case: toJS(this.case),
      analysis: toJS(this.analysis),
      entries: toJS(this.entries),
      entriesByRole: toJS(this.entriesByRole),
      counts: toJS(this.counts),
      order: toJS(this.order),
    }
  },

  get claim(): Claim | null | undefined {
    return this.case?.claims?.length > 0 && this.case.claims[0]
  },

  get entriesByRole(): EntriesByRole | null | undefined {
    if (!this.entries) {
      return null
    }

    return Object.keys(this.entries).reduce((result, key) => {
      this.entries[key].forEach((entry) => {
        const { role } = entry

        if (result[role]) {
          result[role].push(entry)
        } else {
          result[role] = [entry]
        }
      })

      return result
    }, {})
  },

  get caseReviewStart() {
    return get(this, 'case.guaranteeReviewedAt', 0)
  },

  get isAutoDecisionReviewerID() {
    return AUTO_DECISION_REVIEWER_IDS.has(
      this.caseReport.case.guaranteeReviewedBy
    )
  },
})
export default store
