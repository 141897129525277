import { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './PopoverTrigger.less'

type Props = {
  className?: string
  onMouseEnter?: (arg0: any) => void
  onMouseLeave?: (arg0: any) => void
  onFocus?: (arg0: any) => void
  onClick?: (arg0: any) => void
}

const PopoverTrigger: FC<Props> = ({ children, className, ...events }) => (
  <span className={joinClassNames(['PopoverTrigger', className])} {...events}>
    {children}
  </span>
)

export default PopoverTrigger
