import { FC } from 'react'
import type { MenuProps } from './CasePageSiderMenu.types'
import CaseQueueMenu from './CaseQueueMenu'
import CaseListMenu from './CaseListMenu'

type Props = MenuProps & {
  showCaseQueue: boolean
}

const CasePageSiderMenu: FC<Props> = ({ showCaseQueue, ...props }) =>
  showCaseQueue ? <CaseQueueMenu {...props} /> : <CaseListMenu {...props} />

export default CasePageSiderMenu
