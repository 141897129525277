import { $Keys } from 'utility-types'

export const LINK_KEYS = {
  GOOGLE_WEB: 'GOOGLE_WEB',
  GOOGLE_MAP: 'GOOGLE_MAP',
  ZILLOW: 'ZILLOW',
  EKATA: 'EKATA',
  _118712: '_118712',
  PAGES_JAUNES: 'PAGES_JAUNES',
  PAGINE_BIANCHE: 'PAGINE_BIANCHE',
  ESPANA_DIRECTORIO: 'ESPANA_DIRECTORIO',
  ABC_TELEFONOS: 'ABC_TELEFONOS',
}
export type LinkKey = $Keys<typeof LINK_KEYS>
export type LinkKeysSubset = Array<LinkKey>
const {
  GOOGLE_WEB,
  GOOGLE_MAP,
  ZILLOW,
  EKATA,
  _118712,
  PAGES_JAUNES,
  PAGINE_BIANCHE,
  ESPANA_DIRECTORIO,
  ABC_TELEFONOS,
} = LINK_KEYS
export const US_ADDRESS_LINKS: LinkKeysSubset = [
  GOOGLE_WEB,
  GOOGLE_MAP,
  ZILLOW,
  EKATA,
]
export const FR_ADDRESS_LINKS: LinkKeysSubset = [
  GOOGLE_WEB,
  GOOGLE_MAP,
  EKATA,
  _118712,
  PAGES_JAUNES,
]
export const IT_ADDRESS_LINKS: LinkKeysSubset = [
  GOOGLE_WEB,
  GOOGLE_MAP,
  EKATA,
  PAGINE_BIANCHE,
]
export const ES_ADDRESS_LINKS: LinkKeysSubset = [
  GOOGLE_WEB,
  GOOGLE_MAP,
  EKATA,
  ESPANA_DIRECTORIO,
  ABC_TELEFONOS,
]
export const AU_ADDRESS_LINKS: LinkKeysSubset = [GOOGLE_WEB, GOOGLE_MAP, EKATA]
export const CA_ADDRESS_LINKS: LinkKeysSubset = US_ADDRESS_LINKS
export const DEFAULT_ADDRESS_LINKS: LinkKeysSubset = [
  GOOGLE_WEB,
  GOOGLE_MAP,
  EKATA,
]
