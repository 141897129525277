import React from 'react'
import SigCard from 'core/components/SigCard'
import SigKeyValue from 'core/components/SigKeyValue'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import CollapseButton from 'core/components/CollapseButton'
import './PeopleEntry.less'
import type { People } from 'core/http/cases/EntriesHttp.types'
import type { DeeplinkProps } from 'core/components/Deeplink'
import { getTaxLinks } from './utils/getLinks'

type PeopleWithDeeplinks = People & {
  deeplinks: Array<DeeplinkProps>
}
type Props = {
  people: Array<PeopleWithDeeplinks>
  collapseButtonCount: number
  collapsed: boolean
  handleToggleCollapse: () => void
  accountHolderTaxId: string | null | undefined
  accountHolderTaxIdCountry: string | null | undefined
}

const PeopleEntry = ({
  people,
  collapseButtonCount,
  collapsed,
  handleToggleCollapse,
  accountHolderTaxId,
  accountHolderTaxIdCountry,
}: Props) => (
  <SigCard inner title="People" className="PeopleEntry">
    {people.map(({ entityName, seeder, role, deeplinks, details }, index) => (
      <div key={index}>
        <SigKeyValue
          startCaseName
          name={role}
          copyValue={entityName}
          seeder={seeder}
          alignValueToRight
          value={
            <div className="PeopleEntry--value">
              <DeeplinkPopover deeplinks={deeplinks}>
                {entityName}
              </DeeplinkPopover>
              {details.ageRange && (
                <div className="PeopleEntry--details">
                  <span className="PeopleEntry--details-label">Age: </span>
                  {details.ageRange}
                </div>
              )}
              {details.phoneNumber && (
                <div className="PeopleEntry--details">
                  <span className="PeopleEntry--details-label">Phone: </span>
                  {details.phoneNumber}
                </div>
              )}
            </div>
          }
        />
      </div>
    ))}
    {accountHolderTaxId && (
      <div>
        <SigKeyValue
          seeder
          startCaseName
          alignValueToRight
          name="Card Holder Tax ID"
          copyValue={accountHolderTaxId}
          value={
            <DeeplinkPopover deeplinks={getTaxLinks}>
              <div className="PeopleEntry--value">{accountHolderTaxId}</div>
            </DeeplinkPopover>
          }
        />
      </div>
    )}
    {accountHolderTaxIdCountry && (
      <div>
        <SigKeyValue
          seeder
          startCaseName
          alignValueToRight
          name="Card Holder Tax ID Country"
          copyValue={accountHolderTaxIdCountry}
          value={
            <div className="PeopleEntry--value">
              {accountHolderTaxIdCountry}
            </div>
          }
        />
      </div>
    )}

    {collapseButtonCount > 0 && (
      <CollapseButton
        collapsed={collapsed}
        count={collapseButtonCount}
        onClick={handleToggleCollapse}
      />
    )}
  </SigCard>
)

export default PeopleEntry
