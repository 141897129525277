import type { DeeplinkProps } from 'core/components/Deeplink'
import { PHONE_NUM_SEARCH_LABEL } from './constants'

const elenchiTelefoniciPhone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined => {
  if (!phoneNum) {
    return
  }
  const query = `telefono=${phoneNum.replace(/ /g, '+')}`

  return {
    iconType: 'elenchi-telefonici',
    label: PHONE_NUM_SEARCH_LABEL,
    url: `https://www.elenchitelefonici.it/aziende/visualizza_n.asp?${query}`,
  }
}

export default elenchiTelefoniciPhone
