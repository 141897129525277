import { debounce as lodashDebounce } from 'lodash'
import { compose, withHandlers, lifecycle, mapProps } from 'recompose'

type Props = {
  onResize: () => void
  debounce?: number
}
const ResizeListener = compose(
  mapProps(({ onResize, debounce }: Props) => ({
    onResize,
    debounce: debounce || 200,
  })),
  withHandlers({
    handleResize: ({ onResize, debounce }) =>
      lodashDebounce(onResize, debounce),
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.handleResize)
    },

    componentWillUnmount() {
      window.removeEventListener('resize', this.props.handleResize)
    },
  })
)(() => null)
export default ResizeListener
