import { action } from 'mobx'
import { isEmpty } from 'lodash/fp'
import type {
  Store,
  SelectValue,
  TextValue,
  CommentsValue,
  ChecklistKey,
} from './orderReviewChecklist.store'

const actions = (store: Store) => {
  const setValueByType = (type: 'selectValues' | 'textValues') => {
    return action((key: ChecklistKey, value: SelectValue | TextValue) => {
      const values = store[type]

      if (isEmpty(value)) {
        delete store[type][key]
        store[type] = { ...values }

        return
      }

      store[type] = { ...values, [key]: value }
    })
  }

  const setComments = action((value: CommentsValue) => {
    store.comments = value
  })
  const reset = action(() => {
    store.selectValues = {}
    store.textValues = {}
    store.comments = ''
  })

  return {
    setSelectValue: setValueByType('selectValues'),
    setTextValue: setValueByType('textValues'),
    setComments,
    reset,
  }
}

export default actions
