/* eslint-disable no-return-assign */

import { action } from 'mobx'
import { forEach, isEmpty, isNil } from 'lodash'
import moment from 'moment-timezone'
import { parseColFilterRange } from 'core/utils/queryParams'
import { getDefaultFilters } from './caseFilters.store'

const caseFilterActions = (store) => {
  const SET = action('caseFilterActions', (key, value) => {
    if (key === 'caseDateRange') {
      return (store.caseDateRange = {
        min: moment(value.min),
        max: moment(value.max),
      })
    }

    store.filters[key] = value
  })

  const setCaseFiltersStoreFromQueryParams = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // https://developers.google.com/web/updates/2016/01/urlsearchparams
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('searchTerms')) {
      const commaListToArray = searchParams.get('searchTerms').split(',')
      SET('searchTerms', commaListToArray)
    }

    if (searchParams.has('orderBy')) {
      SET('sortBy', searchParams.get('orderBy'))
    }

    if (searchParams.has('ascending')) {
      // cast ascending 'true' | 'fasle' to boolean from string. TODO: move to utils?
      const ascendingValue = JSON.parse(
        searchParams.get('ascending').toLowerCase()
      )
      const parsedAscendingValue = ascendingValue ? 'ASC' : 'DSC'
      SET('sortOrder', parsedAscendingValue)
    }

    if (searchParams.has('status')) {
      SET('caseStatus', searchParams.get('status'))
    }

    if (searchParams.has('claimStatuses')) {
      const commaListToArray = searchParams.get('claimStatuses').split(',')
      SET('claimStatus', commaListToArray)
    }

    if (searchParams.has('claimDispositions')) {
      const commaListToArray = searchParams.get('claimDispositions').split(',')
      SET('claimDispositions', commaListToArray)
    }

    if (searchParams.has('guaranteeDispositions')) {
      const commaListToArray = searchParams
        .get('guaranteeDispositions')
        .split(',')
      SET('guaranteeStatus', commaListToArray)
    }

    if (searchParams.has('investigationReviewDispositions')) {
      const commaListToArray = searchParams
        .get('investigationReviewDispositions')
        .split(',')
      SET('investigationReviewDispositions', commaListToArray)
    }

    if (searchParams.has('authorizationGatewayStatuses')) {
      const commaListToArray = searchParams
        .get('authorizationGatewayStatuses')
        .split(',')
      SET('authorizationGatewayStatuses', commaListToArray)
    }

    if (searchParams.has('guaranteeRequested')) {
      SET('guaranteeRequested', searchParams.get('guaranteeRequested'))
    }

    if (searchParams.has('reReviewOnly')) {
      SET('reReviewOnly', searchParams.get('reReviewOnly') === 'true')
    }

    if (searchParams.has('colFilters[]') || searchParams.has('colFilters')) {
      // MR users may have bookmarks that contain `colFilters=x` vs `colFilters[]=x`
      // check for both but use `colFilters[]=x` from now on as that is what the API accepts
      const colFiltersArray = searchParams.has('colFilters[]')
        ? searchParams.getAll('colFilters[]')
        : searchParams.getAll('colFilters')
      const colFilterNameToStoreKeyMap = {
        signifydScore: {
          field: 'caseScoreRange',
          type: 'range',
        },
        orderTotalAmount: {
          field: 'caseAmountRange',
          type: 'range',
        },
        guaranteeReviewEscalationLevel: {
          field: 'guaranteeReviewEscalationLevel',
          type: 'range',
        },
        riskAssessmentRegion: {
          field: 'riskAssessmentRegion',
          type: 'value',
        },
        isTestInvestigation: {
          field: 'isTestInvestigation',
          type: 'boolean',
        },
      }

      forEach(colFiltersArray, (colFilter) => {
        if (isEmpty(colFilter) || isNil(colFilter)) {
          console.error(
            'Method: parseColFilterRange error - parameter `colFilter` can not be nil or empty'
          )
        } else {
          const [name, value = ''] = colFilter.split('::')
          const { type = '', field = '' } =
            colFilterNameToStoreKeyMap[name] || {}

          switch (type) {
            case 'value':
              field && SET(field, value)
              break

            case 'range': {
              const { min, max } = parseColFilterRange(value)
              field && SET(field, { ...store[field], min, max })
              break
            }

            case 'boolean':
              field && SET(field, value === 'true')
              break

            default:
              console.log('colFilterNameToStoreKeyMap not found')
          }
        }
      })
    }

    if (searchParams.has('teamIds')) {
      SET('teamsSelected', searchParams.get('teamIds')?.split(','))
    }

    if (searchParams.has('wasDecisionedByManualReview')) {
      SET(
        'wasDecisionedByManualReview',
        searchParams.get('wasDecisionedByManualReview')
      )
    }

    if (searchParams.has('isTrialCase')) {
      SET('isTrialCase', searchParams.get('isTrialCase') === 'true')
    }
  }

  const setDefaultFilters = action(() => {
    store.filters = getDefaultFilters()
  })

  return {
    SET,
    setCaseFiltersStoreFromQueryParams,
    setDefaultFilters,
  }
}

export default caseFilterActions
