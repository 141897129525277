import React from 'react'
import { Button } from 'antd'
import './LinkButton.less'
import type { ButtonProps } from 'core/flow-types/antd'
import { joinClassNames } from '@signifyd/utils'

type ButtonType = 'default' | 'danger'
type Props = Partial<ButtonProps> & {
  type?: ButtonType
  children: React.ReactNode
  disabled: boolean
}

const LinkButton = ({ className, type, ...rest }: Props) => (
  <Button
    className={joinClassNames(['LinkButton', className])}
    type={type}
    {...rest}
  />
)

export default LinkButton
