import { observable } from 'mobx'
import type {
  QueueMetricsData,
  QueueConfigData,
} from 'core/http/users/ReviewerQueueHttp.types'

export type ReviewQueueStore = {
  metrics: QueueMetricsData
  config: QueueConfigData
}
const store: ReviewQueueStore = observable({
  metrics: {},
  config: {},
})
export default store
