import { FC } from 'react'
import './NotFound.less'

const NotFound: FC = () => {
  document.title = 'Page not found - Signifyd'

  return (
    <div className="container">
      <h1>Page Not found</h1>
    </div>
  )
}

export default NotFound
