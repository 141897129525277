import React, { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import CaseNotesContainer from 'caseReport/containers/CaseNotesContainer'
import OrderReviewChecklistContainer from 'caseReport/containers/OrderReviewChecklistContainer'
import ResizeListener from 'core/components/ResizeListener'
import CaseNotesCollpase from 'caseReport/components/CaseNotesCollapse'
import SigCard from 'core/components/SigCard'
import NotesContainerSM from './NotesContainerSM'
import NotesContainerMD from './NotesContainerMD'
import NotesContainerLG from './NotesContainerLG'
import './NotesContainer.less'
import { ErrorBoundary } from '@signifyd/legacy-components'

type Props = {
  className?: string
  notesModalVisible: boolean
  notesSiderVisible: boolean
  cannedNotesVisible: boolean
  onHideNotes: () => void
  onHideNotesModal: () => void
  refreshNotesOnResize: () => void
  toggleCannedNotes: (arg0: boolean) => void
  handleCannedNoteSelected: (arg0: string) => void
}

const NotesContainer: FC<Props> = ({
  className,
  notesModalVisible,
  notesSiderVisible,
  cannedNotesVisible,
  onHideNotesModal,
  refreshNotesOnResize,
  toggleCannedNotes,
  handleCannedNoteSelected,
}) => {
  const caseNotes = (
    <div>
      <CaseNotesCollpase
        defaultActiveKey="case_notes"
        panels={[
          {
            key: 'order_review_checklist',
            header: 'Order Review Checklist',
            children: <OrderReviewChecklistContainer />,
          },
        ]}
      />
      <SigCard
        title="Case Notes"
        inner
        className="NotesContainer--case-notes-title"
      >
        <CaseNotesContainer />
      </SigCard>
    </div>
  )

  return (
    <div className={joinClassNames(['NotesContainer', className])}>
      <ErrorBoundary message="Notes failed to load.">
        <ResizeListener onResize={refreshNotesOnResize} />
        {notesSiderVisible && (
          <NotesContainerSM
            notesModalVisible={notesModalVisible}
            cannedNotesVisible={cannedNotesVisible}
            onHideNotesModal={onHideNotesModal}
            onToggleCannedNotes={toggleCannedNotes}
            onCannedNoteSelect={handleCannedNoteSelected}
          />
        )}
        {notesSiderVisible && (
          <NotesContainerMD
            caseNotes={caseNotes}
            cannedNotesVisible={cannedNotesVisible}
            onToggleCannedNotes={toggleCannedNotes}
            onCannedNoteSelect={handleCannedNoteSelected}
          />
        )}
        {notesSiderVisible && (
          <NotesContainerLG
            caseNotes={caseNotes}
            cannedNotesVisible={cannedNotesVisible}
            onToggleCannedNotes={toggleCannedNotes}
            onCannedNoteSelect={handleCannedNoteSelected}
          />
        )}
      </ErrorBoundary>
    </div>
  )
}

export default NotesContainer
