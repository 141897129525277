import { $Keys } from 'utility-types'
import { FC, ReactNode } from 'react'
import { Icon, Tooltip, Button } from 'antd'
import TrainingMask from 'core/components/TrainingMask'
import { joinClassNames } from '@signifyd/utils'
import FormatDate from 'core/components/FormatDate'
import './CaseNote.less'
import NoteFileLink from './NoteFileLink'
import { CaseNote as CaseNoteType } from '@signifyd/http'

export type TimelineItemType = $Keys<typeof COLOR_MAP>
export interface CaseNoteProps {
  type: TimelineItemType
  who: string
  note: CaseNoteType
  message: ReactNode
  fileDisabled: boolean
  deleteClicked: boolean
  onDelete: () => void
  onUndo: () => void
}
const COLOR_MAP = {
  event: {
    iconColor: '#9199ac',
    iconName: 'clock-circle-o',
    className: 'event',
  },
  note: {
    iconColor: 'blue',
    iconName: 'user',
    className: 'note',
  },
  approved: {
    iconColor: 'green',
    iconName: 'check-circle-o',
    className: 'approved',
  },
  declined: {
    iconColor: 'red',
    iconName: 'close-circle-o',
    className: 'declined',
  },
}

const CaseNote: FC<CaseNoteProps> = ({
  type,
  who,
  note,
  message,
  fileDisabled,
  deleteClicked,
  onDelete,
  onUndo,
}) => {
  const { className, iconName } = COLOR_MAP[type]
  const { files } = note

  return (
    <div
      className={joinClassNames([
        'CaseNote--container',
        `CaseNote--container-${className}`,
        fileDisabled && 'CaseNote--container-file-disabled',
      ])}
      data-test-id="caseNoteContainer"
    >
      <div
        className="CaseNote--heading"
        data-test-id="caseNoteHeading"
        title={who}
      >
        <span className="CaseNote--heading-icon">
          <Icon type={iconName} />
        </span>
        {who}
      </div>
      <div className="CaseNote--date" data-test-id="caseNoteDate">
        <FormatDate
          UTCTimestamp={note.createdAt}
          format="MM/DD/YY hh:mm A"
          showTimeZoneAbbr
        />
      </div>
      <div className="CaseNote--message" data-test-id="caseNoteMsg">
        {message}
      </div>
      {!!files.length && (
        <>
          <strong>File(s) attached:</strong>
          {files.map((file) => (
            <NoteFileLink key={file.presignedUrl} file={file} />
          ))}
        </>
      )}
      <TrainingMask>
        <Tooltip placement="left" title="Delete" mouseEnterDelay={1}>
          <Icon
            type="close"
            className={`CaseNote--delete-icon ${className}`}
            onClick={onDelete}
            data-test-id="caseNoteDeleteIcon"
          />
        </Tooltip>
      </TrainingMask>
      {deleteClicked && (
        <div className="CaseNote--undo">
          <span>Note deleted</span>
          <Button
            className="CaseNote--undo-btn"
            type="primary"
            size="small"
            ghost
            onClick={onUndo}
            data-test-id="caseNoteUndoBtn"
          >
            Undo
          </Button>
        </div>
      )}
    </div>
  )
}

export default CaseNote
