import { compose, mapProps, branch, renderNothing } from 'recompose'
import moment from 'moment-timezone'
import CaseTimeout from './CaseTimeout'

type Props = {
  guaranteeReviewDeadline: string | null | undefined
}
export const normalizeDeadline = ({ guaranteeReviewDeadline }: Props): any => {
  const deadlineDate = moment(guaranteeReviewDeadline)
  if (!deadlineDate.isValid()) {
    return null
  }
  const now = moment(new Date())
  const diff = Math.ceil(deadlineDate.diff(now, 'minutes', true))
  const invalidDeadline = diff < 0
  if (invalidDeadline) {
    return null
  }
  const deadlineMins = diff % 60
  const deadlineHours = Math.floor((diff - deadlineMins) / 60)

  return {
    deadlineHours,
    deadlineMins,
    timeUnitHours: deadlineHours > 1 ? 'hours' : 'hour',
    timeUnitMins: deadlineMins > 1 ? 'mins' : 'min',
  }
}
const enhance = compose(
  mapProps(normalizeDeadline),
  branch((props) => !props, renderNothing)
)
export default enhance(CaseTimeout)
