import { FC } from 'react'
import moment from 'moment-timezone'
import type {
  QueueMetricsData,
  QueueConfigData,
} from 'core/http/users/ReviewerQueueHttp.types'
import ReviewQueueMetric from 'caseReport/components/ReviewQueueMetric'
import './QueueMetricsContainer.less'
import { ErrorBoundary } from '@signifyd/legacy-components'

type Props = {
  queueMetrics: QueueMetricsData
  queueConfig: QueueConfigData
}

const QueueMetricsContainer: FC<Props> = ({ queueMetrics, queueConfig }) => {
  const { allQueued, allInReview, allInReviewOverdue } = queueMetrics

  return (
    <section
      className="QueueMetricsContainer"
      data-test-id="queueMetricsContainer"
    >
      <ErrorBoundary message="Queue Metrics failed to load.">
        <h5 className="QueueMetricsContainer--title">Queue Metrics</h5>

        <ReviewQueueMetric
          title="In Queue"
          value={allQueued}
          tooltip="Cases with guarantee status of Pending and have been assigned a review priority score."
        />
        <ReviewQueueMetric title="In Review" value={allInReview} />
        <ReviewQueueMetric
          title="Overdue"
          value={allInReviewOverdue}
          tooltip={`Cases that are In Review and past timeout deadline by ${moment
            .duration(queueConfig.inReviewOverdueAge)
            .hours()} hour.`}
        />
      </ErrorBoundary>
    </section>
  )
}

export default QueueMetricsContainer
