import { FC, useCallback, useEffect } from 'react'
import { Layout } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import NotesContainer from 'caseReport/containers/NotesContainer'
import CaseListContainer from 'caseReport/containers/CaseListContainer'
import CaseListAlertContainer from 'caseReport/containers/CaseListAlertContainer'
import QueueMetricsContainer from 'caseReport/containers/QueueMetricsContainer'
import { CasePageSiderMenu } from 'caseReport/containers/CasePageMenu'
import CaseReportPageCaseDetails from './_CaseDetails'
import './CaseReportPage.less'
import caseReportUIActions from 'caseReport/stores/caseReportUI.actions'
import caseReportActions from 'caseReport/stores/caseReport.actions'
import { useParams } from 'react-router-dom'
import useStores from 'useStores'
import { observer } from 'mobx-react'

const { Header, Sider } = Layout

const CaseReportPage: FC = observer(() => {
  const { caseNotesStore, caseReportUIStore, caseReportStore, appStore } =
    useStores()

  const { case: caseInfo, loading } = caseReportStore
  const { notesCount } = caseNotesStore
  const {
    siderCollapsed,
    notesModalVisible,
    notesSiderVisible,
    cannedNotesVisible,
  } = caseReportUIStore

  const caseLoaded = !loading.pending && !!caseInfo

  const { toggleNotesModal, toggleNotesSider } =
    caseReportUIActions(caseReportUIStore)

  const getCaseReportActions = useCallback(() => {
    return caseReportActions(caseReportStore)
  }, [caseReportStore])

  const { id } = useParams()

  useEffect(() => {
    document.title = `Case ${id || ''} - Signifyd`

    const { setNoCaseFound, getAllCaseDataById } = getCaseReportActions()

    if (!id) {
      setNoCaseFound()
    } else {
      getAllCaseDataById(parseInt(id, 10))
    }
  }, [id, getCaseReportActions])

  const sider = (
    <Sider
      className="CaseReportPage--sider"
      trigger={null}
      collapsible
      collapsed={siderCollapsed}
      width="375"
    >
      <Header className="CaseReportPage--sider-header">
        <CasePageSiderMenu />
      </Header>
      {appStore.isManualReviewer && <QueueMetricsContainer />}
      <CaseListAlertContainer />
      <CaseListContainer />
    </Sider>
  )

  return (
    <Layout
      className={joinClassNames([
        'CaseReportPage',
        siderCollapsed && 'CaseReportPage--sider-collapsed',
        cannedNotesVisible && 'CaseReportPage--canned-notes-visible',
        !notesSiderVisible && 'CaseReportPage--notes-collapsed',
      ])}
      data-test-id="caseReportPage"
    >
      <CaseReportPageCaseDetails
        siderCollapsed={siderCollapsed}
        notesCount={notesCount}
        caseLoaded={caseLoaded}
        case={caseInfo}
        notesModalVisible={notesModalVisible}
        notesSiderVisible={notesSiderVisible}
        cannedNotesVisible={cannedNotesVisible}
        onToggleNotesModal={toggleNotesModal}
        onToggleNotesSider={toggleNotesSider}
      />
      <NotesContainer className="CaseReportPage--notes" />
      {sider}
    </Layout>
  )
})

export default CaseReportPage
