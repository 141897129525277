import { FC } from 'react'
import { Button } from 'antd'
import './GoogleCustomMapBtn.less'

type Props = {
  disabled?: boolean
  onClick?: () => void
}

const GoogleCustomMapBtn: FC<Props> = ({ disabled, onClick }) => (
  <Button
    data-test-id="GoogleCustomMapBtn"
    className="GoogleCustomMapBtn"
    icon="reload"
    disabled={disabled}
    onClick={onClick}
  >
    Show All Markers
  </Button>
)

export default GoogleCustomMapBtn
