import { ReactNode, FC } from 'react'
import { isEmpty } from 'lodash'
import SigCard from 'core/components/SigCard'
import './AnalysisCard.less'
import type { Detail } from 'core/http/cases/AnalysisHttp.types'
import { CaseAnalysisItem } from '@signifyd/http'

interface Props {
  title: string
  analysis?: CaseAnalysisItem
  extra?: ReactNode
}

const detailItems = (details: Array<Omit<Detail, 'score'>>): ReactNode => {
  return details
    .filter((detail) => detail.shortDescription && detail.longDescription)
    .map((detail, i) => (
      <li key={i} className={`AnalysisCard--li ${detail.meaning || ''}`}>
        {detail.shortDescription}
      </li>
    ))
}

const AnalysisCard: FC<Props> = ({ title, analysis, extra }) => (
  <SigCard className="AnalysisCard" title={title} extra={extra} inner>
    {analysis && !isEmpty(analysis.details) ? (
      <ul className="AnalysisCard--ul">{detailItems(analysis.details)}</ul>
    ) : (
      <div>No analysis available.</div>
    )}
  </SigCard>
)

export default AnalysisCard
