import React, { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import './NotesAutoScrollContainer.less'

type Props = {
  className: string | null | undefined
  onMounted: () => void
}

const NotesAutoScrollContainer: FC<Props> = ({
  className,
  children,
  onMounted,
}) => (
  <div
    className={joinClassNames(['NotesAutoScrollContainer', className])}
    ref={onMounted}
  >
    {children}
  </div>
)

export default NotesAutoScrollContainer
