import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'

const telFRLastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName) {
    return
  }
  const query = `q=${lastName}&w=${zipCode || ''}`.replace(/ /g, '+')

  return {
    iconType: 'tel-fr',
    label,
    url: `https://www.tel.fr/pro/search?${query}`,
  }
}

export default telFRLastNameAndZipCode
