import { $Keys } from 'utility-types'
import { observable, toJS } from 'mobx'
import { isEmpty, flow, map, filter } from 'lodash/fp'
// JSON format when posting new notes

/*
{
  "OR_CL": {
    "ck": [
      {
        "h": "CH tied to BA",
        "t": "text",
        "i": ["LN", "Ekata"]
      },
      ...
    ],
    "cm": "comment"
  }
}
*/
type OrderReviewChecklistItem = {
  h: string
  t?: string
  i: Array<string>
}
type OrderReviewChecklistNote = {
  OR_CL: {
    ck: Array<OrderReviewChecklistItem>
    cm?: string
  }
}
export type SelectValue = Array<string>
export type TextValue = string
export type CommentsValue = string
export type ChecklistKey = $Keys<typeof CHECKLIST_ITEMS_BY_KEY>
export type Store = {
  selectValues: Record<ChecklistKey, SelectValue>
  textValues: Record<ChecklistKey, TextValue>
  comments: CommentsValue
  // isEmpty: () => boolean,
  // caseNote: () => OrderReviewChecklistNote,
  // caseNoteJSON: () => string
}

// TODO FET-2001 Replace store with react-query
const store: Store = observable({
  selectValues: {},
  textValues: {},
  comments: '',

  get isEmpty() {
    return (
      isEmpty(this.selectValues) &&
      isEmpty(this.textValues) &&
      isEmpty(this.comments)
    )
  },

  get caseNote() {
    const selectValues = toJS(this.selectValues)
    const textValues = toJS(this.textValues)
    const removeEmptyItems = filter(
      (key) => !isEmpty(selectValues[key]) || !isEmpty(textValues[key])
    )
    const mapValuesToChecklistNote = map((key) => ({
      i: selectValues[key] || [],
      t: textValues[key] || '',
      h: CHECKLIST_ITEMS_BY_KEY[key].heading,
    }))
    const checklistNote = flow([removeEmptyItems, mapValuesToChecklistNote])(
      CHECKLIST_ITEM_KEYS
    )
    const caseNote: OrderReviewChecklistNote = {
      OR_CL: {
        ck: checklistNote,
      },
    }

    if (this.comments) {
      caseNote.OR_CL.cm = this.comments
    }

    return caseNote
  },

  get caseNoteJSON() {
    return JSON.stringify(this.caseNote)
  },
})
export const CHECKLIST_ITEMS_BY_KEY = {
  CH_tied_to_BA: {
    heading: 'CH tied to BA',
    options: ['Ekata', 'No', 'LN', 'TT', 'Other'],
  },
  CH_tied_to_DA: {
    heading: 'CH tied to DA',
    options: ['Ekata', 'No', 'LN', 'TT', 'Other'],
  },
  CH_tied_to_PH: {
    heading: 'CH Tied to PH#',
    options: ['Ekata', 'No', 'LN', 'FB', 'Other', 'VoIP', 'Prepaid'],
  },
  CH_tied_to_email: {
    heading: 'CH Tied to Email',
    options: ['Ekata', 'No', 'LI', 'FB', 'Other', 'Yahoo', 'Twitter', 'Pipl'],
  },
  email_age: {
    heading: 'Email Age',
    options: ['< 1yr', 'No Age', '> 1yr'],
  },
  email_domain: {
    heading: 'Email Domain',
    options: [
      'Tied to BA',
      'Tied to CH',
      'Tied to DA',
      'Tied to DR',
      'New Domain',
      'Bad Domain',
      'Std Domain',
    ],
  },
  email_history: {
    heading: 'Email History',
    options: [
      '~ 30 Days',
      '< 90 Days',
      '> 90 Days',
      'Multiple Names',
      'No',
      'CB',
    ],
  },
  IP_history: {
    heading: 'IP History',
    options: [
      'Consistent',
      'Multiple Names',
      'No',
      'Inconsistent',
      'CB',
      'Proxy/Host',
      'Mobile',
      'Shared IP',
    ],
  },
  other_history: {
    heading: 'Other History',
    options: [
      'Consistent',
      'Multiple Names',
      'No',
      'Inconsistent',
      'CB',
      'Bad DA',
    ],
  },
  AVS_info: {
    heading: 'AVS Info',
    options: [
      'Full Match',
      'No Match',
      'Unavailable',
      'None',
      'Zip Only',
      'Street Only',
      'PayPal',
    ],
  },
  CVV_info: {
    heading: 'CVV Info',
    options: ['Full Match', 'No Match', 'Unavailable'],
  },
}
export const CHECKLIST_ITEM_KEYS = [
  'CH_tied_to_BA',
  'CH_tied_to_DA',
  'CH_tied_to_PH',
  'CH_tied_to_email',
  'email_age',
  'email_domain',
  'email_history',
  'IP_history',
  'other_history',
  'AVS_info',
  'CVV_info',
]
export default store
