import caseReportUIActions from 'caseReport/stores/caseReportUI.actions'
import { FC } from 'react'
import useStores from 'useStores'
import Menu from '../Menu'
import MenuItem from '../MenuItem'

interface Props {
  className?: string
  siderCollapsed?: boolean
}

const CasePageUIMenu: FC<Props> = ({ className, siderCollapsed }) => {
  const { caseReportUIStore } = useStores()
  const { toggleSiderCollapsed } = caseReportUIActions(caseReportUIStore)

  return (
    <Menu
      className={className}
      onClick={toggleSiderCollapsed}
      selectable={false}
    >
      <MenuItem
        key="TOGGLE_SIDER_COLLAPSED"
        iconProps={{
          antIconType: siderCollapsed ? 'menu-unfold' : 'menu-fold',
        }}
        tooltip={siderCollapsed ? 'Show Case List' : 'Hide Case List'}
        tooltipPlacement="bottomLeft"
        mouseEnterDelay={2}
      />
    </Menu>
  )
}

export default CasePageUIMenu
