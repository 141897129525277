import { getCaseSearchUrl } from '@signifyd/legacy-components'
import buildUrl from 'core/utils/buildUrl/buildUrl'
import { mapProps } from 'recompose'
import NetworkEntry from './NetworkEntry'

const enhance = mapProps(({ networkLocations, case: _case }) => {
  const createDeeplinks = (networkLoc) => {
    if (networkLoc.role !== 'purchaseIP') {
      return networkLoc
    }
    const deeplinks = [
      {
        iconType: 'signifyd',
        label: 'Search for other cases',
        url: buildUrl(getCaseSearchUrl(), {
          similarityInvestigationId: _case.investigationId,
          similarityAttributes: 'SIM_IP_ADDRESS',
        }),
      },
    ]

    return { ...networkLoc, deeplinks }
  }

  return {
    networkLocations: networkLocations.map(createDeeplinks),
  }
})
export default enhance(NetworkEntry)
