import React, { FC } from 'react'
import CaseAnalysisContainer from 'caseReport/containers/CaseAnalysisContainer'
import CaseSummaryContainer from 'caseReport/containers/CaseSummaryContainer'
import ClaimStatus from 'caseReport/components/ClaimStatus'
import { joinClassNames } from '@signifyd/utils'
import Spinner from 'core/components/Spinner'
import TrainingMask from 'core/components/TrainingMask'
import { LOADING_NAMESPACE } from 'caseReport/stores/caseReport.actions'
import './CaseReportContainer.less'
import type {
  CaseReport,
  LoadingStatus,
} from 'caseReport/stores/caseReport.store'
import CaseReportError from './CaseReportError'
import CaseReportHeadline from './CaseReportHeadline'
import CaseDetailsContainer from '../CaseDetailsContainer/CaseDetailsContainer'
import { ErrorBoundary } from '@signifyd/legacy-components'

type Props = {
  caseReport: CaseReport
  className?: string
  loading: LoadingStatus
  noCaseFound: boolean
  userCanAccessJSONViewer: boolean
}

const CaseReportContainer: FC<Props> = ({
  caseReport,
  loading,
  noCaseFound,
  className,
  userCanAccessJSONViewer,
}) => {
  if (!caseReport.case && noCaseFound) {
    return (
      <CaseReportError
        type="info"
        message="Select a case"
        description="Please select a case from the left or enter a case ID in the URL."
      />
    )
  }

  if (
    loading.error &&
    loading.namespace === LOADING_NAMESPACE.GET_ALL_CASE_DATA_BY_ID
  ) {
    return <CaseReportError description={loading.error} />
  }

  if (
    loading.pending &&
    loading.namespace === LOADING_NAMESPACE.GET_ALL_CASE_DATA_BY_ID
  ) {
    return <Spinner noBackground />
  }

  return (
    <ErrorBoundary message="Case Report Details failed to load.">
      <div
        className={joinClassNames(['CaseReportContainer', className])}
        data-test-id="caseReportMainContainer"
      >
        <TrainingMask>
          <ClaimStatus />
        </TrainingMask>
        <div
          className="CaseReportContainer--card first"
          data-test-id="caseReportHeadlineContainer"
        >
          <CaseReportHeadline
            case={caseReport.case}
            userCanAccessJSONViewer={userCanAccessJSONViewer}
          />
        </div>
        <div
          className="CaseReportContainer--card"
          data-test-id="caseReportAnalysisContainer"
        >
          <CaseAnalysisContainer />
        </div>
        <div
          className="CaseReportContainer--card"
          data-test-id="caseReportSummaryContainer"
        >
          <CaseSummaryContainer
            case={caseReport.case}
            order={caseReport.order}
            entries={caseReport.entries}
          />
        </div>
        <div
          className="CaseReportContainer--card"
          data-test-id="caseReportDetailsContainer"
        >
          <CaseDetailsContainer />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default CaseReportContainer
