import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  getTeamsByNameOrId,
  SelectModalEntity,
} from '@signifyd/legacy-components'
import { message } from 'antd'

const useGetTeamSearch = (
  filterVal: string
): UseQueryResult<Array<SelectModalEntity>> => {
  return useQuery(['filterTeams', filterVal], async () => {
    if (filterVal.length <= 1) {
      return []
    }

    try {
      return getTeamsByNameOrId(filterVal)
    } catch (e) {
      message.error('Error searching for teams - clear filter and try again.')

      return []
    }
  })
}

export default useGetTeamSearch
