import { FC } from 'react'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import SigKeyValue from 'core/components/SigKeyValue'
import SigCard from 'core/components/SigCard'
import { SellerDetails, SellerRecord } from './types'
import DataUnavailable from 'core/components/DataUnavailable/DataUnavailable'
import { capitalize } from 'lodash'

interface Props {
  seller: SellerDetails | SellerRecord
  title: string
}

const SellerEntry: FC<Props> = ({ seller, title }) => {
  const validFields = Object.entries(seller).filter(([, field]) => field.value)

  return (
    <SigCard title={title} inner data-test-id={`seller-entry-${title}`}>
      {validFields.length === 0 && (
        <DataUnavailable dataTitle={capitalize(title.toLowerCase())} />
      )}
      {validFields.map(([fieldName, field]) => (
        <SigKeyValue
          key={fieldName}
          name={fieldName}
          startCaseName
          data-test-id={`seller-entry-key-${fieldName}`}
          value={
            field.links.length ? (
              <DeeplinkPopover deeplinks={field.links}>
                {field.value}
              </DeeplinkPopover>
            ) : (
              field.value
            )
          }
          copyValue={field.value}
          seeder
          alignValueToRight
        />
      ))}
    </SigCard>
  )
}

export default SellerEntry
