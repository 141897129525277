import type { LastNameAndZipCodeLabel } from './constants'
import type { DeeplinkProps } from 'core/components/Deeplink'
import { PHONE_NUM_SEARCH_LABEL } from './constants'

export const _118000LastNameAndZipCode = (
  lastName: string | null | undefined,
  zipCode: string | null | undefined,
  label: LastNameAndZipCodeLabel
): DeeplinkProps | null | undefined => {
  if (!lastName && !zipCode) {
    return
  }
  const query = `label=${zipCode || ''}&who=${lastName || ''}`

  return {
    iconType: '_118000',
    label,
    url: `https://www.118000.fr/search?${query}`,
  }
}
export const _118000Phone = (
  phoneNum: string | null | undefined
): DeeplinkProps | null | undefined =>
  phoneNum
    ? {
        iconType: '_118000',
        label: PHONE_NUM_SEARCH_LABEL,
        url: `https://www.118000.fr/search?who=${phoneNum.replace(/ /g, '+')}`,
      }
    : null
