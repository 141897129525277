import { FC } from 'react'
import SigCard from 'core/components/SigCard'
import SigKeyValueGroup from 'core/components/SigKeyValueGroup'
import formatCurrencyNumber from 'core/utils/formatCurrencyNumber'
import type { CaseInfo } from 'core/http/investigations/CaseSearchHttp.types'

type Props = {
  case: CaseInfo
}

const DiscountSummary: FC<Props> = ({ case: _case }) => (
  <SigCard title="Discounts" inner>
    <SigKeyValueGroup
      placeholder="No discount information available"
      properties={_case.discountCodes.map(({ amount, code, percentage }) => {
        return {
          name: code,
          value: percentage
            ? `${percentage * 100}%`
            : `${_case.currency || 'USD'} ${formatCurrencyNumber(amount || 0)}`,
        }
      })}
    />
  </SigCard>
)

export default DiscountSummary
