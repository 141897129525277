import { FC } from 'react'
import SigKeyValue from 'core/components/SigKeyValue'
import SigCard from 'core/components/SigCard'
import { Membership } from 'core/http/investigations/CaseSearchHttp.types'
import DeeplinkPopover from 'core/components/DeeplinkPopover'
import { MembershipDetails } from './utils'

interface Props {
  membership: MembershipDetails
}

const MembershipEntry: FC<Props> = ({ membership }) => {
  const membershipFields = Object.entries(membership)

  const displayNameMap: Record<keyof Membership, string> = {
    membershipId: 'Membership ID',
    membershipName: 'Membership name',
    phoneNumber: 'Membership phone number',
    emailAddress: 'Membership email address',
  }

  return (
    <SigCard
      title="Membership Information"
      inner
      data-test-id={`membershipEntry-${membership.membershipId}`}
    >
      {membershipFields.map(([fieldName, fieldValue]) => (
        <SigKeyValue
          key={fieldName}
          name={displayNameMap[fieldName as keyof Membership]}
          startCaseName={false}
          data-test-id={`membershipEntryKey-${fieldName}`}
          value={
            fieldValue.links.length ? (
              <DeeplinkPopover deeplinks={fieldValue.links}>
                {fieldValue.value}
              </DeeplinkPopover>
            ) : (
              fieldValue.value
            )
          }
          copyValue={fieldValue.value}
          seeder
          alignValueToRight
        />
      ))}
    </SigCard>
  )
}

export default MembershipEntry
