import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { compose, withProps } from 'recompose'
import { JSONViewerAllowedRolesList } from 'caseReport/Constants'
import CaseReportContainer from './CaseReportContainer'

const enhance = compose(
  inject('caseReportStore', 'appStore'),
  observer,
  withProps(({ caseReportStore, appStore }) => {
    return {
      userCanAccessJSONViewer: JSONViewerAllowedRolesList.some((role) =>
        appStore.userHasRole(role)
      ),
      caseReport: toJS(caseReportStore.caseReport),
      noCaseFound: caseReportStore.noCaseFound,
      loading: caseReportStore.loading,
    }
  })
)
export default enhance(CaseReportContainer)
