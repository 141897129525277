import React, { FC } from 'react'
import { Tooltip } from 'antd'
import ButtonWithTimeout from 'caseReport/components/ButtonWithTimeout'
import { CASE_PAGE_SIDER_TOOLTIPS } from 'caseReport/Constants'
import TrainingMask from 'core/components/TrainingMask'
import CasePageSiderMenuTitle from '../CasePageSiderMenuTitle'
import CasePageSiderMenuIcons from '../CasePageSiderMenuIcons'
import './CaseQueueMenu.less'
import type { MenuProps } from '../CasePageSiderMenu.types'

const CaseQueueMenu: FC<MenuProps> = ({
  loading,
  canToggleCaseQueue,
  handleToggleCaseQueue,
  handleRefresh,
  handleGetNextCasesForMR,
}) => (
  <div className="CaseQueueMenu">
    <CasePageSiderMenuTitle
      enabled={canToggleCaseQueue && !loading}
      onClick={() => canToggleCaseQueue && handleToggleCaseQueue(false)}
      text="My Cases"
      tooltip={CASE_PAGE_SIDER_TOOLTIPS.SWITCH_TO_CASE_LIST}
    />
    <CasePageSiderMenuIcons loading={loading} onRefresh={handleRefresh}>
      <TrainingMask>
        <Tooltip
          title={CASE_PAGE_SIDER_TOOLTIPS.NEXT_CASE}
          placement="bottom"
          mouseEnterDelay={0.5}
        >
          <ButtonWithTimeout
            onClick={handleGetNextCasesForMR}
            text="Next"
            duration={5000}
          />
        </Tooltip>
      </TrainingMask>
    </CasePageSiderMenuIcons>
  </div>
)

export default CaseQueueMenu
