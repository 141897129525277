import { Membership } from 'core/http/investigations/CaseSearchHttp.types'
import { FC, useState } from 'react'
import { Col, Row } from 'antd'
import MembershipEntry from './MembershipEntry'
import ShowMore from 'core/components/ShowMore/ShowMore'
import { processMembership as processMembershipLinks } from './utils'

interface Props {
  memberships: Array<Membership>
}

const DEFAULT_MAX_MEMBERSHIPS_TO_DISPLAY = 4

const MembershipEntries: FC<Props> = ({ memberships }) => {
  const membershipWithLinks = processMembershipLinks(memberships)
  const [showAll, setShowAll] = useState<boolean>(false)

  if (!membershipWithLinks.length) {
    return <></>
  }

  const numberMoreThanMaxItems =
    membershipWithLinks.length - DEFAULT_MAX_MEMBERSHIPS_TO_DISPLAY

  const membershipsToShow = showAll
    ? membershipWithLinks
    : membershipWithLinks.slice(0, DEFAULT_MAX_MEMBERSHIPS_TO_DISPLAY)

  return (
    <Col sm={24} lg={24} data-test-id="membershipEntriesColumn">
      <Row type="flex" gutter={30} data-test-id="membershipEntryRow">
        {membershipsToShow.map((membership) => (
          <Col
            key={membership.membershipId.value}
            sm={24}
            lg={12}
            data-test-id="membershipEntryInfoColumn"
          >
            <MembershipEntry membership={membership} />
          </Col>
        ))}
      </Row>
      {membershipWithLinks.length > DEFAULT_MAX_MEMBERSHIPS_TO_DISPLAY && (
        <ShowMore
          numberMoreThanMaxItems={numberMoreThanMaxItems}
          showAll={showAll}
          setShowAll={setShowAll}
        />
      )}
    </Col>
  )
}

export default MembershipEntries
