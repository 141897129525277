import { Fetch } from '@signifyd/legacy-components'
import { Select } from 'antd'
import { FC } from 'react'
import useGetTeamsById from 'core/queries/useGetTeamsById'
import './styles.less'
import TeamSelectHandler from './TeamSelectHandler'

export type SelectType = 'single' | 'multi'

interface Props {
  selectedIds: Array<number>
  onSave: (selectedIds: Array<string>) => void
  type: SelectType
}

const TeamSelect: FC<Props> = ({ onSave, selectedIds, type }) => (
  <Fetch
    useFetchFunction={useGetTeamsById}
    fetchFunctionArgs={[selectedIds]}
    LoadingComponent={<Select loading disabled className="teamSelect" />}
    renderComponent={(teams) => (
      <TeamSelectHandler
        initialSelectedEntities={teams}
        onSave={onSave}
        type={type}
      />
    )}
  />
)

export default TeamSelect
